import {
    TextField,
    IconButton,
    Typography,
    createStyles,
    Button,
    Dialog,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core';
import { XIcon } from '@blamelesshq/blameless-components';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NEW_BOARD_TYPES } from 'src/constants/dashboard';
import { addBoard } from 'src/actions/dashboard/dashboard-page-actions';
import { omit } from 'lodash';

const messages = defineMessages({
    duplicateBoard: {
        id: 'dashboard.modal.addBoard.duplicateBoard',
        defaultMessage: 'Duplicate Current Board',
        description: 'Reliability Insights clone board modal title'
    },
    createFromExistingBoard: {
        id: 'dashboard.modal.cloneBoard.createFromExistingBoard',
        defaultMessage: 'Create Board from Existing Board',
        description: 'Reliability Insights clone board modal title'
    },
    chooseBoard: {
        id: 'dashboard.modal.cloneBoard.chooseBoard',
        defaultMessage: 'Choose existing board',
        description: 'Reliability Insights clone board modal existing board select label'
    },
    boardName: {
        id: 'dashboard.modal.cloneBoard.boardName',
        defaultMessage: 'Board Name',
        description: 'Reliability Insights add board input label'
    },
    cancel: {
        id: 'dashboard.modal.cloneBoard.cancel',
        defaultMessage: 'cancel',
        description: 'Reliability Insights cancel button label'
    },
    create: {
        id: 'dashboard.modal.cloneBoard.create',
        defaultMessage: 'create',
        description: 'Reliability Insights create button label'
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.colors.ui.grey1,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '456px',
            [theme.breakpoints.up('xs')]: {
                width: '300px'
            },
            [theme.breakpoints.up('sm')]: {
                width: '564px'
            }
        },
        modalContent: {
            flexGrow: 1,
            padding: '16px',
            '& label': {
                color: theme.colors.ui.grey3,
                fontSize: '1.2rem',
                marginBottom: '5px',
                '& > span': {
                    color: theme.colors.semantic.error
                }
            }
        },
        selectField: {
            marginBottom: '16px'
        },
        select: {
            padding: '6px 10px 7px',
            backgroundColor: '#f8fafa'
        },
        modalHeader: {
            background: theme.colors.ui.grey5,
            color: theme.colors.ui.grey1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '56px',
            padding: '0 16px'
        },
        modalFooter: {
            borderTop: `1px solid ${theme.colors.ui.grey5}`,
            flexShrink: 0,
            height: '64px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        closeButton: {
            width: '24px',
            height: '24px',
            '&:hover': {
                background: 'none'
            }
        }
    })
);

interface IProps {
    modal: string;
    onClose: () => void;
}
const CloneBoardModal = ({ modal, onClose }: IProps) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newBoardName, setNewBoardName] = useState('');
    const [targetBoardId, setTargetBoardId] = useState('');
    const boards = useSelector((state: RootState) => state.dashboard.boards);
    const { boardId } = useParams();

    const handleCloseModal = () => {
        onClose();
        setNewBoardName('');
        setTargetBoardId('');
    };
    const handleAddBoard = () => {
        let targetBoard: IDashboard.IBoard | null = null;
        if (modal === NEW_BOARD_TYPES.CLONE_EXISTING) {
            targetBoard = boards.find((board) => board._id === targetBoardId)!;
        } else if (modal === NEW_BOARD_TYPES.CLONE_CURRENT) {
            targetBoard = boards.find((board) => board._id === boardId)!;
        }

        if (targetBoard) {
            let newBoard: IDashboard.IBoard = {
                ...targetBoard
            };
            newBoard.name = newBoardName;
            // @ts-ignore
            newBoard = omit(newBoard, ['created', 'updated', '_id']);
            newBoard.tiles = [...targetBoard.tiles];
            newBoard.tiles.map((tile, index) => {
                newBoard.tiles[index] = omit(tile, ['created', 'updated', '_id']);
                if (newBoard.isReadonly) {
                    tile.layout.isDraggable = true;
                    tile.layout.isResizable = true;
                    tile.layout.static = false;
                }
            });
            if (newBoard.isReadonly) {
                newBoard.isReadonly = false;
            }
            dispatch(addBoard(newBoard));
        }
        onClose();
        setNewBoardName('');
        setTargetBoardId('');
    };

    const getDataCy = () => {
        if (modal === NEW_BOARD_TYPES.CLONE_CURRENT) {
            return 'duplicate-board';
        }
        if (modal === NEW_BOARD_TYPES.CLONE_EXISTING) {
            return 'create-from-existing-board';
        }
        return '';
    };

    const dataCy = getDataCy();

    return (
        <Dialog
            data-cy={dataCy}
            open={modal === NEW_BOARD_TYPES.CLONE_CURRENT || modal === NEW_BOARD_TYPES.CLONE_EXISTING}
            onClose={handleCloseModal}
            classes={{ paper: classes.root }}
        >
            <div className={classes.modalHeader}>
                <Typography variant="h6">
                    {modal === NEW_BOARD_TYPES.CLONE_CURRENT && formatMessage(messages.duplicateBoard)}
                    {modal === NEW_BOARD_TYPES.CLONE_EXISTING && formatMessage(messages.createFromExistingBoard)}
                </Typography>
                <IconButton className={classes.closeButton} onClick={handleCloseModal}>
                    <XIcon />
                </IconButton>
            </div>
            <div className={classes.modalContent}>
                {modal === NEW_BOARD_TYPES.CLONE_EXISTING && (
                    <>
                        <InputLabel required>{formatMessage(messages.chooseBoard)}</InputLabel>
                        <Select
                            data-cy={`${dataCy}-select`}
                            className={classes.selectField}
                            classes={{ select: classes.select }}
                            value={targetBoardId}
                            fullWidth
                            variant="outlined"
                            autoFocus
                            onChange={(event) => setTargetBoardId(event.target.value as string)}
                        >
                            {boards.map((board) => (
                                <MenuItem
                                    key={board._id}
                                    value={board._id}
                                    data-cy={`${dataCy}-menu-item`}
                                    data-value={board._id}
                                >
                                    {board.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                )}
                <InputLabel required>{formatMessage(messages.boardName)}</InputLabel>
                <TextField
                    data-cy={`${dataCy}-input`}
                    onChange={(event) => setNewBoardName(event.target.value)}
                    value={newBoardName}
                    id="name"
                    variant="outlined"
                    fullWidth
                    autoFocus={modal === NEW_BOARD_TYPES.CLONE_CURRENT}
                />
            </div>
            <div className={classes.modalFooter}>
                <Button
                    data-cy={`${dataCy}-cancel-button`}
                    onClick={handleCloseModal}
                    variant="contained"
                    color="secondary"
                >
                    {formatMessage(messages.cancel)}
                </Button>
                <Button
                    data-cy={`${dataCy}-create-button`}
                    disabled={!newBoardName || (modal === NEW_BOARD_TYPES.CLONE_EXISTING && !targetBoardId)}
                    variant="contained"
                    color="primary"
                    onClick={handleAddBoard}
                >
                    {formatMessage(messages.create)}
                </Button>
            </div>
        </Dialog>
    );
};

export default CloneBoardModal;
