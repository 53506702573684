import { ADVANCED_FILTERS } from 'src/constants/incident';

export const getFiltersEmptyState = (): IIncident.IFilters => ({
    [ADVANCED_FILTERS.SEVERITY]: [],
    [ADVANCED_FILTERS.STATUS]: [],
    [ADVANCED_FILTERS.CREATOR]: [],
    [ADVANCED_FILTERS.INCLUDE_TAGS]: {},
    [ADVANCED_FILTERS.EXCLUDE_TAGS]: {},
    [ADVANCED_FILTERS.TYPE]: [],
    [ADVANCED_FILTERS.CREATED]: {
        from: '',
        to: ''
    },
    [ADVANCED_FILTERS.UPDATED]: {
        from: '',
        to: ''
    },
    [ADVANCED_FILTERS.IS_POSTMORTEM_REQUIRED]: []
});
