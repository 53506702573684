// @ts-nocheck
import { ApiClass } from 'src/utils/api-interface';

const envConfig = ShowcaseConfig;

const createApiInterface = (apiConfig) => {
    const api = new ApiClass(apiConfig);

    return {
        get: (...args) => api.get.apply(api, args),
        put: (...args) => api.put.apply(api, args),
        post: (...args) => api.post.apply(api, args),
        head: (...args) => api.head.apply(api, args),
        patch: (...args) => api.patch.apply(api, args),
        delete: (...args) => api.delete.apply(api, args),
        options: (...args) => api.options.apply(api, args),
        request: (...args) => api.request.apply(api, args)
    };
};

export const $api_identity_v2 = createApiInterface(envConfig.api_identity_v2);
export const $api_exporter_v2 = createApiInterface(envConfig.api_exporter_v2);
export const $api_integrations_v2 = createApiInterface(envConfig.api_integrations_v2);
export const $api_ai_v2 = createApiInterface(envConfig.api_ai_v2);
export const $api_workflows_v2 = createApiInterface(envConfig.api_workflows_v2);
export const $api_configuration_v2 = createApiInterface(envConfig.api_configuration_v2);
