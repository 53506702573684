import {
    IRandomDateTime,
    generateRandomIntInRange,
    generateRandomDecimalInRange
} from 'src/components/dashboard/demo-data/utils';

// Utils
const generateGroupedRows = (data: any[], field: string) => {
    return data.reduce((acc, row) => {
        const key = row[field];

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(row);

        return acc;
    }, {});
};

const sortByIncidentCreated = (a: any, b: any) => {
    const aDate = new Date(a['Incident Created']);
    const bDate = new Date(b['Incident Created']);
    return aDate.getTime() - bDate.getTime();
};

const sortByField = (field: string) => (a: any, b: any) => {
    return b[field] - a[field];
};
// End Utils

const generateTimeToIdentifyRows = (dateRows: IRandomDateTime[]) => {
    return new Array(Math.round(dateRows.length / 2)).fill(null).map(() => {
        return {
            'Incident #': generateRandomIntInRange(1, 1000),
            'Incident Created': [...dateRows]
                .splice(
                    generateRandomIntInRange(0, dateRows.length / 2 - 1),
                    generateRandomIntInRange(dateRows.length / 2, dateRows.length - 1)
                )
                .map((date) => `${date.date}`)[0],
            'Time to Identification': generateRandomDecimalInRange(0, 10)
        };
    });
};

const generateTimeToTakeActionRows = (dateRows: IRandomDateTime[]) => {
    return new Array(Math.round(dateRows.length / 2)).fill(null).map(() => {
        const actionTime = generateRandomDecimalInRange(0, 10);
        return {
            'Incident #': generateRandomIntInRange(1, 1000),
            'Incident Created': [...dateRows]
                .splice(
                    generateRandomIntInRange(0, dateRows.length / 2 - 1),
                    generateRandomIntInRange(dateRows.length / 2, dateRows.length - 1)
                )
                .map((date) => `${date.date}`)[0],
            'Time to Action': actionTime,
            'Time to take Action': actionTime
        };
    });
};

const generateTimeToResolveRows = (dateRows: IRandomDateTime[]) => {
    return new Array(Math.round(dateRows.length / 2)).fill(null).map(() => {
        return {
            'Incident #': generateRandomIntInRange(1, 1000),
            'Incident Created': [...dateRows]
                .splice(
                    generateRandomIntInRange(0, dateRows.length / 2 - 1),
                    generateRandomIntInRange(dateRows.length / 2, dateRows.length - 1)
                )
                .map((date) => `${date.date}`)[0],
            'Time to Resolve': generateRandomDecimalInRange(0, 10)
        };
    });
};

const getIncidentTimingsBoardTiles = (dateRows: IRandomDateTime[]) => {
    // ---Time To Identify Rows
    const timeToIdentifyRows = generateTimeToIdentifyRows(dateRows);
    const timeToIdentifyRowsGrouped = generateGroupedRows(timeToIdentifyRows, 'Incident Created');
    const timeToIdentifyGroupedRows = Object.keys(timeToIdentifyRowsGrouped).map((key) => {
        return {
            ['Incident #']: timeToIdentifyRowsGrouped[key].length,
            ['Incident Created']: timeToIdentifyRowsGrouped[key][0]['Incident Created'],
            ['Time to Identification']: timeToIdentifyRowsGrouped[key][0]['Time to Identification']
        };
    });
    const timeToIdentifyDataTile = [...timeToIdentifyGroupedRows].sort(sortByField('Time to Identification'));
    const timeToIdentifyMultiline = [...timeToIdentifyGroupedRows].sort(sortByIncidentCreated);

    // ---Time To Take Action Rows
    const timeToTakeActionRows = generateTimeToTakeActionRows(dateRows);
    const timeToTakeActionRowsGrouped = generateGroupedRows(timeToTakeActionRows, 'Incident Created');
    const timeToTakeActionGroupedRows = Object.keys(timeToTakeActionRowsGrouped).map((key) => {
        return {
            ['Incident #']: timeToTakeActionRowsGrouped[key].length,
            ['Incident Created']: timeToTakeActionRowsGrouped[key][0]['Incident Created'],
            ['Time to Action']: timeToTakeActionRowsGrouped[key][0]['Time to Action'],
            ['Time to take Action']: timeToTakeActionRowsGrouped[key][0]['Time to take Action']
        };
    });
    const timeToTakeActionDataTile = [...timeToTakeActionGroupedRows].sort(sortByField('Time to Action'));
    const timeToTakeActionMultiline = [...timeToTakeActionGroupedRows].sort(sortByIncidentCreated);

    // ---Time To Resolve Rows
    const timeToResolveRows = generateTimeToResolveRows(dateRows);
    const timeToResolveRowsGrouped = generateGroupedRows(timeToResolveRows, 'Incident Created');
    const timeToResolveGroupedRows = Object.keys(timeToResolveRowsGrouped).map((key) => {
        return {
            ['Incident #']: timeToResolveRowsGrouped[key].length,
            ['Incident Created']: timeToResolveRowsGrouped[key][0]['Incident Created'],
            ['Time to Resolve']: timeToResolveRowsGrouped[key][0]['Time to Resolve']
        };
    });
    const timeToResolveDataTile = [...timeToResolveGroupedRows].sort(sortByField('Time to Resolve'));
    const timeToResolveMultiline = [...timeToResolveGroupedRows].sort(sortByIncidentCreated);

    return {
        max_time_to_identify_hour_datatile: {
            headers: ['Incident Created', 'Incident #', 'Time to Identification'],
            dataTypes: ['text', 'number', 'number'],
            rows: timeToIdentifyDataTile
        },
        max_time_to_identify_hour_multiline: {
            headers: ['Incident Created', 'Incident #', 'Time to Identification'],
            dataTypes: ['text', 'number', 'number'],
            rows: timeToIdentifyMultiline
        },
        max_time_to_take_action_hour_datatile: {
            headers: ['Incident Created', 'Incident #', 'Time to take Action'],
            dataTypes: ['text', 'number', 'number'],
            rows: timeToTakeActionDataTile
        },
        max_time_to_take_action_hour_multiline: {
            headers: ['Incident Created', 'Incident #', 'Time to Action'],
            dataTypes: ['text', 'number', 'number'],
            rows: timeToTakeActionMultiline
        },
        max_time_to_resolve_hour_datatile: {
            headers: ['Incident Created', 'Incident #', 'Time to Resolve'],
            dataTypes: ['text', 'number', 'number'],
            rows: timeToResolveDataTile
        },
        max_time_to_resolve_hour_multiline: {
            headers: ['Incident Created', 'Incident #', 'Time to Resolve'],
            dataTypes: ['text', 'number', 'number'],
            rows: timeToResolveMultiline
        }
    };
};

export default getIncidentTimingsBoardTiles;
