import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import { TABS } from 'src/constants/identity-management';

const IdentityManagementPage = lazy(() => import('src/components/identity-management/identity-management-page'));
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));

interface IProps {
    match: Router.IMatch;
}

const IdentityManagementPageRoutes = () => {
    const enableIdentityManagementPage = useSelector(
        (state: RootState) => state.launchDarkly.enableIdentityManagementPage
    );
    return (
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                {!enableIdentityManagementPage && <Route component={ErrorNotFound} />}
                <Redirect exact from="/identity-management" to={`/identity-management/${TABS.GROUPS}`} />
                <Route
                    path="/identity-management/:tab"
                    render={({ match }: IProps) => {
                        const tab = match.params.tab;
                        if (
                            tab !== TABS.GROUPS &&
                            tab !== TABS.USERS &&
                            tab !== TABS.ROLES &&
                            tab !== TABS.KEY_MANAGEMENT
                        ) {
                            return <ErrorNotFound />;
                        }
                        return <IdentityManagementPage match={match} />;
                    }}
                />
            </Switch>
        </Suspense>
    );
};

export default IdentityManagementPageRoutes;
