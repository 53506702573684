import {
    clearFilteredFollowupActions,
    createFollowupAction,
    filterFollowupActions,
    getFollowupActions,
    getFollowupActionsForIncident,
    getFollowupActionsMetadata,
    removeFollowupAction,
    setFollowupActionValues,
    setQuery,
    sseUpdateFollowupActions,
    updateFollowupAction
} from 'src/actions/followup-action/followup-action-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    action: IFollowupAction.IAction | null;
    actions: IFollowupAction.IAction[];
    actionsForIncidentCount: number;
    query: string;
    loadStatus: string;
    updateActionStatus: string;
    createActionStatus: string;
    removeActionStatus: string;
    filteredActions: IFollowupAction.IAction[];
    actionsMetadata: Settings.ISetting[];
    actionsMetadataStatus: string;
    error: string | null;
}

export const defaultState: IDefaultState = {
    action: null,
    actions: [],
    actionsForIncidentCount: 0,
    query: '',
    loadStatus: LoadStatus.EMPTY,
    updateActionStatus: LoadStatus.EMPTY,
    createActionStatus: LoadStatus.EMPTY,
    removeActionStatus: LoadStatus.EMPTY,
    filteredActions: [],
    actionsMetadata: [],
    actionsMetadataStatus: LoadStatus.EMPTY,
    error: null
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case sseUpdateFollowupActions.getType():
                draft.actions = [
                    ...draft.actions.filter((a) => a._id != action.payload.action._id),
                    action.payload.action
                ];
                return;
            case clearFilteredFollowupActions.getType():
                draft.query = '';
                draft.filteredActions = [];
                return;
            case filterFollowupActions.getType():
                draft.filteredActions = draft.actions.filter((a) => {
                    const { description = '', type = '', assignee = '', incident_id = '', summary = '' } = a;
                    const filterText = action.payload.toLowerCase();
                    return (
                        description.toLowerCase().includes(filterText) ||
                        type.toLowerCase().includes(filterText) ||
                        assignee.toLowerCase().includes(filterText) ||
                        summary.toLowerCase().includes(filterText) ||
                        incident_id.toString().toLowerCase().includes(filterText)
                    );
                });
                return;
            case getFollowupActions.request.getType():
                draft.loadStatus = LoadStatus.REQUEST;
                return;
            case getFollowupActions.ok.getType():
                draft.actions = action.payload.response.actions;
                draft.loadStatus = LoadStatus.OK;
                return;
            case getFollowupActions.error.getType():
                draft.loadStatus = LoadStatus.ERROR;
                return;
            case getFollowupActionsForIncident.request.getType():
                draft.loadStatus = LoadStatus.REQUEST;
                return;
            case getFollowupActionsForIncident.ok.getType():
                draft.actions = action.payload.response.actions;
                draft.actionsForIncidentCount = action.payload.response.pagination.count;
                draft.loadStatus = LoadStatus.OK;
                return;
            case getFollowupActionsForIncident.error.getType():
                draft.loadStatus = LoadStatus.ERROR;
                return;
            case setFollowupActionValues.getType(): {
                draft.actions[action.payload.index][action.payload.id] = action.payload.value;
                return;
            }
            case setQuery.getType(): {
                draft.query = action.payload;
                return;
            }
            case createFollowupAction.request.getType():
                draft.createActionStatus = LoadStatus.REQUEST;
                return;
            case createFollowupAction.ok.getType():
                draft.createActionStatus = LoadStatus.OK;
                draft.actionsForIncidentCount += 1;
                // SSE could arrive first
                draft.actions = [
                    ...draft.actions.filter((a) => a._id != action.payload.response.action._id),
                    action.payload.response.action
                ];
                return;
            case createFollowupAction.error.getType():
                draft.createActionStatus = LoadStatus.ERROR;
                draft.error = action.payload.error.response.data;
                return;
            case removeFollowupAction.request.getType():
                draft.removeActionStatus = LoadStatus.REQUEST;
                return;
            case removeFollowupAction.ok.getType():
                draft.removeActionStatus = LoadStatus.OK;
                draft.actions = draft.actions.filter((a) => a._id !== action.payload.response.action._id);
                draft.actionsForIncidentCount -= 1;
                return;
            case removeFollowupAction.error.getType():
                draft.removeActionStatus = LoadStatus.ERROR;
                draft.error = action.payload.response;
                return;
            case updateFollowupAction.request.getType():
                draft.updateActionStatus = LoadStatus.REQUEST;
                return;
            case updateFollowupAction.ok.getType():
                const query = draft.query.toLowerCase();
                draft.action = action.payload.response;
                draft.actions = draft.actions.map((draftAction) => {
                    if (draftAction._id === action.payload.response.action._id) {
                        return action.payload.response.action;
                    }
                    return draftAction;
                });
                draft.filteredActions = draft.actions.filter((draftAction) => {
                    const { description = '', type = '', assignee = '', incident_id = '', summary = '' } = draftAction;
                    return (
                        description.toLowerCase().includes(query) ||
                        type.toLowerCase().includes(query) ||
                        assignee.toLowerCase().includes(query) ||
                        summary.toLowerCase().includes(query) ||
                        incident_id.toString().toLowerCase().includes(query)
                    );
                });
                draft.updateActionStatus = LoadStatus.OK;
                return;
            case updateFollowupAction.error.getType():
                draft.updateActionStatus = LoadStatus.ERROR;
                draft.error = action.payload.response;
                return;
            case getFollowupActionsMetadata.request.getType():
                draft.actionsMetadataStatus = LoadStatus.REQUEST;
                return;
            case getFollowupActionsMetadata.ok.getType():
                const { actionsMetadata } = action.payload.response;
                const { actionFieldsUIMetadata, additionalTicketingSystemData } = actionsMetadata;
                const { requiredFields } = additionalTicketingSystemData;
                draft.actionsMetadataStatus = LoadStatus.OK;
                draft.actionsMetadata = [
                    ...actionFieldsUIMetadata,
                    ...(requiredFields?.map((field) => ({ ...field, isRequired: true })) ?? [])
                ];
                return;
            case getFollowupActionsMetadata.error.getType():
                draft.actionsMetadataStatus = LoadStatus.ERROR;
                draft.error = action.payload.error.response.data;
                return;
        }
    });
}
