export default {
    incident: [
        {
            name: 'Incident',
            parent: '',
            fields: [
                {
                    name: 'incident.number',
                    label: 'Incident #',
                    dataType: 'number',
                    defaultFormat: 'incident_url'
                },
                {
                    name: 'incident.type',
                    label: 'Incident Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.severity',
                    label: 'Incident Severity',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'incident.severity_label',
                    label: 'Severity Label',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.created',
                    label: 'Incident Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.duration_of_customer_impact',
                    label: 'Duration Of Customer Impact',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'incident.end_of_customer_impact',
                    label: 'End Of Customer Impact',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.duration_of_incident_impact',
                    label: 'Duration Of Incident Impact',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'incident.end_of_incident_impact',
                    label: 'End Of Incident Impact',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.mute',
                    label: 'Incident Muted',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'incident.slack_channel_is_private',
                    label: 'Incident Slack Channel Is Private',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.slack_channel_name',
                    label: 'Incident Slack Channel Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.start_of_customer_impact',
                    label: 'Start Of Customer Impact',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.start_of_incident_impact',
                    label: 'Start Of Incident Impact',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.time_to_identification',
                    label: 'Time To Identification',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'incident.time_to_resolution',
                    label: 'Time To Resolution',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'incident.first_resolution_time',
                    label: 'First time incident is resolved',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.last_resolution_time',
                    label: 'Last time incident was resolved',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.time_to_take_action',
                    label: 'Time To Take Action',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'incident.updated',
                    label: 'Incident Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'incident.description',
                    label: 'Incident Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.status',
                    label: 'Incident Status',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.bridge_type',
                    label: 'Bridge Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.bridge_url',
                    label: 'Bridge Url',
                    dataType: 'text',
                    defaultFormat: 'link'
                },
                {
                    name: 'incident.is_postmortem_required',
                    label: 'Is Retrospective Required',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'incident.slack_channel_url',
                    label: 'Incident Slack Channel Url',
                    dataType: 'text',
                    defaultFormat: 'link'
                },
                {
                    name: 'incident.ticket_type',
                    label: 'Incident Ticket Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.ticket_key',
                    label: 'Incident Ticket Key',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident.ticket_url',
                    label: 'Incident Ticket Url',
                    dataType: 'text',
                    defaultFormat: 'link'
                },
                {
                    name: 'incident.team_size',
                    label: 'Team Size',
                    dataType: 'number',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Retrospective',
            parent: '',
            fields: [
                {
                    name: 'postmortem.summary',
                    label: 'Retrospective Summary',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.state',
                    label: 'Retrospective State',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.created',
                    label: 'Retrospective Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.updated',
                    label: 'Retrospective Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.root_cause_why',
                    label: 'Retrospective Root Cause - Why',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.root_cause_because',
                    label: 'Retrospective Root Cause - Because',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.root_cause',
                    label: 'Retrospective Root Cause @Deprecated',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.analysis',
                    label: 'Retrospective Analysis',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem.complete',
                    label: 'Retrospective Complete',
                    dataType: 'boolean',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Tag',
            parent: '',
            fields: [
                {
                    name: 'tag.label',
                    label: 'Tag Label',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'tag_category.name',
                    label: 'Tag Category',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Task',
            parent: '',
            fields: [
                {
                    name: 'task.description',
                    label: 'Task Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'task.completed',
                    label: 'Task Completed',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'task.incident_state',
                    label: 'Task Incident State',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'task.owner_name',
                    label: 'Task Owner Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'task.created',
                    label: 'Task Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'task.updated',
                    label: 'Task Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'task.task_number',
                    label: 'Task Number',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'task.complete',
                    label: 'Task Completed',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'task.owner_type',
                    label: 'Task Owner Type',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Incident Creator',
            parent: 'Incident',
            fields: [
                {
                    name: 'incident_creator.is_bot',
                    label: 'Incident Creator Is Bot',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'incident_creator.name',
                    label: 'Incident Creator Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident_creator.email',
                    label: 'Incident Creator Email',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Action',
            parent: '',
            fields: [
                {
                    name: 'action.created',
                    label: 'Action Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'action.updated',
                    label: 'Action Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'action.assignee',
                    label: 'Action Assignee',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'action.summary',
                    label: 'Action Summary',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'action.description',
                    label: 'Action Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'action.type',
                    label: 'Action Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'action.completed',
                    label: 'Action Completed',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'action.jira_url',
                    label: 'Action Jira Url',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Event',
            parent: '',
            fields: [
                {
                    name: 'event.date',
                    label: 'Event Date',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'event.event_type',
                    label: 'Event Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'event.value',
                    label: 'Event Value',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'event.source_type',
                    label: 'Event Source Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'event.source_link',
                    label: 'Event Source Link',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Incident Role',
            parent: 'Incident',
            fields: [
                {
                    name: 'incident_role.role_name',
                    label: 'Incident Role Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident_role_user.name',
                    label: 'Incident Role User Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident_role_user.email',
                    label: 'Incident Role Email',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Impacted Services',
            parent: 'Incident',
            fields: [
                {
                    name: 'incident_impacted_service.provider',
                    label: 'Impacted Service Provider',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident_impacted_service.service_name',
                    label: 'Impacted Service Name',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Event user',
            parent: 'Event',
            fields: [
                {
                    name: 'event_user.name',
                    label: 'Event User Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'event_user.email',
                    label: 'Event User Email',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Custom Fields',
            parent: 'Retrospective',
            fields: [
                {
                    name: 'postmortem_custom.tab_name',
                    label: 'Custom Tab Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem_custom.custom_key',
                    label: 'Custom Retrospective Field',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem_custom.custom_value',
                    label: 'Custom Retrospective Value',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Roles',
            parent: 'Retrospective',
            fields: [
                {
                    name: 'postmortem_role.role_name',
                    label: 'Retrospective Role Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem_role.user_name',
                    label: 'Retrospective Role User Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'postmortem_role.user_email',
                    label: 'Retrospective Role User Email',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Team',
            parent: 'Incident',
            fields: [
                {
                    name: 'incident_team.name',
                    label: 'Incident Team Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'incident_team.email',
                    label: 'Incident Team Email',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        }
    ],
    identity: [
        {
            name: 'User',
            parent: '',
            fields: [
                {
                    name: 'identity_user.created',
                    label: 'User Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.updated',
                    label: 'User Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.deleted',
                    label: 'User Deleted',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.description',
                    label: 'User Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.name',
                    label: 'User Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.auth_name',
                    label: 'User Auth Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.email',
                    label: 'User Email',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.avatar_32',
                    label: 'Avatar 32',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user.avatar_48',
                    label: 'Avatar 48',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'User Integration',
            parent: '',
            fields: [
                {
                    name: 'identity_user_integration.created',
                    label: 'Created',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.updated',
                    label: 'Updated',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.deleted',
                    label: 'Deleted',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.kvs',
                    label: 'Kvs',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.integration_type',
                    label: 'Integration Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.integration_id',
                    label: 'Integration Id',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.created_date',
                    label: 'Created Date',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_user_integration.updated_date',
                    label: 'Updated Date',
                    dataType: 'datetime',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Group',
            parent: '',
            fields: [
                {
                    name: 'identity_group.created',
                    label: 'Group Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_group.updated',
                    label: 'Group Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_group.deleted',
                    label: 'Group Deleted',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'identity_group.description',
                    label: 'Group Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_group.name',
                    label: 'Group Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_group.email',
                    label: 'Group Email',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_group.org_id',
                    label: 'Group Org Id',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Role',
            parent: '',
            fields: [
                {
                    name: 'identity_role.created',
                    label: 'Role Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_role.updated',
                    label: 'Role Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_role.deleted',
                    label: 'Role Deleted',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'identity_role.description',
                    label: 'Role Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_role.name',
                    label: 'Role Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_role.component',
                    label: 'Role Component',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Rule',
            parent: '',
            fields: [
                {
                    name: 'identity_rule.created',
                    label: 'Rule Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.updated',
                    label: 'Rule Updated',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.deleted',
                    label: 'Rule Deleted',
                    dataType: 'boolean',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.description',
                    label: 'Rule Description',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.name',
                    label: 'Rule Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.resource',
                    label: 'Rule Resource',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.action',
                    label: 'Rule Action',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'identity_rule.params',
                    label: 'Rule Params',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        }
    ],
    audit: [
        {
            name: 'Audit Log',
            parent: '',
            fields: [
                {
                    name: 'audit_log.user_info',
                    label: 'User Info',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'audit_log.name',
                    label: 'User Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'audit_log.source',
                    label: 'Source',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'audit_log.created',
                    label: 'Created',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'audit_log.action',
                    label: 'Action',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'audit_log.resource',
                    label: 'Resource',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'audit_log.device',
                    label: 'Device',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        }
    ],
    comment: [
        {
            name: 'Comments Info',
            parent: '',
            fields: [
                {
                    name: 'comment_info.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment_info.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment_info.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment_info.resource_type',
                    label: 'Resource Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment_info.resource_id',
                    label: 'Resource Id',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment_info.block_id',
                    label: 'Block Id',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Comment',
            parent: '',
            fields: [
                {
                    name: 'comment.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment.type',
                    label: 'Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment.resolved_at',
                    label: 'Resolved At',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'comment.source',
                    label: 'Source',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment.body',
                    label: 'Body',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment.payload',
                    label: 'Payload',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment.updated_at_timestamp',
                    label: 'Updated At Timestamp',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment.deleted_at_timestamp',
                    label: 'Deleted At Timestamp',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'comment.resolved_at_timestamp',
                    label: 'Resolved At Timestamp',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'comment_created_by.name',
                    label: 'Created By Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment_created_by.avatar',
                    label: 'Created By Avatar',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment_resolved_by.name',
                    label: 'Resolved By Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'comment_resolved_by.avatar',
                    label: 'Resolved By Avatar',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Replied Comment',
            parent: 'Comment',
            fields: [
                {
                    name: 'replied_comment.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.type',
                    label: 'Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.resolved_at',
                    label: 'Resolved At',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.source',
                    label: 'Source',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.body',
                    label: 'Body',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.payload',
                    label: 'Payload',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.updated_at_timestamp',
                    label: 'Updated At Timestamp',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.deleted_at_timestamp',
                    label: 'Deleted At Timestamp',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment.resolved_at_timestamp',
                    label: 'Resolved At Timestamp',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment_created_by.name',
                    label: 'Created By Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment_created_by.avatar',
                    label: 'Created By Avatar',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment_resolved_by.name',
                    label: 'Resolved By Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'replied_comment_resolved_by.avatar',
                    label: 'Resolved By Avatar',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        }
    ],
    slo: [
        {
            name: 'SLIO',
            parent: '',
            fields: [
                {
                    name: 'slio.slio_id',
                    label: 'SLIO ID',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'slio.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slio.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slio.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slio.data_source',
                    label: 'Data Source',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.metric_type',
                    label: 'Metric Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.metric_unit',
                    label: 'Metric Unit',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.value_metric',
                    label: 'Value Metric',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.good_metric',
                    label: 'Good Metric',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.valid_metric',
                    label: 'Valid Metric',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.metric_id',
                    label: 'Metric Id',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.aggregation_type',
                    label: 'Aggregation Type',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.aggregation_period',
                    label: 'Aggregation Period',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.ingestion_delay',
                    label: 'Ingestion Delay',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.measurement_source',
                    label: 'Measurement Source',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.objective_value',
                    label: 'Objective Value',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.objective_percentage',
                    label: 'Objective Percentage',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.comparision_operator',
                    label: 'Comparision Operator',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.objective_rational',
                    label: 'Objective Rational',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio.is_null_violation',
                    label: 'Is Null Violation',
                    dataType: 'boolean',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLO Violation',
            parent: '',
            fields: [
                {
                    name: 'slo_violation.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_violation.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_violation.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_violation.duration',
                    label: 'Duration',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'slo_violation.from_time',
                    label: 'From Time',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_violation.to_time',
                    label: 'To Time',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_violation.is_violation',
                    label: 'Is Violation',
                    dataType: 'boolean',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLO Manual Minute',
            parent: '',
            fields: [
                {
                    name: 'slo_manual_minute.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_manual_minute.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_manual_minute.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slo_manual_minute.duration',
                    label: 'Duration',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'slo_manual_minute.comment',
                    label: 'Comment',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slo_manual_minute.violation_seconds_for_this_cycle',
                    label: 'Violation Seconds For This Cycle',
                    dataType: 'number',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLIO Contact',
            parent: 'SLIO',
            fields: [
                {
                    name: 'slio_owner_contact.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slio_owner_contact.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slio_owner_contact.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'slio_owner_contact.name',
                    label: 'Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio_owner_contact.email',
                    label: 'Email',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'slio_owner_contact.role',
                    label: 'Role',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLI Computed',
            parent: '',
            fields: [
                {
                    name: 'sli_computed.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_computed.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_computed.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_computed.from_time',
                    label: 'From Time',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_computed.to_time',
                    label: 'To Time',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_computed.value',
                    label: 'Value',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Service',
            parent: '',
            fields: [
                {
                    name: 'service.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'service.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'service.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'service.name',
                    label: 'Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'service.description',
                    label: 'Description',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'User Journey',
            parent: '',
            fields: [
                {
                    name: 'user_journey.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'user_journey.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'user_journey.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'user_journey.name',
                    label: 'Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'user_journey.rational',
                    label: 'Rational',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Service Contact',
            parent: 'Service',
            fields: [
                {
                    name: 'service_owner_contact.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'service_owner_contact.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'service_owner_contact.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'service_owner_contact.name',
                    label: 'Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'service_owner_contact.email',
                    label: 'Email',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'service_owner_contact.role',
                    label: 'Role',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLI Menu',
            parent: '',
            fields: [
                {
                    name: 'sli_menu.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_menu.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_menu.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLI type',
            parent: 'SLI Menu',
            fields: [
                {
                    name: 'sli_type.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_type.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_type.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_type.name',
                    label: 'Name',
                    dataType: 'text',
                    defaultFormat: ''
                },
                {
                    name: 'sli_type.description',
                    label: 'Description',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'SLI Interaction',
            parent: 'SLI Menu',
            fields: [
                {
                    name: 'sli_interaction.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_interaction.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_interaction.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'sli_interaction.interaction_type',
                    label: 'Interaction Type',
                    dataType: 'text',
                    defaultFormat: ''
                }
            ]
        },
        {
            name: 'Error Budget',
            parent: '',
            fields: [
                {
                    name: 'error_budget.created_at',
                    label: 'Created At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.updated_at',
                    label: 'Updated At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.deleted_at',
                    label: 'Deleted At',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.cummulative_total_violation',
                    label: 'Cummulative Total Violation',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.date',
                    label: 'Date',
                    dataType: 'datetime',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.remaing_budget',
                    label: 'Remaing Budget',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.total_budget',
                    label: 'Total Budget',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.percentage_used',
                    label: 'Percentage Used',
                    dataType: 'number',
                    defaultFormat: ''
                },
                {
                    name: 'error_budget.rolling_day_window',
                    label: 'Rolling Day Window',
                    dataType: 'number',
                    defaultFormat: ''
                }
            ]
        }
    ]
};
