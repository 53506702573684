export const DEFAULT_LIMIT = 500;
export const DEFAULT_PAGE_NUM = 0;
export const DEFAULT_PAGE_ROWS = 100;
export const ENHANCED_TABLE_ROWS: IFollowupAction.IHeaderRow[] = [
    {
        id: 'summary',
        label: 'Title',
        numeric: false
    },
    { id: 'assignee', numeric: false, label: 'Assignee' },
    { id: 'type', numeric: false, label: 'Type' },
    {
        id: 'complete',
        label: 'State',
        numeric: false
    },
    { id: 'ticket', numeric: false, label: 'Ticket' },
    { id: 'icon', numeric: false, label: '' }
];

export enum FILTER_NAME {
    FILTER = 'Filter',
    COMPLETED = 'Completed',
    ASSIGNED = 'Assigned',
    TYPE = 'Type',
    INCIDENT = 'Incident',
    TICKET = 'Ticket'
}

export enum SORT_OPTIONS {
    ASSIGNED = 'assigned',
    TYPE = 'type',
    INCIDENT = 'incident',
    TICKET = 'ticket'
}

export enum SORT_DIRECTION {
    ASC = 'asc',
    DESC = 'desc'
}

export enum BULK_ACTIONS {
    DELETE = 'delete',
    COMPLETE = 'complete',
    REOPEN = 'reopen'
}

export enum DATA_KEY_LABELS {
    COMPLETE = 'Complete',
    COMPLETED = 'Completed',
    SUMMARY = 'Title',
    ASSIGNEE = 'Assignee',
    TYPE = 'Type',
    UPDATED = 'Updated',
    INCIDENT = 'Incident',
    TICKET = 'Ticket'
}

export enum DATA_KEY {
    ID = 'id',
    COMPLETE = 'complete',
    COMPLETED = 'completed',
    SUMMARY = 'summary',
    ASSIGNEE = 'assignee',
    TYPE = 'type',
    UPDATED = 'updated',
    INCIDENT_ID = 'incident_id',
    TICKET = 'ticket'
}

export enum COMPLETE {
    YES = 'Yes',
    NO = 'No'
}

export enum QUERY {
    QUERY = 'query',
    COMPLETED = 'completed',
    ASSIGNEE = 'assignee',
    TYPE = 'type',
    INCIDENT = 'incident',
    TICKET = 'ticket'
}

export const actionColumns = [
    { width: 50, label: DATA_KEY_LABELS.COMPLETE, dataKey: DATA_KEY.COMPLETE },
    { width: 300, label: DATA_KEY_LABELS.SUMMARY, dataKey: DATA_KEY.SUMMARY },
    { width: 50, label: DATA_KEY_LABELS.COMPLETED, dataKey: DATA_KEY.COMPLETED },
    { width: 100, label: DATA_KEY_LABELS.ASSIGNEE, dataKey: DATA_KEY.ASSIGNEE },
    { width: 100, label: DATA_KEY_LABELS.TYPE, dataKey: DATA_KEY.TYPE },
    { width: 150, label: DATA_KEY_LABELS.UPDATED, dataKey: DATA_KEY.UPDATED },
    { width: 50, label: DATA_KEY_LABELS.INCIDENT, dataKey: DATA_KEY.INCIDENT_ID },
    { width: 100, label: DATA_KEY_LABELS.TICKET, dataKey: DATA_KEY.TICKET }
];

export const DEFAULT_HEIGHT = 50;

export enum PROVIDER {
    NONE = 'NONE',
    REFERENCE_TICKET = 'REFERENCE_TICKET',
    JIRA = 'JIRA',
    SERVICE_NOW = 'SERVICE_NOW',
    BLAMELESS = 'BLAMELESS'
}

export const TYPE_OPTIONS = [
    { label: 'Mitigation', value: 'Mitigation' },
    { label: 'Prevention', value: 'Prevention' },
    { label: 'Process', value: 'Process' }
];
