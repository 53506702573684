export const SETTINGS_URL = '/settings';
export const DASHBOARD_URL = '/dashboard/incidents';
export const RELIABILITY_INSIGHTS_URL = '/reliability-insights';
export const INCIDENTS_URL = '/incidents';
export const POSTMORTEM_URL_OLD = '/postmortem';
export const POSTMORTEM_URL = '/retrospective';
export const FOLLOWUP_ACTIONS_URL = '/followup-actions';
export const IDENTITY_MANAGEMENT_URL = '/identity-management';
export const ONBOARDING_URL = '/onboarding';
export const ONBOARDING_STEP_URL = `${ONBOARDING_URL}/step`;
export const RUNBOOKS_URL = '/runbooks';
export const SLO_MANAGE_URL = '/manage';
export const USER_JOURNEY_URL = '/user-journey';
export const USER_JOURNEY_SLO_TAB_URL = '/slo';
export const SERVICE_REGISTRY_URL = '/service-registry';
export const SERVICE_REGISTRY_SLI_URL = '/sli';
export const SERVICE_REGISTRY_SLI_DETAILS_URL = '/details';
export const SERVICE_REGISTRY_SLI_LOGS_URL = '/logs';
export const SERVIICE_REGISTRY_NOTES_URL = '/notes';
export const ERROR_BUDGET_POLICY_URL = '/error-budget-policy';
export const SLO_WIZARD = '/slo-wizard';
export const COMMS_FLOW_URL = '/comms-flow';
export const WORKFLOWS_URL = '/workflows';
export const FLOWS = '/flows';
export const TEMPLATES = '/teamplates';
export const LOGS = '/logs';
export const WIZARD = '/wizard';
export const RECIPIENTS_AND_MESSAGE = '/recipients-message';
export const TRIGGERS = '/triggers';
export const SUMMARY = '/summary';
export const STEPS = '/steps';
export const SLACK_AUTH_FLOW_URL = '/slack-integration';
export const WELCOME_PAGE_URL = '/welcome';
export const DOWNLOAD_URL = '/download';
export const ACTION_URL = '/action';

// Public docs urls
export const HELP_URL = 'https://docs.blameless.com/';
export const HELP_RUNBOOKS_FEATURE_URL = 'features/runbooks';
export const HELP_CREATE_RUNBOOK_URL = `${HELP_URL}${HELP_RUNBOOKS_FEATURE_URL}#creating-a-runbook`;
export const HELP_RUNBOOKS_REQUIRE_CONFIRMATION_URL = `${HELP_URL}${HELP_RUNBOOKS_FEATURE_URL}#requiring-confirmation`;
export const HELP_RUNBOOKS_REQUIRE_FEEDBACK_URL = `${HELP_URL}${HELP_RUNBOOKS_FEATURE_URL}#requiring-feedback`;

const routes: {
    HELP_URL: string;
    SETTINGS_URL: string;
    DASHBOARD_URL: string;
    RELIABILITY_INSIGHTS_URL: string;
    INCIDENTS_URL: string;
    POSTMORTEM_URL: string;
    POSTMORTEM_URL_OLD: string;
    FOLLOWUP_ACTIONS_URL: string;
    IDENTITY_MANAGEMENT_URL: string;
    ONBOARDING_URL: string;
    ONBOARDING_STEP_URL: string;
    RUNBOOKS_URL: string;
    SLO_MANAGE_URL: string;
    USER_JOURNEY_URL: string;
    USER_JOURNEY_SLO_TAB_URL: string;
    SERVICE_REGISTRY_URL: string;
    ERROR_BUDGET_POLICY_URL: string;
    SERVICE_REGISTRY_SLI_URL: string;
    SERVIICE_REGISTRY_NOTES_URL: string;
    SLO_WIZARD: string;
    COMMS_FLOW_URL: string;
    FLOWS: string;
    TEMPLATES: string;
    LOGS: string;
    WIZARD: string;
    RECIPIENTS_AND_MESSAGE: string;
    TRIGGERS: string;
    SUMMARY: string;
    STEPS: string;
    SLACK_AUTH_FLOW_URL: string;
    WELCOME_PAGE_URL: string;
    DOWNLOAD_URL: string;
    ACTION_URL: string;
    WORKFLOWS_URL: string;
} = {
    HELP_URL,
    SETTINGS_URL,
    DASHBOARD_URL,
    RELIABILITY_INSIGHTS_URL,
    INCIDENTS_URL,
    POSTMORTEM_URL,
    POSTMORTEM_URL_OLD,
    FOLLOWUP_ACTIONS_URL,
    IDENTITY_MANAGEMENT_URL,
    ONBOARDING_URL,
    ONBOARDING_STEP_URL,
    RUNBOOKS_URL,
    SLO_MANAGE_URL,
    USER_JOURNEY_URL,
    USER_JOURNEY_SLO_TAB_URL,
    SERVICE_REGISTRY_URL,
    SERVICE_REGISTRY_SLI_URL,
    SERVIICE_REGISTRY_NOTES_URL,
    ERROR_BUDGET_POLICY_URL,
    SLO_WIZARD,
    COMMS_FLOW_URL,
    FLOWS,
    TEMPLATES,
    LOGS,
    WIZARD,
    RECIPIENTS_AND_MESSAGE,
    TRIGGERS,
    SUMMARY,
    STEPS,
    SLACK_AUTH_FLOW_URL,
    WELCOME_PAGE_URL,
    DOWNLOAD_URL,
    ACTION_URL,
    WORKFLOWS_URL
};

export const generateUserJourneySloUrl = (userJourneyId: number, sloId: number) => {
    return `${SLO_MANAGE_URL}${USER_JOURNEY_URL}/${userJourneyId}${USER_JOURNEY_SLO_TAB_URL}/${sloId}`;
};

export default routes;
