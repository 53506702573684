import { generateRandomDatesInRange, filterDatesByRange } from 'src/components/dashboard/demo-data/utils';
import getIncidentBoardTiles from 'src/components/dashboard/demo-data/incident-board-tiles';
import getTagCategoriesBoardTiles from 'src/components/dashboard/demo-data/tag-categories-board-tiles';
import getIncidentTimingsBoardTiles from 'src/components/dashboard/demo-data/incident-timings-board-tiles';
import getHoursInIncidentsBoardTiles from 'src/components/dashboard/demo-data/hours-in-incidents-board-tiles';

const entireRange = generateRandomDatesInRange(['3', 'month', '0', 'day']);

// entireRange is an array of  return a subset of entireRange for the last week
const dateRowsLastWeek = filterDatesByRange(entireRange, 'last week');
const lastWeekTiles = {
    ...getIncidentBoardTiles(dateRowsLastWeek),
    ...getTagCategoriesBoardTiles(dateRowsLastWeek),
    ...getIncidentTimingsBoardTiles(dateRowsLastWeek),
    ...getHoursInIncidentsBoardTiles(dateRowsLastWeek)
};

const dateRowsLastMonth = filterDatesByRange(entireRange, 'last month');
const lastMonthTiles = {
    ...getIncidentBoardTiles(dateRowsLastMonth),
    ...getTagCategoriesBoardTiles(dateRowsLastMonth),
    ...getIncidentTimingsBoardTiles(dateRowsLastMonth),
    ...getHoursInIncidentsBoardTiles(dateRowsLastMonth)
};

const dateRowsLast3Month = filterDatesByRange(entireRange, 'last 3 months');
const last3MonthTiles = {
    ...getIncidentBoardTiles(dateRowsLast3Month),
    ...getTagCategoriesBoardTiles(dateRowsLast3Month),
    ...getIncidentTimingsBoardTiles(dateRowsLast3Month),
    ...getHoursInIncidentsBoardTiles(dateRowsLastMonth)
};
const getDemoTilesByName = (tileName: string, range: string[]) => {
    const [fromValue, fromUnit, toValue, toUnit] = range;
    const rangeLabel = `${fromValue} ${fromUnit} to ${toValue} ${toUnit}`;
    switch (rangeLabel) {
        case '1 week to 0 day':
            return lastWeekTiles[tileName];
        case '1 month to 0 day':
            return lastMonthTiles[tileName];
        case '3 month to 0 day':
            return last3MonthTiles[tileName];
        default:
            break;
    }
};

export default getDemoTilesByName;
