import { Button, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import Warning from '@material-ui/icons/Warning';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'ui.errorDeletedUser.title',
        description: 'Message for deleted user ui title',
        defaultMessage: 'Access Denied'
    },
    deletedUser: {
        id: 'ui.errorDeletedUser.deletedUser',
        description: 'Message for deleted user',
        defaultMessage:
            'This account has been deactivated. Please contact your administrator or sign in with a different account.'
    },
    backToHome: {
        id: 'ui.errorDeletedUser.backToHome',
        description: 'Message for back to home button',
        defaultMessage: 'Back to Home'
    }
});

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            paddingLeft: '90px',
            display: 'flex',
            fontSize: '2.0rem',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            flexDirection: 'column'
        },
        button: {
            marginTop: '20px'
        },
        icon: {
            margin: '4px',
            verticalAlign: 'top',
            color: theme.colors.ui.grey1,
            opacity: 0.2,
            fontSize: '5.2rem'
        },
        link: {
            textDecoration: 'none',
            pointer: 'cursor'
        },
        title: {
            margin: '10px'
        },
        body: {
            width: '25%'
        }
    })
);

const ErrorDeletedUser = ({ logout }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    return (
        <div data-cy="error-deleted-user" className={classes.root}>
            <Warning className={classes.icon} />
            <Typography variant="h2" className={classes.title}>
                {formatMessage(messages.title)}
            </Typography>
            <Typography variant="subtitle1" className={classes.body}>
                {formatMessage(messages.deletedUser)}
            </Typography>
            <Link className={classes.link} to="/">
                <Button className={classes.button} variant="contained" color="primary" onClick={logout}>
                    {formatMessage(messages.backToHome)}
                </Button>
            </Link>
        </div>
    );
};
export default ErrorDeletedUser;
