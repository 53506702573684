import $api from 'src/utils/api-interface';
import { createActionAsync } from 'redux-act-async';

export const getSlackUsers = createActionAsync('GET_USERS_FROM_SLACK', (name: string) =>
    $api
        .post({
            url: '/topics/slack/execute',
            data: {
                items: [
                    {
                        formula: '[slack_user._id]'
                    },
                    {
                        formula: '[slack_user.profile_real_name]',
                        sort: 'ASC',
                        filters: [{ operator: 'ct', values: [name] }]
                    },
                    {
                        formula: '[slack_user.profile_image_24]'
                    },
                    {
                        hidden: true,
                        formula: '[slack_user.is_bot]',
                        filters: [
                            {
                                operator: 'eq',
                                values: ['0']
                            }
                        ]
                    }
                ]
            }
        })
        .then((res) => res.data.result.rows)
        .catch((error) => {
            throw error;
        })
);

export const getSlackUsersById = createActionAsync('GET_USERS_FROM_SLACK_BY_ID', (ids: string[]) =>
    $api
        .post({
            url: '/topics/slack/execute',
            data: {
                items: [
                    {
                        formula: '[slack_user._id]',
                        sort: 'ASC',
                        filters: [
                            {
                                operator: 'in',
                                values: ids.length ? ids : [0]
                            }
                        ]
                    },
                    {
                        formula: '[slack_user.profile_real_name]'
                    },
                    {
                        formula: '[slack_user.profile_image_24]'
                    },
                    {
                        hidden: true,
                        formula: '[slack_user.is_bot]',
                        filters: [
                            {
                                operator: 'eq',
                                values: ['0']
                            }
                        ]
                    }
                ]
            }
        })
        .then((res) => res.data.result.rows)
        .catch((error) => {
            throw error;
        })
);

export const getSlackUserGroups = createActionAsync('GET_SLACK_USER_GROUPS', () =>
    $api
        .get({ url: '/slack-usergroups' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getSlackBots = createActionAsync('GET_SLACK_BOTS', () =>
    $api
        .get({ url: '/slack/bots' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
