import { captureException, configureScope } from '@sentry/react';

export const logSentryError = (error: Error, profile: IAuth.IUserProfile) => {
    if (import.meta.env.PROD) {
        configureScope((scope) => {
            scope.setUser({
                name: profile.name,
                email: profile.email
            });
            scope.setLevel('fatal');
            scope.setTag('hostname', window.location.href);
            if (window?.FS?.getCurrentSessionURL) {
                scope.setExtra('fullstory_session', window.FS.getCurrentSessionURL());
            }
            captureException(error, scope);
        });
    }
};
