import { createActionAsync } from 'redux-act-async';
import $api from 'src/utils/api-interface';

export const createAuditLog = createActionAsync('CREATE_AUDIT_LOG', (action: string, resource: string) =>
    $api
        .post({
            url: '/audit-log',
            data: { action, resource },
            headers: { 'Content-Type': 'application/json;charset=UTF-8' }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
