export const RESOURCE = {
    SETTINGS_PAGE: 'SETTINGS_PAGE',
    INCIDENT_LIST_PAGE: 'INCIDENT_LIST_PAGE',
    POSTMORTEM_LIST_PAGE: 'POSTMORTEM_LIST_PAGE',
    INCIDENT_DETAILS_PAGE: 'INCIDENT_DETAILS_PAGE',
    POSTMORTEM_DETAILS_PAGE: 'POSTMORTEM_DETAILS_PAGE',
    IDENTITY_MANAGEMENT_PAGE: 'IDENTITY_MANAGEMENT_PAGE',
    MAIN_NAV: 'MAIN_NAV'
};

export const ACTION = {
    NAV_LINK_ORGANIZATION: 'NAV_LINK_ORGANIZATION',
    NAV_LINK_USER_PREFERENCES: 'NAV_LINK_USER_PREFERENCES',
    NAV_LINK_WEBHOOKS: 'NAV_LINK_WEBHOOKS',
    NAV_LINK_INCIDENT_TYPES: 'NAV_LINK_INCIDENT_TYPES',
    NAV_LINK_EMAILS_REMINDERS: 'NAV_LINK_EMAILS_AND_REMINDERS',
    NAV_LINK_CUSTOM_TAB: 'NAV_LINK_CUSTOM_TAB',
    NAV_LINK_RETRO_EXPORT: 'NAV_LINK_RETRO_EXPORT',
    NAV_LINK_BOT_SETTINGS: 'NAV_LINK_BOT_SETTINGS',
    NAV_LINK_NOTIFICATIONS: 'NAV_LINK_NOTIFICATIONS',
    NAV_LINK_SECURITY: 'NAV_LINK_SECURITY',
    NAV_LINK_INTEGRATIONS: 'NAV_LINK_INTEGRATIONS',
    NAV_LINK_TAGS: 'NAV_LINK_TAGS',
    NAV_LINK_TOKEN_MANAGEMENT: 'NAV_LINK_TOKEN_MANAGEMENT',
    NAV_LINK_SSO: 'NAV_LINK_SSO',
    NAV_LINK_EMAIL_DOMAINS: 'NAV_LINK_EMAIL_DOMAINS',
    OPEN_ADVANCED_FILTERS: 'OPEN_ADVANCED_FILTERS',
    CLICK_INCIDENT_TILE: 'CLICK_INCIDENT_TILE',
    CLICK_POSTMORTEM_TILE: 'CLICK_POSTMORTEM_TILE',
    IAM_TAB_CHANGE: 'IAM_TAB_CHANGE',
    IAM_SORT_CHANGE: 'IAM_SORT_CHANGE',
    IAM_GROUPS_ASSIGN_USERS: 'IAM_GROUPS_ASSIGN_USERS',
    IAM_GROUPS_ASSIGN_ROLES: 'IAM_GROUPS_ASSIGN_ROLES',
    IAM_GROUPS_EDIT_GROUP: 'IAM_GROUPS_EDIT_GROUP',
    IAM_GROUPS_SHOW_ACCESS_SETTINGS: 'IAM_GROUPS_SHOW_ACCESS_SETTINGS',
    IAM_GROUPS_DELETE_GROUP: 'IAM_GROUPS_DELETE_GROUP',
    IAM_GROUPS_SET_GROUP_AS_DEFAULT: 'IAM_GROUPS_SET_GROUP_AS_DEFAULT',
    IAM_USERS_SHOW_ASSIGNMENTS: 'IAM_USERS_SHOW_ASSIGNMENTS',
    IAM_USERS_EDIT_USER: 'IAM_USERS_EDIT_USER',
    IAM_USERS_DELETE_USER: 'IAM_USERS_DELETE_USER',
    IAM_ROLES_SHOW_ASSOCIATIONS: 'IAM_ROLES_SHOW_ASSOCIATIONS',
    MAIN_NAV_SEARCH: 'MAIN_NAV_SEARCH',
    MAIN_NAV_RELEASE_NOTES: 'MAIN_NAV_RELEASE_NOTES',
    MAIN_NAV_HELP: 'MAIN_NAV_HELP',
    MAIN_NAV_USER: 'MAIN_NAV_USER',
    INCIDENT_EDIT_SUMMARY: 'INCIDENT_EDIT_SUMMARY',
    INCIDENT_EDIT_DESCRIPTION: 'INCIDENT_EDIT_DESCRIPTION',
    INCIDENT_EDIT_START_OF_CUSTOMER_IMPACT: 'INCIDENT_EDIT_START_OF_CUSTOMER_IMPACT',
    INCIDENT_EDIT_END_OF_CUSTOMER_IMPACT: 'INCIDENT_EDIT_END_OF_CUSTOMER_IMPACT',
    INCIDENT_EDIT_START_OF_INCIDENT_IMPACT: 'INCIDENT_EDIT_START_OF_INCIDENT_IMPACT',
    INCIDENT_EDIT_END_OF_INCIDENT_IMPACT: 'INCIDENT_EDIT_END_OF_INCIDENT_IMPACT',
    INCIDENT_EDIT_TAGS: 'INCIDENT_EDIT_TAGS',
    INCIDENT_EDIT_TEAM: 'INCIDENT_EDIT_TEAM',
    INCIDENT_EDIT_COMMUNICATION: 'INCIDENT_EDIT_COMMUNICATION',
    INCIDENT_EDIT_TASK: 'INCIDENT_EDIT_TASK',
    POSTMORTEM_EDIT_SUMMARY: 'POSTMORTEM_EDIT_SUMMARY',
    POSTMORTEM_EDIT_CUSTOM_QUESTIONS: 'POSTMORTEM_EDIT_CUSTOM_QUESTIONS',
    POSTMORTEM_EDIT_START_OF_CUSTOMER_IMPACT: 'POSTMORTEM_EDIT_START_OF_CUSTOMER_IMPACT',
    POSTMORTEM_EDIT_END_OF_CUSTOMER_IMPACT: 'POSTMORTEM_EDIT_END_OF_CUSTOMER_IMPACT',
    POSTMORTEM_EDIT_START_OF_INCIDENT_IMPACT: 'POSTMORTEM_EDIT_START_OF_INCIDENT_IMPACT',
    POSTMORTEM_EDIT_END_OF_INCIDENT_IMPACT: 'POSTMORTEM_EDIT_END_OF_INCIDENT_IMPACT',
    POSTMORTEM_EDIT_TAGS: 'POSTMORTEM_EDIT_TAGS',
    POSTMORTEM_EDIT_ACTIONS: 'POSTMORTEM_EDIT_ACTIONS',
    POSTMORTEM_EDIT_ROOT_CAUSE: 'POSTMORTEM_EDIT_ROOT_CAUSE',
    POSTMORTEM_EDIT_CUSTOM_EVENT: 'POSTMORTEM_EDIT_CUSTOM_EVENT',
    POSTMORTEM_EDIT_TEAM: 'POSTMORTEM_EDIT_TEAM',
    POSTMORTEM_EDIT_AUTHORS: 'POSTMORTEM_EDIT_AUTHORS',
    POSTMORTEM_TAB_CHANGE: 'POSTMORTEM_TAB_CHANGE',
    POSTMORTEM_REMOVE_OWNER: 'POSTMORTEM_REMOVE_OWNER',
    POSTMORTEM_REMOVE_REVIEWER: 'POSTMORTEM_REMOVE_REVIEWER',
    INCIDENT_TAB_CHANGE: 'INCIDENT_TAB_CHANGE',
    INCIDENT_SORT_CHANGE: 'INCIDENT_SORT_CHANGE',
    MORE_ACTIONS_BUTTON_CLICK: 'MORE_ACTIONS_BUTTON_CLICK'
};
