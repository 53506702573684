import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { removeSnackbar } from 'src/actions/notification/notification-actions';
import { withSnackbar, ProviderContext } from 'notistack';

interface IProps extends ProviderContext {
    notifications: Array<Record<string, any>>;
}

class Notifier extends Component<IProps> {
    displayed: string[] = [];

    shouldComponentUpdate({ notifications: newSnacks = [] }: IProps) {
        const { notifications: currentSnacks } = this.props;
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i += 1) {
            if (notExists) continue;
            notExists = notExists || !currentSnacks.filter(({ key }) => newSnacks[i].key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [], enqueueSnackbar } = this.props;

        notifications.forEach((notification) => {
            const { message, options, key } = notification;

            if (this.displayed.includes(key)) return;

            enqueueSnackbar(message, options);
            this.storeDisplayed(key);
        });
    }

    storeDisplayed = (id) => {
        this.displayed = [...this.displayed, id];
    };

    render() {
        return null;
    }
}

const mapStateToProps = (store) => ({
    notifications: store.notification.snackbars
});

const mapDispatchToProps = (dispatch) => bindActionCreators({ removeSnackbar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(Notifier));
