import { createAction } from 'redux-act';
import { createActionAsync } from 'redux-act-async';
import $api from 'src/utils/api-interface';
import { DEFAULT_LIMIT, DEFAULT_PAGE_NUM } from 'src/constants/followup-actions';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import {
    setJiraFollowupCreateProject,
    setJiraFollowUpCreateIssueType
} from 'src/actions/json-schema/json-schema-actions';
import { SCHEMA_FIELDS } from 'src/constants/schema-fields';

export const sseUpdateFollowupActions = createAction<string>('SSE_UPDATE_FOLLOWUP_ACTIONS');

export const filterFollowupActions = createAction<string>('FILTER_FOLLOWUP_ACTIONS');
export const clearFilteredFollowupActions = createAction('CLEAR_FILTERED_FOLLOWUP_ACTIONS');

export const setQuery = createAction<string>('SET_QUERY');
export const setFollowupActionValues = createAction('SET_FOLLOWUP_ACTION_VALUES');

export const getFollowupActions = createActionAsync('GET_FOLLOWUP_ACTIONS', () =>
    $api
        .get({
            url: '/actions',
            params: {
                limit: 1000
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getFollowupActionsMetadata = createActionAsync('GET_FOLLOWUP_ACTIONS_METADATA', (incidentId, dispatch) =>
    $api
        .get({
            url: `/incidents/${incidentId}/actionsMetadata`
        })
        .then((res) => {
            const { actionsMetadata } = res.data;
            if (actionsMetadata) {
                const { actionFieldsUIMetadata } = actionsMetadata;
                if (actionFieldsUIMetadata && actionFieldsUIMetadata.length) {
                    const project = actionFieldsUIMetadata.find(
                        (field) => field.name === SCHEMA_FIELDS.FOLLOWUP_JIRA_TICKET_PROJECT
                    );
                    const typeField = actionFieldsUIMetadata.find(
                        (field) => field.name === SCHEMA_FIELDS.FOLLOWUP_JIRA_ISSUE_TYPE
                    );
                    if (project) {
                        dispatch(setJiraFollowupCreateProject(project.currentValue, project.currentDisplayValue));
                    }
                    if (typeField) {
                        dispatch(setJiraFollowUpCreateIssueType(typeField.currentValue));
                    }
                }
            }
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const getFollowupActionsForIncident = createActionAsync(
    'GET_FOLLOWUP_ACTIONS_FOR_INCIDENT',
    (id, pageSize = DEFAULT_LIMIT, pageNum = DEFAULT_PAGE_NUM) =>
        $api
            .get({
                url: `/incidents/${id}/actions`,
                params: {
                    limit: pageSize,
                    offset: pageSize * pageNum
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const createFollowupAction = createActionAsync(
    'CREATE_FOLLOWUP_ACTION',
    (action: IFollowupAction.IAction, incidentId: number, notificationMessages: INotification.IMessages, dispatch) =>
        $api
            .post({
                url: `/incidents/${incidentId}/actions`,
                data: action
            })
            .then((res) => {
                if (res.data.ok) {
                    dispatch(
                        enqueueSnackbar({
                            message: notificationMessages.onSuccess,
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                    return res.data;
                }
            })
            .catch((error) => {
                dispatch(
                    enqueueSnackbar({
                        message: notificationMessages.onError,
                        options: {
                            variant: 'error'
                        }
                    })
                );
                throw error;
            })
);

export const removeFollowupAction = createActionAsync(
    'REMOVE_FOLLOWUP_ACTION',
    (incidentId: number, actionId: number, notificationMessages: INotification.IMessages, dispatch) =>
        $api
            .delete({
                url: `/incidents/${incidentId}/actions/${actionId}`
            })
            .then((res) => {
                if (res.data.ok) {
                    dispatch(
                        enqueueSnackbar({
                            message: notificationMessages.onSuccess,
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                    return res.data;
                }
            })
            .catch((error) => {
                dispatch(
                    enqueueSnackbar({
                        message: notificationMessages.onError,
                        options: {
                            variant: 'error'
                        }
                    })
                );
                throw error;
            })
);

export const updateFollowupAction = createActionAsync(
    'UPDATE_FOLLOWUP_ACTION',
    (id: number, action: IFollowupAction.IAction, notificationMessages: INotification.IMessages, dispatch) => {
        const data = {
            ...action
        };
        return $api
            .put({
                url: `/incidents/${id}/actions/${action._id}`,
                data
            })
            .then((res) => {
                if (res.data.ok) {
                    dispatch(
                        enqueueSnackbar({
                            message: notificationMessages.onSuccess,
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                    return res.data;
                }
            })
            .catch((error) => {
                dispatch(
                    enqueueSnackbar({
                        message: notificationMessages.onError,
                        options: {
                            variant: 'error'
                        }
                    })
                );
                throw error;
            });
    }
);
