import {
    addTagToPostmortem,
    createPostmortem,
    getAvailableStates,
    getCollaborativeEditor,
    getComputedPostmortem,
    getFirstPageOfPostmortems,
    getMorePostmortems,
    getPostmortem,
    getServiceNowImportFields,
    removeTagFromPostmortem,
    setIsCQModal,
    setPostmortemValue,
    setPostmortemValues,
    sseUpdatePostmortem,
    updatePostmortem,
    getCustomTabSetting,
    getExportLinks
} from 'src/actions/postmortem/postmortem-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    incident: IIncident.IIncident | null;
    postmortem: Postmortem.IDetail | null;
    postmortemList: Postmortem.IPostmortemListItem[];
    postmortemListPagination: {
        limit: number;
        offset: number;
        count: number;
    };
    collaborativeEditor: Postmortem.ICollaborativeEditor;
    collaborativeEditorLoadStatus: string;
    postmortemListLoadStatus: string;
    postmortemLoadStatus: string;
    computedPostmortemLoadStatus: string;
    addTagToPostmortemLoadStatus: string;
    removeTagFromPostmortemLoadStatus: string;
    createStatus: string;
    errorMessage: string;
    availableStates: Postmortem.IPostmortemState[];
    servicenowImportFieldsStatus: string;
    servicenowImportFields: { key: string; value: string }[];
    availableStatesLoadStatus: string;
    getMorePostmortemsStatus: string;
    filteredPostmortems: Postmortem.IPostmortemListItem[];
    isCQModal: boolean;
    customTabs: Settings.ICustomTab[];
    getCustomTabSettingLoadStatus: string;
    exportLinksLoadStatus: string;
    exportLinks: Postmortem.IExportLink[];
}

export const defaultState: IDefaultState = {
    incident: null,
    postmortem: null,
    postmortemList: [],
    postmortemListPagination: {
        limit: 20,
        offset: 0,
        count: 0
    },
    collaborativeEditor: {} as Postmortem.ICollaborativeEditor,
    collaborativeEditorLoadStatus: LoadStatus.EMPTY,
    postmortemListLoadStatus: LoadStatus.EMPTY,
    postmortemLoadStatus: LoadStatus.EMPTY,
    computedPostmortemLoadStatus: LoadStatus.EMPTY,
    addTagToPostmortemLoadStatus: LoadStatus.EMPTY,
    removeTagFromPostmortemLoadStatus: LoadStatus.EMPTY,
    createStatus: LoadStatus.EMPTY,
    errorMessage: '',
    availableStates: [],
    servicenowImportFieldsStatus: LoadStatus.EMPTY,
    servicenowImportFields: [],
    availableStatesLoadStatus: LoadStatus.EMPTY,
    getMorePostmortemsStatus: LoadStatus.EMPTY,
    filteredPostmortems: [],
    isCQModal: false,
    customTabs: [],
    getCustomTabSettingLoadStatus: LoadStatus.EMPTY,
    exportLinksLoadStatus: LoadStatus.EMPTY,
    exportLinks: []
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getFirstPageOfPostmortems.request.getType():
                draft.postmortemListLoadStatus = LoadStatus.REQUEST;
                return;
            case getFirstPageOfPostmortems.error.getType():
                draft.postmortemListLoadStatus = LoadStatus.ERROR;
                return;
            case getFirstPageOfPostmortems.ok.getType():
                draft.postmortemListLoadStatus = LoadStatus.OK;
                draft.postmortemList = action.payload.response.postmortems_by_state;
                draft.postmortemListPagination = action.payload.response.pagination;
                return;
            case getMorePostmortems.request.getType():
                draft.getMorePostmortemsStatus = LoadStatus.REQUEST;
                return;
            case getMorePostmortems.ok.getType():
                draft.getMorePostmortemsStatus = LoadStatus.OK;
                draft.postmortemList = state.postmortemList.concat(action.payload.response.postmortems_by_state);
                draft.postmortemListPagination = action.payload.response.pagination;
                return;
            case getMorePostmortems.error.getType():
                draft.getMorePostmortemsStatus = LoadStatus.ERROR;
                return;
            case sseUpdatePostmortem.getType():
                if (draft.postmortem && draft.postmortem._id['$oid'] === action.payload.postmortem._id['$oid']) {
                    draft.postmortem = {
                        ...draft.postmortem,
                        ...action.payload.postmortem
                    };
                    return;
                }
                return;
            case setIsCQModal.getType():
                draft.isCQModal = action.payload;
                return;
            case setPostmortemValue.getType():
                if (draft.postmortem) {
                    draft.postmortem[action.payload.id] = action.payload.value;
                }
                return;
            case setPostmortemValues.getType():
                action.payload.map((update) => {
                    if (draft.postmortem) {
                        draft.postmortem[update.id] = update.value;
                    }
                });
                return;
            case getComputedPostmortem.request.getType():
                draft.computedPostmortemLoadStatus = LoadStatus.REQUEST;
                return;
            case getComputedPostmortem.ok.getType():
                const { postmortemData, incidentData } = action.payload.response;
                const { postmortem } = postmortemData;
                const { incident } = incidentData;
                draft.incident = incident;
                draft.postmortem = postmortem;
                draft.postmortemLoadStatus = LoadStatus.OK;
                draft.computedPostmortemLoadStatus = LoadStatus.OK;
                return;
            case getComputedPostmortem.error.getType():
                draft.computedPostmortemLoadStatus = LoadStatus.ERROR;
                return;
            case createPostmortem.request.getType():
                draft.createStatus = LoadStatus.REQUEST;
                return;
            case createPostmortem.ok.getType():
                draft.postmortem = action.payload.response.postmortem;
                draft.createStatus = LoadStatus.OK;
                return;
            case createPostmortem.error.getType():
                draft.errorMessage = action.payload.error.message;
                draft.createStatus = LoadStatus.ERROR;
                return;
            case updatePostmortem.request.getType():
                draft.createStatus = LoadStatus.REQUEST;
                return;
            case updatePostmortem.ok.getType():
                draft.createStatus = LoadStatus.OK;
                return;
            case updatePostmortem.error.getType():
                if (typeof action.payload.error.response.data.message === 'string') {
                    draft.errorMessage = action.payload.error.response.data.message;
                } else if (typeof action.payload.error.response.data.message === 'object') {
                    draft.errorMessage = action.payload.error.response.data.message.error;
                }
                draft.createStatus = LoadStatus.ERROR;
                return;
            case getPostmortem.request.getType():
                draft.postmortemLoadStatus = LoadStatus.REQUEST;
                return;
            case getPostmortem.ok.getType():
                draft.postmortem = action.payload.response?.postmortem;
                draft.postmortemLoadStatus = LoadStatus.OK;
                return;
            case getPostmortem.error.getType():
                draft.postmortemLoadStatus = LoadStatus.ERROR;
                return;
            case getAvailableStates.request.getType():
                draft.availableStatesLoadStatus = LoadStatus.REQUEST;
                return;
            case getAvailableStates.ok.getType():
                draft.availableStates = action.payload.response.postmortem_states;
                draft.availableStatesLoadStatus = LoadStatus.OK;
                return;
            case getAvailableStates.error.getType():
                draft.availableStatesLoadStatus = LoadStatus.ERROR;
                return;
            case getCollaborativeEditor.request.getType():
                draft.collaborativeEditorLoadStatus = LoadStatus.REQUEST;
                return;
            case getCollaborativeEditor.ok.getType():
                draft.collaborativeEditor = action.payload.response.collaborative_editor;
                draft.collaborativeEditorLoadStatus = LoadStatus.OK;
                return;
            case getServiceNowImportFields.request.getType():
                draft.servicenowImportFieldsStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowImportFields.ok.getType():
                draft.servicenowImportFieldsStatus = LoadStatus.OK;
                const fields = action.payload.response.ticket_fields;
                draft.servicenowImportFields = Object.keys(fields)
                    .sort()
                    .reduce<{ key: string; value: string }[]>((acc, cur) => {
                        const splitVals = cur.split('_');
                        const uppercasedVals = splitVals.map((s) => s.charAt(0).toUpperCase() + s.slice(1));
                        acc.push({
                            key: cur,
                            value: `${uppercasedVals.join(' ')}: ${fields[cur]}`
                        });
                        return acc;
                    }, []);
                return;
            case getServiceNowImportFields.error.getType():
                draft.servicenowImportFieldsStatus = LoadStatus.ERROR;
                return;
            case getCollaborativeEditor.error.getType():
                draft.collaborativeEditorLoadStatus = LoadStatus.ERROR;
                return;
            case addTagToPostmortem.request.getType():
                draft.addTagToPostmortemLoadStatus = LoadStatus.REQUEST;
                return;
            case addTagToPostmortem.ok.getType():
                if (draft.postmortem) {
                    draft.postmortem.tags = action.payload.response.postmortem.tags;
                }
                draft.addTagToPostmortemLoadStatus = LoadStatus.OK;
                return;
            case addTagToPostmortem.error.getType():
                draft.errorMessage = action.payload.error.response.data.message;
                draft.addTagToPostmortemLoadStatus = LoadStatus.ERROR;
                return;
            case removeTagFromPostmortem.request.getType():
                draft.removeTagFromPostmortemLoadStatus = LoadStatus.REQUEST;
                return;
            case removeTagFromPostmortem.ok.getType():
                if (draft.postmortem) {
                    draft.postmortem.tags = action.payload.response.postmortem.tags;
                }
                draft.removeTagFromPostmortemLoadStatus = LoadStatus.OK;
                return;
            case removeTagFromPostmortem.error.getType():
                draft.errorMessage = action.payload.error.response.data.message;
                draft.removeTagFromPostmortemLoadStatus = LoadStatus.ERROR;
                return;

            case getCustomTabSetting.request.getType():
                draft.getCustomTabSettingLoadStatus = LoadStatus.REQUEST;
                return;
            case getCustomTabSetting.ok.getType():
                const { settings } = action.payload.response;
                draft.customTabs = settings ? settings : {};
                draft.getCustomTabSettingLoadStatus = LoadStatus.OK;
                return;
            case getCustomTabSetting.error.getType():
                draft.errorMessage = action.payload.error.response.data.message;
                draft.getCustomTabSettingLoadStatus = LoadStatus.ERROR;
                return;

            case getExportLinks.request.getType():
                draft.exportLinksLoadStatus = LoadStatus.REQUEST;
                return;
            case getExportLinks.ok.getType():
                draft.exportLinksLoadStatus = LoadStatus.OK;
                draft.exportLinks = action.payload.response.exports;
                return;
            case getExportLinks.error.getType():
                draft.errorMessage = action.payload.error.response.statusText;
                draft.exportLinksLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
