// @ts-nocheck
const config = ShowcaseConfig;
import { ApiClass } from 'src/utils/api-interface';

const $b = new ApiClass(config.files);

export default {
    get: (...args) => $b.get.apply($b, args),
    put: (...args) => $b.put.apply($b, args),
    post: (...args) => $b.post.apply($b, args),
    head: (...args) => $b.head.apply($b, args),
    patch: (...args) => $b.patch.apply($b, args),
    delete: (...args) => $b.delete.apply($b, args),
    options: (...args) => $b.options.apply($b, args),
    request: (...args) => $b.request.apply($b, args)
};
