export default [
    {
        _id: 'incidents',
        name: 'Incidents',
        tiles: [
            {
                _id: 'incidents_by_severity',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Incidents by severity',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 0,
                    y: 0,
                    i: '5c78642982edb91242e2523d',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Incident #'], z: ['Severity Label'] },
                    legend: {
                        enabled: true,
                        position: 'left',
                        keys: [
                            { label: 'critical', color: '#DF5A49' },
                            { label: 'high', color: '#E27A3F' },
                            { label: 'low', color: '#EFC94C' },
                            { label: 'medium', color: '#45B29D' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'incidents_by_type',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Incidents by type',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident Type',
                            formula: '[incident.type]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 6,
                    y: 0,
                    i: '5c7864fe82edb91242e2523e',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Incident #'], z: ['Incident Type'] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Blameless-Test', color: '#DF5A49' },
                            { label: 'Cloud', color: '#E27A3F' },
                            { label: 'Default', color: '#EFC94C' },
                            { label: 'Emptytasklist', color: '#45B29D' },
                            { label: 'Security', color: '#334D5C' },
                            { label: 'Test', color: '#F03F35' },
                            { label: 'Test-Hyphen', color: '#FDBA3D' },
                            { label: 'Type1', color: '#310E5F' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag_count_by_severity',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Tag count by severity',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 6,
                    y: 18,
                    i: '5c799ab182edb95481d45abc',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Tag Label'], z: ['Severity Label'] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Critical', color: '#DF5A49' },
                            { label: 'High', color: '#E27A3F' },
                            { label: 'Low', color: '#EFC94C' },
                            { label: 'Medium', color: '#45B29D' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'most_recent_tags',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Most recent tags',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: '!empty', values: [''], beforeAggregate: false }]
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'year_month_number_day_number_time_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 89,
                    i: '5c799c9482edb95481d45abd',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created', 'Tag Category', 'Severity Label', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'most_recent_actions',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Most recent actions',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Action Created',
                            formula: '[action.created]',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'year_month_number_day_number_time_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Action Assignee',
                            formula: '[action.assignee]',
                            orderSequence: 0,
                            grouped: false,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Action Summary',
                            formula: '[action.summary]',
                            orderSequence: 0,
                            grouped: false,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Action Jira Url',
                            formula: '[action.jira_url]',
                            orderSequence: 0,
                            grouped: false,
                            format: 'link',
                            hidden: false,
                            filters: [{ operator: '!empty', values: [''], beforeAggregate: false }]
                        },
                        {
                            header: 'Action Completed',
                            formula: '[action.completed]',
                            orderSequence: 0,
                            grouped: false,
                            format: 'yes_no',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 6,
                    y: 27,
                    i: '5c799e7182edb95481d45abe',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: {
                        x: [
                            'Action Created',
                            'Action Assignee',
                            'Action Summary',
                            'Action Jira Url',
                            'Action Completed'
                        ],
                        y: [],
                        z: []
                    },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'completed_actions_by_assignee',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Completed Actions by assignee',
                description: 'Stacked by incident type',
                query: {
                    items: [
                        {
                            header: 'Assignee',
                            formula: '[action.assignee]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: [{ operator: '!empty', values: [''], beforeAggregate: true }]
                        },
                        {
                            header: 'Completed',
                            formula: '[action.completed]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            format: '',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['1'], beforeAggregate: true }]
                        },
                        {
                            header: 'Created',
                            formula: '[action.created]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident Type',
                            formula: '[incident.type]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 0,
                    y: 53,
                    i: '5c799e8382edb95481d45abf',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Assignee'], y: ['Completed'], z: ['Incident Type'] },
                    legend: {
                        enabled: true,
                        position: 'left',
                        keys: [
                            { label: 'Default', color: '#DF5A49' },
                            { label: 'Security', color: '#E27A3F' },
                            { label: 'Test', color: '#EFC94C' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'most_participation_across_incidents',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Most participation across incidents',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Event User Name',
                            formula: '[event_user.name]',
                            orderSequence: 0,
                            order: 'asc',
                            grouped: true,
                            hidden: false,
                            filters: [{ operator: '!empty', values: [''], beforeAggregate: false }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 6,
                    y: 36,
                    i: '5c79a38782edb95481d45b68',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'bar',
                    orientation: 'vertical',
                    axes: { x: ['Event User Name'], y: ['Incident #'], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [{ label: 'Incident #', color: '#009cd2' }] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'most_active_users_by_event_type',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Most active users by event type',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Event Date',
                            formula: '[event.date]',
                            dataType: 'datetime',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Event Value',
                            formula: '[event.value]',
                            dataType: 'text',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Event Type',
                            formula: '[event.event_type]',
                            orderSequence: 0,
                            dataType: 'text',
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Event User Name',
                            formula: '[event_user.name]',
                            order: 'asc',
                            dataType: 'text',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: [{ operator: '!empty', values: [''], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 6,
                    y: 45,
                    i: '5c79a4d982edb95481d45b93',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Event User Name'], y: ['Event Value'], z: ['Event Type'] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Chat Highlight', color: '#009cd2' },
                            { label: 'File Upload', color: '#fc7753' },
                            { label: 'Image Link', color: '#fed766' },
                            { label: 'Image Upload', color: '#13b479' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'role_assignments',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Role assignments',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Role Name',
                            formula: '[incident_role.role_name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: '!empty', values: [''], beforeAggregate: false }]
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 0,
                    y: 71,
                    i: '5c79aa2582edb95481d45bbe',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Incident #'], z: ['Role Name'] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Commander', color: '#009cd2' },
                            { label: 'Communication Lead', color: '#fc7753' },
                            { label: 'Lead Response Engineer', color: '#fed766' },
                            { label: 'Test Role', color: '#13b479' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'most_recent_open_postmortems',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Most recent open retrospectives',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Retrospective Complete',
                            formula: '[postmortem.complete]',
                            orderSequence: 0,
                            grouped: false,
                            hidden: false,
                            filters: [{ operator: '!eq', values: ['1'], beforeAggregate: true }]
                        },
                        {
                            header: 'Retrospective State',
                            formula: '[postmortem.state]',
                            orderSequence: 0,
                            grouped: false,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Retrospective Summary',
                            formula: '[postmortem.summary]',
                            orderSequence: 0,
                            grouped: false,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'year_month_number_day_number_time_hyphen',
                            hidden: false,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 0,
                    y: 80,
                    i: '5c79ac3f82edb95481d45c13',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: {
                        x: ['Incident Created', 'Severity Label', 'Retrospective Summary', 'Retrospective State'],
                        y: [],
                        z: []
                    },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'number_of_open_postmortems',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Number of Open Retrospective by Severity',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Retrospective Completed',
                            formula: '[postmortem.complete]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'yes_no',
                            hidden: false,
                            filters: [
                                { operator: '!empty', values: [''], beforeAggregate: false },
                                { operator: 'eq', values: ['0'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            function: 'date',
                            hidden: true,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 9,
                    x: 0,
                    y: 62,
                    i: '5c7f111082edb922353a3be5',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'pie',
                    orientation: 'vertical',
                    axes: { x: ['Severity Label'], y: ['Incident #'], z: [] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            }
        ],
        created: '2023-10-02 18:59:01.351227',
        updated: '2023-10-02 18:59:01.351227',
        isReadonly: true
    },
    {
        _id: 'tag-categories',
        name: 'Tag Categories',
        tiles: [
            {
                _id: 'tag-category-severity-alert-source',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Alert Source',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['ALERT SOURCE'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 0,
                    i: '1696273141340726',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-alert-source',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Alert Source - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['ALERT SOURCE'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 0,
                    i: '1696273141341293',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-contributing-factor',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Contributing Factor',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['CONTRIBUTING-FACTOR'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 8,
                    i: '1696273141341721',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-contributing-factor',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Contributing Factor - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['CONTRIBUTING-FACTOR'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 8,
                    i: '1696273141342258',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-customer',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Customer',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['CUSTOMER'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 16,
                    i: '1696273141342694',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-customer',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Customer - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['CUSTOMER'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 16,
                    i: '1696273141343151',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-group/team',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Group/Team',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['GROUP/TEAM'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 24,
                    i: '1696273141343569',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-group/team',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Group/Team - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['GROUP/TEAM'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 24,
                    i: '1696273141344017',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-number-of-impacted-users',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Number Of Impacted Users',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['NUMBER OF IMPACTED USERS'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 32,
                    i: '1696273141344470',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-number-of-impacted-users',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Number Of Impacted Users - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['NUMBER OF IMPACTED USERS'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 32,
                    i: '1696273141344911',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-owning-squad',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Owning Squad',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['OWNING SQUAD'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 40,
                    i: '1696273141345331',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-owning-squad',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Owning Squad - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['OWNING SQUAD'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 40,
                    i: '1696273141345979',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-product',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Product',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['PRODUCT'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 48,
                    i: '1696273141346460',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-product',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Product - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['PRODUCT'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 48,
                    i: '1696273141346907',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-region',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Region',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['REGION'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 56,
                    i: '1696273141347358',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-region',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Region - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['REGION'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 56,
                    i: '1696273141347938',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-service-impacted',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Service Impacted',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['SERVICE IMPACTED'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 64,
                    i: '1696273141348379',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-service-impacted',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Service Impacted - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['SERVICE IMPACTED'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 64,
                    i: '1696273141348778',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-team',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Team',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['TEAM'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 72,
                    i: '1696273141349212',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-team',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Team - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['TEAM'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 72,
                    i: '1696273141349635',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-severity-was-there-a-workaround?',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Was There A Workaround?',
                description: 'count by severity',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['WAS THERE A WORKAROUND?'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Severity',
                            formula: '[incident.severity]',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            hidden: true,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 0,
                    y: 80,
                    i: '1696273141350073',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Tag Label'], y: ['Incident #'], z: ['Incident Severity'] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'tag-category-breakdown-was-there-a-workaround?',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Was There A Workaround? - Breakdown',
                description: 'category breakdown',
                query: {
                    items: [
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            orderSequence: 0,
                            format: 'incident_url',
                            grouped: true,
                            hidden: false,
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            order: 'asc',
                            format: 'year_month_number_day_number_time_hyphen',
                            grouped: true,
                            hidden: false,
                            filters: [
                                { operator: 'rw', beforeAggregate: true, values: ['3', 'month', '0', 'day'] },
                                { operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }
                            ]
                        },
                        {
                            header: 'Tag Category',
                            formula: '[tag_category.name]',
                            orderSequence: 0,
                            grouped: true,
                            format: 'pretty',
                            hidden: false,
                            filters: [{ operator: 'eq', values: ['WAS THERE A WORKAROUND?'], beforeAggregate: true }]
                        },
                        {
                            header: 'Tag Label',
                            formula: '[tag.label]',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: false,
                            format: 'pretty',
                            aggregate: 'concatenate',
                            hidden: false,
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 6,
                    h: 8,
                    x: 6,
                    y: 80,
                    i: '1696273141350543',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident #', 'Incident Created', 'Tag Label'], y: [], z: [] },
                    legend: { enabled: false, position: 'bottom', keys: [] }
                },
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            }
        ],
        created: '2023-10-02 18:59:01.351227',
        updated: '2023-10-02 18:59:01.351227',
        isReadonly: true
    },
    {
        _id: 'incident-timings',
        name: 'Incident Timings',
        tiles: [
            {
                _id: 'max_time_to_identify_hour_datatile',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Max Time to Identify (hour)',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-jxdqxl2g',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-jxdqxlom',
                            filters: []
                        },
                        {
                            header: 'Time to Identification',
                            formula: '[incident.time_to_identification] /3600',
                            dataType: 'number',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'max',
                            format: 'round_1',
                            hidden: false,
                            ref: 'field-jxdsk5n3',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 4,
                    h: 6,
                    x: 0,
                    y: 0,
                    i: '5d13e25e450f1a00015af5f3',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'dataTile',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created', 'Incident #'], y: ['Time to Identification'], z: ['0'] },
                    legend: {
                        enabled: false,
                        position: 'bottom',
                        keys: [{ label: 'Time to Identification', color: '#a7a7a7' }]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'max_time_to_take_action_hour_datatile',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Max Time To Take Action (Hour)',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-jxdrpmec',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-jxdrptsj',
                            filters: []
                        },
                        {
                            header: 'Time to take Action',
                            formula: '[incident.time_to_take_action] /3600',
                            dataType: 'number',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'max',
                            format: 'round_1',
                            hidden: false,
                            ref: 'field-jxdsptfh',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 4,
                    h: 6,
                    x: 4,
                    y: 0,
                    i: '5d13e39b2eb1c100014c88e7',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'dataTile',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created', 'Incident #'], y: ['Time to take Action'], z: ['0'] },
                    legend: {
                        enabled: false,
                        position: 'bottom',
                        keys: [{ label: 'Time to take Action', color: '#a7a7a7' }]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'max_time_to_resolve_hour_datatile',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Max Time To Resolve (Hour)',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-jxdrqqzf',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-jxdrrcso',
                            filters: []
                        },
                        {
                            header: 'Time to Resolve',
                            formula: '[incident.duration_of_customer_impact] /3600',
                            dataType: 'number',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'max',
                            format: 'round_1',
                            hidden: false,
                            ref: 'field-jxdsr282',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 4,
                    h: 6,
                    x: 8,
                    y: 0,
                    i: '5d13e39e450f1a00015af5f4',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'dataTile',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created', 'Incident #'], y: ['Time to Resolve'], z: ['0'] },
                    legend: {
                        enabled: false,
                        position: 'bottom',
                        keys: [{ label: 'Time to Resolve', color: '#a7a7a7' }]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'max_time_to_take_action_hour_multiline',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Max Time To Take Action (Hour)',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-jxdrpmec',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-jxdrptsj',
                            filters: []
                        },
                        {
                            header: 'Time to Action',
                            formula: '[incident.time_to_take_action] /3600',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'max',
                            format: '',
                            hidden: false,
                            ref: 'field-jxdt2j3g',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 4,
                    h: 9,
                    x: 4,
                    y: 6,
                    i: '5d13e8b0450f1a00015af5f8',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'multiline',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Time to Action', 'Incident #'], z: [] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Time to Action', color: '#009cd2' },
                            { label: 'Incident #', color: '#fc7753' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'max_time_to_resolve_hour_multiline',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Max Time To Resolve (Hour)',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-jxdrqqzf',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-jxdrrcso',
                            filters: []
                        },
                        {
                            header: 'Time to Resolve',
                            formula: '[incident.duration_of_customer_impact] /3600',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'max',
                            format: '',
                            hidden: false,
                            ref: 'field-jxdt4dpu',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 4,
                    h: 9,
                    x: 8,
                    y: 6,
                    i: '5d13e8cc2eb1c100014c88e9',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'multiline',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Time to Resolve', 'Incident #'], z: [] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Time to Resolve', color: '#009cd2' },
                            { label: 'Incident #', color: '#fc7753' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: 'max_time_to_identify_hour_multiline',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Max Time To Identify (Hour)',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-jxdsevf5',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-jxdsf3bm',
                            filters: []
                        },
                        {
                            header: 'Time to Identification',
                            formula: '[incident.time_to_identification] /3600',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'max',
                            format: '',
                            hidden: false,
                            ref: 'field-jxdsu7k8',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 4,
                    h: 9,
                    x: 0,
                    y: 0,
                    i: 'NaN',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'multiline',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Time to Identification', 'Incident #'], z: [] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Time to Identification', color: '#009cd2' },
                            { label: 'Incident #', color: '#fc7753' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            }
        ],
        created: '2023-10-02 18:59:01.351227',
        updated: '2023-10-02 18:59:01.351227',
        isReadonly: true
    },
    {
        _id: 'hours-in-incidents',
        name: 'Hours in Incidents',
        tiles: [
            {
                _id: '5dbcc924af029e00015321fc',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Total Participant hours',
                description: 'by incident type/severity',
                query: {
                    items: [
                        {
                            header: 'Team Size',
                            formula: '[incident.team_size]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'sum',
                            hidden: false,
                            ref: 'field-k2gt7but',
                            filters: []
                        },
                        {
                            header: 'Incident Type',
                            formula: '[incident.type]',
                            dataType: 'text',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            ref: 'field-k2gti5kv',
                            filters: []
                        },
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            dataType: 'text',
                            order: 'asc',
                            orderSequence: 1,
                            grouped: true,
                            hidden: false,
                            ref: 'field-k2gti6pi',
                            filters: []
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-k2gti7s5',
                            filters: []
                        },
                        {
                            header: 'Duration Of Customer Impact',
                            formula: '[incident.duration_of_customer_impact]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'sum',
                            hidden: false,
                            ref: 'field-k2gtiai9',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        },
                        {
                            header: 'Participant Hours',
                            formula: '( [ref:field-k2gtiai9] /3600)* [ref:field-k2gt7but]',
                            dataType: 'number',
                            orderSequence: 0,
                            format: 'round_2',
                            grouped: false,
                            hidden: false,
                            ref: 'field-k2gtnsrn',
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            function: 'date',
                            hidden: true,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 12,
                    h: 8,
                    x: 0,
                    y: 24,
                    i: '5dbcc924af029e00015321fc',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: {
                        x: ['Incident Type', 'Severity Label', 'Team Size', 'Incident #', 'Participant Hours'],
                        y: [],
                        z: []
                    },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: '5dc0ac8def65b40001bd5297',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Total Participant hours - by incident type',
                description: 'stacked by severity',
                query: {
                    items: [
                        {
                            header: 'Team Size',
                            formula: '[incident.team_size]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'sum',
                            hidden: false,
                            ref: 'field-k2gt7but',
                            filters: []
                        },
                        {
                            header: 'Incident Type',
                            formula: '[incident.type]',
                            dataType: 'text',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            ref: 'field-k2gti5kv',
                            filters: []
                        },
                        {
                            header: 'Severity Label',
                            formula: '[incident.severity_label]',
                            dataType: 'text',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            ref: 'field-k2gti6pi',
                            filters: []
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-k2gti7s5',
                            filters: []
                        },
                        {
                            header: 'Duration Of Customer Impact',
                            formula: '[incident.duration_of_customer_impact]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'sum',
                            hidden: false,
                            ref: 'field-k2gtiai9',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        },
                        {
                            header: 'Participant Hours',
                            formula: '( [ref:field-k2gtiai9] /3600)* [ref:field-k2gt7but]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            hidden: false,
                            ref: 'field-k2gtnsrn',
                            filters: []
                        },
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'concatenate',
                            function: 'date',
                            hidden: true,
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 50, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 12,
                    h: 8,
                    x: 0,
                    y: 0,
                    i: '5dc0ac8def65b40001bd5297',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'stackedBar',
                    orientation: 'vertical',
                    axes: { x: ['Incident Type'], y: ['Participant Hours'], z: ['Severity Label'] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: '1', color: '#4C8DF0' },
                            { label: '2', color: '#82D3EC' },
                            { label: '3', color: '#6E629C' },
                            { label: '4', color: '#8092E5' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: '5dc0b2c1af029e00015321ff',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Top Participants in Incidents - by hours',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            order: 'desc',
                            orderSequence: 1,
                            grouped: false,
                            aggregate: 'concatenate',
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-k2l1vduq',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Incident Team Name',
                            formula: '[incident_team.name]',
                            dataType: 'text',
                            orderSequence: 0,
                            grouped: true,
                            hidden: false,
                            ref: 'field-k2l1vltn',
                            filters: []
                        },
                        {
                            header: 'Incident Duration',
                            formula: '[incident.duration_of_customer_impact] /3600',
                            dataType: 'number',
                            order: 'desc',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'sum',
                            format: 'round_2',
                            hidden: false,
                            ref: 'field-k2l24zx3',
                            filters: [{ operator: 'gt', values: ['0'], beforeAggregate: true }]
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 1000, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 12,
                    h: 8,
                    x: 0,
                    y: 16,
                    i: '5dc0b2c1af029e00015321ff',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'table',
                    orientation: 'vertical',
                    axes: { x: ['Incident Team Name', 'Incident Duration'], y: [], z: [] },
                    legend: { enabled: true, position: 'bottom', keys: [] }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            },
            {
                _id: '5dc0c25282edb9a40dc3aaaa',
                warehouse: 'dashboard',
                topic: 'incident',
                title: 'Participant Hours vs Incidents count',
                description: '',
                query: {
                    items: [
                        {
                            header: 'Incident Created',
                            formula: '[incident.created]',
                            dataType: 'datetime',
                            order: 'asc',
                            orderSequence: 0,
                            grouped: true,
                            function: 'date',
                            format: 'year_month_number_day_number_hyphen',
                            hidden: false,
                            ref: 'field-k2l1584r',
                            filters: [{ operator: 'rw', values: ['3', 'month', '0', 'day'], beforeAggregate: true }]
                        },
                        {
                            header: 'Participant Hours',
                            formula: '( [incident.duration_of_customer_impact] /3600)* [incident.team_size]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'sum',
                            hidden: false,
                            ref: 'field-k2l16p37',
                            filters: []
                        },
                        {
                            header: 'Incident #',
                            formula: '[incident.number]',
                            dataType: 'number',
                            orderSequence: 0,
                            grouped: false,
                            aggregate: 'count',
                            hidden: false,
                            ref: 'field-k2l175mb',
                            filters: []
                        }
                    ],
                    options: { pageNumber: 0, itemsPerPage: 500, ignoreRepeatedRows: false }
                },
                layout: {
                    w: 12,
                    h: 8,
                    x: 0,
                    y: 8,
                    i: '5dc0c25282edb9a40dc3aaaa',
                    minW: 2,
                    maxW: 16777216,
                    minH: 5,
                    maxH: 16777216,
                    static: false,
                    moved: false,
                    isDraggable: false,
                    isResizable: false
                },
                visualization: {
                    type: 'multiline',
                    orientation: 'vertical',
                    axes: { x: ['Incident Created'], y: ['Participant Hours', 'Incident #'], z: [] },
                    legend: {
                        enabled: true,
                        position: 'bottom',
                        keys: [
                            { label: 'Participant Hours', color: '#4C8DF0' },
                            { label: 'Incident #', color: '#82D3EC' }
                        ]
                    }
                },
                isDeleted: false,
                created: '2023-10-02 18:59:01.351227',
                updated: '2023-10-02 18:59:01.351227'
            }
        ],
        created: '2023-10-02 18:59:01.351227',
        updated: '2023-10-02 18:59:01.351227',
        isReadonly: true
    }
];
