import $api from 'src/utils/api-interface';
import $filesApi from 'src/utils/api-files-interface';
import { $api_ai_v2 } from 'src/utils/apis/v2';
import { createAction } from 'redux-act';
import { createActionAsync } from 'redux-act-async';

import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { STATUS_CODES } from 'src/constants/status';

export const updateIncidentTimeline = createAction<string>('UPDATE_INCIDENT_TIMELINE');
export const sseUpdateIncident = createAction<string>('SSE_UPDATE_INCIDENT');
export const sseUpdateTimelineEvent = createAction<string>('SSE_UPDATE_TIMELINE_EVENT');
export const sseCreateTimelineEvent = createAction<string>('SSE_CREATE_TIMELINE_EVENT');
export const sseDeleteTimelineEvent = createAction<string>('SSE_DELETE_TIMELINE_EVENT');
export const sseDeleteMemberFromTeam = createAction<string>('SSE_DELETE_MEMBER_FROM_TEAM');
export const sseUpdateTask = createAction<string>('SSE_UPDATE_TASK');
export const sseCreateTask = createAction<string>('SSE_CREATE_TASK');
export const sseDeleteTask = createAction<string>('SSE_DELETE_TASK');
export const sseAddUpdateTasks = createAction<string>('SSE_ADD_UPDATE_TASKS');
export const errorExportingPDF = createAction<string>('ERROR_EXPORTING_PDF');

export const getIncidentDetails = createActionAsync('GET_INCIDENT_DETAILS', (id) =>
    $api
        .get({ url: `/incidents/${id}` }, { preventAlertingOnStatusCodes: [STATUS_CODES.NOT_FOUND], retries: 0 })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

// Returns all public Slack channels, excluding incident channels, EXCEPT FOR THE CURRENT INCIDENT CHANNEL
export const getSlackChannelsAndIncidentChannel = createActionAsync(
    'GET_SLACK_CHANNELS_AND_INCIDENT_CHANNEL',
    (incidentId) =>
        $api
            .get({ url: `/incidents/${incidentId}/slack-channels` })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getIncidentRoles = createActionAsync('GET_INCIDENT_ROLES', () =>
    $api
        .get({ url: '/incident-roles' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getIncidentTimeline = createActionAsync('GET_INCIDENT_TIMELINE', (id, isPostmortem) => {
    const params = isPostmortem ? { postmortem: !!isPostmortem } : '';
    return $api
        .get({
            url: `/incidents/${id}/events`,
            params
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const addIncidentEvent = createActionAsync('ADD_INCIDENT_EVENT', (id, data) =>
    $api
        .post({ url: `/incidents/${id}/events`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateIncidentEvent = createActionAsync('UPDATE_INCIDENT_EVENT', (incidentId, eventId, data, dispatch) =>
    $api
        .put({
            url: `/incidents/${incidentId}/events/${eventId}`,
            data
        })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: 'Event updated.',
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: 'Fail updating event.',
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const getIncidentTasks = createActionAsync('GET_INCIDENT_TASKS', (id) =>
    $api
        .get({ url: `/incidents/${id}/tasks` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getMoreIncidentsTasks = createActionAsync('GET_MORE_INCIDENT_TASKS', (incidentId, pageNum, pageSize) =>
    $api
        .get({
            url: `/incidents/${incidentId}/tasks?limit=${pageSize}&offset=${pageSize * pageNum}`
        })
        .then((res) => {
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const addIncidentTask = createActionAsync('ADD_INCIDENT_TASK', (id, data) =>
    $api
        .post({ url: `/incidents/${id}/tasks`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateIncidentTask = createActionAsync('UPDATE_INCIDENT_TASK', (incidentId, taskId, data) => {
    return $api
        .put({ url: `/incidents/${incidentId}/tasks/${taskId}`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const updateIncidentTasks = createActionAsync('UPDATE_INCIDENT_TASKS', (incidentId, tasks) =>
    $api
        .patch({ url: `/incidents/${incidentId}/tasks`, data: { tasks } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteMemberFromTeam = createActionAsync('DELETE_MEMBER_FROM_TEAM', (incidentId, memberId) =>
    $api
        .delete({ url: `/incidents/${incidentId}/team/${memberId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteIncidentTask = createActionAsync('DELETE_INCIDENT_TASK', (incidentId, taskId) =>
    $api
        .delete({ url: `/incidents/${incidentId}/tasks/${taskId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteIncidentTimelineEvent = createActionAsync('DELETE_INCIDENT_TIMELINE_EVENT', (incidentId, eventId) =>
    $api
        .delete({ url: `/incidents/${incidentId}/events/${eventId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const setIncidentValue = createActionAsync('SET_INCIDENT_VALUE', (incidentId, data, dispatch) =>
    $api
        .put(
            { url: `/incidents/${incidentId}`, data },
            // For updating incidents we don't want to send a sentry alert for 422 errors since they are considered expected errors
            // This can be moved to the global config if we ever decide that all 422 errors are considered expected and should not be sent to sentry
            { preventAlertingOnStatusCodes: [STATUS_CODES.UNPROCESSABLE_ENTITY], retries: 0 }
        )
        .then((res) => res.data)
        .catch((error) => {
            // If an error code is 500 or higher we want to throw the error for sentry since it's an unexpected error
            if (error.response && error.response.status < 500) {
                dispatch(
                    enqueueSnackbar({
                        message:
                            error.response.data.message ||
                            // This default message can be removed once we know for sure that all error responses have a message from resto
                            "Sorry, we couldn't update this incident. Something happened on our end. Please try again later",
                        options: {
                            variant: 'error'
                        }
                    })
                );
            } else {
                throw error;
            }
        })
);

export const updateIncidentTicket = createActionAsync('UPDATE_INCIDENT_TICKET', (incidentId) =>
    $api
        .put({ url: `/incidents/${incidentId}/ticket` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const addIncidentMeeting = createActionAsync('ADD_INCIDENT_MEETING', (incidentId, dispatch) =>
    $api
        .post({ url: `/incidents/${incidentId}/meeting` })
        .then((res) => {
            dispatch(
                enqueueSnackbar({
                    message: 'Added meeting',
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const getIncidentCommsChannels = createActionAsync('GET_INCIDENT_COMMS_CHANNELS', () =>
    $api
        .get({
            url: '/incidents/communications/channels'
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getStatuspageIncidents = createActionAsync('GET_STATUSPAGE_INCIDENTS', (query) =>
    $api
        .get({
            url: `/incidents/statuspage?query=${query}`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getStatuspageComponents = createActionAsync('GET_STATUSPAGE_COMPONENTS', () =>
    $api
        .get({
            url: '/incidents/statuspage/components'
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getIncidentTypes = createActionAsync(
    'GET_INCIDENT_TYPES',
    (includeExisting = false, isActive = true, action = 'read') => {
        const params = new URLSearchParams('');
        params.append('isActive', isActive ? '1' : '0');
        params.append('action', action);
        return $api
            .get({
                url: `incident-types?${includeExisting ? 'includeExisting&' : ''}${params.toString()}`
            })
            .then((res) => ({ ...res.data, action }))
            .catch((error) => {
                if (error.response.status === STATUS_CODES.FORBIDDEN) {
                    return { forbidden: true };
                } else {
                    throw error;
                }
            });
    }
);

export const getIncidentSeverities = createActionAsync('GET_INCIDENT_SEVERITIES', () =>
    $api
        .get({ url: '/incident-severities' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const exportIncidentAsPdf = createActionAsync(
    'EXPORT_INCIDENT_AS_PDF',
    (incidentId, sections, data, dispatch) => {
        return $filesApi
            .post({
                url: '/full-export/pdf',
                responseType: 'blob',
                data: {
                    incidentId,
                    sections,
                    data
                }
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `INCIDENT-${incidentId}.pdf`);
                document.body.appendChild(link);
                link.click();
                dispatch(
                    enqueueSnackbar({
                        message: 'Success exporting as PDF.',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res;
            })
            .catch((error) => {
                throw error;
            });
    }
);

export const exportIncidentAsMarkdown = createActionAsync('EXPORT_INCIDENT_AS_MARKDOWN', (incidentId) =>
    $filesApi
        .post({
            url: `/full-export/${incidentId}.md`,
            responseType: 'blob',
            data: {
                baseUrl: window.location.origin
            }
        })
        .then((response) => {
            response?.data?.text().then((mdBlob) => {
                const url = window.URL.createObjectURL(new Blob([mdBlob.replace(/Postmortem/gi, 'Retrospective')]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'incidentExport.md');
                document.body.appendChild(link);
                link.click();
            });
        })
        .catch((error) => {
            throw error;
        })
);

export const exportIncidentAsEmail = createActionAsync(
    'EXPORT_INCIDENT_AS_EMAIL',
    (emails = [], sections = [], dataToExport = {}, dispatch) =>
        $filesApi
            .post({
                url: '/full-export/email',
                data: {
                    emails,
                    sections,
                    data: dataToExport
                }
            })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Success exporting as email.',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getCategoriesForIncident = createActionAsync(
    'GET_CATEGORIES_FOR_INCIDENT',
    (incidentId, errorMessage: string) =>
        $api
            .get({
                url: `/tagging/get_categories_for_incident?incident_id=${incidentId}`
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const addPredefinedTagToIncident = createActionAsync(
    'ADD_PREDEFINED_TAG_TO_INCIDENT',
    (incidentId, categoryId, tagId, errorMessage) =>
        $api
            .post({
                url: '/tagging/add_predefined_tag_to_incident',
                data: {
                    incident_id: incidentId,
                    category_id: categoryId,
                    tag_id: tagId
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const addFreeFormTagToIncident = createActionAsync(
    'ADD_FREE_FORM_TAG_TO_INCIDENT',
    (incidentId, categoryId, tagLabel, errorMessage) =>
        $api
            .post({
                url: '/tagging/add_free_form_tag_to_incident',
                data: {
                    incident_id: incidentId,
                    category_id: categoryId,
                    tag_label: tagLabel
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const deleteTagFromIncident = createActionAsync(
    'DELETE_TAG_FROM_INCIDENT',
    (incidentId, categoryId, tagId, errorMessage) =>
        $api
            .delete({
                url: `/tagging/delete_tag_from_incident/${incidentId}/${categoryId}/${tagId}`
            })
            .then((res) => res.data)
            .catch((error) => {
                enqueueSnackbar({
                    message: errorMessage,
                    options: {
                        variant: 'error'
                    }
                });
                throw error;
            })
);

export const getIncidentAssistantSettings = createActionAsync('GET_INCIDENT_ASSISTANT_SETTINGS', () =>
    $api_ai_v2
        .get({ url: '/settings/incident-assistant' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateIncidentAssistantSettings = createActionAsync(
    'UPDATE_INCIDENT_ASSISTANT_SETTINGS',
    (enabled: boolean) =>
        $api_ai_v2
            .put({ url: '/settings/incident-assistant', data: { enabled } })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getIncidentPermissions = createActionAsync('GET_INCIDENT_PERMISSIONS', (incidentId) =>
    $api
        .get({ url: `/incidents/${incidentId}/permissions` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateIncidentPermission = createActionAsync('UPDATE_INCIDENT_PERMISSION', (incidentId, data) =>
    $api
        .put({ url: `/incidents/${incidentId}/permissions`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
