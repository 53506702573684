import { createActionAsync } from 'redux-act-async';
import { CATALOG_PAGE_SIZE } from 'src/constants/catalog';
import $api from 'src/utils/api-interface';

export const createComponent = createActionAsync('CREATE_COMPONENT', (component) =>
    $api
        .post({
            url: '/components',
            data: component
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateComponent = createActionAsync('UPDATE_COMPONENT', (component, componentId) =>
    $api
        .put({
            url: `/components/${componentId}`,
            data: component
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const removeComponent = createActionAsync('REMOVE_COMPONENT', (componentId) =>
    $api
        .delete({ url: `/components/${componentId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createService = createActionAsync('CREATE_SERVICE', (service) => {
    const s = service;
    s.productIds = service.productIds.join();
    s.componentIds = service.componentIds.join();

    return $api
        .post({
            url: '/services',
            data: s
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const updateService = createActionAsync('UPDATE_SERVICE', (service, serviceId) => {
    const s = Object.assign({}, service);
    s.componentIds = s.componentIds.join();
    s.productIds = s.productIds.join();

    return $api
        .put({
            url: `/services/${serviceId}`,
            data: s
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const removeService = createActionAsync('REMOVE_SERVICE', (serviceId) =>
    $api
        .delete({ url: `/services/${serviceId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createProduct = createActionAsync('CREATE_PRODUCT', (product) => {
    const p = product;
    p.serviceIds = product.serviceIds.join();

    return $api
        .post({ url: '/products', data: p })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const updateProduct = createActionAsync('UPDATE_PRODUCT', (product, productId) => {
    const p = Object.assign({}, product);
    p.serviceIds = p.serviceIds.join();

    return $api
        .put({
            url: `/products/${productId}`,
            data: p
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const removeProduct = createActionAsync('REMOVE_PRODUCT', (productId) =>
    $api
        .delete({ url: `/products/${productId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getServiceTypes = createActionAsync('GET_SERVICE_TYPES', () =>
    $api
        .get({ url: '/services/types' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getComponentTypes = createActionAsync('GET_COMPONENT_TYPES', () =>
    $api
        .get({ url: '/components/types' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getComponents = createActionAsync(
    'GET_COMPONENTS',
    (pageNum, limit = CATALOG_PAGE_SIZE, expandFields = true) =>
        $api
            .get({
                url: '/components',
                params: {
                    limit,
                    offset: limit * pageNum,
                    expandFields
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getProducts = createActionAsync(
    'GET_PRODUCTS',
    (pageNum, limit = CATALOG_PAGE_SIZE, expandFields = true) =>
        $api
            .get({
                url: '/products',
                params: {
                    limit,
                    offset: limit * pageNum,
                    expandFields
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getServices = createActionAsync(
    'GET_SERVICES',
    (pageNum, limit = CATALOG_PAGE_SIZE, expandFields = true) =>
        $api
            .get({
                url: '/services',
                params: {
                    limit,
                    offset: limit * pageNum,
                    expandFields
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);
