import React, { memo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { LoadingIndicator } from '@blamelesshq/blameless-components';

const messages = defineMessages({
    loadingPage: {
        id: 'common.loadingPage',
        defaultMessage: 'Loading...',
        description: 'The text for loading page spinner'
    }
});

const LoadingPageIndicator = memo(() => {
    const { formatMessage } = useIntl();
    return <LoadingIndicator text={formatMessage(messages.loadingPage)} />;
});
export default LoadingPageIndicator;
