import { createAction } from 'redux-act';
import $api from 'src/utils/api-interface';
import { createActionAsync } from 'redux-act-async';
import { IGNORE_ERROR } from 'src/constants/load-status';
import { STATUS_CODES } from 'src/constants/status';

export const showSessionRefreshMessage = createAction('SHOW_SESSION_REFRESH_MESSAGE');
export const showLoginErrorMessage = createAction<string>('SHOW_LOGIN_MESSAGE');
export const setAuthProfile = createAction<Record<string, any>>('SET_AUTH_PROFILE');
export const deactivateUser = createAction('DEACTIVATE_USER');
export const getBlamelessId = createActionAsync(
    'GET_BLAMELESS_ID',
    (dispatch) =>
        $api
            .get(
                { url: '/identity/user/info' },
                {
                    retries: 3,
                    retryCondition: (err) => {
                        err.response.status !== STATUS_CODES.NOT_FOUND;
                    }
                }
            )
            .then((res) => res.data)
            .catch((err) => {
                // If user is not found, deactivate user.
                if (err.response.status === STATUS_CODES.NOT_FOUND) {
                    dispatch(deactivateUser());
                } else {
                    throw err;
                }
            }),
    {
        error: {
            metaReducer: () => {
                // Supress snackbar message to show full error page instead.
                return IGNORE_ERROR;
            }
        }
    }
);
export const getAuthUserRoles = createActionAsync('GET_AUTH_USER_ROLES', (id) =>
    $api
        .get({ url: `/identity/user/roles/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
export const getAuthUserIntegrations = createActionAsync('GET_AUTH_USER_INTEGRATIONS', (id) =>
    $api
        .get({
            url: `/identity/user/integrations/${id}`,
            params: {
                internal: false
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
