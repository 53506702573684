export default [
    {
        name: 'incident',
        description: 'Incident Management',
        warehouse: 'dashboard'
    },
    {
        name: 'slo',
        description: 'SLO Information',
        warehouse: 'dashboard'
    },
    {
        name: 'audit',
        description: 'Audit Log',
        warehouse: 'dashboard'
    },
    {
        name: 'identity',
        description: 'Identity Management',
        warehouse: 'dashboard'
    },
    {
        name: 'comment',
        description: 'Comments',
        warehouse: 'dashboard'
    }
];
