import format from 'date-fns/format';
import addSeconds from 'date-fns/addSeconds';
import addMinutes from 'date-fns/addMinutes';
import parseISO from 'date-fns/parseISO';
import fromUnixTime from 'date-fns/fromUnixTime';
import { formatToTimeZone, parseFromTimeZone } from 'date-fns-timezone';
import { setTimeZone, findTimeZone, getUTCOffset } from 'timezone-support';
import enLocale from 'date-fns/locale/en-US';

const locales = {
    en: enLocale
};

const { locale } = __I18N__;

export const dateFormatTimezone = (date, timezone, format = 'MMM D, YYYY h:mm A') => {
    return formatToTimeZone(new Date(date), format, {
        locale: locales[locale] || locales['en'],
        timeZone: timezone
    } as any);
};
export const dateFormat = (date, _format?, options?) => {
    _format = _format == null ? 'MMM d, yyyy h:mm a' : _format;
    return format(date, _format, {
        ...options,
        locale: locales[locale] || locales['en']
    });
};

export const getUTCDate = (date = new Date()) => {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
};

export const getUTCTimestamp = (date = new Date()) => {
    return getUTCDate(date).getTime();
};

export const convertToTimestamp = (date, time) => {
    const d = parseISO(`${date}T${time}:00`);
    return Math.floor(new Date(d).getTime() / 1000.0);
};

export const convertToTimezone = (date, time, timezone = getBrowserTimezone()) => {
    const d = parseISO(`${date}T${time}`);
    const tz = findTimeZone(timezone);
    const tzTime = setTimeZone(d, tz, { useUTC: false });
    return tzTime.epoch! / 1000.0;
};

export const getTimeDurationBySeconds = (seconds, limitToTwoUnits = false) => {
    let signAdjustment = '';
    if (seconds < 0) {
        seconds *= -1;
        signAdjustment = '-';
    }
    const dayInSeconds = 60 * 60 * 24;
    const days = Math.floor(seconds / dayInSeconds);
    const helperDate = addSeconds(new Date(0, 0), seconds);
    if (days >= 1) {
        return formatDuration(helperDate, days, signAdjustment, limitToTwoUnits ? "'d' H'h'" : "'d' H'h' m'm' s's'");
    }
    if (seconds >= 60 * 60) {
        return formatDuration(helperDate, days, signAdjustment, limitToTwoUnits ? "H'h' m'm'" : "H'h' m'm' s's'");
    }
    if (seconds >= 60) {
        return formatDuration(helperDate, days, signAdjustment, "m'm' s's'");
    }
    return formatDuration(helperDate, days, signAdjustment, "s's'");
};

const formatDuration = (date, days, signAdjustment, formatStr) => {
    if (days >= 1) {
        return signAdjustment + dateFormat(date, `${days}${formatStr}`);
    }
    return signAdjustment + dateFormat(date, `${formatStr}`);
};

export const getTimeDurationByMinutes = (minutes: number): string => {
    const dayInMinutes = 60 * 24;
    const days = Math.round(minutes / dayInMinutes);
    const helperDate = addMinutes(new Date(0, 0), minutes);
    if (days >= 1) {
        return dateFormat(helperDate, `${days}'d' H'h' m'm' s's'`);
    }
    if (minutes >= 60) {
        return dateFormat(helperDate, "H'h' m'm' s's'");
    }
    if (minutes >= 1) {
        return dateFormat(helperDate, "m'm' s's'");
    }
    return dateFormat(helperDate, "s's'");
};

export const getBrowserTimezone = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (tz === 'America/Cordoba') {
        return 'America/Argentina/Cordoba';
    }
    if (tz === 'America/Buenos_Aires') {
        return 'America/Argentina/Buenos_Aires';
    }
    return tz;
};

export const getZonedTime = (dateString, timeZone) => {
    return parseFromTimeZone(dateString, { timeZone });
};

export const convertToUnixTimestamp = (dateTime) => {
    return Math.floor(dateTime / 1000.0);
};

export const fromUnixTimeToDate = (unixTime: number, customFormat?: string) => {
    const zonedDate = fromUnixTime(unixTime);
    return format(zonedDate, customFormat || 'M-dd-yyyy hh:mm');
};

export function fromUnixTimeToDateGMT(unixtime: string) {
    const zonedDate = fromUnixTime(parseInt(unixtime));
    return format(new Date(zonedDate.toLocaleDateString('en-US', { timeZone: 'GMT' })), 'yyyy-MM-dd');
}

export const getTimeDurationObject = (timeDuration: string): IDatetime.ITimeduration => {
    const result = { days: '0', hours: '0', minutes: '0', seconds: '0' };
    return timeDuration.split(' ').reduce((accumulator, time) => {
        switch (time.slice(-1)) {
            case 'd':
                return { ...accumulator, days: time.slice(0, -1) };
            case 'h':
                return { ...accumulator, hours: time.slice(0, -1) };
            case 'm':
                return { ...accumulator, minutes: time.slice(0, -1) };
            case 's':
                return { ...accumulator, seconds: time.slice(0, -1) };
            default:
                return accumulator;
        }
    }, result);
};

export const getTimezoneUTCOffset = (timezone: string): number => {
    const timezoneOffsetInMins = getUTCOffset(new Date(), findTimeZone(timezone)).offset;
    const offset = timezoneOffsetInMins === 0 ? 0 : timezoneOffsetInMins * -1;
    return offset;
};
