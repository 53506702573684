import {
    Avatar,
    ButtonBase,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    WithStyles,
    WithTheme,
    createStyles
} from '@material-ui/core';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { Component } from 'react';
import { defineMessages, injectIntl, IntlShape } from 'react-intl';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { ExitToApp } from '@material-ui/icons';
import { ProfileIcon } from '@blamelesshq/blameless-components';
import Auth from 'src/auth/auth';

const messages = defineMessages({
    logout: {
        id: 'userNav.menu.logout',
        defaultMessage: 'Logout',
        description: 'The text for the Logout menu'
    }
});

const styles = () =>
    createStyles({
        avatar: {
            height: '24px',
            width: '24px'
        },
        root: {
            display: 'flex',
            width: '100%'
        },
        userButton: {
            margin: '0 16px'
        }
    });

interface IProps extends WithStyles<typeof styles>, WithTheme {
    auth: Auth;
    intl: IntlShape;
    onClick: () => void;
}

interface IState {
    open: boolean;
    profile: Record<string, any> | IAuth.IProfile;
}

export class UserNav extends Component<IProps, IState> {
    private anchorEl;
    public state = {
        open: false,
        profile: {
            name: '',
            picture: ''
        }
    };

    public componentWillMount = () => {
        const { auth } = this.props;
        const { userProfile, getProfile } = auth;
        if (!userProfile) {
            getProfile((err, profile) => {
                this.setState({ profile });
            });
        } else {
            this.setState({ profile: userProfile });
        }
    };

    private handleToggle = () => {
        this.setState((state) => ({ open: !state.open }));
    };

    private handleClose = (event: React.MouseEvent<Document | MouseEvent>) => {
        if (this.anchorEl && this.anchorEl.contains(event.target)) {
            return;
        }

        this.setState({ open: false });
    };

    private handleLogout = (e: any) => {
        const { auth } = this.props;
        auth.logout();
        this.handleClose(e);
    };

    public render() {
        const { classes, intl, theme, onClick } = this.props;
        const { profile } = this.state;
        const { formatMessage } = intl;

        if (!profile.name && !profile.picture) {
            return null;
        }

        return (
            <PopupState variant="popover" popupId="usernav-popup-menu">
                {(popupState) => (
                    <div data-cy="user-nav">
                        <ButtonBase
                            data-cy="user-nav-button"
                            classes={{
                                root: classes.userButton
                            }}
                            {...bindTrigger(popupState)}
                            onClick={(event) => {
                                onClick();
                                popupState.setAnchorEl(event.currentTarget);
                                popupState.toggle();
                            }}
                        >
                            {profile.picture && (
                                <Avatar
                                    data-cy="user-nav-avatar"
                                    alt={profile.name}
                                    src={profile.picture}
                                    className={classes.avatar}
                                />
                            )}
                            {!profile.picture && <ProfileIcon fillColor={theme.colors.icon.light} />}
                        </ButtonBase>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem data-cy="user-nav-logout" onClick={(e) => this.handleLogout(e)}>
                                <ListItemIcon>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText primary={formatMessage(messages.logout)} />
                            </MenuItem>
                        </Menu>
                    </div>
                )}
            </PopupState>
        );
    }
}

export default withTheme(withStyles(styles)(injectIntl(UserNav)));
