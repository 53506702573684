import produce from 'immer';
import { LoadStatus } from 'src/constants/load-status';
import {
    getService,
    getServices,
    postNewService,
    removeService,
    updateExistingService,
    clearServicesAndMetaData
} from 'src/actions/service-registry/service-registry-page-actions';

interface IDefaultState {
    service: ServiceRegistry.IService | null;
    services: ServiceRegistry.IService[];
    servicesPagination: Common.IPaginationMetaData;
    servicesLoadStatus: string;
    removeServiceLoadStatus: string;
    updateServiceLoadStatus: string;
    postNewServiceLoadStatus: string;
}

const defaultState: IDefaultState = {
    service: null,
    services: [],
    servicesPagination: {
        limit: 0,
        offset: 0,
        total: 0,
        pageIndex: 0,
        rowsPerPage: 100,
        totalRows: 0,
        totalPages: 0,
        hasNextPage: false,
        hasPrevPage: false
    },
    servicesLoadStatus: LoadStatus.EMPTY,
    removeServiceLoadStatus: LoadStatus.EMPTY,
    updateServiceLoadStatus: LoadStatus.EMPTY,
    postNewServiceLoadStatus: LoadStatus.EMPTY
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getService.request.getType():
                draft.servicesLoadStatus = LoadStatus.REQUEST;
                return;
            case getService.ok.getType():
                draft.servicesLoadStatus = LoadStatus.OK;
                draft.service = action.payload.response.service;
                return;
            case getService.error.getType():
                draft.servicesLoadStatus = LoadStatus.ERROR;
                return;
            case getServices.request.getType():
                draft.servicesLoadStatus = LoadStatus.REQUEST;
                return;
            case getServices.ok.getType():
                draft.servicesLoadStatus = LoadStatus.OK;
                draft.services = action.payload.response.services;
                draft.servicesPagination = Object.assign(draft.servicesPagination, action.payload.response.metadata);
                return;
            case getServices.error.getType():
                draft.servicesLoadStatus = LoadStatus.ERROR;
                return;
            case postNewService.request.getType():
                draft.postNewServiceLoadStatus = LoadStatus.REQUEST;
                return;
            case postNewService.ok.getType():
                draft.postNewServiceLoadStatus = LoadStatus.OK;
                draft.services.unshift(action.payload.response.service);
                return;
            case postNewService.error.getType():
                draft.postNewServiceLoadStatus = LoadStatus.ERROR;
                return;
            case updateExistingService.request.getType():
                draft.updateServiceLoadStatus = LoadStatus.REQUEST;
                return;
            case updateExistingService.ok.getType():
                draft.updateServiceLoadStatus = LoadStatus.OK;
                const service = action.payload.response.service;
                if (draft.service && draft.service.id === service.id) {
                    draft.service = service;
                }
                const serviceIndex = draft.services.findIndex((service) => service.id === service.id);
                if (serviceIndex >= 0) {
                    draft.services[serviceIndex] = service;
                }
                return;
            case updateExistingService.error.getType():
                draft.updateServiceLoadStatus = LoadStatus.ERROR;
                return;
            case removeService.request.getType():
                draft.removeServiceLoadStatus = LoadStatus.REQUEST;
                return;
            case removeService.ok.getType():
                draft.removeServiceLoadStatus = LoadStatus.OK;
                draft.services = draft.services.filter((service) => service.id !== action.payload.response.id);
                return;
            case removeService.error.getType():
                draft.removeServiceLoadStatus = LoadStatus.ERROR;
                return;
            case clearServicesAndMetaData.getType():
                draft.service = null;
                draft.services = [];
                draft.servicesPagination = {
                    total: 0,
                    limit: 0,
                    offset: 0,
                    pageIndex: 0,
                    rowsPerPage: 100,
                    totalRows: 0,
                    totalPages: 0,
                    hasNextPage: false,
                    hasPrevPage: false
                };
                return;
        }
    });
}
