import $api from 'src/utils/api-interface';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { createActionAsync } from 'redux-act-async';
import { createAction } from 'redux-act';
import { Dispatch } from 'redux';

// GraphQL
import { SliTypeFragment } from 'src/graphql/slo';

export const getSlos = createActionAsync(
    'GET_SLOS_FOR_USER_JOURNEY',
    (userJourneyId: number, limit: number, offset: number) =>
        $api
            .post({
                url: '/services/SLOService/GetSLOsWithErrorBudgetData',
                data: {
                    userJourneyId,
                    limit,
                    offset
                }
            })
            .then((res: { data: any }) => res.data as SLO.ISLOPaginatedResult)
            .catch((error) => {
                throw error;
            })
);

export const getSlo = createActionAsync('GET_SLO_BY_ID', (id: number) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetSLO', data: { id } })
        .then((res: { data: any }) => res.data as SLO.ISlo)
        .catch((error) => {
            throw error;
        })
);

export const getSlosBySliId = createActionAsync('GET_SLOS_BY_SLI_ID', (sliId: number) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetSLOsBySliId', data: { sliId, options: { rowsPerPage: 1000 } } })
        .then((res: { data: any }) => res.data as SLO.ISlo[])
        .catch((error) => {
            throw error;
        })
);

export const getSli = createActionAsync('GET_SLI_BY_ID', (id: number) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetSLI', data: { id } })
        .then((res: { data: any }) => res.data as SLO.ISliPost)
        .catch((error) => {
            throw error;
        })
);

export const getLimitedSlosForSli = createActionAsync('GET_SLOS_FOR_SLI', (sliId: number) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetSLOsBySliId', data: { sliId, options: { rowsPerPage: 1 } } })
        .then((res: { data: any }) => res.data as SLO.ISLOPaginatedResult)
        .catch((error) => {
            throw error;
        })
);

export const getSLITypes = createAction<{ sliTypes: SliTypeFragment[] }>('GET_SLI_TYPES');

export const getSLIs = createActionAsync('GET_SLIS', () =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetSLIs' })
        .then((res: { data: any }) => res.data as Common.IPaginationMetaData)
        .catch((error) => {
            throw error;
        })
);

export const getSLIsForService = createActionAsync('GET_SLIS_FOR_SERVICE', (serviceId: number) =>
    $api
        .post({
            url: '/services/SLOServiceCrud/GetSLIsByServiceId',
            data: { serviceId, options: { rowsPerPage: 1000 } }
        })
        .then((res: { data: any }) => res.data as Common.IPaginationMetaData)
        .catch((error) => {
            throw error;
        })
);

export const createSLI = createActionAsync('CREATE_SLI', (sli: SLO.ISliPost, dispatch: Dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/CreateSLI', data: { model: sli } })
        .then((res: { data: any }) => {
            const newSLI = res.data.sli as SLO.ISliPost;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully created SLI: ${newSLI.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const updateSLI = createActionAsync('UPDATE_SLI', (id: number, model: SLO.ISliPost, dispatch: Dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/UpdateSLI', data: { id, model } })
        .then((res: { data: any }) => {
            const updatedSli = res.data.sli as SLO.ISliPost;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully updated SLI: ${updatedSli.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const deleteSli = createActionAsync('DELETE_SLI', (id, dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/DeleteSLI', data: { id } })
        .then((res: { data: any }) => {
            dispatch(
                enqueueSnackbar({
                    message: `Successfully deleted SLI: ${res.data.sli.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const createSLO = createActionAsync('CREATE_SLO', (slo: SLO.ISLOPost, dispatch: Dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/CreateSLO', data: { model: slo } })
        .then((res: { data: any }) => {
            const newSLO = res.data.slo as SLO.ISlo;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully created SLO: ${newSLO.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const updateSLO = createActionAsync('UPDATE_SLO', (id: number, slo: SLO.ISLOPost, dispatch: Dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/UpdateSLO', data: { id, model: slo } })
        .then((res: { data: any }) => {
            const updatedSLO = res.data.slo as SLO.ISlo;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully updated SLO: ${updatedSLO.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return res.data;
        })
        .catch((error) => {
            throw error;
        })
);

export const deleteSlo = createActionAsync('DELETE_SLO', (id: number, dispatch: Dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/DeleteSLO', data: { id } })
        .then((res: { data: any }) => {
            const deletedSlo = res.data.slo as SLO.ISlo;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully deleted SLO: ${deletedSlo.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return deletedSlo;
        })
        .catch((error) => {
            throw error;
        })
);

export const getAppDynamicApplications = createActionAsync('GET_APP_DYNAMICS_APPLICAITONS', () =>
    $api
        .get({ url: '/slo/appdynamics/getapplications' })
        .then((res: { data: any }) => res.data as AppDynamics.IApplicationResults)
        .catch((error) => {
            throw error;
        })
);

export const getAppDynamicMetrics = createActionAsync('GET_APP_DYNAMIC_METRICS', (appId: number) =>
    $api
        .get({ url: `/slo/appdynamics/getmetrics/${appId}` })
        .then((res: { data: any }) => res.data as AppDynamics.IMetricResults)
        .catch((error) => {
            throw error;
        })
);

export const clearAppDApplications = createAction('CLEAR_ALL_APPD_APPS');
export const clearAppDMetrics = createAction('CLEAR_ALL_APPD_METRICS');

export const getDataProviders = createActionAsync('GET_DATA_PROVIDERS', () =>
    $api
        .get({ url: '/slo/dataproviders' })
        .then((res: { data: any }) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getNewRelicApplications = createActionAsync('GET_NEW_RELIC_APPLICATIONS', () =>
    $api
        .get({ url: '/newrelic/applications' })
        .then(({ data }) => data.response) // double wrapp response.response
        .catch((error) => {
            throw error;
        })
);

export const getNewRelicApplicationMetrics = createActionAsync('GET_NEW_RELIC_APPLICATION_METRICS', (applicationId) =>
    $api
        .get({ url: '/newrelic/metrics_names', params: { applicationId } })
        .then(({ data }) => data.response) // double wrapp response.response
        .catch((error) => {
            throw error;
        })
);

export const getPingdomChecks = createActionAsync('GET_PINGDOM_CHECKS', (tenantId: number) =>
    $api
        .get({ url: '/pingdom/checks', params: { tenant_id: tenantId } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getSliRawData = createActionAsync('GET_SLI_RAW_DATA', (sliId: number, start: number, end: number) =>
    $api
        .post({ url: '/services/SLOTimeSeriesService/SliRawDataGetRange', data: { sliId, start, end } })
        .then(({ data }) => data)
        .catch((error) => {
            throw error;
        })
);

export const validateSliMetric = createActionAsync(
    'VALIDATE_SLI_METRIC',
    (vendor: string, tenantId: number, metric: string, gcpSettingsId: string) =>
        $api
            .get({
                url: `/${vendor.toLowerCase()}/validate?tenant_id=${tenantId}&query=${encodeURIComponent(
                    metric
                )}&gcp_settings_id=${gcpSettingsId}`
            })
            .then(({ data }) => data.response)
            .catch((error) => {
                throw error;
            })
);

export const clearSlos = createAction('CLEAR_ALL_SLO');
export const clearSLIs = createAction('CLEAR_ALL_SLI');

export const getEnabledDatasources = createActionAsync('GET_ENABLED_DATASOURCES', () =>
    $api
        .get({ url: '/metrics/datasources/enabled' })
        .then((res) => res.data.response)
        .catch((error) => {
            throw error;
        })
);
