import { useDispatch, useSelector } from 'react-redux';
import { createAuditLog as createAuditLogAction } from 'src/actions/audit-log/audit-log-actions';
import { RootState } from 'src/store';

const useAuditLog = (): IAuditLog.IAuditLogProps => {
    const dispatch = useDispatch();
    const enableAuditLog = useSelector((state: RootState) => state.launchDarkly.enableAuditLog);

    const createAuditLog = (action: string, resource: string) => {
        if (enableAuditLog) {
            dispatch(createAuditLogAction(action, resource));
        }
    };

    return {
        createAuditLog
    };
};

export { useAuditLog };
