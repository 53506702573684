import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Switch, Route, Redirect } from 'react-router-dom';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';

// Components
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));
const SLOWizard = lazy(() => import('src/components/slo/slo-wizard/wizard'));

// Constants
import { WIZARD_STEPS_ID } from 'src/constants/slo';
import SectionURLs from 'src/constants/urls';

const SLOWizardRoutes = () => {
    const { sloPreviewFeature, enableSloWizard } = useSelector((state: RootState) => state.launchDarkly);
    return enableSloWizard ? (
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                {!sloPreviewFeature && <Route component={ErrorNotFound} />}
                <Redirect
                    exact
                    from={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SLO_WIZARD}`}
                    to={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SLO_WIZARD}/user-journey`}
                />
                <Route
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SLO_WIZARD}/user-journey`}
                    render={() => <SLOWizard openOnStep={WIZARD_STEPS_ID.USER_JOURNEY} />}
                />
                <Route
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SLO_WIZARD}/sli`}
                    render={() => <SLOWizard openOnStep={WIZARD_STEPS_ID.SLI} />}
                />
                <Route
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SLO_WIZARD}/slo`}
                    render={() => <SLOWizard openOnStep={WIZARD_STEPS_ID.SLO} />}
                />
            </Switch>
        </Suspense>
    ) : null;
};

export default SLOWizardRoutes;
