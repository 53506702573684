import { captureException, configureScope, ErrorBoundary } from '@sentry/react';
import { PropInjector } from '@material-ui/types';
import { ErrorBoundary as BlamelessErrorBoundary } from '@blamelesshq/blameless-components';
import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';

interface IFullstoryWindow extends Window {
    FS: {
        getCurrentSessionURL: () => string;
    };
}

interface IProps {
    profile: IAuth.IUserProfile;
}

// biome-ignore lint/suspicious/noShadowRestrictedNames: <explanation>
const globalThis = window;

const sentryErrorBoundary = <P extends React.Component>(WrappedComponent: React.ComponentType<P>) =>
    class SentryErrorBoundary extends React.Component<P & IProps> {
        private logToSentryError = (error: Error) => {
            const { profile } = this.props;
            if (import.meta.env.PROD) {
                configureScope((scope) => {
                    scope.setUser({
                        name: profile.name,
                        email: profile.email
                    });
                    scope.setLevel('fatal');
                    scope.setTag('hostname', window.location.href);
                    if ((window as IFullstoryWindow & typeof globalThis)?.FS?.getCurrentSessionURL) {
                        scope.setExtra(
                            'fullstory_session',
                            (window as IFullstoryWindow & typeof globalThis).FS.getCurrentSessionURL()
                        );
                    }
                    captureException(error, scope);
                });
            }
        };

        public render() {
            return (
                <ErrorBoundary
                    beforeCapture={(scope) => {
                        console.log('beforeCapture', scope);
                        return scope;
                    }}
                    onError={this.logToSentryError}
                    fallback={<BlamelessErrorBoundary />}
                >
                    <WrappedComponent {...(this.props as P)} />
                </ErrorBoundary>
            );
        }
    };

const mapStateToProps = ({ auth }) => ({
    profile: auth.authProfile
});

const composedComponent: PropInjector<React.Component> = compose(connect(mapStateToProps, null), sentryErrorBoundary);

export default composedComponent;
