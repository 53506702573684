import $api from 'src/utils/api-interface';
import { createAction } from 'redux-act';
import { createActionAsync } from 'redux-act-async';
import { IGNORE_ERROR } from 'src/constants/load-status';
import { enqueueSnackbar } from '../notification/notification-actions';

export const getDemoBoards = createAction('GET_DEMO_BOARDS', (range) => ({
    range
}));
export const getBoards = createActionAsync('GET_BOARDS', (includePredefinedBoards, options) => {
    return $api
        .get({
            url: '/boards',
            params: {
                include_predefined: includePredefinedBoards,
                exclude_tiles: true,
                ['incident.created|action.created|event.date']: `${options.filter},${options.value}`
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const getBoardTiles = createActionAsync('GET_BOARD', (boardId) => {
    return $api
        .get({
            url: `/boards/${boardId}`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const getBoardByTemplate = createActionAsync('GET_BOARD_BY_TEMPLATE', (resource) => {
    const params = {
        [`${resource.name}.id`]: resource.id,
        ...resource.params
    };
    return $api
        .get({
            url: `/board_templates/${resource.name}_page`,
            params
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const deleteBoard = createActionAsync('DELETE_BOARD', (boardId) =>
    $api
        .delete({ url: `/boards/${boardId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateBoard = createActionAsync('UPDATE_BOARD', (boardId, data) =>
    $api
        .put({ url: `/boards/${boardId}`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const addBoard = createActionAsync('ADD_BOARD', (data) =>
    $api
        .post({ url: '/boards', data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const addTile = createActionAsync('ADD_TILE', (boardId, data) =>
    $api
        .post({ url: `/boards/${boardId}/tiles`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteTile = createActionAsync('DELETE_TILE', (boardId, tileId) =>
    $api
        .delete({ url: `/boards/${boardId}/tiles/${tileId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateTile = createActionAsync('UPDATE_TILE', (boardId, tileId, data) =>
    $api
        .put({
            url: `/boards/${boardId}/tiles/${tileId}`,
            data
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getDemoTopics = createAction('GET_DEMO_TOPICS');
export const getTopics = createActionAsync('GET_TOPICS', () =>
    $api
        .get({ url: '/topics' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getDemoSegments = createAction('GET_DEMO_SEGMENTS');
export const getSegments = createActionAsync('GET_SEGMENTS', (topic) =>
    $api
        .get({ url: `/topics/${topic}/segments` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getDemoFacts = createAction('GET_DEMO_FACTS');
export const getFacts = createActionAsync('GET_FACTS', () =>
    $api
        .get({ url: '/facts' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const executeDemoQuery = createAction('EXECUTE_DEMO_QUERY', (topic, data, tileId, utcOffset) => ({
    topic,
    data,
    tileId,
    utcOffset
}));
export const executeQuery = createActionAsync('EXECUTE_QUERY', (topic, data, tileId, utcOffset = 0) =>
    $api
        .post({
            url: `topics/${topic}/execute?${tileId}`,
            data: { ...data, offset: utcOffset }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const resetCalculatedFieldValidation = createAction('RESET_CALCULATED_FIELD_VALIDATION');

export const validateCalculatedField = createActionAsync(
    'VALIDATE_CALCULATED_FIELD',
    (topic, data) =>
        $api
            .post({ url: `topics/${topic}/validate`, data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            }),
    {
        error: {
            metaReducer: () => {
                // Disable snackbar on formula validation failure
                return IGNORE_ERROR;
            }
        }
    }
);

export const validatePostCalculatedField = createActionAsync(
    'VALIDATE_POST_CALCULATED_FIELD',
    (topic, data, tileId, utcOffset = 0) =>
        $api
            .post({ url: `topics/${topic}/execute?${tileId}`, data: { ...data, offset: utcOffset } })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            }),
    {
        error: {
            metaReducer: () => {
                // Disable snackbar on formula validation failure
                return IGNORE_ERROR;
            }
        }
    }
);

export const exportToCsv = createActionAsync('EXPORT_INCIDENTS_DASHBOARD_AS_CSV', (dispatch) =>
    $api
        .get({
            url: '/dashboard/incidents/export/csv',
            responseType: 'blob'
        })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'incidents.csv');
            document.body.appendChild(link);
            link.click();

            dispatch(
                enqueueSnackbar({
                    message: 'Success exporting as SCV.',
                    options: {
                        variant: 'success'
                    }
                })
            );

            return res;
        })
        .catch((error) => {
            throw error;
        })
);

export const switchDemoData = createAction('SWITCH_DEMO_DATA');
