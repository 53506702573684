import React, { useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { defineMessages, useIntl } from 'react-intl';
import withSentryErrorBoundary from 'src/components/hocs/sentryErrorBoundary';

// Components
import { NavLink } from 'react-router-dom';
import { createStyles, makeStyles, ListItem, ListItemText } from '@material-ui/core';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import { SectionNav } from '@blamelesshq/blameless-components';

// Routes
import ServiceRegistryRoutes from 'src/components/slo/service-registry';
import ErrorBudgetPolicyRoutes from 'src/components/slo/error-budget-policy';
import UserJourneyRoutes from 'src/components/slo/user-journey';
import SLOWizardRoutes from 'src/components/slo/slo-wizard';

// Constants
import SectionURLs from 'src/constants/urls';

// Actions
import { getTenantId } from 'src/actions/identity-management/identity-management-actions';

const useStyles = makeStyles(() =>
    createStyles({
        frameWrapper: {
            padding: '10px 15px',
            flexGrow: 1,
            height: 'calc(100vh - 20px)',
            overflowY: 'auto'
        },
        root: {
            display: 'flex',
            margin: '-10px -15px'
        },
        navLink: {
            padding: '5px 16px 5px',
            borderRadius: '4px',
            minHeight: '32px',
            '&:hover': {
                backgroundColor: '#0000000d'
            }
        },
        activeLink: {
            backgroundColor: '#0000000d'
        },
        navContentWrapper: {
            padding: '0 16px'
        }
    })
);

const messages = defineMessages({
    sloNavHeader: {
        id: 'slo.sub.nav.header',
        defaultMessage: 'SLO Manager',
        description: 'Header for SLO sub menu'
    },
    userJourneysNavItem: {
        id: 'slo.sub.nav.item.userJourneys',
        defaultMessage: 'User Journeys',
        description: 'List item for submenu SLO'
    },
    errorBudgetPolicyNavItem: {
        id: 'slo.sub.nav.item.errorBudgetPolicy',
        defaultMessage: 'Error Budget Policies',
        description: 'List item for submenu SLO'
    },
    serviceRegistryNavItem: {
        id: 'slo.sub.nav.item.serviceRegistry',
        defaultMessage: 'Service Level Indicators',
        description: 'List item for submenu SLO'
    }
});

export const SloRoutes = () => {
    const { tenantId } = useSelector((state: RootState) => state.identityManagement);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { formatMessage } = useIntl();

    useEffect(() => {
        if (!tenantId) {
            dispatch(getTenantId());
        }
    }, [tenantId, dispatch]);

    const sloSections: Common.ISubMenuSection[] = [
        {
            itemLabel: formatMessage(messages.userJourneysNavItem),
            navLinkTo: `${SectionURLs.SLO_MANAGE_URL}${SectionURLs.USER_JOURNEY_URL}`,
            component: <UserJourneyRoutes />
        },
        {
            itemLabel: formatMessage(messages.errorBudgetPolicyNavItem),
            navLinkTo: `${SectionURLs.SLO_MANAGE_URL}${SectionURLs.ERROR_BUDGET_POLICY_URL}`,
            component: <ErrorBudgetPolicyRoutes />
        },
        {
            itemLabel: formatMessage(messages.serviceRegistryNavItem),
            navLinkTo: `${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SERVICE_REGISTRY_URL}`,
            component: <ServiceRegistryRoutes />
        },
        {
            itemLabel: formatMessage(messages.userJourneysNavItem),
            navLinkTo: `${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SLO_WIZARD}`,
            component: <SLOWizardRoutes />,
            hideInSectionNav: true
        }
    ];

    return (
        <Suspense fallback={<LoadingPageIndicator />}>
            <div className={classes.root}>
                <SectionNav
                    navTitle={formatMessage(messages.sloNavHeader)}
                    navContent={sloSections
                        .filter((section) => !section.hideInSectionNav)
                        .map((section) => (
                            <div className={classes.navContentWrapper} key={section.navLinkTo}>
                                <ListItem
                                    button
                                    data-cy={`slo-nav-link-${section.navLinkTo}`}
                                    className={classes.navLink}
                                    component={NavLink}
                                    exact
                                    to={section.navLinkTo}
                                    activeClassName={classes.activeLink}
                                >
                                    <ListItemText primary={section.itemLabel} />
                                </ListItem>
                            </div>
                        ))}
                />
                <div className={classes.frameWrapper}>
                    {sloSections.map((section) => (
                        <React.Fragment key={section.navLinkTo}>{section.component}</React.Fragment>
                    ))}
                </div>
            </div>
        </Suspense>
    );
};

export default withSentryErrorBoundary(SloRoutes);
