import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import { RootState } from 'src/store';
import SectionURLs from 'src/constants/urls';

// Components
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));
const ErrorBudgetPolicyPage = lazy(() => import('src/components/slo/error-budget-policy/error-budget-policy-page'));
const PolicyDetailsPage = lazy(
    () => import('src/components/slo/error-budget-policy/policy-details/policy-details-page')
);

type launchDarkly = {
    sloPreviewFeature: boolean;
};

const EBP_URL = `${SectionURLs.SLO_MANAGE_URL}${SectionURLs.ERROR_BUDGET_POLICY_URL}`;
const ErrorBudgetPolicyRoutes = () => {
    const sloPreviewFeatureFlag = useSelector(
        (state: RootState) => (state.launchDarkly as unknown as launchDarkly).sloPreviewFeature
    );
    return (
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                {!sloPreviewFeatureFlag && <Route component={ErrorNotFound} />}
                <Route exact path={EBP_URL} component={(props) => <ErrorBudgetPolicyPage {...props} />} />
                <Route exact path={`${EBP_URL}/:id`} render={(props) => <PolicyDetailsPage {...props} />} />
            </Switch>
        </Suspense>
    );
};

export default ErrorBudgetPolicyRoutes;
