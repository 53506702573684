import { getSlackUsers, getSlackUserGroups, getSlackUsersById, getSlackBots } from 'src/actions/slack/slack-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    loadSlackUsersStatus: string;
    slackUsersList: any[];
    loadSlackUserGroupsStatus: string;
    slackUserGroups: ISlack.IUserGroup[];
    loadSlackBotsStatus: string;
    slackBots: ISlack.IBot[];
}

export const defaultState: IDefaultState = {
    loadSlackUsersStatus: LoadStatus.EMPTY,
    slackUsersList: [],
    loadSlackUserGroupsStatus: LoadStatus.EMPTY,
    slackUserGroups: [],
    loadSlackBotsStatus: LoadStatus.EMPTY,
    slackBots: []
};

export default function (state: IDefaultState = defaultState, action: ISlack.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getSlackUsers.request.getType():
                draft.loadSlackUsersStatus = LoadStatus.REQUEST;
                return;
            case getSlackUsers.ok.getType():
                draft.slackUsersList = action.payload.response;
                draft.loadSlackUsersStatus = LoadStatus.OK;
                return;
            case getSlackUsers.error.getType():
                draft.loadSlackUsersStatus = LoadStatus.ERROR;
                return;
            case getSlackUsersById.request.getType():
                draft.loadSlackUsersStatus = LoadStatus.REQUEST;
                return;
            case getSlackUsersById.ok.getType():
                draft.slackUsersList = action.payload.response;
                draft.loadSlackUsersStatus = LoadStatus.OK;
                return;
            case getSlackUsersById.error.getType():
                draft.loadSlackUsersStatus = LoadStatus.ERROR;
                return;
            case getSlackUserGroups.request.getType():
                draft.loadSlackUserGroupsStatus = LoadStatus.REQUEST;
                return;
            case getSlackUserGroups.ok.getType():
                draft.slackUserGroups = action.payload.response.slack_usergroups;
                draft.loadSlackUserGroupsStatus = LoadStatus.OK;
                return;
            case getSlackUserGroups.error.getType():
                draft.loadSlackUserGroupsStatus = LoadStatus.ERROR;
                return;
            case getSlackBots.request.getType():
                draft.loadSlackBotsStatus = LoadStatus.REQUEST;
                return;
            case getSlackBots.ok.getType():
                draft.slackBots = action.payload.response;
                draft.loadSlackBotsStatus = LoadStatus.OK;
                return;
            case getSlackBots.error.getType():
                draft.loadSlackBotsStatus = LoadStatus.ERROR;
                return;
        }
    });
}
