import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { WithStyles, WithTheme, createStyles, withStyles } from '@material-ui/core/styles';

import $http from 'axios';
import Auth from './auth/auth';
import Drawer from '@material-ui/core/Drawer';
import { IntlProvider } from 'react-intl';
import { LoadingIndicator } from '@blamelesshq/blameless-components';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import Notifier from 'src/utils/notifier';
import Routes from 'src/components/routes';
import SearchPage from 'src/components/search/search-page';
import SideNavigation from 'src/components/navigation/side-navigation';
const config = ShowcaseConfig;
import { connect } from 'react-redux';
import store from './store';

const Home = lazy(() => import('src/components/home'));
const Callback = lazy(() => import('./callback/callback'));

const styles = (theme) =>
    createStyles({
        content: {
            background: '#f5f5f5',
            minHeight: '100vh',
            overflowX: 'hidden' as const,
            flexGrow: 1,
            padding: '10px 15px',
            boxSizing: 'border-box'
        },
        drawerPaper: {
            background: theme.colors.ui.grey1,
            left: '56px',
            width: '540px'
        },
        searchRoot: {
            '& .MuiBackdrop-root': {
                left: '56px'
            },
            zIndex: '1200 !important' as unknown as number
        },
        root: {
            display: 'flex'
        }
    });

const locale = __I18N__.locale;
let messages;
if (import.meta.env.PROD) {
    messages = __I18N__.messages;
}

interface IProps extends WithStyles<typeof styles>, WithTheme {
    location: Router.ILocation;
    isOnboarding: boolean;
    authProfile: Record<string, any>;
    isSearchbarOpen: boolean;
    enableFreeTrialWelcomePage: boolean;
}

interface IState {
    authConfigLoaded: boolean;
    auth: Auth | null;
}

class App extends Component<IProps, IState> {
    state: IState = {
        authConfigLoaded: false,
        auth: null
    };

    componentDidMount() {
        // get the auth config required for authentication
        $http.get(`${config.api.url}/info/auth`).then((res) => {
            // set the auth config
            if (res.data.clientID) {
                let data = res.data;
                if (window.location.pathname === '/callback') {
                    const urlParams = new URLSearchParams(window.location.search);
                    data = {
                        ...data,
                        redirectUri: urlParams.get('redirecturl')
                    };
                }
                const auth = new Auth(data, store);
                this.setState({
                    authConfigLoaded: true,
                    auth
                });
            }
        });
    }

    handleAuthInitiateLogin = () => {
        const { auth } = this.state;
        auth!.loginViaIdPInitiatedSsoFlow();
    };

    handleAuthentication = (): boolean => {
        const { location } = this.props;
        const { auth } = this.state;
        if (/access_token|id_token|error/.test(location.hash)) {
            auth!.handleAuthentication();
            return true;
        } else {
            return false;
        }
    };

    render() {
        const { classes, location, isOnboarding, isSearchbarOpen, enableFreeTrialWelcomePage } = this.props;
        const { authConfigLoaded, auth } = this.state;

        if (!(authConfigLoaded && auth)) {
            return <LoadingIndicator text="Loading Blameless..." />;
        }

        const isAuthenticated = auth!.isAuthenticated;

        return (
            <IntlProvider locale={locale} messages={messages}>
                <div className={classes.root} data-cy="app">
                    <Notifier />
                    {((location.pathname !== '/' && isAuthenticated() && !isOnboarding) ||
                        (location.pathname === '/' && isAuthenticated() && enableFreeTrialWelcomePage)) && (
                        <>
                            <SideNavigation auth={auth!} activeItem={location.pathname} />
                        </>
                    )}
                    <main className={classes.content}>
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                                root: classes.searchRoot
                            }}
                            open={isSearchbarOpen}
                            onClose={() => {}}
                        >
                            <SearchPage />
                        </Drawer>
                        <Suspense fallback={<LoadingPageIndicator />}>
                            <Switch>
                                <Route exact path="/" render={(props) => <Home auth={auth} {...props} />} />
                                <Route
                                    path="/callback"
                                    render={(props: RouteComponentProps) => {
                                        if (this.handleAuthentication()) {
                                            return <Callback />;
                                        } else {
                                            /* If redirecturl query param is present, redirect there */
                                            const redirectUrl = new URLSearchParams(props.location.search).get(
                                                'redirecturl'
                                            );
                                            if (redirectUrl) {
                                                return <Redirect to={redirectUrl} />;
                                            }
                                            return <Redirect to="/" />;
                                        }
                                    }}
                                />
                                <Route
                                    path="/auth/initiate_login"
                                    render={() => {
                                        this.handleAuthInitiateLogin();
                                        return <Callback />;
                                    }}
                                />
                                <Routes auth={auth} />
                            </Switch>
                        </Suspense>
                    </main>
                </div>
            </IntlProvider>
        );
    }
}

const mapStateToProps = ({ settings, search, launchDarkly }) => ({
    isOnboarding: settings.isOnboarding,
    isSearchbarOpen: search.isSearchbarOpen,
    enableFreeTrialWelcomePage: launchDarkly.enableFreeTrialWelcomePage
});

export default connect(mapStateToProps)(withRouter(withStyles(styles)(App)));
