import { $api_identity_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { Dispatch } from 'redux';

export const createSsoSettings = createActionAsync(
    'CREATE_SSO_SETTINGS',
    (data: IAM.ISsoSettings, dispatch: Dispatch): void =>
        $api_identity_v2
            .post({ url: '/sso/connections', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully created SSO settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const updateSsoSettings = createActionAsync(
    'UPDATE_SSO_SETTINGS',
    (data: IAM.ISsoSettings, dispatch: Dispatch): void =>
        $api_identity_v2
            .put({ url: '/sso/connections', data })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Successfully updated SSO settings',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getSsoSettings = createActionAsync(
    'GET_SSO_SETTINGS',
    (_: Dispatch): IAM.ISsoSettings =>
        $api_identity_v2
            .get({ url: '/sso/connections' })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);
