import {
    getSlo,
    getSlos,
    createSLO,
    updateSLO,
    clearSlos,
    getSLITypes,
    getSli,
    getSLIs,
    createSLI,
    updateSLI,
    clearSLIs,
    deleteSli,
    getSlosBySliId,
    getDataProviders,
    getSLIsForService,
    getAppDynamicMetrics,
    getAppDynamicApplications,
    clearAppDApplications,
    clearAppDMetrics,
    getNewRelicApplications,
    getNewRelicApplicationMetrics,
    getPingdomChecks,
    getEnabledDatasources
} from 'src/actions/slo/slo-actions';
import { LoadStatus } from 'src/constants/load-status';
import { format, fromUnixTime } from 'date-fns';
import produce from 'immer';

// graphQL
import { SloFragment, SliTypeFragment } from 'src/graphql/slo';

interface IDefaultState {
    slo: SLO.ISlo | null;
    slos: SloFragment[];
    slosMetadata: Common.IPaginationMetaData;
    sli: SLO.ISli | null;
    slis: SLO.ISli[];
    slisMetadata: Common.IPaginationMetaData;
    sliTypes: SliTypeFragment[];
    appDynamicApplications: AppDynamics.IApplication[];
    dataProviders: {
        datadog: SLO.IDataProvider;
        prometheus: SLO.IDataProvider;
        appdynamics: SLO.IDataProvider;
        newrelic: SLO.IDataProvider;
        'blameless-api': SLO.IDataProvider;
    };
    appDynamicMetrics: AppDynamics.IMetric[];
    sloLoadStatus: string;
    slosLoadStatus: string;
    sliLoadStatus: string;
    sliDeleteLoadStatus: string;
    slisLoadStatus: string;
    sliTypesLoadStatus: string;
    updateSLILoadStatus: string;
    postNewSLILoadStatus: string;
    postNewSLOLoadStatus: string;
    dataProviderLoadStatus: string;
    postUpdateSLOLoadStatus: string;
    slisForServiceLoadStatus: string;
    appDynamicMetricLoadStatus: string;
    appDynamicApplicationLoadStatus: string;
    newRelicApplicationsLoadStatus: string;
    newRelicApplications: NewRelic.IApplication[];
    newRelicMetricsLoadStatus: string;
    newRelicMetrics: NewRelic.IMetricName[];
    pingdomChecks: IIntegration.IPingdomCheck[];
    pingdomChecksLoadStatus: string;
    enabledDatasources: string[];
    enabledDatasourcesLoadStatus: string;
    prometheusSettings?: Settings.IPrometheusMinimalSettings;
}

const defaultPagination = {
    limit: 0,
    offset: 0,
    total: 0,
    pageIndex: 0,
    rowsPerPage: 0,
    totalRows: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false
};

export const defaultState: IDefaultState = {
    slo: null,
    slos: [],
    slosMetadata: { ...defaultPagination },
    sli: null,
    slis: [],
    slisMetadata: { ...defaultPagination },
    sliTypes: [],
    dataProviders: {
        datadog: {
            enabled: false,
            options: {}
        },
        prometheus: {
            enabled: false,
            options: {}
        },
        appdynamics: {
            enabled: false,
            options: {}
        },
        newrelic: {
            enabled: false,
            options: {}
        },
        'blameless-api': {
            enabled: false,
            options: {}
        }
    },
    appDynamicMetrics: [],
    appDynamicApplications: [],
    sloLoadStatus: LoadStatus.EMPTY,
    slosLoadStatus: LoadStatus.EMPTY,
    sliLoadStatus: LoadStatus.EMPTY,
    sliDeleteLoadStatus: LoadStatus.EMPTY,
    slisLoadStatus: LoadStatus.EMPTY,
    sliTypesLoadStatus: LoadStatus.EMPTY,
    updateSLILoadStatus: LoadStatus.EMPTY,
    postNewSLILoadStatus: LoadStatus.EMPTY,
    postNewSLOLoadStatus: LoadStatus.EMPTY,
    dataProviderLoadStatus: LoadStatus.EMPTY,
    postUpdateSLOLoadStatus: LoadStatus.EMPTY,
    slisForServiceLoadStatus: LoadStatus.EMPTY,
    appDynamicMetricLoadStatus: LoadStatus.EMPTY,
    appDynamicApplicationLoadStatus: LoadStatus.EMPTY,
    newRelicApplicationsLoadStatus: LoadStatus.EMPTY,
    newRelicApplications: [],
    newRelicMetricsLoadStatus: LoadStatus.EMPTY,
    newRelicMetrics: [],
    pingdomChecks: [],
    pingdomChecksLoadStatus: LoadStatus.EMPTY,
    enabledDatasources: [],
    enabledDatasourcesLoadStatus: LoadStatus.EMPTY,
    prometheusSettings: undefined
};

export function fromUnixTimeToDate(unixtime: string) {
    const zonedDate = fromUnixTime(parseInt(unixtime));
    return format(zonedDate, 'yyyy-MM-dd hh:mm:ss');
}

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getSlo.request.getType():
                draft.sloLoadStatus = LoadStatus.REQUEST;
                return;
            case getSlo.ok.getType():
                draft.slo = action.payload.response.slo;
                draft.sloLoadStatus = LoadStatus.OK;
                return;
            case getSlo.error.getType():
                draft.sloLoadStatus = LoadStatus.ERROR;
                return;
            case getSlos.request.getType():
                draft.slosLoadStatus = LoadStatus.REQUEST;
                return;
            case getSlos.ok.getType():
                draft.slosLoadStatus = LoadStatus.OK;
                draft.slos = draft.slos.concat(action.payload.response.slos);
                draft.slosMetadata = {
                    ...draft.slosMetadata,
                    ...action.payload.response.metadata
                };
                return;
            case getSlos.error.getType():
                draft.slosLoadStatus = LoadStatus.ERROR;
                return;
            case createSLO.request.getType():
                draft.postNewSLOLoadStatus = LoadStatus.REQUEST;
                return;
            case createSLO.ok.getType():
                draft.postNewSLOLoadStatus = LoadStatus.OK;
                draft.slos.push(action.payload.response.slo);
                return;
            case createSLO.error.getType():
                draft.postNewSLOLoadStatus = LoadStatus.ERROR;
                return;
            case updateSLO.request.getType():
                draft.postUpdateSLOLoadStatus = LoadStatus.REQUEST;
                return;
            case updateSLO.ok.getType():
                draft.postUpdateSLOLoadStatus = LoadStatus.OK;
                return;
            case updateSLO.error.getType():
                draft.postUpdateSLOLoadStatus = LoadStatus.ERROR;
                return;
            case clearSlos.getType():
                draft.slos = [];
                draft.slo = null;
                draft.slosMetadata = { ...defaultPagination };
                draft.postNewSLOLoadStatus = LoadStatus.EMPTY;
                draft.postUpdateSLOLoadStatus = LoadStatus.EMPTY;
                draft.slosLoadStatus = LoadStatus.EMPTY;
                draft.sloLoadStatus = LoadStatus.EMPTY;
                return;
            case getSli.request.getType():
                draft.sliLoadStatus = LoadStatus.REQUEST;
                return;
            case getSli.ok.getType():
                draft.sli = action.payload.response.sli;
                draft.sliLoadStatus = LoadStatus.OK;
                return;
            case getSli.error.getType():
                draft.sliLoadStatus = LoadStatus.ERROR;
                return;
            case getSLIs.request.getType():
                draft.slisForServiceLoadStatus = LoadStatus.REQUEST;
                return;
            case getSLIs.ok.getType():
                draft.slis = action.payload.response.slis;
                draft.slisForServiceLoadStatus = LoadStatus.OK;
                return;
            case getSLIs.error.getType():
                draft.slisForServiceLoadStatus = LoadStatus.ERROR;
                return;
            case createSLI.request.getType():
                draft.postNewSLILoadStatus = LoadStatus.REQUEST;
                return;
            case createSLI.ok.getType():
                draft.postNewSLILoadStatus = LoadStatus.OK;
                draft.slis.push(action.payload.response.sli);
                draft.slisMetadata = {
                    ...draft.slisMetadata,
                    ...action.payload.response.metadata
                };
                return;
            case createSLI.error.getType():
                draft.postNewSLILoadStatus = LoadStatus.ERROR;
                return;
            case updateSLI.request.getType():
                draft.updateSLILoadStatus = LoadStatus.REQUEST;
                return;
            case updateSLI.ok.getType():
                draft.sli = action.payload.response.sli;
                draft.updateSLILoadStatus = LoadStatus.OK;
                return;
            case updateSLI.error.getType():
                draft.updateSLILoadStatus = LoadStatus.ERROR;
                return;
            case getSLIsForService.request.getType():
                draft.slisForServiceLoadStatus = LoadStatus.REQUEST;
                return;
            case getSLIsForService.ok.getType():
                draft.slisForServiceLoadStatus = LoadStatus.OK;
                draft.slis = action.payload.response.slis;
                return;
            case getSLIsForService.error.getType():
                draft.slisForServiceLoadStatus = LoadStatus.ERROR;
                return;
            case getSLITypes.getType():
                draft.sliTypesLoadStatus = LoadStatus.REQUEST;
                draft.sliTypes = action.payload.sliTypes;
                return;
            case clearSLIs.getType():
                draft.slis = [];
                draft.sli = null;
                draft.slisMetadata = { ...defaultPagination };
                draft.postNewSLILoadStatus = LoadStatus.EMPTY;
                return;
            case getAppDynamicApplications.request.getType():
                draft.appDynamicApplicationLoadStatus = LoadStatus.REQUEST;
                return;
            case getAppDynamicApplications.ok.getType():
                draft.appDynamicApplications = action.payload.response.applications;
                draft.appDynamicApplicationLoadStatus = LoadStatus.OK;
                return;
            case getAppDynamicApplications.error.getType():
                draft.appDynamicApplicationLoadStatus = LoadStatus.ERROR;
                return;
            case getAppDynamicMetrics.request.getType():
                draft.appDynamicMetricLoadStatus = LoadStatus.REQUEST;
                return;
            case getAppDynamicMetrics.ok.getType():
                draft.appDynamicMetrics = action.payload.response.metrics;
                draft.appDynamicMetricLoadStatus = LoadStatus.OK;
                return;
            case getAppDynamicMetrics.error.getType():
                draft.appDynamicMetricLoadStatus = LoadStatus.ERROR;
                return;
            case getDataProviders.request.getType():
                draft.dataProviderLoadStatus = LoadStatus.REQUEST;
                return;
            case getDataProviders.ok.getType():
                draft.dataProviders = action.payload.response.dataProviders;
                draft.dataProviderLoadStatus = LoadStatus.OK;
                return;
            case getDataProviders.error.getType():
                draft.dataProviderLoadStatus = LoadStatus.ERROR;
                return;
            case deleteSli.request.getType():
                draft.sliDeleteLoadStatus = LoadStatus.REQUEST;
                return;
            case deleteSli.ok.getType():
                draft.slis = draft.slis.filter((sli) => sli.id !== action.payload.response.sli.id);
                draft.sliDeleteLoadStatus = LoadStatus.OK;
                return;
            case deleteSli.error.getType():
                draft.sliDeleteLoadStatus = LoadStatus.ERROR;
                return;
            case getSlosBySliId.request.getType():
                draft.slosLoadStatus = LoadStatus.REQUEST;
                return;
            case getSlosBySliId.ok.getType():
                draft.slos = action.payload.response.slos;
                draft.slosLoadStatus = LoadStatus.OK;
                return;
            case getSlosBySliId.error.getType():
                draft.slosLoadStatus = LoadStatus.ERROR;
                return;
            case clearAppDApplications.getType():
                draft.appDynamicApplications = [];
                draft.appDynamicApplicationLoadStatus = LoadStatus.EMPTY;
                return;
            case clearAppDMetrics.getType():
                draft.appDynamicMetrics = [];
                draft.appDynamicMetricLoadStatus = LoadStatus.EMPTY;
                return;
            case getNewRelicApplications.request.getType():
                draft.newRelicApplicationsLoadStatus = LoadStatus.REQUEST;
                return;
            case getNewRelicApplications.ok.getType():
                draft.newRelicApplications = action.payload.response.applications;
                draft.newRelicApplicationsLoadStatus = LoadStatus.OK;
                return;
            case getNewRelicApplications.error.getType():
                draft.newRelicApplicationsLoadStatus = LoadStatus.ERROR;
                return;
            case getNewRelicApplicationMetrics.request.getType():
                draft.newRelicMetricsLoadStatus = LoadStatus.REQUEST;
                return;
            case getNewRelicApplicationMetrics.ok.getType():
                draft.newRelicMetrics = action.payload.response.metricsNames;
                draft.newRelicMetricsLoadStatus = LoadStatus.OK;
                return;
            case getNewRelicApplicationMetrics.error.getType():
                draft.newRelicMetricsLoadStatus = LoadStatus.ERROR;
                return;
            case getPingdomChecks.request.getType():
                draft.pingdomChecksLoadStatus = LoadStatus.REQUEST;
                return;
            case getPingdomChecks.ok.getType():
                draft.pingdomChecksLoadStatus = LoadStatus.OK;
                draft.pingdomChecks = action.payload.response.response.checks;
                return;
            case getPingdomChecks.error.getType():
                draft.pingdomChecksLoadStatus = LoadStatus.ERROR;
                return;
            case getEnabledDatasources.request.getType():
                draft.enabledDatasourcesLoadStatus = LoadStatus.REQUEST;
                return;
            case getEnabledDatasources.ok.getType():
                draft.enabledDatasourcesLoadStatus = LoadStatus.OK;
                draft.enabledDatasources = action.payload.response.vendors;
                draft.prometheusSettings = action.payload.response.prometheusSettings
                    ? action.payload.response.prometheusSettings
                    : undefined;
                return;
            case getEnabledDatasources.error.getType():
                draft.enabledDatasourcesLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
