import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'src/store';
import { ConnectedRouter } from 'connected-react-router';
import history from 'src/history';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { blamelessTheme } from '@blamelesshq/blameless-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Favicon from 'react-favicon';
import { BlamelessFavicon } from 'src/constants/images';
import { SingletonHooksContainer } from 'react-singleton-hook';

// Custom Providers
import SnackbarProvider from 'src/providers/snackbar-provider';
import ApolloProvider from 'src/providers/apollo-provider';

const AppProviders: React.FC = ({ children }) => {
    return (
        <ReduxProvider store={store}>
            <ConnectedRouter history={history}>
                <MuiThemeProvider theme={blamelessTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <SnackbarProvider>
                            <ApolloProvider>
                                <Favicon url={BlamelessFavicon} />
                                <SingletonHooksContainer />
                                {children}
                            </ApolloProvider>
                        </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider>
            </ConnectedRouter>
        </ReduxProvider>
    );
};

export default AppProviders;
