import {
    deleteComment,
    getComments,
    getMentionSuggestions,
    postComment,
    registerComponentRef,
    resolveComment
} from 'src/actions/comment/comment-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    comments: { [componentRef: string]: IComment.IComment[] };
    commentsLoadStatus: string;
    mentionSuggestions: IComment.IComment[];
    mentionSuggestionsLoadStatus: string;
    registeredComponentRefs: { [componentRef: string]: boolean };
    registerComponentRefLoadStatus: string;
    deleteCommentLoadStatus: string;
    resolveCommentLoadStatus: string;
    postCommentLoadStatus: string;
}

const defaultState: IDefaultState = {
    comments: {},
    commentsLoadStatus: LoadStatus.EMPTY,
    mentionSuggestions: [],
    mentionSuggestionsLoadStatus: LoadStatus.EMPTY,
    postCommentLoadStatus: LoadStatus.EMPTY,
    registeredComponentRefs: {},
    registerComponentRefLoadStatus: LoadStatus.EMPTY,
    deleteCommentLoadStatus: LoadStatus.EMPTY,
    resolveCommentLoadStatus: LoadStatus.EMPTY
};

let componentRef;

export default function (state: IDefaultState = defaultState, action: IComment.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getComments.request.getType():
                draft.commentsLoadStatus = LoadStatus.REQUEST;
                return;
            case getComments.ok.getType():
                componentRef = action.payload.request[0];
                draft.commentsLoadStatus = LoadStatus.OK;
                draft.comments = {
                    ...draft.comments,
                    ...{ [componentRef]: action.payload.response.response }
                };
                return;
            case getComments.error.getType():
                draft.commentsLoadStatus = LoadStatus.ERROR;
                return;
            case registerComponentRef.request.getType():
                draft.registerComponentRefLoadStatus = LoadStatus.REQUEST;
                return;
            case registerComponentRef.ok.getType():
                componentRef = action.payload.request[0];
                draft.registerComponentRefLoadStatus = LoadStatus.OK;
                draft.registeredComponentRefs = {
                    ...draft.registeredComponentRefs,
                    ...{ [componentRef]: action.payload.response.response === 'ok' }
                };
                return;
            case registerComponentRef.error.getType():
                draft.registerComponentRefLoadStatus = LoadStatus.ERROR;
                return;
            case getMentionSuggestions.request.getType():
                draft.mentionSuggestionsLoadStatus = LoadStatus.REQUEST;
                return;
            case getMentionSuggestions.ok.getType():
                draft.mentionSuggestionsLoadStatus = LoadStatus.OK;
                draft.mentionSuggestions = action.payload.response.result.rows;
                return;
            case getMentionSuggestions.error.getType():
                draft.mentionSuggestionsLoadStatus = LoadStatus.ERROR;
                return;
            case postComment.request.getType():
                draft.postCommentLoadStatus = LoadStatus.REQUEST;
                return;
            case postComment.ok.getType():
                componentRef = action.payload.request[0].componentRef;
                const postedComment = action.payload.response.response.comment;
                draft.postCommentLoadStatus = LoadStatus.OK;
                if (postedComment.hasOwnProperty('parentId') && typeof postedComment.parentId === 'number') {
                    draft.comments[componentRef] = draft.comments[componentRef].map((comment) => {
                        if (comment.id === postedComment.parentId) {
                            if (comment.replies) {
                                comment.replies.push(postedComment);
                            } else {
                                comment.replies = [postedComment];
                            }
                        }
                        return comment;
                    });
                } else {
                    draft.comments[componentRef].push(postedComment);
                }
                return;
            case postComment.error.getType():
                draft.postCommentLoadStatus = LoadStatus.ERROR;
                return;
            case deleteComment.request.getType():
                draft.deleteCommentLoadStatus = LoadStatus.REQUEST;
                return;
            case deleteComment.ok.getType():
                componentRef = action.payload.request[1];
                const parentId = action.payload.request[2];
                draft.deleteCommentLoadStatus = LoadStatus.OK;
                if (typeof parentId === 'number') {
                    draft.comments[componentRef] = draft.comments[componentRef].map((comment) => {
                        if (comment.id === parentId) {
                            comment.replies = comment.replies!.filter(
                                (c) => c.id !== action.payload.response.response.id
                            );
                        }
                        return comment;
                    });
                } else {
                    draft.comments[componentRef] = [
                        ...draft.comments[componentRef].filter(
                            (comment) => comment.id !== action.payload.response.response.id
                        )
                    ];
                }
                return;
            case deleteComment.error.getType():
                draft.deleteCommentLoadStatus = LoadStatus.ERROR;
                return;
            case resolveComment.request.getType():
                draft.resolveCommentLoadStatus = LoadStatus.REQUEST;
                return;
            case resolveComment.ok.getType():
                componentRef = action.payload.request[1];
                draft.resolveCommentLoadStatus = LoadStatus.OK;
                draft.comments[componentRef] = draft.comments[componentRef].map((comment) => {
                    if (comment.id === action.payload.request[0]) {
                        comment = {
                            replies: comment.replies,
                            ...action.payload.response.response.comment
                        };
                    }
                    return comment;
                });
                return;
            case resolveComment.error.getType():
                draft.resolveCommentLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
