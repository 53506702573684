import { setAuthProfile, showLoginErrorMessage, getBlamelessId } from 'src/actions/auth/auth-actions';
import { Store } from 'redux';

import Cookies from 'universal-cookie';
import Timeout from 'smart-timeout';
import auth0 from 'auth0-js';
const config = ShowcaseConfig;
import history from '../history';

import { hashToSha256 } from 'src/utils/common-utils';
import { PENDO_SALT } from 'src/constants/common';
import SectionURLs from 'src/constants/urls';

const newWindowObject = window as any;
const pendo = newWindowObject.pendo;

export default class Auth {
    auth0;
    userProfile;
    tokenRenewalTimeout;
    store: Store;

    constructor(authConfig, store) {
        this.store = store;
        window.timerLog = null;

        if (authConfig) {
            this.scheduleRenewal();
            let redirectUri = `${window.location.origin}/callback`;
            const pathname = `${window.location.pathname}${window.location.search}`;
            if (pathname && pathname !== '/') {
                const setPathname = pathname === '/callback' ? '' : pathname;
                redirectUri = `${redirectUri}?redirecturl=${
                    config.redirectUri
                        ? config.redirectUri
                        : pathname === '/callback'
                        ? ''
                        : encodeURIComponent(setPathname)
                }`;
            }
            this.auth0 = new auth0.WebAuth({
                domain: authConfig.domain,
                clientID: authConfig.clientID,
                redirectUri,
                audience: authConfig.audience,
                responseType: authConfig.responseType,
                scope: authConfig.scope
            });

            const url = new URL(window.location.href);
            const urlParams = new URLSearchParams(url.searchParams);
            const tempRedirectUrl = decodeURIComponent(urlParams.get('redirecturl') ?? '');

            const redirectUrl = localStorage.getItem('redirect_url');

            if (
                redirectUrl !== tempRedirectUrl &&
                tempRedirectUrl !== '' &&
                tempRedirectUrl !== null &&
                tempRedirectUrl !== '/auth/initiate_login' &&
                tempRedirectUrl !== '/callback'
            ) {
                localStorage.setItem('redirect_url', tempRedirectUrl);
            }

            const userProfile = this.getLocalProfile();
            if (!userProfile) {
                this.initializePendo({
                    email: '',
                    name: ''
                });
            } else {
                this.store.dispatch(getBlamelessId()).then(({ response }) => {
                    this.initializePendo(response.response);
                });
            }
        }
    }

    static getAccessToken() {
        const isExpired = new Date().getTime() > JSON.parse(localStorage.getItem('expires_at')!);
        if (!isExpired) {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) {
                throw new Error('No access token found');
            }
            return accessToken;
        }

        return undefined;
    }

    static getLocalStorageProfile() {
        const localProfile = localStorage.getItem('profile');
        if (localProfile) {
            return JSON.parse(localProfile);
        }
        return null;
    }

    initializePendo = (authProfile) => {
        const {
            baseOptions: { audience }
        } = this.auth0;
        // extract clientName from domain 'blamelesscre.blameless.io' => blamelesscre
        const clientAccountId =
            audience.includes('localhost') || audience.includes('127.0.0.1') ? audience : audience.split('.')[0];

        const hostname = window.location.hostname;
        Promise.all([
            hashToSha256(`${hostname}${authProfile.email}`, PENDO_SALT),
            hashToSha256(`${hostname}${authProfile.name}`, PENDO_SALT)
        ]).then(([emailHash, nameHash]) => {
            // https://app.pendo.io/setup?setupKey=eyJTdWJzY3JpcHRpb25OYW1lIjoiQmxhbWVsZXNzIiwiSG1hYyI6ImVLWG1ab2g0eTBYV01OS1dXbFFuaHZKYURWT3k4eWJEZWt6ZEdGV19QQUU9In0
            if (pendo?.initialize) {
                pendo.initialize({
                    visitor: {
                        id: emailHash,
                        email: emailHash,
                        full_name: nameHash
                    },
                    account: {
                        id: clientAccountId
                    }
                });
            }
        });
    };

    isAuthenticated = () => {
        const expiresAt = JSON.parse(localStorage.getItem('expires_at')!);
        return new Date().getTime() < expiresAt;
    };

    getProfile = (cb) => {
        const accessToken = Auth.getAccessToken();
        const userProfile = this.getLocalProfile();
        if (userProfile) {
            this.store.dispatch(setAuthProfile(userProfile));
            return cb(null, userProfile);
        }
        this.auth0.client.userInfo(accessToken, (err, profile) => {
            if (profile) {
                return cb(null, this.setLocalProfile(profile));
            }
            return cb(err, {});
        });
    };

    handleAuthentication = () => {
        this.auth0.parseHash((err, authResult) => {
            if (authResult?.accessToken && authResult.idToken) {
                this.setSession(authResult);
            } else if (err) {
                history.push('/');
                this.store.dispatch(showLoginErrorMessage(err));
            }
        });
    };

    login = () => {
        localStorage.removeItem('redirect_url');
        this.auth0.authorize({
            access_type: 'offline'
        });
    };

    loginViaIdPInitiatedSsoFlow = () => {
        localStorage.removeItem('redirect_url');
        const redirectUri = `${window.location.origin}/callback`;
        this.auth0.authorize({
            prompt: 'none',
            redirectUri: redirectUri,
            access_type: 'offline'
        });
    };

    logout = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('redirect_url');
        localStorage.removeItem('profile');

        Timeout.clear('jwtRefresh');

        this.auth0.logout({
            returnTo: window.location.origin
        });
    };

    renewToken = () => {
        this.auth0.checkSession({}, (err, result) => {
            if (err) {
                this.logout();
            } else {
                localStorage.setItem('redirect_url', `${window.location.pathname}${window.location.search}`);
                this.setSession(result);
            }
        });
    };

    scheduleRenewal = () => {
        const expiresAt = JSON.parse(localStorage.getItem('expires_at')!);
        const delay = expiresAt - Date.now();
        if (delay > 0 && !(Timeout.exists('jwtRefresh') && Timeout.pending('jwtRefresh'))) {
            Timeout.set('jwtRefresh', this.renewToken.bind(this), delay);
        }
    };

    setSession = (authResult) => {
        // Set the time that the access token will expire at
        const nowDate = new Date();
        const expiresAtDateAsStr = JSON.stringify(nowDate.setTime(authResult.expiresIn * 1000 + nowDate.getTime()));

        const expiresAtDate = nowDate;
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAtDateAsStr);

        this.setAuthCookie(authResult, expiresAtDate);
        this.setLocalProfile(authResult.idTokenPayload);

        // schedule a token renewal
        this.scheduleRenewal();

        let redirectPage = '/';

        const redirectUrl = localStorage.getItem('redirect_url');

        if (redirectUrl === SectionURLs.SLACK_AUTH_FLOW_URL) {
            localStorage.removeItem('redirect_url');
        }

        if (redirectUrl) {
            redirectPage = redirectUrl;
        }
        history.push(redirectPage);
    };

    setLocalProfile = (profile) => {
        if (profile) {
            const cleansedProfile = (() => {
                if ('at_hash' in profile) {
                    delete profile.at_hash;
                }
                if ('aud' in profile) {
                    delete profile.aud;
                }
                if ('exp' in profile) {
                    delete profile.exp;
                }
                if ('iat' in profile) {
                    delete profile.iat;
                }
                if ('iss' in profile) {
                    delete profile.iss;
                }
                if ('nonce' in profile) {
                    delete profile.nonce;
                }
                return profile;
            })();
            localStorage.setItem('profile', JSON.stringify(cleansedProfile));

            // Associate User to Fullstory session
            if (window.hasOwnProperty('FS')) {
                window.FS.identify(cleansedProfile.sub, {
                    displayName: cleansedProfile.name,
                    email: cleansedProfile.email
                });
            }

            this.userProfile = cleansedProfile;
            this.store.dispatch(setAuthProfile(cleansedProfile));
            return cleansedProfile;
        }
    };

    getLocalProfile = () => {
        if (this.userProfile) {
            return this.userProfile;
        }

        const localProfile = localStorage.getItem('profile');
        if (localProfile) {
            return JSON.parse(localProfile);
        }

        return null;
    };

    setAuthCookie = (authResult, expiresAtDate) => {
        const cookieDomain =
            config.env === 'prod' ? `.${window.location.hostname}` : config.collaborativePostmortem.hostname;

        const cookies = new Cookies();
        cookies.set('access_token', authResult.accessToken, {
            expires: expiresAtDate,
            domain: cookieDomain,
            path: '/',
            secure: true
        });
    };
}
