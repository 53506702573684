import $api from 'src/utils/api-interface';
import { createActionAsync } from 'redux-act-async';
import { createAction } from 'redux-act';
import { Dispatch } from 'redux';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';

export const getErrorBudgetPolycies = createActionAsync('GET_ERROR_BUDGET_POLICIES', () =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetErrorBudgetPolicies', data: {} })
        .then((res: { data: any }) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getErrorBudgetPolicyById = createActionAsync('GET_ERROR_BUDGET_POLICY_BY_ID', (id: string) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetErrorBudgetPolicy', data: { id } })
        .then((res: { data: any }) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getLimitedSlosForErrorBudgetPolicy = createActionAsync('GET_SLOS_FOR_EBP', (errorBudgetPolicyId: number) =>
    $api
        .post({
            url: '/services/SLOServiceCrud/GetSLOsByErrorBudgetPolicyId',
            data: { errorBudgetPolicyId, options: { rowsPerPage: 1 } }
        })
        .then((res: { data: any }) => res.data as SLO.ISLOPaginatedResult)
        .catch((error) => {
            throw error;
        })
);

export const postNewErrorBudgetPolicy = createActionAsync(
    'POST_NEW_ERROR_BUDGET_POLICY',
    (newPolicy: ErrorBudget.IErrorBudgetPolicyPost, dispatch: Dispatch) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/CreateErrorBudgetPolicy',
                data: {
                    model: newPolicy
                }
            })
            .then((res: { data: any }) => {
                const newPolicy = res.data.errorBudgetPolicy as ErrorBudget.IErrorBudgetPolicy;
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully created ${newPolicy.name}`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return newPolicy;
            })
            .catch((error) => {
                throw error;
            })
);

export const updateExistingErrorBudgetPolicy = createActionAsync(
    'UPDATE_EXISTING_ERROR_BUDGET_POLICY',
    (id: string | number, updatedPolicy: ErrorBudget.IErrorBudgetPolicyPost, dispatch: Dispatch) =>
        $api
            .post({ url: `/services/SLOServiceCrud/UpdateErrorBudgetPolicy`, data: { id, model: updatedPolicy } })
            .then((res: { data: any }) => {
                const updatedPolicy = res.data.errorBudgetPolicy as ErrorBudget.IErrorBudgetPolicy;
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully updated ${updatedPolicy.name}`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return updatedPolicy;
            })
            .catch((error) => {
                throw error;
            })
);

export const deleteErrorBudgetPolicy = createActionAsync(
    'DELETE_ERROR_BUDGET_POLICY',
    (id: number, dispatch: Dispatch) =>
        $api
            .post({ url: '/services/SLOServiceCrud/DeleteErrorBudgetPolicy', data: { id } })
            .then((res: { data: any }) => {
                const errorBudgetPolicy = res.data.errorBudgetPolicy as ErrorBudget.IErrorBudgetPolicy;
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully deleted error budget policy: ${errorBudgetPolicy.name}`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return errorBudgetPolicy;
            })
            .catch((error) => {
                throw error;
            })
);

export const getErrorBudgetDatasBySloId = createActionAsync('GET_ERROR_BUDGET_DATAS_BY_SLO_ID', (sloId: number) =>
    $api
        .post({
            url: '/services/SLOServiceCrud/GetErrorBudgetDatasBySloId',
            data: { sloId, options: { rowsPerPage: 1000 } }
        })
        .then((res: { data: any }) => res.data as ErrorBudget.IErrorBudgetData[])
        .catch((error) => {
            throw error;
        })
);

export const getErrorBudgetChartData = createActionAsync('GET_ERROR_BUDGET_CHART_DATA', (sloId: number) =>
    $api
        .post({ url: '/services/SLOService/GetErrorBudgetDataChart', data: { sloId } })
        .then((res: { data: any }) => res.data as ErrorBudget.IChartData[])
        .catch((error) => {
            throw error;
        })
);

export const getErrorBudgetPolicyThresholdsByErrorBudgetPolicyId = createActionAsync(
    'GET_ERROR_BUDGET_POLICY_THRESHOLDS_BY_ERROR_BUDGET_POLICY_ID',
    (errorBudgetPolicyId: string) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/GetErrorBudgetPolicyThresholdsByErrorBudgetPolicyId',
                data: { errorBudgetPolicyId }
            })
            .then((res: { data: any }) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const updateErrorBudgetPolicyThreshold = createActionAsync(
    'UPDATE_ERROR_BUDGET_POLICY_THRESHOLD',
    (thresholdId: number, updatedThreshold: ErrorBudget.IThresholdUpdateModel) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/UpdateErrorBudgetPolicyThreshold',
                data: {
                    id: thresholdId,
                    model: updatedThreshold
                }
            })
            .then((res: { data: any }) => {
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const createErrorBudgetPolicyThreshold = createActionAsync(
    'CREATE_ERROR_BUDGET_POLICY_THRESHOLD',
    (updatedThreshold: ErrorBudget.IThresholdUpdateModel) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/CreateErrorBudgetPolicyThreshold',
                data: {
                    model: updatedThreshold
                }
            })
            .then((res: { data: any }) => {
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getSLOsByErrorBudgetPolicyId = createActionAsync('GET_SLOS_BY_ERROR_BUDGET_POLICY_ID', (id: string) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetSLOsByErrorBudgetPolicyId', data: { errorBudgetPolicyId: id } })
        .then((res: { data: any }) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const clearErrorBudgetDatas = createAction('CLEAR_ERROR_BUDGET_DATAS');

export const createManualMinute = createActionAsync(
    'CREATE_MANUAL_MINUTE',
    (sloId: number, comment: string, durationSeconds: number, dispatch: Dispatch) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/CreateManualMinute',
                data: {
                    model: {
                        duration: durationSeconds,
                        comment: comment,
                        sloId: sloId,
                        userId: 1, // TODO: remove when backend fixes requirements
                        violationSecondsForThisCycle: durationSeconds
                    }
                }
            })
            .then((res) => {
                dispatch(
                    enqueueSnackbar({
                        message: 'Error budget updated',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const getManualMinutesBySloId = createActionAsync('GET_MANUAL_MINUTES_BY_SLO_ID', (sloId: string) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetManualMinutesBySloId', data: { sloId } })
        .then((res: { data: any }) => {
            return {
                sloId,
                manualMinutes: res.data.manualMinutes
            };
        })
        .catch((error) => {
            throw error;
        })
);

export const getCommentCountForErrorBudgetData = createActionAsync(
    'GET_COMMENT_COUNT_FOR_ERROR_BUDGET_DATA',
    (errorBudgetDataIds: number[]) => {
        const data = {
            items: [
                {
                    formula: '[comment_info.resource_id]',
                    grouped: true,
                    filters: [{ operator: 'in', values: errorBudgetDataIds }],
                    header: 'resourceId'
                },
                {
                    formula: '[comment_info.resource_type]',
                    grouped: true,
                    filters: [{ operator: 'in', values: ['error_budget_data'] }]
                },
                {
                    formula: '[comment.id]',
                    aggregate: 'count',
                    header: 'count'
                }
            ]
        };
        return $api
            .post({ url: '/topics/comment/execute', data })
            .then((res: { data: any }) => res.data)
            .catch((error) => {
                throw error;
            });
    }
);

export const recalculateErrorBudget = createActionAsync(
    'RECALCULATE_ERROR_BUDGET',
    (sloID: number, from_time: number, to_time: number) =>
        $api
            .post({ url: `slo-updater/recalculate-error-budget/${sloID}`, data: { from_time, to_time } })
            .then(({ data }) => data)
            .catch((error) => {
                throw error;
            })
);

export const clearErrorBudgetDataComments = createAction('CLEAR_ERROR_BUDGET_DATA_COMMENTS');
