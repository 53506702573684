import {
    deleteIncident,
    getIncidentList,
    getMoreIncidents,
    createIncident
} from 'src/actions/incidents/incident-list-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    loadStatus: string;
    getMoreIncidentsStatus: string;
    searchStatus: string;
    deleteIncidentStatus: string;
    createIncidentStatus: string;
    incidents: IIncident.IIncidentItem[];
    filteredIncidents: IIncident.IIncidentItem[];
    count: number;
    error: string | null;
}

export const defaultState: IDefaultState = {
    loadStatus: LoadStatus.EMPTY,
    getMoreIncidentsStatus: LoadStatus.EMPTY,
    searchStatus: LoadStatus.EMPTY,
    deleteIncidentStatus: LoadStatus.EMPTY,
    createIncidentStatus: LoadStatus.EMPTY,
    incidents: [],
    filteredIncidents: [],
    count: 0,
    error: null
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getIncidentList.request.getType():
                draft.loadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentList.ok.getType():
                draft.incidents = action.payload.response.incidents;
                draft.count = action.payload.response.pagination.count;
                draft.loadStatus = LoadStatus.OK;
                return;
            case getIncidentList.error.getType():
                draft.loadStatus = LoadStatus.ERROR;
                return;
            case getMoreIncidents.request.getType():
                draft.getMoreIncidentsStatus = LoadStatus.REQUEST;
                return;
            case getMoreIncidents.ok.getType():
                draft.incidents = state.incidents.concat(action.payload.response.incidents);
                draft.count = action.payload.response.pagination.count;
                draft.getMoreIncidentsStatus = LoadStatus.OK;
                return;
            case getMoreIncidents.error.getType():
                draft.getMoreIncidentsStatus = LoadStatus.ERROR;
                return;
            case deleteIncident.request.getType():
                draft.deleteIncidentStatus = LoadStatus.REQUEST;
                return;
            case deleteIncident.ok.getType():
                draft.incidents = state.incidents.filter(
                    (incident) => incident._id !== action.payload.response.incident._id
                );
                draft.deleteIncidentStatus = LoadStatus.OK;
                return;
            case deleteIncident.error.getType():
                draft.deleteIncidentStatus = LoadStatus.ERROR;
                return;
            case createIncident.request.getType():
                draft.createIncidentStatus = LoadStatus.REQUEST;
                return;
            case createIncident.ok.getType():
                draft.incidents = [...state.incidents, action.payload.response.incident];
                draft.createIncidentStatus = LoadStatus.OK;
                return;
            case createIncident.error.getType():
                draft.createIncidentStatus = LoadStatus.ERROR;
                return;
        }
    });
}
