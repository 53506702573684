import { clearSearchResults, searchIncidents, toggleSearchSidebar } from 'src/actions/search/search-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    isSearchbarOpen: boolean;
    searchStatus: string;
    searchResults: any[];
    searchString: string;
    count: number;
    error: string | null;
}

const defaultState: IDefaultState = {
    isSearchbarOpen: false,
    searchStatus: LoadStatus.EMPTY,
    searchResults: [],
    searchString: '',
    count: 0,
    error: null
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case clearSearchResults.getType():
                draft.searchResults = [];
                return;
            case toggleSearchSidebar.getType():
                draft.isSearchbarOpen = !draft.isSearchbarOpen;
                return;
            case searchIncidents.request.getType():
                draft.searchStatus = LoadStatus.REQUEST;
                draft.searchString = action.payload;
                return;
            case searchIncidents.ok.getType():
                draft.searchResults = action.payload.response.incidents;
                draft.searchStatus = LoadStatus.OK;
                return;
            case searchIncidents.error.getType():
                draft.searchStatus = LoadStatus.ERROR;
                return;
        }
    });
}
