import { getConfigurationIncidentTypes } from 'src/actions/configuration/configuration-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    getIncidentTypesLoadStatus: string;
    incidentTypes: IConfiguration.IIncidentType[];
}

export const defaultState: IDefaultState = {
    getIncidentTypesLoadStatus: LoadStatus.EMPTY,
    incidentTypes: []
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getConfigurationIncidentTypes.request.getType():
                draft.getIncidentTypesLoadStatus = LoadStatus.REQUEST;
                return;
            case getConfigurationIncidentTypes.ok.getType():
                draft.incidentTypes = action.payload.response.incidentTypes;
                draft.getIncidentTypesLoadStatus = LoadStatus.OK;
                return;
            case getConfigurationIncidentTypes.error.getType():
                draft.getIncidentTypesLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
