import {
    SettingsRPCMethod,
    getAutocompleteOptions,
    getSelectSearchData,
    setWebhooksMigrationId,
    getV2WebHooks,
    getV3WebHooks,
    setIsOnboarding,
    setSchemaFormInvalid,
    createIncidentType,
    updateSettings,
    clearSettingsSection,
    getSettingsScopeSectionsByRoles,
    clearGoToMeetingOAuthUrl,
    setSettingsSection,
    GET_SETTING_SECTION,
    GET_USER_TIMEZONE,
    GET_SLACK_OAUTH_URL,
    GET_MICROSOFT_OAUTH_URL,
    GET_MICROSOFT_MEETING_OAUTH_URL,
    GET_INTEGRATION_PROBES,
    GET_SETTINGS_SECTION_MENU_DATA,
    GET_PAGERDUTY_RESPONDERS,
    GET_GOOGLE_CALENDAR_OAUTH_URL,
    GET_GOTOMEETING_OAUTH_URL,
    setIsMigrationWebhookModal,
    setSettingsLoadStatus,
    GET_JIRA_OAUTH_URL,
    POST_JIRA_OAUTH_ACCESS_CODE,
    createWebhook,
    setIsCreateWebhookModal,
    editSettings,
    getTagCategories,
    addTagCategory,
    setTagCategoryFilterText,
    setTagCategorySortBy,
    setTagCategorySortDirection,
    updateTagCategory,
    addTagToCategory,
    deleteTagCategory,
    removeTagFromCategory,
    addIncidentTypeToCategory,
    removeIncidentTypeFromCategory,
    addExpandedTagCategory,
    removeExpandedTagCategory,
    importPDServices,
    duplicateTagCategory,
    setTagCategoryStatusFilter,
    restoreTagCategory,
    mergeTagCategories,
    setIncidentTypeSortDirection,
    setIncidentTypeStatusFilter,
    setIncidentTypeFilterText,
    setIncidentTypeSortBy,
    setAreErrorsMuted
} from 'src/actions/settings/settings-page-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

export const SORT_BY = {
    NAME: 'name',
    CREATED: 'created',
    UPDATED: 'updated'
};

export const SORT_DIRECTION = {
    ASCENDING: 'asc',
    DESCENDING: 'desc'
};

export const CATEGORY_STATUS_FILTER = {
    ARCHIVED: 'archived',
    ACTIVE: 'active'
};

export const INCIDENT_TYPE_STATUS_FILTER = {
    ACTIVE: 'active',
    INACTIVE: 'inactive'
};

interface PDResponder {
    field_name: string;
    label: string;
    value: string;
    url: string;
    teamID?: string;
}

interface IDefaultState {
    updateSettingsLoadStatus: string;
    slackOAuthUrlLoadStatus: string;
    googleCalendarOAuthUrlLoadStatus: string;
    autocompleteOptionsLoadStatus: string;
    selectSearchDataLoadStatus: string;
    settingsRPCMethodLoadStatus: string;
    getUserTimezoneLoadStatus: string;
    getSettingsSectionWithSubStatus: string;
    getSettingsSectionMenuDataStatus: string;
    getSettingsScopeSectionsByRolesStatus: string;
    getIntegrationProbesStatus: string;
    getPagerdutyRespondersLoadStatus: string;
    getGoogleCalendarOAuthUrl: string;
    getSlackOAuthUrl: string;
    getMicrosoftOAuthUrl: string;
    getMicrosoftMeetingOAuthUrl: string;
    getGoToMeetingOAuthUrlStatus: string;
    getJiraOAuthUrlStatus: string;
    postJiraOAuthAccessCode: string;
    autocompleteOptions: Record<string, any>;
    slackOAuthUrl: string;
    msteamOAuthUrl: string;
    msteamMeetingOAuthUrl: string;
    googleCalendarOAuthUrl: string;
    gotoMeetingOAuthUrl: string;
    jiraOAuthUrl: string;
    settings: any[];
    pagerdutyResponders: PDResponder[];
    settingsMenuData: Record<string, Settings.IMetaData | Settings.IIncidentTypesMeta[]>;
    settingsSectionInfoBanners: Settings.IInfoBanner[];
    settingsSection: Settings.ISetting[];
    settingsSectionErrors: Record<string, any>;
    settingsScopeSectionsByRoles: string[];
    schemaFormInvalid: boolean;
    incidentRoles: any[];
    selectSearchData: Record<string, any>;
    isOnboarding: boolean;
    error: string | null;
    integrationProbes: Map<string, any>;
    userTimezone: string;
    success: any[];
    failure: any[];
    oldPagerdutyWebhooks: Settings.IOldPagerdutyWebhooks[];
    migratedHooks: { description: string }[];
    isMigrationWebhookModal: boolean;
    isCreateWebhookModal: boolean;
    failedToMigrateWebhooks: number[];
    webhookUrl: string;
    getTagCategoriesStatus: string;
    tagCategories: Settings.ITagCategory[];
    addTagCategoryStatus: string;
    deleteTagCategoryStatus: string;
    restoreTagCategoryStatus: string;
    tagCategoryFilterText: string;
    tagCategorySortBy: string;
    tagCategorySortDirection: string;
    tagCategoryStatusFilter: string;
    updateTagCategoryStatus: string;
    mergeTagCategoriesStatus: string;
    addTagToCategoryStatus: string;
    removeTagFromCategoryStatus: string;
    addIncidentTypeToCategoryStatus: string;
    removeIncidentTypeFromCategoryStatus: string;
    duplicateTagCategoryStatus: string;
    expandedTagCategories: number[];
    importPDServicesStatus: string;
    incidentTypeFilterText: string;
    incidentTypeSortBy: string;
    incidentTypeSortDirection: string;
    incidentTypeStatusFilter: string[];
    areErrorsMuted: boolean;
}

export const defaultState: IDefaultState = {
    updateSettingsLoadStatus: LoadStatus.EMPTY,
    slackOAuthUrlLoadStatus: LoadStatus.EMPTY,
    googleCalendarOAuthUrlLoadStatus: LoadStatus.EMPTY,
    autocompleteOptionsLoadStatus: LoadStatus.EMPTY,
    selectSearchDataLoadStatus: LoadStatus.EMPTY,
    settingsRPCMethodLoadStatus: LoadStatus.EMPTY,
    getUserTimezoneLoadStatus: LoadStatus.EMPTY,
    getSettingsSectionWithSubStatus: LoadStatus.EMPTY,
    getSettingsSectionMenuDataStatus: LoadStatus.EMPTY,
    getSettingsScopeSectionsByRolesStatus: LoadStatus.EMPTY,
    getIntegrationProbesStatus: LoadStatus.EMPTY,
    getGoogleCalendarOAuthUrl: LoadStatus.EMPTY,
    getSlackOAuthUrl: LoadStatus.EMPTY,
    getMicrosoftOAuthUrl: LoadStatus.EMPTY,
    getMicrosoftMeetingOAuthUrl: LoadStatus.EMPTY,
    getGoToMeetingOAuthUrlStatus: LoadStatus.EMPTY,
    getJiraOAuthUrlStatus: LoadStatus.EMPTY,
    postJiraOAuthAccessCode: LoadStatus.EMPTY,
    getTagCategoriesStatus: LoadStatus.EMPTY,
    addTagCategoryStatus: LoadStatus.EMPTY,
    deleteTagCategoryStatus: LoadStatus.EMPTY,
    restoreTagCategoryStatus: LoadStatus.EMPTY,
    updateTagCategoryStatus: LoadStatus.EMPTY,
    mergeTagCategoriesStatus: LoadStatus.EMPTY,
    addTagToCategoryStatus: LoadStatus.EMPTY,
    removeTagFromCategoryStatus: LoadStatus.EMPTY,
    addIncidentTypeToCategoryStatus: LoadStatus.EMPTY,
    removeIncidentTypeFromCategoryStatus: LoadStatus.EMPTY,
    importPDServicesStatus: LoadStatus.EMPTY,
    duplicateTagCategoryStatus: LoadStatus.EMPTY,
    getPagerdutyRespondersLoadStatus: LoadStatus.EMPTY,
    gotoMeetingOAuthUrl: '',
    jiraOAuthUrl: '',
    autocompleteOptions: {},
    slackOAuthUrl: '',
    msteamOAuthUrl: '',
    msteamMeetingOAuthUrl: '',
    googleCalendarOAuthUrl: '',
    settings: [],
    pagerdutyResponders: [],
    settingsMenuData: {},
    settingsSectionInfoBanners: [],
    settingsSection: [],
    settingsSectionErrors: {},
    settingsScopeSectionsByRoles: [],
    schemaFormInvalid: false,
    incidentRoles: [],
    selectSearchData: {},
    isOnboarding: false,
    error: null,
    integrationProbes: new Map(),
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    success: [],
    failure: [],
    oldPagerdutyWebhooks: [],
    migratedHooks: [],
    isMigrationWebhookModal: false,
    isCreateWebhookModal: false,
    failedToMigrateWebhooks: [],
    webhookUrl: '',
    tagCategories: [],
    tagCategoryFilterText: '',
    tagCategorySortBy: SORT_BY.NAME,
    tagCategorySortDirection: SORT_DIRECTION.ASCENDING,
    tagCategoryStatusFilter: CATEGORY_STATUS_FILTER.ACTIVE,
    expandedTagCategories: [],
    incidentTypeFilterText: '',
    incidentTypeSortBy: SORT_BY.NAME,
    incidentTypeSortDirection: SORT_DIRECTION.ASCENDING,
    incidentTypeStatusFilter: [],
    areErrorsMuted: false
};

const updateTagCategoryData = (action, statusPropertyName, draft) => {
    draft[statusPropertyName] = LoadStatus.OK;
    const updatedCategory = action.payload.response;
    if (updatedCategory) {
        const newTagCategories = [...draft.tagCategories];
        const index = newTagCategories.findIndex((category) => category._id === updatedCategory._id);

        if (index >= 0) {
            newTagCategories[index] = updatedCategory;
        }
        draft.tagCategories = newTagCategories;
    }
};

const addTagCategoryData = (action, statusPropertyName, draft) => {
    draft[statusPropertyName] = LoadStatus.OK;
    const updatedCategory = action.payload.response;
    if (updatedCategory) {
        const newTagCategories = [...draft.tagCategories];
        newTagCategories.push(updatedCategory);
        draft.tagCategories = newTagCategories;
    }
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getAutocompleteOptions.request.getType():
                draft.autocompleteOptionsLoadStatus = LoadStatus.REQUEST;
                return;

            case getAutocompleteOptions.ok.getType():
                const optionsKey = `${action.payload.request[0]}-${Object.values(
                    action.payload.request[1]
                ).toString()}`;
                const autocompleteOptions =
                    action.payload.response[
                        Object.keys(action.payload.response).find(
                            (field) => typeof action.payload.response[field] === 'object'
                        )!
                    ];
                draft.autocompleteOptionsLoadStatus = LoadStatus.OK;
                draft.autocompleteOptions = Object.assign(
                    { [optionsKey]: autocompleteOptions },
                    draft.autocompleteOptions
                );
                return;

            case getAutocompleteOptions.error.getType():
                draft.autocompleteOptionsLoadStatus = LoadStatus.ERROR;
                return;

            case setSettingsLoadStatus.getType():
                draft.updateSettingsLoadStatus = action.payload.loadStatus;
                return;

            case getV2WebHooks.request.getType():
                draft.updateSettingsLoadStatus = LoadStatus.REQUEST;
                return;

            case getV2WebHooks.ok.getType():
                draft.updateSettingsLoadStatus = LoadStatus.OK;
                draft.oldPagerdutyWebhooks = action.payload.response.extensions;
                return;

            case getV2WebHooks.error.getType():
                draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                return;

            case getV3WebHooks.request.getType():
                draft.updateSettingsLoadStatus = LoadStatus.REQUEST;
                return;

            case getV3WebHooks.ok.getType():
                draft.updateSettingsLoadStatus = LoadStatus.OK;
                draft.migratedHooks = action.payload.response.webhook_subscriptions;
                return;

            case getV3WebHooks.error.getType():
                draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                return;

            case createWebhook.request.getType():
                draft.updateSettingsLoadStatus = LoadStatus.REQUEST;
                return;

            case createWebhook.ok.getType():
                draft.updateSettingsLoadStatus = LoadStatus.OK;
                return;

            case createWebhook.error.getType():
                draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                return;

            case setWebhooksMigrationId.request.getType():
                draft.updateSettingsLoadStatus = LoadStatus.REQUEST;
                return;

            case setWebhooksMigrationId.ok.getType():
                const { request, response } = action.payload;
                if (!response.ok) {
                    draft.failedToMigrateWebhooks = [...state.failedToMigrateWebhooks, request?.[0]?.extension_id];
                    draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                } else {
                    draft.updateSettingsLoadStatus = LoadStatus.OK;
                }
                return;

            case setWebhooksMigrationId.error.getType():
                draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                draft.failedToMigrateWebhooks = [...state.failedToMigrateWebhooks, action.payload.response.data];
                return;
            case setIsMigrationWebhookModal.getType():
                draft.isMigrationWebhookModal = action.payload.isMigrationWebhookModal;
                return;

            case setIsCreateWebhookModal.getType():
                draft.isCreateWebhookModal = action.payload.isCreateWebhookModal;
                return;

            case updateSettings.request.getType():
            case createIncidentType.request.getType():
                draft.updateSettingsLoadStatus = LoadStatus.REQUEST;
                return;

            case updateSettings.ok.getType():
            case createIncidentType.ok.getType():
                draft.updateSettingsLoadStatus = LoadStatus.OK;
                draft.settingsMenuData = action.payload.response.settingsMetadata;
                draft.success = action.payload.response.success;
                draft.failure = action.payload.response.failure;
                draft.settingsSectionErrors = {};
                return;

            case updateSettings.error.getType():
            case createIncidentType.error.getType():
                draft.settingsSectionErrors = { ...state.settingsSectionErrors, ...action.payload.error.response.data };
                draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                return;

            case editSettings.request.getType():
                draft.updateSettingsLoadStatus = LoadStatus.REQUEST;
                return;

            case editSettings.ok.getType():
                draft.updateSettingsLoadStatus = LoadStatus.OK;
                draft.success = action.payload.response.success;
                draft.failure = action.payload.response.failure;
                return;

            case editSettings.error.getType():
                draft.updateSettingsLoadStatus = LoadStatus.ERROR;
                return;

            case getSelectSearchData.request.getType():
                draft.selectSearchDataLoadStatus = LoadStatus.REQUEST;
                return;

            case getSelectSearchData.ok.getType():
                draft.selectSearchDataLoadStatus = LoadStatus.OK;
                draft.selectSearchData = {
                    ...draft.selectSearchData,
                    [action.payload.response.settingsMetadata.name]:
                        action.payload.response.settingsMetadata.uiValueOptions
                };
                return;

            case getSelectSearchData.error.getType():
                draft.selectSearchDataLoadStatus = LoadStatus.ERROR;
                return;

            case setIsOnboarding.getType():
                draft.isOnboarding = action.payload.isOnboarding;
                return;

            case setSchemaFormInvalid.getType():
                draft.schemaFormInvalid = action.payload;
                return;

            case SettingsRPCMethod.request.getType():
                draft.settingsRPCMethodLoadStatus = LoadStatus.REQUEST;
                return;

            case SettingsRPCMethod.ok.getType():
                draft.settingsRPCMethodLoadStatus = LoadStatus.OK;
                return;

            case SettingsRPCMethod.error.getType():
                draft.settingsRPCMethodLoadStatus = LoadStatus.ERROR;
                return;

            case GET_PAGERDUTY_RESPONDERS:
                if (!action.meta.done) {
                    draft.getPagerdutyRespondersLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error & action.meta.done) {
                    draft.getPagerdutyRespondersLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getPagerdutyRespondersLoadStatus = LoadStatus.OK;
                    draft.pagerdutyResponders = action.payload.response;
                    return;
                }
                return;

            case GET_SETTINGS_SECTION_MENU_DATA:
                if (!action.meta.done) {
                    draft.getSettingsSectionMenuDataStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error & action.meta.done) {
                    draft.getSettingsSectionMenuDataStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getSettingsSectionMenuDataStatus = LoadStatus.OK;
                    draft.settingsMenuData = action.payload.sectionMetadata;
                    return;
                }
                return;

            case GET_USER_TIMEZONE:
                if (!action.meta.done) {
                    draft.getUserTimezoneLoadStatus = LoadStatus.REQUEST;
                    return;
                }

                if (action.error & action.meta.done) {
                    draft.getUserTimezoneLoadStatus = LoadStatus.ERROR;
                    return;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getUserTimezoneLoadStatus = LoadStatus.OK;
                    draft.userTimezone = action.payload.settings.timezone;
                    return;
                }
                return;

            case GET_SETTING_SECTION:
                if (!action.meta.done) {
                    draft.getSettingsSectionWithSubStatus = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getSettingsSectionWithSubStatus = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getSettingsSectionWithSubStatus = LoadStatus.OK;
                    const { settings, incidentRoles, info } = action.payload;
                    draft.settingsSectionInfoBanners = info ? info : [];
                    draft.settingsSection = settings ? settings : {};
                    draft.incidentRoles = incidentRoles ? incidentRoles : [];
                }
                return;

            case getSettingsScopeSectionsByRoles.request.getType():
                draft.getSettingsScopeSectionsByRolesStatus = LoadStatus.REQUEST;
                return;
            case getSettingsScopeSectionsByRoles.error.getType():
                draft.getSettingsScopeSectionsByRolesStatus = LoadStatus.ERROR;
                return;
            case getSettingsScopeSectionsByRoles.ok.getType():
                draft.getSettingsScopeSectionsByRolesStatus = LoadStatus.OK;
                draft.settingsScopeSectionsByRoles = action.payload.response.paramValues;

                // temporary measure for new tagging, if the user is allowed to see incident settings
                // than they are allowed to see tag settings
                if (draft.settingsScopeSectionsByRoles?.includes('org.incident')) {
                    draft.settingsScopeSectionsByRoles.push('org.tags');
                }
                return;

            case GET_GOOGLE_CALENDAR_OAUTH_URL:
                if (!action.meta.done) {
                    draft.getGoogleCalendarOAuthUrl = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getGoogleCalendarOAuthUrl = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getGoogleCalendarOAuthUrl = LoadStatus.OK;
                    draft.googleCalendarOAuthUrl = action.payload.redirect_url;
                }
                return;

            case GET_SLACK_OAUTH_URL:
                if (!action.meta.done) {
                    draft.getSlackOAuthUrl = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getSlackOAuthUrl = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getSlackOAuthUrl = LoadStatus.OK;
                    draft.slackOAuthUrl = action.payload.redirect_url;
                }
                return;

            case GET_MICROSOFT_OAUTH_URL:
                if (!action.meta.done) {
                    draft.getMicrosoftOAuthUrl = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getMicrosoftOAuthUrl = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getMicrosoftOAuthUrl = LoadStatus.OK;
                    draft.msteamOAuthUrl = action.payload.redirect_url;
                }
                return;

            case GET_MICROSOFT_MEETING_OAUTH_URL:
                if (!action.meta.done) {
                    draft.getMicrosoftMeetingOAuthUrl = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getMicrosoftMeetingOAuthUrl = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getMicrosoftMeetingOAuthUrl = LoadStatus.OK;
                    draft.msteamMeetingOAuthUrl = action.payload.redirect_url;
                }
                return;

            case GET_GOTOMEETING_OAUTH_URL:
                if (!action.meta.done) {
                    draft.getGoToMeetingOAuthUrlStatus = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getGoToMeetingOAuthUrlStatus = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getGoToMeetingOAuthUrlStatus = LoadStatus.OK;
                    draft.gotoMeetingOAuthUrl = action.payload.redirect_url;
                }
                return;

            case GET_JIRA_OAUTH_URL:
                if (!action.meta.done) {
                    draft.getJiraOAuthUrlStatus = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getJiraOAuthUrlStatus = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getJiraOAuthUrlStatus = LoadStatus.OK;
                    draft.jiraOAuthUrl = action.payload.redirect_url;
                }
                return;

            case POST_JIRA_OAUTH_ACCESS_CODE:
                if (!action.meta.done) {
                    draft.postJiraOAuthAccessCode = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.postJiraOAuthAccessCode = LoadStatus.ERROR;
                }

                if (action.meta.done && !action.error) {
                    draft.postJiraOAuthAccessCode = LoadStatus.OK;
                }
                return;

            case GET_INTEGRATION_PROBES:
                if (!action.meta.done) {
                    draft.getIntegrationProbesStatus = LoadStatus.REQUEST;
                }

                if (action.error && action.meta.done) {
                    draft.getIntegrationProbesStatus = LoadStatus.ERROR;
                }

                if (action.payload && action.meta.done && !action.error) {
                    draft.getIntegrationProbesStatus = LoadStatus.OK;
                    const { probes } = action.payload;
                    if (probes && Object.keys(probes).length > 0) {
                        const probesMap = new Map(draft.integrationProbes);
                        const enabledArray: string[] = [];
                        const disabledArray: string[] = [];

                        Object.keys(probes).forEach((probe) => {
                            if (probes[probe].hasOwnProperty('enabled') && probes[probe].enabled) {
                                enabledArray.push(probe);
                            }
                            if (probes[probe].hasOwnProperty('enabled') && !probes[probe].enabled) {
                                disabledArray.push(probe);
                            }
                        });

                        enabledArray.sort();
                        disabledArray.sort();

                        enabledArray.forEach((probe) => probesMap.set(probe, probes[probe]));
                        disabledArray.forEach((probe) => probesMap.set(probe, probes[probe]));

                        draft.integrationProbes = probesMap;
                    }
                }
                return;

            case clearSettingsSection.getType():
                draft.settingsSectionInfoBanners = [];
                draft.settingsSection = [];
                draft.incidentRoles = [];
                return;

            case clearGoToMeetingOAuthUrl.getType():
                draft.gotoMeetingOAuthUrl = '';
                return;

            case setSettingsSection.getType():
                const data = action.payload;
                draft.settingsSection = draft.settingsSection.map((section) => {
                    return { ...section, currentValue: data[section.name] };
                });
                return;

            case getTagCategories.request.getType():
                draft.getTagCategoriesStatus = LoadStatus.REQUEST;
                return;

            case getTagCategories.ok.getType():
                draft.getTagCategoriesStatus = LoadStatus.OK;
                const categories = action.payload.response;
                if (categories?.length) {
                    draft.tagCategories = categories;
                }
                return;

            case getTagCategories.error.getType():
                draft.getTagCategoriesStatus = LoadStatus.ERROR;
                return;

            case setTagCategoryFilterText.getType():
                draft.tagCategoryFilterText = action.payload.tagCategoryFilterText;
                return;

            case setTagCategorySortBy.getType():
                draft.tagCategorySortBy = action.payload.tagCategorySortBy;
                return;

            case setTagCategorySortDirection.getType():
                draft.tagCategorySortDirection = action.payload.tagCategorySortDirection;
                return;

            case setTagCategoryStatusFilter.getType():
                draft.tagCategoryStatusFilter = action.payload.tagCategoryStatusFilter;
                return;

            case setIncidentTypeFilterText.getType():
                draft.incidentTypeFilterText = action.payload.incidentTypeFilterText;
                return;

            case setIncidentTypeSortBy.getType():
                draft.incidentTypeSortBy = action.payload.incidentTypeSortBy;
                return;

            case setIncidentTypeSortDirection.getType():
                draft.incidentTypeSortDirection = action.payload.incidentTypeSortDirection;
                return;

            case setIncidentTypeStatusFilter.getType():
                draft.incidentTypeStatusFilter = action.payload.incidentTypeStatusFilter;
                return;

            case addTagCategory.request.getType():
                draft.addTagCategoryStatus = LoadStatus.REQUEST;
                return;

            case addTagCategory.ok.getType():
                addTagCategoryData(action, 'addTagCategoryStatus', draft);
                return;

            case addTagCategory.error.getType():
                draft.addTagCategoryStatus = LoadStatus.ERROR;
                return;

            case deleteTagCategory.request.getType():
                draft.deleteTagCategoryStatus = LoadStatus.REQUEST;
                return;

            case deleteTagCategory.ok.getType():
                draft.deleteTagCategoryStatus = LoadStatus.OK;
                const deletedCategory = action.payload.response;
                if (deletedCategory) {
                    const category = draft.tagCategories.find((category) => category._id === deletedCategory._id);
                    if (category) {
                        category.archive = true;
                    }
                }
                return;

            case deleteTagCategory.error.getType():
                draft.deleteTagCategoryStatus = LoadStatus.ERROR;
                return;

            case restoreTagCategory.request.getType():
                draft.restoreTagCategoryStatus = LoadStatus.REQUEST;
                return;

            case restoreTagCategory.ok.getType():
                draft.restoreTagCategoryStatus = LoadStatus.OK;
                const restoredCategory = action.payload.response;
                if (restoredCategory) {
                    const category = draft.tagCategories.find((category) => category._id === restoredCategory._id);
                    if (category) {
                        category.archive = false;
                    }
                }
                return;

            case restoreTagCategory.error.getType():
                draft.restoreTagCategoryStatus = LoadStatus.ERROR;
                return;

            case updateTagCategory.request.getType():
                draft.updateTagCategoryStatus = LoadStatus.REQUEST;
                return;

            case updateTagCategory.error.getType():
                draft.addTagToCategoryStatus = LoadStatus.ERROR;
                return;

            case updateTagCategory.ok.getType():
                updateTagCategoryData(action, 'updateTagCategoryStatus', draft);
                return;

            case mergeTagCategories.request.getType():
                draft.mergeTagCategoriesStatus = LoadStatus.REQUEST;
                return;

            case mergeTagCategories.error.getType():
                draft.mergeTagCategoriesStatus = LoadStatus.ERROR;
                return;

            case mergeTagCategories.ok.getType():
                addTagCategoryData(action, 'mergeTagCategoriesStatus', draft);
                return;

            case addTagToCategory.request.getType():
                draft.addTagToCategoryStatus = LoadStatus.REQUEST;
                return;

            case addTagToCategory.error.getType():
                draft.addTagToCategoryStatus = LoadStatus.ERROR;
                return;

            case addTagToCategory.ok.getType():
                updateTagCategoryData(action, 'addTagToCategoryStatus', draft);
                return;

            case removeTagFromCategory.request.getType():
                draft.removeTagFromCategoryStatus = LoadStatus.REQUEST;
                return;

            case removeTagFromCategory.error.getType():
                draft.removeTagFromCategoryStatus = LoadStatus.ERROR;
                return;

            case removeTagFromCategory.ok.getType():
                updateTagCategoryData(action, 'removeTagFromCategoryStatus', draft);
                return;

            case addIncidentTypeToCategory.request.getType():
                draft.addIncidentTypeToCategoryStatus = LoadStatus.REQUEST;
                return;

            case addIncidentTypeToCategory.error.getType():
                draft.addIncidentTypeToCategoryStatus = LoadStatus.ERROR;
                return;

            case addIncidentTypeToCategory.ok.getType():
                updateTagCategoryData(action, 'addIncidentTypeToCategoryStatus', draft);
                return;

            case removeIncidentTypeFromCategory.request.getType():
                draft.removeIncidentTypeFromCategoryStatus = LoadStatus.REQUEST;
                return;

            case removeIncidentTypeFromCategory.error.getType():
                draft.removeIncidentTypeFromCategoryStatus = LoadStatus.ERROR;
                return;

            case removeIncidentTypeFromCategory.ok.getType():
                updateTagCategoryData(action, 'removeIncidentTypeFromCategoryStatus', draft);
                return;

            case duplicateTagCategory.request.getType():
                draft.duplicateTagCategoryStatus = LoadStatus.REQUEST;
                return;

            case duplicateTagCategory.ok.getType():
                addTagCategoryData(action, 'duplicateTagCategoryStatus', draft);
                return;

            case duplicateTagCategory.error.getType():
                draft.duplicateTagCategoryStatus = LoadStatus.ERROR;
                return;

            case addExpandedTagCategory.getType():
                const addCategoryId = action.payload.id;
                if (!draft.expandedTagCategories.includes(addCategoryId)) {
                    draft.expandedTagCategories = [...draft.expandedTagCategories, addCategoryId];
                }
                return;

            case removeExpandedTagCategory.getType():
                const removeCategoryId = action.payload.id;
                const index = draft.expandedTagCategories.indexOf(removeCategoryId);

                if (index > -1) {
                    draft.expandedTagCategories = [...draft.expandedTagCategories];
                    draft.expandedTagCategories.splice(index, 1);
                }
                return;

            case importPDServices.request.getType():
                draft.importPDServicesStatus = LoadStatus.REQUEST;
                return;

            case importPDServices.error.getType():
                draft.importPDServicesStatus = LoadStatus.ERROR;
                return;

            case importPDServices.ok.getType():
                draft.importPDServicesStatus = LoadStatus.OK;
                return;

            case setAreErrorsMuted.getType():
                draft.areErrorsMuted = action.payload.areErrorsMuted;
                return;
        }
    });
}
