const config = ShowcaseConfig;
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import history from 'src/history';
import { setIsOnboarding } from 'src/actions/settings/settings-page-actions';

const actionName = 'ENQUEUE_SNACKBAR';
export const infoLoggingMiddleware = (store) => (next) => (action) => {
    if (config.env === 'dev' && action.type !== actionName && action.meta !== 'ERROR') {
        const { type, payload } = action;
        const message = type;
        if (payload) {
            console.info(payload);
        }
        store.dispatch(
            enqueueSnackbar({
                message,
                options: {
                    variant: 'info'
                }
            })
        );
    }
    return next(action);
};

const errorActionIgnored = [
    'GET_POSTMORTEM_ERROR',
    'UPDATE_POSTMORTEM_ERROR',
    'GET_INCIDENT_DETAILS_ERROR',
    'CREATE_WORKFLOW_ERROR',
    'UPDATE_WORKFLOW_ERROR',
    'CREATE_WORKFLOW_TRIGGER_ERROR',
    'UPDATE_WORKFLOW_TRIGGER_ERROR',
    'UPDATE_WORKFLOW_STEPS_ERROR'
];

export const errorLoggingMiddleware = (store) => (next) => (action) => {
    if (
        !store.getState().settings.areErrorsMuted &&
        action.type !== actionName &&
        (action.meta === 'ERROR' || (action.error && Object.keys(action.error).length)) &&
        !errorActionIgnored.some((ignoredActionName) => action.type === ignoredActionName)
    ) {
        let message = '';
        if (action.payload?.error) {
            message = action.payload.error?.response?.data?.message ?? action.payload.error?.message;
            console.error(message);
        }
        if (action.error && Object.keys(action.error).length) {
            message = action.error.response.data.message;
            console.error(message);
        }
        message = message ? message : action.payload.error.message;
        store.dispatch(
            enqueueSnackbar({
                message: typeof message === 'string' ? message : JSON.stringify(message),
                options: {
                    variant: 'error',
                    preventDuplicate: true,
                    autoHideDuration: 10000
                }
            })
        );
    }
    return next(action);
};

export const determineIfOnboardingNeeded = (store) => (next) => (action) => {
    if (action.type === 'GET_ORGS_OK') {
        const { router, launchDarkly } = store.getState();
        const { onboarding, onboardingTermsOfService } = launchDarkly;

        if (!onboarding) {
            return next(action);
        }

        const orgs = action.payload.response.response.organizations;
        if (orgs.length > 0) {
            let { settings } = orgs[0];

            if (settings === '' || settings === undefined) {
                if (onboardingTermsOfService) {
                    history.push('/onboarding/step/1');
                } else {
                    history.push('/onboarding/step/2');
                }
                store.dispatch(setIsOnboarding({ isOnboarding: true }));
                return next(action);
            }

            settings = JSON.parse(settings);
            if (settings?.hasOwnProperty('onboarding')) {
                const { onboarding: onboardingSettings } = settings;
                if (onboardingSettings.completed) {
                    if (router.location.pathname.indexOf('onboarding/step') > -1) {
                        history.push('/incidents');
                        store.dispatch(setIsOnboarding({ isOnboarding: false }));
                    }
                    return next(action);
                }
                if (onboardingSettings.stepsCompleted) {
                    const stepIndex = onboardingSettings.stepsCompleted.findIndex((stepBoolean, index) => {
                        if (onboardingTermsOfService && index === 0 && !stepBoolean) {
                            return true;
                        }
                        if (index !== 0 && !stepBoolean) {
                            return true;
                        }
                        return false;
                    });
                    if (stepIndex === 0 && !onboardingTermsOfService) {
                        history.push('/onboarding/step/2');
                        store.dispatch(setIsOnboarding({ isOnboarding: true }));
                        return next(action);
                    }
                    if (stepIndex > -1) {
                        history.push(`/onboarding/step/${stepIndex + 1}`);
                        store.dispatch(setIsOnboarding({ isOnboarding: true }));
                        return next(action);
                    }
                    return next(action);
                }
            }
            history.push('/onboarding/step/1');
            store.dispatch(setIsOnboarding({ isOnboarding: true }));
            return next(action);
        }
    }
    return next(action);
};

const inFlightActions: Record<string, { id: string; done: boolean; reqId?: string }> = {};
export const deDuplicateAsyncRequest = () => (next) => (action) => {
    const { meta } = action;
    if (meta?.id) {
        const { id, done, reqId } = meta;
        if (id) {
            const isInflight = !!inFlightActions[id];

            if (isInflight && !done) {
                if (reqId && inFlightActions[id].reqId !== reqId) {
                    inFlightActions[id] = meta;
                    return next(action);
                }
                return false;
            }

            if (isInflight && done) {
                if (reqId && reqId !== inFlightActions[id].reqId) {
                    return false;
                }
                delete inFlightActions[id];
                return next(action);
            }

            if (!(isInflight || done)) {
                inFlightActions[id] = meta;
                return next(action);
            }
        }
    }
    return next(action);
};
