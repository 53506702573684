import React, { memo, useEffect, useState } from 'react';

// Utils
import { useSelector, useDispatch } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

// Components
import { NavbarSlideOutModal, HealthCheckNotification } from '@blamelesshq/blameless-components';
import { createStyles, makeStyles } from '@material-ui/core/styles';

// Actions
import { getGoogleDriveProbe } from 'src/actions/integration/integration-actions';

// Constants
import { RootState } from 'src/store';
import LoadStatus from 'src/constants/load-status';

const useStyles = makeStyles(() =>
    createStyles({
        navbarModal: {
            zIndex: '1200 !important' as unknown as 1200
        },
        noNotifications: {
            color: 'rgba(255, 255, 255, 0.55)',
            display: 'flex',
            flex: '1 1 0px',
            flexGrow: 1,
            fontStyle: 'italic',
            justifyContent: 'center'
        },
        slideOut: {
            left: '56px',
            zIndex: 1200
        }
    })
);

const messages = defineMessages({
    notifications: {
        id: 'sidenavigation.notifications.label',
        defaultMessage: 'Notifications',
        description: 'The label for the notification tab in the slide out modal'
    },
    healthCheck: {
        id: 'sidenavigationn.health.check.label',
        defaultMessage: 'Health Check',
        description: 'The label for the health check tab in the slide out modal'
    },
    noNotifications: {
        id: 'sidenavigation.notifications.none.label',
        defaultMessage: 'No Notifications...',
        description: 'The label for no notifications'
    },
    noHealthChecks: {
        id: 'sidenavigation.health.check.none.label',
        defaultMessage: 'No Health Checks Reported...',
        description: 'The label for no health checks'
    }
});

interface IProps {
    open: boolean;
    onClose: () => void;
}

const NotificationSlideOutModal = memo<IProps>(({ open, onClose }) => {
    const [mergedHealthProbes, setMergedHealthProbes] = useState<Map<string, any>>(new Map());
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const healthProbes = useSelector((state: RootState) => state.settings.integrationProbes);
    const googleDriveIntegrationProbeStatus = useSelector(
        (state: RootState) => state.integration.googleDriveIntegrationProbeStatus
    );
    const googleDriveIntegrationProbe = useSelector(
        (state: RootState) => state.integration.googleDriveIntegrationProbe
    );

    // fetch google drive probe if it hasn't been fetched yet
    useEffect(() => {
        if (googleDriveIntegrationProbeStatus === LoadStatus.EMPTY) {
            dispatch(getGoogleDriveProbe());
        }
    }, [googleDriveIntegrationProbeStatus, dispatch]);

    // merge google drive probe with health probes
    useEffect(() => {
        if (googleDriveIntegrationProbe.size > 0) {
            const isGoogleDriveEnabled = googleDriveIntegrationProbe.get('google-drive').enabled;
            setMergedHealthProbes(
                new Map(
                    // if enabled add google drive probe to the top of the list, else add it to the bottom
                    isGoogleDriveEnabled
                        ? [...googleDriveIntegrationProbe, ...healthProbes]
                        : [...healthProbes, ...googleDriveIntegrationProbe]
                )
            );
        }
    }, [googleDriveIntegrationProbe, healthProbes]);

    const noNotifications = (
        <p className={classes.noNotifications} data-cy="navbar-no-notifications">
            {formatMessage(messages.noNotifications)}
        </p>
    );
    const noHealthChecks = (
        <p className={classes.noNotifications} data-cy="navbar-no-health-checks">
            {formatMessage(messages.noHealthChecks)}
        </p>
    );

    const tabs = [
        {
            tabBodyElement: noNotifications,
            tabNameElement: formatMessage(messages.notifications),
            tabValue: 'notifications'
        },
        {
            tabBodyElement:
                mergedHealthProbes.size > 0 ? (
                    <HealthCheckNotification integrationStates={mergedHealthProbes} />
                ) : (
                    noHealthChecks
                ),
            tabNameElement: formatMessage(messages.healthCheck),
            tabValue: 'health-check'
        }
    ];

    return (
        <>
            <NavbarSlideOutModal
                open={open}
                overrideClasses={{
                    modal: classes.navbarModal,
                    paperAnchorLeft: classes.slideOut
                }}
                headerElement={formatMessage(messages.notifications)}
                onClose={onClose}
                tabSections={tabs}
            />
        </>
    );
});

export default NotificationSlideOutModal;
