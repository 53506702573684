import {
    getMsTeamsTeams,
    getMsTeamsChannels,
    getMsTeamsUsers,
    getMsTeamsUsersByName,
    getMsTeamsUsersByIds,
    getMsTeamsUsersByPhones
} from 'src/actions/msteams/msteams-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    loadMsTeamsTeamsStatus: string;
    loadMsTeamsUsersStatus: string;
    loadMsTeamsChannelsStatus: string;
    msTeamsTeams: MsTeams.Team[];
    msTeamsUsers: MsTeams.User[];
    msTeamsChannels: MsTeams.TeamChannel[];
    allFetchedTeamsUsers: MsTeams.UserFeilds[];
    msTeamsUsersByName: MsTeams.UserFeilds[];
    msTeamsUsersByNameLoadStatus: string;
    msTeamsUsersByIds: MsTeams.UserFeilds[];
    msTeamsUsersByIdsLoadStatus: string;
    msTeamsUsersByPhones: MsTeams.UserFeilds[];
    msTeamsUsersByPhonesLoadStatus: string;
}

export const defaultState: IDefaultState = {
    loadMsTeamsTeamsStatus: LoadStatus.EMPTY,
    loadMsTeamsUsersStatus: LoadStatus.EMPTY,
    loadMsTeamsChannelsStatus: LoadStatus.EMPTY,
    msTeamsTeams: [],
    msTeamsUsers: [],
    msTeamsChannels: [],
    allFetchedTeamsUsers: [],
    msTeamsUsersByName: [],
    msTeamsUsersByNameLoadStatus: LoadStatus.EMPTY,
    msTeamsUsersByIds: [],
    msTeamsUsersByIdsLoadStatus: LoadStatus.EMPTY,
    msTeamsUsersByPhones: [],
    msTeamsUsersByPhonesLoadStatus: LoadStatus.EMPTY
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getMsTeamsTeams.request.getType():
                draft.loadMsTeamsTeamsStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsTeams.ok.getType():
                draft.msTeamsTeams = action.payload.response.groups;
                draft.loadMsTeamsTeamsStatus = LoadStatus.OK;
                return;
            case getMsTeamsTeams.error.getType():
                draft.loadMsTeamsTeamsStatus = LoadStatus.ERROR;
                return;
            case getMsTeamsChannels.request.getType():
                draft.loadMsTeamsChannelsStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsChannels.ok.getType():
                draft.msTeamsChannels = action.payload.response.channels;
                draft.loadMsTeamsChannelsStatus = LoadStatus.OK;
                return;
            case getMsTeamsChannels.error.getType():
                draft.loadMsTeamsChannelsStatus = LoadStatus.ERROR;
                return;
            case getMsTeamsUsers.request.getType():
                draft.loadMsTeamsUsersStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsUsers.ok.getType():
                draft.msTeamsUsers = action.payload.response.users;
                draft.loadMsTeamsUsersStatus = LoadStatus.OK;
                return;
            case getMsTeamsUsers.error.getType():
                draft.loadMsTeamsUsersStatus = LoadStatus.ERROR;
                return;

            // Get users by name
            case getMsTeamsUsersByName.request.getType():
                draft.msTeamsUsersByNameLoadStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsUsersByName.ok.getType():
                draft.msTeamsUsersByName = action.payload.response;
                // add only new fetched users to the all fetched list
                draft.allFetchedTeamsUsers = [
                    ...draft.allFetchedTeamsUsers,
                    ...action.payload.response.filter(
                        (user) => !draft.allFetchedTeamsUsers.some((u) => u.id === user.id)
                    )
                ];
                draft.msTeamsUsersByNameLoadStatus = LoadStatus.OK;
                return;
            case getMsTeamsUsersByName.error.getType():
                draft.msTeamsUsersByNameLoadStatus = LoadStatus.ERROR;
                return;

            // Get users by ids
            case getMsTeamsUsersByIds.request.getType():
                draft.msTeamsUsersByIdsLoadStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsUsersByIds.ok.getType():
                draft.msTeamsUsersByIds = action.payload.response;
                // add only new fetched users to the all fetched list
                draft.allFetchedTeamsUsers = [
                    ...draft.allFetchedTeamsUsers,
                    ...action.payload.response.filter(
                        (user) => !draft.allFetchedTeamsUsers.some((u) => u.id === user.id)
                    )
                ];
                draft.msTeamsUsersByIdsLoadStatus = LoadStatus.OK;
                return;
            case getMsTeamsUsersByIds.error.getType():
                draft.msTeamsUsersByIdsLoadStatus = LoadStatus.ERROR;
                return;

            // Get users by phones
            case getMsTeamsUsersByPhones.request.getType():
                draft.msTeamsUsersByPhonesLoadStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsUsersByPhones.ok.getType():
                draft.msTeamsUsersByPhones = action.payload.response;
                // add only new fetched users to the all fetched list
                draft.allFetchedTeamsUsers = [
                    ...draft.allFetchedTeamsUsers,
                    ...action.payload.response.filter(
                        (user) => !draft.allFetchedTeamsUsers.some((u) => u.id === user.id)
                    )
                ];
                draft.msTeamsUsersByPhonesLoadStatus = LoadStatus.OK;
                return;
            case getMsTeamsUsersByPhones.error.getType():
                draft.msTeamsUsersByPhonesLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
