import { transform, isEqual, isObject, isEmpty } from 'lodash';
import { getFiltersEmptyState } from 'src/components/incidents/utils/utils';
import { ADVANCED_FILTERS } from 'src/constants/incident';
import { fromUnixTimeToDate, getZonedTime } from './date-time-util';

export const getAcronym = (str: string) => {
    return str
        .trim()
        .split(' ')
        .slice(0, 2)
        .map((str) => getFirstChar(str).toUpperCase())
        .reduce((initial, str) => initial + str);
};

export const getFirstChar = (str: string) => {
    return str.charAt(0);
};

export const capitalizeFirstChar = (str: string) => {
    return str.charAt(0).toLocaleUpperCase() + str.substring(1);
};

export const capitalizeString = (str: string) => {
    if (str) {
        const lowerCaseStr = str.toLowerCase();
        const strCheck = lowerCaseStr.includes('_') ? lowerCaseStr.split('_') : lowerCaseStr.split(' ');
        return strCheck
            .map((x) => {
                if (x[0] !== undefined) {
                    const newStr = x[0].toUpperCase() + x.slice(1);
                    return newStr;
                }
                return x;
            })
            .join(' ');
    }
    return '';
};

export const mapFiltersParamsToQueryString = (filters: { [key: string]: any }) => {
    return Object.keys(filters)
        .map((key) => {
            if (key === ADVANCED_FILTERS.INCLUDE_TAGS || key === ADVANCED_FILTERS.EXCLUDE_TAGS) {
                const tags =
                    !isEmpty(filters[key]) &&
                    Object.keys(filters[key]).reduce((acc, category) => {
                        acc[category] = filters[key][category].map((tag) => tag.label);
                        return acc;
                    }, {});
                return `${key}=${encodeURIComponent(JSON.stringify(tags))}`;
            }
            if (Array.isArray(filters[key])) {
                return `${key}=${encodeURIComponent(filters[key].join(','))}`;
            } else if (isObject(filters[key])) {
                return Object.keys(filters[key])
                    .filter((nestedKey) => filters[key][nestedKey] !== '' && filters[key][nestedKey] !== null)
                    .map((nestedKey) => `${key}_${nestedKey}=${encodeURIComponent(filters[key][nestedKey])}`)
                    .join('&');
            }
            return `${key}=${encodeURIComponent(filters[key])}`;
        })
        .join('&');
};

export const mapFiltersParamsFromQueryString = (
    queryString: string,
    userTimeZone,
    allTags,
    format = 'MM/dd/yyyy hh:mm a'
): { [key: string]: any } => {
    const initialFilterState = getFiltersEmptyState();
    return queryString
        ? queryString
              .split('&')
              .map((queryElement) => queryElement.split('='))
              .reduce((acc: any, [key, value]) => {
                  const filterValue = decodeURIComponent(value).split(',');
                  if (key === ADVANCED_FILTERS.INCLUDE_TAGS || key === ADVANCED_FILTERS.EXCLUDE_TAGS) {
                      const tagsValue = JSON.parse(decodeURIComponent(value));
                      acc[key] = {
                          ...Object.entries(tagsValue).reduce(
                              (acc, tag: any) => ({
                                  ...acc,
                                  [tag[0]]: tag[1].map((tagEl) => ({
                                      _id: allTags?.find((neededTag) => {
                                          return neededTag.label === tagEl && neededTag.category === tag[0];
                                      })?._id,
                                      label: tagEl,
                                      category: tag[0]
                                  }))
                              }),
                              {}
                          )
                      };
                      return acc;
                  } else if (
                      key === ADVANCED_FILTERS.SEVERITY ||
                      key === ADVANCED_FILTERS.STATUS ||
                      key === ADVANCED_FILTERS.TYPE
                  ) {
                      return { ...acc, [key]: [...initialFilterState[key], ...filterValue] };
                  } else if (key === ADVANCED_FILTERS.IS_POSTMORTEM_REQUIRED) {
                      return { ...acc, [key]: value ? ['required'] : [] };
                  } else if (key === ADVANCED_FILTERS.CREATOR) {
                      return { ...acc, [key]: [...initialFilterState[key], ...filterValue] };
                  } else if (
                      key === `${ADVANCED_FILTERS.CREATED}_to` ||
                      key === `${ADVANCED_FILTERS.CREATED}_from` ||
                      key === `${ADVANCED_FILTERS.UPDATED}_to` ||
                      key === `${ADVANCED_FILTERS.UPDATED}_from`
                  ) {
                      const [mainKey, subKey] = key.split('_');
                      acc[mainKey] = isObject(acc[mainKey])
                          ? {
                                  ...acc[mainKey],
                                  [subKey]: !!value
                                      ? getZonedTime(fromUnixTimeToDate(+value, format), userTimeZone)
                                      : ''
                              }
                          : {
                                  [subKey]: !!value
                                      ? getZonedTime(fromUnixTimeToDate(+value, format), userTimeZone)
                                      : ''
                              };
                      return acc;
                  } else {
                      return { ...acc, [key]: filterValue };
                  }
              }, {})
        : null;
};

export const isUrl = (str: string) =>
    str.match(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/
    ) != null;

export const wait = (amount = 0) => new Promise((resolve) => setTimeout(resolve, amount));
export const isImage = (url) => url.match(/\.(jpeg|jpg|gif|png|webp)$/i) != null;

export const getObjectDifference = (object: Record<string, any>, base: Record<string, any>): Record<string, any> =>
    transform(object, (result: Record<string, any>, value: any, key: string) => {
        if (!isEqual(value, base[key])) {
            result[key] = isObject(value) && isObject(base[key]) ? getObjectDifference(value, base[key]) : value;
        }
    });

export const hashToSha256 = async (message, salt) => {
    const msgUint8 = new TextEncoder().encode(message + salt);
    // Add optional chaining to prevent error when ssl is not supported
    const hashBuffer = await crypto?.subtle?.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};

export const isEmailValid = (input: string): boolean => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        input
    );
};

export const isJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return false;
    }
};

export const urlPattern =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

export const isWebhookUrlValid = (url: string): boolean => {
    const urlPattern = /^https?:\/\/([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    // reject any URL where the top level domain is .local or is an IP address starting with 192.168, 172.16, 172.17, 127 or 10
    const restrictedPattern = /^https?:\/\/((192\.168|172\.1[67]|127|10)\.\d{1,3}\.\d{1,3}\.\d{1,3}|.*\.local)/;
    if (urlPattern.test(url)) {
        try {
            new URL(url);
            return !restrictedPattern.test(url);
        } catch (_e) {
            return false;
        }
    }
    return false;
};

export const objectIsEmpty = (obj) =>
    obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
