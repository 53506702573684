import auth from './auth/auth-reducer';
import catalog from './catalog/catalog-reducer';
import { combineReducers } from 'redux';
import comment from './comment/comment-reducer';
import { connectRouter } from 'connected-react-router';
import dashboard from './dashboard/dashboard-page-reducer';
import followup from './followup-action/followup-action-reducer';
import iam from './iam/iam-reducer';
import identityManagement from './identity-management/identity-management-reducer';
import incident from './incidents/incident-reducer';
import incidentList from './incidents/incident-list-reducer';
import integration from './integration/integration-reducer';
import launchDarkly from 'ld-redux';
import notification from './notification/notification-reducer';
import postmortem from './postmortem/postmortem-reducer';
import search from './search/search-reducer';
import settings from './settings/settings-page-reducer';
import slack from './slack/slack-reducer';
import tags from './tag/tag-reducer';
import user from './user/user-reducer';
import jsonSchema from './json-schema/json-schema-reducer';
import auditLog from './audit-log/audit-log-reducer';
import slo from './slo/slo-reducer';
import serviceRegistry from './service-registry/service-registry-reducer';
import errorBudget from './error-budget/error-budget-reducer';
import userJourney from './user-journey/user-journey-reducer';
import restartSliBackfill from './restart-backfill/restart-backfill-reducer';
import msteams from './msteams/msteams-reducer';
import tokenManagement from './token-management/token-management-reducer';
import workflows from './workflows/workflows-reducer';
import configuration from './configuration/configuration-reducer';

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        auth,
        dashboard,
        catalog,
        incidentList,
        incident,
        postmortem,
        followup,
        search,
        tags,
        user,
        settings,
        identityManagement,
        notification,
        integration,
        slack,
        comment,
        jsonSchema,
        launchDarkly: launchDarkly.reducer(),
        auditLog,
        slo,
        serviceRegistry,
        errorBudget,
        userJourney,
        restartSliBackfill,
        msteams,
        tokenManagement,
        iam,
        workflows,
        configuration
    });
