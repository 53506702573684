export const REQUEST = 'REQUEST';
export const OK = 'OK';
export const ERROR = 'ERROR';
export const IGNORE_ERROR = 'IGNORE_ERROR';
export const EMPTY = 'EMPTY';

export const LoadStatus = {
    EMPTY,
    REQUEST,
    OK,
    ERROR
};

export default LoadStatus;
