import { restartBackfill } from 'src/actions/service-registry/service-registry-page-actions';
import { LoadStatus } from 'src/constants/load-status';
import { format } from 'date-fns';
import produce from 'immer';

interface IDefaultState {
    lastSliBackfillRestartStatus: string;
    lastSuccessfulBackfillRestartDate: string;
    lastFailedBackfillRestartDate: string | null;
    sliBackfillRestarts: any[];
}

const defaultState: IDefaultState = {
    lastSliBackfillRestartStatus: LoadStatus.EMPTY,
    lastSuccessfulBackfillRestartDate: '',
    lastFailedBackfillRestartDate: '',
    sliBackfillRestarts: []
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case restartBackfill.request.getType():
                draft.lastSliBackfillRestartStatus = LoadStatus.REQUEST;
                return;
            case restartBackfill.ok.getType():
                draft.sliBackfillRestarts = draft.sliBackfillRestarts.concat({
                    orgId: action.payload!.request![0],
                    sliId: action.payload!.request![1],
                    date: format(new Date(), 'yyyy-MM-dd MM-dd h:mm:ss a')
                });
                draft.lastSliBackfillRestartStatus = LoadStatus.OK;
                draft.lastSuccessfulBackfillRestartDate = format(new Date(), 'yyyy-MM-dd h:mm:ss a');
                draft.lastFailedBackfillRestartDate = null;
                return;
            case restartBackfill.error.getType():
                draft.lastSliBackfillRestartStatus = LoadStatus.ERROR;
                draft.lastFailedBackfillRestartDate = format(new Date(), 'yyyy-MM-dd h:mm:ss a');
                return;
        }
    });
}
