import produce from 'immer';

// Constants
import { LoadStatus } from 'src/constants/load-status';

// Actions
import {
    getUserJourneys,
    addUserJourney,
    deleteUserJourney,
    getCurrentUserJourney,
    updateUserJourney,
    clearUserJourneys
} from 'src/actions/user-journey/user-journey-actions';

interface IDefaultState {
    userJourneysList: UserJourney.IJourney[];
    currentUserJourney: UserJourney.IJourney;
    userJourneysListStatus: string;
    addUserJourneysStatus: string;
    currentUserJourneyStatus: string;
    removeUserJourneyStatus: string;
    currentUserJourneyUpdateStatus: string;
}

const tempUserJourney = {
    id: 0,
    createdAt: 0,
    orgId: 0,
    name: '',
    description: '',
    commanderUserId: 0,
    status: '',
    userId: 0,
    updatedAt: 0,
    slos: []
};

const defaultState: IDefaultState = {
    userJourneysList: [],
    currentUserJourney: { ...tempUserJourney },
    addUserJourneysStatus: LoadStatus.EMPTY,
    currentUserJourneyStatus: LoadStatus.EMPTY,
    currentUserJourneyUpdateStatus: LoadStatus.EMPTY,
    userJourneysListStatus: LoadStatus.EMPTY,
    removeUserJourneyStatus: LoadStatus.EMPTY
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getUserJourneys.request.getType():
                draft.userJourneysListStatus = LoadStatus.REQUEST;
                return;
            case getUserJourneys.ok.getType():
                draft.userJourneysListStatus = LoadStatus.OK;
                draft.userJourneysList = action.payload.response.userJourneys;
                return;
            case getUserJourneys.error.getType():
                draft.userJourneysListStatus = LoadStatus.ERROR;
                return;
            case getCurrentUserJourney.request.getType():
                draft.currentUserJourneyStatus = LoadStatus.REQUEST;
                return;
            case getCurrentUserJourney.ok.getType():
                draft.currentUserJourneyStatus = LoadStatus.OK;
                draft.currentUserJourney = action.payload.response.userJourney;
                return;
            case getCurrentUserJourney.error.getType():
                draft.currentUserJourneyStatus = LoadStatus.ERROR;
                return;
            case updateUserJourney.request.getType():
                draft.currentUserJourneyUpdateStatus = LoadStatus.REQUEST;
                return;
            case updateUserJourney.ok.getType():
                draft.currentUserJourneyUpdateStatus = LoadStatus.OK;
                draft.currentUserJourney = action.payload.response.userJourney;
                return;
            case updateUserJourney.error.getType():
                draft.currentUserJourneyUpdateStatus = LoadStatus.ERROR;
                return;
            case addUserJourney.request.getType():
                draft.addUserJourneysStatus = LoadStatus.REQUEST;
                return;
            case addUserJourney.ok.getType():
                draft.addUserJourneysStatus = LoadStatus.OK;
                draft.userJourneysList.push({ ...action.payload.response, slos: [] });
                return;
            case addUserJourney.error.getType():
                draft.addUserJourneysStatus = LoadStatus.ERROR;
                return;
            case deleteUserJourney.request.getType():
                draft.removeUserJourneyStatus = LoadStatus.REQUEST;
                return;
            case deleteUserJourney.ok.getType():
                draft.userJourneysList = draft.userJourneysList.filter(
                    (journey) => journey.id !== action.payload.response.id
                );
                draft.removeUserJourneyStatus = LoadStatus.OK;
                return;
            case deleteUserJourney.error.getType():
                draft.removeUserJourneyStatus = LoadStatus.ERROR;
                return;
            case clearUserJourneys.getType():
                draft.userJourneysList = [];
                draft.currentUserJourney = { ...tempUserJourney };
                draft.currentUserJourneyStatus = LoadStatus.EMPTY;
                return;
        }
    });
}
