import { createKey, getAllKeys, deleteKey, editKey } from 'src/actions/token-management/token-management-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    getAllKeysLoadStatus: string;
    createKeyLoadStatus: string;
    deleteKeyLoadStatus: string;
    editKeyLoadStatus: string;
    keys: IIdentity.IApiKey[];
}

export const defaultState: IDefaultState = {
    getAllKeysLoadStatus: LoadStatus.EMPTY,
    createKeyLoadStatus: LoadStatus.EMPTY,
    deleteKeyLoadStatus: LoadStatus.EMPTY,
    editKeyLoadStatus: LoadStatus.EMPTY,
    keys: []
};

export default function (state = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getAllKeys.request.getType():
                draft.getAllKeysLoadStatus = LoadStatus.REQUEST;
                return;
            case getAllKeys.ok.getType():
                draft.keys = action.payload.response?.keys.map(
                    ({ id, name, obfuscatedKey, createdAt }: IIdentity.IApiKey) => ({
                        id,
                        name,
                        obfuscatedKey,
                        createdAt
                    })
                );
                draft.getAllKeysLoadStatus = LoadStatus.OK;
                return;
            case getAllKeys.error.getType():
                draft.getAllKeysLoadStatus = LoadStatus.ERROR;
                return;
            case createKey.request.getType():
                draft.createKeyLoadStatus = LoadStatus.REQUEST;
                return;
            case createKey.ok.getType():
                const createResponse = action.payload.response;
                // Doing destructuring here to avoid storing real key in the redux state
                const { id, name, obfuscatedKey, createdAt } = createResponse;
                draft.keys = [...state.keys, { id, name, obfuscatedKey, createdAt }];
                draft.createKeyLoadStatus = LoadStatus.OK;
                return;
            case createKey.error.getType():
                draft.createKeyLoadStatus = LoadStatus.ERROR;
                return;
            case deleteKey.request.getType():
                draft.deleteKeyLoadStatus = LoadStatus.REQUEST;
                return;
            case deleteKey.ok.getType():
                const deleteResponse = action.payload.response;
                draft.keys = [...state.keys.filter((key) => key.id !== deleteResponse.keyId)];
                draft.deleteKeyLoadStatus = LoadStatus.OK;
                return;
            case deleteKey.error.getType():
                draft.deleteKeyLoadStatus = LoadStatus.ERROR;
                return;
            case editKey.request.getType():
                draft.editKeyLoadStatus = LoadStatus.REQUEST;
                return;
            case editKey.ok.getType():
                const editResponse = action.payload.response;
                draft.keys = state.keys.map((key) => {
                    if (key.id === editResponse.id) {
                        return { ...key, name: editResponse.name };
                    }
                    return key;
                });
                draft.editKeyLoadStatus = LoadStatus.OK;
                return;
            case editKey.error.getType():
                draft.editKeyLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
