import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import { RootState } from 'src/store';
import SectionURLs from 'src/constants/urls';

// Components
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));
const ServiceRegistryPage = lazy(() => import('src/components/slo/service-registry/service-registry-page'));
const ServiceRegistryDetailsPage = lazy(() => import('src/components/slo/service-registry/details'));

const ServiceRegistryRoutes = () => {
    const sloPreviewFeatureFlag = useSelector((state: RootState) => state.launchDarkly.sloPreviewFeature);
    return (
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                {!sloPreviewFeatureFlag && <Route component={ErrorNotFound} />}
                <Route
                    exact
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SERVICE_REGISTRY_URL}`}
                    render={(props) => <ServiceRegistryPage {...props} />}
                />
                <Route
                    exect
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.SERVICE_REGISTRY_URL}/:id`}
                    render={(props) => <ServiceRegistryDetailsPage {...props} />}
                />
            </Switch>
        </Suspense>
    );
};

export default ServiceRegistryRoutes;
