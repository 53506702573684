import React from 'react';

// Utils
import { defineMessages, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// Components
import { makeStyles, createStyles, Button, Theme, IconButton } from '@material-ui/core';
import { SlackColoredIcon, XIcon } from '@blamelesshq/blameless-components';
import withSentryErrorBoundary from 'src/components/hocs/sentryErrorBoundary';

// Constants
import { WELCOME_PAGE_URL } from 'src/constants/urls';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        slackSetupPopup: {
            width: '31rem',
            backgroundColor: theme.colors.ui.grey1,
            color: '#fff',
            position: 'absolute',
            bottom: '2.4rem',
            left: '8rem',
            padding: '2.4rem',
            fontSize: '1.4rem',
            borderRadius: '0.4rem',
            boxSizing: 'border-box',
            animation: '$bounce 1s ease',
            zIndex: 99999
        },
        text: {
            marginBottom: '1.8rem',
            display: 'flex',
            flexDirection: 'column'
        },
        closeButton: {
            position: 'absolute',
            right: '1.6rem',
            top: '1.6rem'
        },
        slackIcon: {
            marginRight: '1rem'
        },
        '@keyframes bounce': {
            '70%': { transform: 'translateY(0%)' },
            '80%': { transform: 'translateY(-15%)' },
            '90%': { transform: 'translateY(0%)' },
            '95%': { transform: 'translateY(-7%)' },
            '97%': { transform: 'translateY(0%)' },
            '99%': { transform: 'translateY(-3%)' },
            '100%': { transform: 'translateY(0)' }
        }
    })
);

const messages = defineMessages({
    chatbot: {
        id: 'slackSetupPopup.chatbot',
        defaultMessage: 'See our blameless chatbot in action.',
        description: 'Slack setup reminder popup text'
    },
    try: {
        id: 'slackSetupPopup.try',
        defaultMessage: 'Try Slack integration!',
        description: 'Slack setup reminder popup text'
    },
    setup: {
        id: 'slackSetupPopup.setup',
        defaultMessage: 'SET UP SLACK',
        description: 'Slack setup reminder popup submit button text'
    }
});

interface IProps {
    onClose: () => void;
}

const SlackSetupPopup = ({ onClose }: IProps) => {
    const styles = useStyles();
    const { formatMessage } = useIntl();
    return (
        <div className={styles.slackSetupPopup} data-cy="slack-setup-reminder-popup">
            <IconButton onClick={onClose} className={styles.closeButton} data-cy="slack-setup-reminder-close-button">
                <XIcon fillColor="#fff" />
            </IconButton>
            <div className={styles.text}>
                <span>{formatMessage(messages.chatbot)}</span>
                <span>{formatMessage(messages.try)}</span>
            </div>
            <Link
                to={WELCOME_PAGE_URL}
                style={{
                    textDecoration: 'none'
                }}
                data-cy="slack-setup-reminder-link"
            >
                <Button variant="contained">
                    <SlackColoredIcon className={styles.slackIcon} />
                    {formatMessage(messages.setup)}
                </Button>
            </Link>
        </div>
    );
};

export default withSentryErrorBoundary(SlackSetupPopup);
