import $api from 'src/utils/api-interface';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { createActionAsync } from 'redux-act-async';
import { Dispatch } from 'redux';
import { createAction } from 'redux-act';

export const getUserJourneys = createActionAsync('GET_USER_JOURNEYS', () => {
    return $api
        .post({ url: '/services/SLOService/GetUserJourneyWithSLOs', data: { limit: 10, offset: 0 } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const getCurrentUserJourney = createActionAsync('GET_CURRENT_USER_JOURNEY', (id: number) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetUserJourney', data: { id } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const addUserJourney = createActionAsync('ADD_NEW_USER_JOURNEY', (newUserJourney, dispatch: Dispatch) =>
    $api
        .post({ url: '/services/SLOServiceCrud/CreateUserJourney', data: { model: newUserJourney } })
        .then((res: { data: any }) => {
            const newUserJourney = res.data.userJourney as UserJourney.IJourney;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully created user journey: ${newUserJourney.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return newUserJourney;
        })
        .catch((error) => {
            throw error;
        })
);

export const deleteUserJourney = createActionAsync('REMOVE_USER_JOURNEY', (id: number, dispatch: Dispatch) =>
    $api
        .post({ url: `/services/SLOServiceCrud/DeleteUserJourney`, data: { id } })
        .then((res: { data: any }) => {
            const deletedUserJourney = res.data.userJourney as UserJourney.IJourney;
            dispatch(
                enqueueSnackbar({
                    message: `Successfully removed User Journey: ${deletedUserJourney.name}`,
                    options: {
                        variant: 'success'
                    }
                })
            );
            return deletedUserJourney;
        })
        .catch((error) => {
            throw error;
        })
);

export const updateUserJourney = createActionAsync(
    'UPDATE_USER_JOURNEY',
    (userJourneyId: number, editableUserJourney) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/UpdateUserJourney',
                data: { id: userJourneyId, model: editableUserJourney }
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const clearUserJourneys = createAction('CLEAR_USER_JOURNEYS');
