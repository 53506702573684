import {
    getSlackChannels,
    getSlackUsers,
    getSlackUsersPhone,
    getMsTeamsUsersPhones
} from 'src/actions/user/user-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    getSlackUsersStatus: string;
    getSlackUsersPhoneStatus: string;
    getMsTeamsUsersPhoneStatus: string;
    getSlackChannelsStatus: string;
    slackUsers: any[];
    slackUsersPhone: any[];
    msTeamsUsersPhone: any[];
    slackChannels: any[];
    error: string | null;
}

export const defaultState: IDefaultState = {
    getSlackUsersStatus: LoadStatus.EMPTY,
    getSlackUsersPhoneStatus: LoadStatus.EMPTY,
    getMsTeamsUsersPhoneStatus: LoadStatus.EMPTY,
    getSlackChannelsStatus: LoadStatus.EMPTY,
    slackUsers: [],
    slackUsersPhone: [],
    msTeamsUsersPhone: [],
    slackChannels: [],
    error: null
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getSlackUsers.request.getType():
                draft.getSlackUsersStatus = LoadStatus.REQUEST;
                return;
            case getSlackUsers.ok.getType():
                draft.slackUsers = action.payload.response.slack_users;
                draft.getSlackUsersStatus = LoadStatus.OK;
                return;
            case getSlackUsers.error.getType():
                draft.getSlackUsersStatus = LoadStatus.ERROR;
                return;
            case getSlackUsersPhone.request.getType():
                draft.getSlackUsersPhoneStatus = LoadStatus.REQUEST;
                return;
            case getSlackUsersPhone.ok.getType():
                draft.slackUsersPhone = action.payload.response.slack_users;
                draft.getSlackUsersPhoneStatus = LoadStatus.OK;
                return;
            case getSlackUsersPhone.error.getType():
                draft.getSlackUsersPhoneStatus = LoadStatus.ERROR;
                return;
            case getMsTeamsUsersPhones.request.getType():
                draft.getMsTeamsUsersPhoneStatus = LoadStatus.REQUEST;
                return;
            case getMsTeamsUsersPhones.ok.getType():
                draft.msTeamsUsersPhone = action.payload.response;
                draft.getMsTeamsUsersPhoneStatus = LoadStatus.OK;
                return;
            case getMsTeamsUsersPhones.error.getType():
                draft.getMsTeamsUsersPhoneStatus = LoadStatus.ERROR;
                return;
            case getSlackChannels.request.getType():
                draft.getSlackChannelsStatus = LoadStatus.REQUEST;
                return;
            case getSlackChannels.ok.getType():
                draft.slackChannels = action.payload.response.slack_channels;
                draft.getSlackChannelsStatus = LoadStatus.OK;
                return;
            case getSlackChannels.error.getType():
                draft.getSlackChannelsStatus = LoadStatus.ERROR;
                return;
        }
    });
}
