import $api from 'src/utils/api-interface';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';
import { createActionAsync } from 'redux-act-async';
import { createAction } from 'redux-act';
import { Dispatch } from 'redux';

export const getService = createActionAsync('GET_SERVICE_VIA_REGISTRY', (id: number) =>
    $api
        .post({ url: '/services/SLOServiceCrud/GetService', data: { id } })
        .then((res: { data: any }) => res.data as ServiceRegistry.IService)
        .catch((error) => {
            throw error;
        })
);

export const getServices = createActionAsync('GET_SERVICES_VIA_REGISTRY', (limit: number, offset: number) =>
    $api
        .post({ url: '/services/SLOService/GetServicesWithSLINames', data: { limit, offset } })
        .then((res: { data: any }) => res.data as ServiceRegistry.IServicePaginatedResult)
        .catch((error) => {
            throw error;
        })
);

export const postNewService = createActionAsync(
    'POST_NEW_SERVICE',
    (newService: ServiceRegistry.IServicePostBody, dispatch: Dispatch) =>
        $api
            .post({
                url: '/services/SLOServiceCrud/CreateService',
                data: {
                    model: {
                        ...newService,
                        createdByUserId: '{userId}'
                    }
                }
            })
            .then((res: { data: any }) => {
                const newService = res.data.service as ServiceRegistry.IService;
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully created service: ${newService.name}`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const updateExistingService = createActionAsync(
    'UPDATE_EXIST_SERVICE_IN_REGISTRY',
    (id: string | number, updatedService: ServiceRegistry.IServicePostBody, dispatch: Dispatch) =>
        $api
            .post({ url: `/services/SLOServiceCrud/UpdateService`, data: { id, model: updatedService } })
            .then((res: { data: any }) => {
                const updatedService = res.data.service as ServiceRegistry.IService;
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully updated service: ${updatedService.name}`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            })
            .catch((error) => {
                throw error;
            })
);

export const removeService = createActionAsync(
    'REMOVE_SERVICE_FROM_REGISTRY',
    (id: string | number, dispatch: Dispatch) =>
        $api
            .post({ url: '/services/SLOServiceCrud/DeleteService', data: { id } })
            .then((res: { data: any }) => {
                const deletedService = res.data.service as ServiceRegistry.IService;
                dispatch(
                    enqueueSnackbar({
                        message: `Successfully removed service: ${deletedService.name}`,
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return deletedService;
            })
            .catch((error) => {
                throw error;
            })
);

export const clearServicesAndMetaData = createAction('CLEAR_SERVICES_AND_PAGINATION_METADATA');

export const restartBackfill = createActionAsync(
    'RESTART_BACKFILL',
    (orgId: number, sliId: number, dispatch: Dispatch) =>
        $api
            .post({ url: `/services/SLOService/RestartBackfill`, data: { orgId, sliId } })
            .then(({ data }) => {
                const isSliBackfillRestarted = data && data.successful;
                if (isSliBackfillRestarted) {
                    dispatch(
                        enqueueSnackbar({
                            message: `Successfully restarted backfill`,
                            options: {
                                variant: 'success'
                            }
                        })
                    );
                    return data;
                }
                if (!isSliBackfillRestarted) {
                    dispatch(
                        enqueueSnackbar({
                            message: `Failed to restart backfill`,
                            options: {
                                variant: 'error'
                            }
                        })
                    );

                    return data;
                }
            })
            .catch((error) => {
                throw error;
            })
);
