import React from 'react';

// Hooks
import { useUserPermissions, useUserPermissionsHook } from 'src/components/auth/user-permissions';

// Hocs
import withSentryErrorBoundary from 'src/components/hocs/sentryErrorBoundary';

// Constants
import { ROLE_COMPONENTS, PERMISSION_ACTION } from 'src/constants/user-permissions';

export interface IPermission {
    component: keyof typeof IUserPermissions.ROLE_COMPONENTS;
    action: keyof typeof IUserPermissions.PERMISSION_ACTION;
    incidentType?: string;
    incidentId?: number | string;
}

interface IProps {
    children: ({ isAuthorized }: { isAuthorized: boolean }) => JSX.Element;
    permissions: IPermission[];
    operator?: 'AND' | 'OR';
    testing?: boolean;
}

const usePermissionGuard = ({ permissions, operator = 'AND', testing }: Omit<IProps, 'children'>) => {
    // if testing, return the non singleton version of the hook
    const { getUserActionsByComponent } = testing ? useUserPermissionsHook() : useUserPermissions();

    const getUserActions = ({ component, action, incidentType, incidentId }: IPermission) => {
        const actions = getUserActionsByComponent(ROLE_COMPONENTS[component], incidentType, incidentId);
        return actions[PERMISSION_ACTION[action]];
    };

    const isAuthorized = operator === 'AND' ? permissions.every(getUserActions) : permissions.some(getUserActions);

    return { isAuthorized };
};

const PermissionGuard = ({ children, permissions, operator = 'AND' }: IProps) => {
    const { isAuthorized } = usePermissionGuard({ permissions, operator });

    return children({
        isAuthorized
    });
};

// This version is for testing since react-singleton-hook has issues with unmounting provider
const PermissionGuardForTesting = ({ children, permissions, operator = 'AND' }: IProps) => {
    const { isAuthorized } = usePermissionGuard({ permissions, operator, testing: true });

    return children({
        isAuthorized
    });
};

export default withSentryErrorBoundary(PermissionGuard);
export { usePermissionGuard, PermissionGuardForTesting };
