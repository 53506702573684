import { createAuditLog } from 'src/actions/audit-log/audit-log-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    createAuditLogLoadStatus: string;
}

const defaultState: IDefaultState = {
    createAuditLogLoadStatus: LoadStatus.EMPTY
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case createAuditLog.request.getType():
                draft.createAuditLogLoadStatus = LoadStatus.REQUEST;
                return;
            case createAuditLog.ok.getType():
                draft.createAuditLogLoadStatus = LoadStatus.OK;
                return;
            case createAuditLog.error.getType():
                draft.createAuditLogLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
