import { enqueueSnackbar, removeSnackbar } from 'src/actions/notification/notification-actions';

import produce from 'immer';

interface IDefaultState {
    snackbars: any[];
}

const defaultState: IDefaultState = {
    snackbars: []
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case enqueueSnackbar.getType():
                draft.snackbars = [
                    ...state.snackbars,
                    {
                        ...action.payload
                    }
                ];
                return;
            case removeSnackbar.getType():
                draft.snackbars = state.snackbars.filter((n) => n.key !== action.payload.key);
                return;
        }
    });
}
