import {
    getIntegrationsStatus,
    getServiceNowGroups,
    getPrometheusSettings,
    updatePrometheusSettings,
    getSplunkSettings,
    updateSplunkSettings,
    getSumoLogicSettings,
    updateSumoLogicSettings,
    getNewrelicSettings,
    updateNewrelicSettings,
    getDatadogSettings,
    updateDatadogSettings,
    getPingdomSettings,
    updatePingdomSettings,
    getConfluenceSettings,
    createConfluenceSettings,
    updateConfluenceSettings,
    getGoogleDriveSettings,
    createGoogleDriveSettings,
    updateGoogleDriveSettings,
    getNotionSettings,
    createNotionSettings,
    updateNotionSettings,
    getServiceNowUsers,
    getServiceNowProblemTaskTypes,
    getServiceNowSettings,
    updateServiceNowSettings,
    getServiceNowMappingFields,
    updateGoogleDriveExportSettings,
    getGoogleDriveProbe,
    getGptModels,
    getOpenAiSettings,
    createOpenAiSettings,
    updateOpenAiSettings,
    getOpenAiAudiences,
    createOpenAiAudience,
    updateOpenAiAudience,
    deleteOpenAiAudience,
    getOpenAiAudienceChannels,
    updateOpenAiAudienceChannels,
    getNotionProbe,
    updateNotionExportSettings,
    getNotionPages,
    getConfluenceProbe,
    updateConfluenceExportSettings,
    getConfluenceSpaces
} from 'src/actions/integration/integration-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    getIntegrationsStatusLoadStatus: string;
    integrationsStatus: IIntegration.IIntegrationStatus;
    serviceNow: {
        groups: { id: string; name: string; users: { id: string; name: string }[] }[];
        users: { id: string; name: string }[];
        taskTypes: { label: string; value: string }[];
        settings: Settings.IServiceNowSettings;
        mappingFields: { name: string; title: string; category: string }[];
    };
    serviceNowMappingFieldsLoadStatus: string;
    serviceNowSettingsLoadStatus: string;
    serviceNowGroupsLoadStatus: string;
    serviceNowUsersLoadStatus: string;
    serviceNowProblemTaskTypesLoadStatus: string;
    prometheusSettings: Settings.IPrometheusSettings;
    prometheusLoadStatus: string;
    splunkSettings: Settings.ISplunkSettings;
    splunkLoadStatus: string;
    sumoLogicSettings: Settings.ISumoLogicSettings;
    sumoLogicLoadStatus: string;
    newrelicSettings: Settings.INewrelicSettings;
    newrelicLoadStatus: string;
    datadogSettings: Settings.IDatadogSettings;
    datadogLoadStatus: string;
    pingdomSettings: Settings.IPingdomSettings;
    pingdomLoadStatus: string;
    confluenceSettings: Settings.IConfluenceSettings;
    confluenceSettingsLoadStatus: string;
    confluenceIntegrationProbeLoadStatus: string;
    confluenceIntegrationProbe: boolean;
    confluenceSpacesLoadStatus: string;
    confluenceSpaces: Settings.IConfluenceSpace[];
    googleDriveSettings?: Settings.IGoogleDriveSettings;
    googleDriveSettingsLoadStatus: string;
    googleDriveIntegrationProbeStatus: string;
    googleDriveIntegrationProbe: Map<string, any>;
    notionSettings: Settings.INotionSettings;
    notionSettingsLoadStatus: string;
    notionIntegrationProbeLoadStatus: string;
    notionIntegrationProbe: boolean;
    gptModels: string[];
    gptModelsLoadStatus: string;
    openAiSettings?: Settings.IOpenAISettings;
    openAiSettingsLoadStatus: string;
    openAiAudiencesLoadStatus: string;
    openAiAudiences: Settings.IAudienceForm[];
    audienceChannels: Settings.IChannelsByAudience;
    audienceChannelsLoadStatus: string;
    notionPagesLoadStatus: string;
    notionPages: Settings.INotionPage[];
}

export const defaultState: IDefaultState = {
    getIntegrationsStatusLoadStatus: LoadStatus.EMPTY,
    integrationsStatus: {},
    serviceNow: {
        groups: [],
        users: [],
        taskTypes: [],
        settings: {
            enabled: false,
            instanceUrl: '',
            authMethod: 'basic',
            username: '',
            password: '',
            createProblem: false,
            createIncident: false,
            clientId: '',
            clientSecret: '',
            importSet: []
        },
        mappingFields: []
    },
    serviceNowSettingsLoadStatus: LoadStatus.EMPTY,
    serviceNowMappingFieldsLoadStatus: LoadStatus.EMPTY,
    serviceNowGroupsLoadStatus: LoadStatus.EMPTY,
    serviceNowUsersLoadStatus: LoadStatus.EMPTY,
    serviceNowProblemTaskTypesLoadStatus: LoadStatus.EMPTY,
    prometheusSettings: {
        enabled: false,
        mode: 'direct',
        apiUrl: '',
        username: '',
        password: '',
        lambdaUrl: '',
        lambdaToken: '',
        gcp: {}
    },
    prometheusLoadStatus: LoadStatus.EMPTY,
    splunkSettings: {
        enabled: false,
        mode: 'aws',
        url: '',
        accessToken: ''
    },
    splunkLoadStatus: LoadStatus.EMPTY,
    sumoLogicSettings: {
        enabled: false,
        apiUrl: '',
        apiKey: ''
    },
    sumoLogicLoadStatus: LoadStatus.EMPTY,
    newrelicSettings: {
        enabled: false,
        key: ''
    },
    newrelicLoadStatus: LoadStatus.EMPTY,
    datadogSettings: {
        enabled: false,
        apiKey: '',
        applicationKey: ''
    },
    datadogLoadStatus: LoadStatus.EMPTY,
    pingdomSettings: {
        enabled: false,
        apiToken: ''
    },
    pingdomLoadStatus: LoadStatus.EMPTY,
    confluenceSettingsLoadStatus: LoadStatus.EMPTY,
    confluenceIntegrationProbeLoadStatus: LoadStatus.EMPTY,
    confluenceIntegrationProbe: false,
    confluenceSettings: {} as Settings.IConfluenceSettings,
    confluenceSpacesLoadStatus: LoadStatus.EMPTY,
    confluenceSpaces: [],
    googleDriveSettingsLoadStatus: LoadStatus.EMPTY,
    googleDriveIntegrationProbeStatus: LoadStatus.EMPTY,
    googleDriveIntegrationProbe: new Map(),
    notionSettings: {} as Settings.INotionSettings,
    notionSettingsLoadStatus: LoadStatus.EMPTY,
    notionIntegrationProbeLoadStatus: LoadStatus.EMPTY,
    notionIntegrationProbe: false,
    gptModels: [],
    gptModelsLoadStatus: LoadStatus.EMPTY,
    openAiSettings: undefined,
    openAiSettingsLoadStatus: LoadStatus.EMPTY,
    openAiAudiencesLoadStatus: LoadStatus.EMPTY,
    openAiAudiences: [],
    audienceChannels: {},
    audienceChannelsLoadStatus: LoadStatus.EMPTY,
    notionPagesLoadStatus: LoadStatus.EMPTY,
    notionPages: []
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getIntegrationsStatus.request.getType():
                draft.getIntegrationsStatusLoadStatus = LoadStatus.REQUEST;
                return;
            case getIntegrationsStatus.ok.getType():
                draft.integrationsStatus = action.payload.response.integrations;
                draft.getIntegrationsStatusLoadStatus = LoadStatus.OK;
                return;
            case getIntegrationsStatus.error.getType():
                draft.getIntegrationsStatusLoadStatus = LoadStatus.ERROR;
                return;
            case getServiceNowGroups.request.getType():
                draft.serviceNowGroupsLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowGroups.ok.getType():
                draft.serviceNowGroupsLoadStatus = LoadStatus.OK;
                draft.serviceNow.groups = action.payload.response.data.groups;
                return;
            case getServiceNowGroups.error.getType():
                draft.serviceNowGroupsLoadStatus = LoadStatus.ERROR;
                draft.serviceNow.groups = [];
                return;
            case getServiceNowUsers.request.getType():
                draft.serviceNowUsersLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowUsers.ok.getType():
                draft.serviceNowUsersLoadStatus = LoadStatus.OK;
                draft.serviceNow.users = action.payload.response.data.users;
                return;
            case getServiceNowUsers.error.getType():
                draft.serviceNow.users = [];
                draft.serviceNowUsersLoadStatus = LoadStatus.ERROR;
                return;
            case getServiceNowProblemTaskTypes.request.getType():
                draft.serviceNowProblemTaskTypesLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowProblemTaskTypes.ok.getType():
                draft.serviceNow.taskTypes = action.payload.response.data.types;
                draft.serviceNowProblemTaskTypesLoadStatus = LoadStatus.OK;
                return;
            case getServiceNowProblemTaskTypes.error.getType():
                draft.serviceNow.taskTypes = [];
                draft.serviceNowProblemTaskTypesLoadStatus = LoadStatus.ERROR;
                return;
            case getServiceNowSettings.request.getType():
            case updateServiceNowSettings.request.getType():
                draft.serviceNowSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowSettings.ok.getType():
            case updateServiceNowSettings.ok.getType():
                draft.serviceNowSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response?.settings) {
                    draft.serviceNow.settings = action.payload.response.settings;
                }
                return;
            case getServiceNowSettings.error.getType():
            case updateServiceNowSettings.error.getType():
                draft.serviceNowSettingsLoadStatus = LoadStatus.ERROR;

                return;
            case getServiceNowMappingFields.request.getType():
                draft.serviceNowMappingFieldsLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceNowMappingFields.ok.getType():
                draft.serviceNowMappingFieldsLoadStatus = LoadStatus.OK;
                draft.serviceNow.mappingFields = action.payload.response.mappingFields;
                return;
            case getServiceNowMappingFields.error.getType():
                draft.serviceNow.mappingFields = [];
                draft.serviceNowMappingFieldsLoadStatus = LoadStatus.ERROR;
                return;
            case getPrometheusSettings.request.getType():
            case updatePrometheusSettings.request.getType():
                draft.prometheusLoadStatus = LoadStatus.REQUEST;
                return;
            case getPrometheusSettings.ok.getType():
            case updatePrometheusSettings.ok.getType():
                draft.prometheusLoadStatus = LoadStatus.OK;
                const prometheusPayload = action.payload.response.response;
                delete prometheusPayload.tenantId;
                draft.prometheusSettings = prometheusPayload;
                return;
            case getPrometheusSettings.error.getType():
            case updatePrometheusSettings.error.getType():
                draft.prometheusLoadStatus = LoadStatus.ERROR;
                return;

            case getSplunkSettings.request.getType():
            case updateSplunkSettings.request.getType():
                draft.splunkLoadStatus = LoadStatus.REQUEST;
                return;
            case getSplunkSettings.ok.getType():
            case updateSplunkSettings.ok.getType():
                draft.splunkLoadStatus = LoadStatus.OK;
                const splunkPayload = action.payload.response.response;
                delete splunkPayload.tenantId;
                draft.splunkSettings = splunkPayload;
                return;
            case getSplunkSettings.error.getType():
            case updateSplunkSettings.error.getType():
                draft.splunkLoadStatus = LoadStatus.ERROR;

            case getSumoLogicSettings.request.getType():
            case updateSumoLogicSettings.request.getType():
                draft.sumoLogicLoadStatus = LoadStatus.REQUEST;
                return;
            case getSumoLogicSettings.ok.getType():
            case updateSumoLogicSettings.ok.getType():
                draft.sumoLogicLoadStatus = LoadStatus.OK;
                const sumoLogicPayload = action.payload.response.response;
                delete sumoLogicPayload.tenantId;
                draft.sumoLogicSettings = sumoLogicPayload;
                return;
            case getSumoLogicSettings.error.getType():
            case updateSumoLogicSettings.error.getType():
                draft.sumoLogicLoadStatus = LoadStatus.ERROR;
                return;

            case getNewrelicSettings.request.getType():
            case updateNewrelicSettings.request.getType():
                draft.newrelicLoadStatus = LoadStatus.REQUEST;
                return;
            case getNewrelicSettings.ok.getType():
            case updateNewrelicSettings.ok.getType():
                draft.newrelicLoadStatus = LoadStatus.OK;
                const newrelicPayload = action.payload.response.response;
                delete newrelicPayload.tenantId;
                draft.newrelicSettings = newrelicPayload;
                return;
            case getNewrelicSettings.error.getType():
            case updateNewrelicSettings.error.getType():
                draft.newrelicLoadStatus = LoadStatus.ERROR;
                return;
            case getDatadogSettings.request.getType():
            case updateDatadogSettings.request.getType():
                draft.datadogLoadStatus = LoadStatus.REQUEST;
                return;
            case getDatadogSettings.ok.getType():
            case updateDatadogSettings.ok.getType():
                draft.datadogLoadStatus = LoadStatus.OK;
                const datadogPayload = action.payload.response.response;
                delete datadogPayload.tenantId;
                draft.datadogSettings = datadogPayload;
                return;
            case getDatadogSettings.error.getType():
            case updateDatadogSettings.error.getType():
                draft.datadogLoadStatus = LoadStatus.ERROR;
                return;
            case getPingdomSettings.request.getType():
            case updatePingdomSettings.request.getType():
                draft.pingdomLoadStatus = LoadStatus.REQUEST;
                return;
            case getPingdomSettings.ok.getType():
            case updatePingdomSettings.ok.getType():
                draft.pingdomLoadStatus = LoadStatus.OK;
                const pingdomPayload = action.payload.response.response;
                delete pingdomPayload.tenantId;
                draft.pingdomSettings = pingdomPayload;
                return;
            case getPingdomSettings.error.getType():
            case updatePingdomSettings.error.getType():
                draft.pingdomLoadStatus = LoadStatus.ERROR;
                return;
            case getConfluenceSettings.request.getType():
            case createConfluenceSettings.request.getType():
            case updateConfluenceSettings.request.getType():
                draft.confluenceSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case getConfluenceSettings.ok.getType():
            case createConfluenceSettings.ok.getType():
            case updateConfluenceSettings.ok.getType():
                draft.confluenceSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response) {
                    const { enabled, folderId, metadata, folders } = action.payload.response;
                    draft.confluenceSettings = { enabled, folderId, metadata, folders };
                }
                return;
            case getConfluenceSettings.error.getType():
            case createConfluenceSettings.error.getType():
            case updateConfluenceSettings.error.getType():
                draft.confluenceSettingsLoadStatus = LoadStatus.ERROR;
                return;
            case updateConfluenceExportSettings.ok.getType():
                draft.confluenceSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response.data) {
                    const { enabled } = action.payload.response.data;
                    // @ts-ignore
                    draft.confluenceSettings = {
                        ...state.confluenceSettings,
                        enabled
                    };
                }
                return;
            case getConfluenceProbe.request.getType():
                draft.confluenceIntegrationProbeLoadStatus = LoadStatus.REQUEST;
                return;
            case getConfluenceProbe.ok.getType():
                draft.confluenceIntegrationProbeLoadStatus = LoadStatus.OK;
                draft.confluenceIntegrationProbe = action.payload.response.data?.probe?.healthy;
                return;
            case getConfluenceProbe.error.getType():
                draft.confluenceIntegrationProbeLoadStatus = LoadStatus.ERROR;
                return;
            case getConfluenceSpaces.request.getType():
                draft.confluenceSpacesLoadStatus = LoadStatus.REQUEST;
                return;
            case getConfluenceSpaces.ok.getType():
                draft.confluenceSpacesLoadStatus = LoadStatus.OK;
                draft.confluenceSpaces = action.payload.response;
                return;
            case getConfluenceSpaces.error.getType():
                draft.confluenceSpacesLoadStatus = LoadStatus.ERROR;
                return;
            case getGoogleDriveSettings.request.getType():
            case createGoogleDriveSettings.request.getType():
            case updateGoogleDriveSettings.request.getType():
                draft.googleDriveSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case getGoogleDriveSettings.ok.getType():
            case createGoogleDriveSettings.ok.getType():
            case updateGoogleDriveSettings.ok.getType():
                draft.googleDriveSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response) {
                    const { enabled, folderId, folders, metadata } = action.payload.response;
                    draft.googleDriveSettings = { enabled, folderId, folders, metadata };
                }
                return;
            case getGoogleDriveSettings.error.getType():
            case createGoogleDriveSettings.error.getType():
            case updateGoogleDriveSettings.error.getType():
                draft.googleDriveSettingsLoadStatus = LoadStatus.ERROR;
                return;
            case updateGoogleDriveExportSettings.ok.getType():
                draft.googleDriveSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response.data) {
                    const { enabled, folders } = action.payload.response.data;
                    // @ts-ignore
                    draft.googleDriveSettings = {
                        ...state.googleDriveSettings,
                        enabled,
                        folders
                    };
                }
                return;
            case getGoogleDriveProbe.request.getType():
                draft.googleDriveIntegrationProbeStatus = LoadStatus.REQUEST;
                return;
            case getGoogleDriveProbe.ok.getType():
                draft.googleDriveIntegrationProbeStatus = LoadStatus.OK;
                draft.googleDriveIntegrationProbe = draft.googleDriveIntegrationProbe.set(
                    'google-drive',
                    action.payload.response.data
                );
                return;
            case getGoogleDriveProbe.error.getType():
                draft.googleDriveIntegrationProbeStatus = LoadStatus.ERROR;
                return;
            case getNotionSettings.request.getType():
            case createNotionSettings.request.getType():
            case updateNotionSettings.request.getType():
                draft.notionSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case getNotionSettings.ok.getType():
            case createNotionSettings.ok.getType():
            case updateNotionSettings.ok.getType():
                draft.notionSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response) {
                    const { enabled, folderId, folders, metadata } = action.payload.response;
                    draft.notionSettings = { enabled, folderId, folders, metadata };
                }
                return;
            case getNotionSettings.error.getType():
            case createNotionSettings.error.getType():
            case updateNotionSettings.error.getType():
                draft.notionSettingsLoadStatus = LoadStatus.ERROR;
                return;
            case updateNotionExportSettings.ok.getType():
                draft.notionSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response.data) {
                    const { enabled, folders } = action.payload.response.data;
                    // @ts-ignore
                    draft.notionSettings = {
                        ...state.notionSettings,
                        enabled,
                        folders
                    };
                }
                return;
            case getNotionProbe.request.getType():
                draft.notionIntegrationProbeLoadStatus = LoadStatus.REQUEST;
                return;
            case getNotionProbe.ok.getType():
                draft.notionIntegrationProbeLoadStatus = LoadStatus.OK;
                draft.notionIntegrationProbe = action.payload.response.data?.probe?.healthy;
                return;
            case getNotionProbe.error.getType():
                draft.notionIntegrationProbeLoadStatus = LoadStatus.ERROR;
                return;
            case getNotionPages.request.getType():
                draft.notionPagesLoadStatus = LoadStatus.REQUEST;
                return;
            case getNotionPages.ok.getType():
                draft.notionPagesLoadStatus = LoadStatus.OK;
                draft.notionPages = action.payload.response?.folders;
                return;
            case getNotionPages.error.getType():
                draft.notionPagesLoadStatus = LoadStatus.ERROR;
                return;
            case getGptModels.request.getType():
                draft.gptModelsLoadStatus = LoadStatus.REQUEST;
                return;
            case getGptModels.ok.getType():
                draft.gptModelsLoadStatus = LoadStatus.OK;
                draft.gptModels = action.payload.response;
                return;
            case getGptModels.error.getType():
                draft.gptModelsLoadStatus = LoadStatus.ERROR;
                return;
            case getOpenAiSettings.request.getType():
                draft.openAiSettings = undefined;
                draft.openAiSettingsLoadStatus = LoadStatus.REQUEST;
                return;
            case getOpenAiSettings.ok.getType():
                draft.openAiSettingsLoadStatus = LoadStatus.OK;
                if (action.payload.response) {
                    draft.openAiSettings = action.payload.response;
                }
                return;
            case getOpenAiSettings.error.getType():
                draft.openAiSettingsLoadStatus = LoadStatus.ERROR;
                return;
            case createOpenAiSettings.ok.getType():
                const [createdSettings] = action.payload.request;
                draft.openAiSettings = createdSettings;
                return;
            case updateOpenAiSettings.ok.getType():
                const [updatedSettings] = action.payload.request;
                draft.openAiSettings = {
                    ...state.openAiSettings,
                    ...updatedSettings
                };
                return;
            case getOpenAiAudiences.request.getType():
                draft.openAiAudiencesLoadStatus = LoadStatus.REQUEST;
                return;
            case getOpenAiAudiences.ok.getType():
                draft.openAiAudiencesLoadStatus = LoadStatus.OK;
                draft.openAiAudiences = action.payload.response;
                return;
            case getOpenAiAudiences.error.getType():
                draft.openAiAudiencesLoadStatus = LoadStatus.ERROR;
                return;
            case createOpenAiAudience.ok.getType():
                const newAudience = {
                    ...action.payload.request[0],
                    id: action.payload.response.data.id
                };
                draft.openAiAudiences = [...state.openAiAudiences, newAudience];
                return;
            case updateOpenAiAudience.ok.getType():
                const [updatedAudience] = action.payload.request;
                draft.openAiAudiences = state.openAiAudiences.map((audience) =>
                    audience.id === updatedAudience.id ? updatedAudience : audience
                );
                return;
            case deleteOpenAiAudience.ok.getType():
                const [deletedAudienceId] = action.payload.request;
                draft.openAiAudiences = state.openAiAudiences.filter((audience) => audience.id !== deletedAudienceId);
                return;
            case createOpenAiAudience.error.getType():
            case updateOpenAiAudience.error.getType():
            case deleteOpenAiAudience.error.getType():
                draft.openAiAudiences = [...state.openAiAudiences];
                return;
            case getOpenAiAudienceChannels.request.getType():
                const loadingAudienceId = action.payload;
                draft.audienceChannelsLoadStatus = LoadStatus.REQUEST;
                if (state.audienceChannels[loadingAudienceId]) {
                    draft.audienceChannels[loadingAudienceId] = {
                        ...state.audienceChannels[loadingAudienceId],
                        isLoading: true
                    };
                } else {
                    draft.audienceChannels = {
                        ...state.audienceChannels,
                        [loadingAudienceId]: {
                            channels: [],
                            isLoading: true
                        }
                    };
                }
                return;
            case getOpenAiAudienceChannels.ok.getType():
                const [audienceId] = action.payload.request;
                const channels = action.payload.response.data;
                if (state.audienceChannels[audienceId]) {
                    draft.audienceChannels[audienceId] = {
                        channels,
                        isLoading: false
                    };
                } else {
                    draft.audienceChannels = {
                        ...state.audienceChannels,
                        [audienceId]: {
                            channels,
                            isLoading: false
                        }
                    };
                }
                draft.audienceChannelsLoadStatus = LoadStatus.OK;
                return;
            case getOpenAiAudienceChannels.error.getType():
                draft.audienceChannelsLoadStatus = LoadStatus.ERROR;
                return;
            case updateOpenAiAudienceChannels.ok.getType():
                const [audienceFormData] = action.payload.request;
                if (state.audienceChannels[audienceFormData.id]) {
                    draft.audienceChannels[audienceFormData.id] = {
                        channels: audienceFormData.channels,
                        isLoading: false
                    };
                } else {
                    draft.audienceChannels = {
                        ...state.audienceChannels,
                        [audienceFormData.id]: {
                            channels: audienceFormData.channels,
                            isLoading: false
                        }
                    };
                }
                return;
        }
    });
}
