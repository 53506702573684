import React from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { SnackbarProvider, WithSnackbarProps } from 'notistack';
import { IconButton } from '@material-ui/core';
import { XIcon } from '@blamelesshq/blameless-components';

const useStyles = makeStyles((theme) =>
    createStyles({
        success: { backgroundColor: theme.colors.semantic.success },
        error: { backgroundColor: theme.colors.semantic.error },
        warning: { backgroundColor: theme.colors.semantic.alert },
        info: { backgroundColor: theme.colors.semantic.info }
    })
);

const CustomSnackbarProvider: React.FC = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();
    const notistackRef = React.useRef<WithSnackbarProps>();
    const handleDismissClick = (key) => () => notistackRef.current!.closeSnackbar(key);
    const action = (key) => (
        <IconButton data-cy="snackbar-dismiss" onClick={handleDismissClick(key)}>
            <XIcon fillColor={theme.colors.icon.light} />
        </IconButton>
    );

    return (
        <SnackbarProvider
            ref={notistackRef}
            action={action}
            classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info
            }}
            maxSnack={1}
            autoHideDuration={8000}
            preventDuplicate
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

export default CustomSnackbarProvider;
