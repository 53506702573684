import { $api_configuration_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';

export const getConfigurationIncidentTypes = createActionAsync('GET_CONFIGURATION_INCIDENT_TYPES', async () => {
    try {
        const response = await $api_configuration_v2.get({ url: '/incident-types' });
        return response.data;
    } catch (error) {
        throw error;
    }
});
