export enum ROLE_COMPONENTS {
    ALL = 'all',
    INCIDENT = 'incident',
    DASHBOARD = 'dashboard',
    SETTING = 'setting',
    SLO = 'slo',
    IDENTITY = 'identity',
    AUDITLOG = 'auditlog',
    COMMSLEAD = 'commslead',
    RUNBOOKS = 'runbooks',
    COMMSFLOW = 'commsFlow',
    FOLLOWUP = 'followup',
    WORKFLOWS = 'workflows'
}

export enum PERMISSION_ACTION {
    DELETE = 'delete',
    UPDATE = 'update',
    EXECUTE = 'execute',
    CREATE = 'create',
    READ = 'read',
    ALL = '*'
}

export const ROOT_ROLE: IUserPermissions.IUserRole = {
    description: 'All Access',
    name: 'Root',
    component: 'all',
    actions: [PERMISSION_ACTION.ALL]
};

export const SETTINGS_ADMIN_ROLE: IUserPermissions.IUserRole = {
    description: 'Setting Admin',
    name: 'SettingAdmin',
    component: 'setting',
    actions: [PERMISSION_ACTION.UPDATE, PERMISSION_ACTION.READ]
};

export const SETTINGS_WRITER_ROLE: IUserPermissions.IUserRole = {
    description: 'Setting Writer',
    name: 'SettingWriter',
    component: 'setting',
    actions: [PERMISSION_ACTION.UPDATE]
};

export const ALL_INTEGRATIONS = ['all'];
