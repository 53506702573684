import { createActionAsync } from 'redux-act-async';
import $api from 'src/utils/api-interface';

export const getTagAutocompleteOptions = createActionAsync(
    'GET_TAG_AUTOCOMPLETE_OPTIONS',
    (tagCategory: string, tagLabelPrefix: string) =>
        $api
            .get({
                url: `/tags/search?category=${encodeURIComponent(tagCategory)}&tagLabelPrefix=${encodeURIComponent(
                    tagLabelPrefix
                )}`
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getPredefinedTags = createActionAsync(
    'GET_PREDEFINED_TAGS',
    (incidentType: string, incidentSeverity: number) =>
        $api
            .get({
                url: '/tags/predefined-tags',
                params: {
                    incidentType,
                    incidentSeverity
                }
            })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const getAllTags = createActionAsync('GET_ALL_TAGS', (enableTagManagement: boolean) =>
    $api
        .get({
            url: enableTagManagement ? '/tagging/all_tags_thriftshop_format?aggregate=true' : '/tags'
        })
        .then((res) => ({ data: res.data, enableTagManagement }))
        .catch((error) => {
            throw error;
        })
);

const incidentTagsAction = (offset, limit) =>
    $api
        .get({
            url: `/tagging/get_incident_tags/?offset=${offset}&limit=${limit}`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });

export const getIncidentTags = createActionAsync('GET_INCIDENT_TAGS', incidentTagsAction);

export const appendIncidentTags = createActionAsync('APPEND_INCIDENT_TAGS', incidentTagsAction);

export const getCategoryTagsStartingWith = createActionAsync(
    'GET_CATEGORY_TAGS_STARTING_WITH',
    (tagCategory: string, tagLabelPrefix: string) =>
        $api
            .get({
                url: `/tagging/get_incident_tags/?category_name=${encodeURIComponent(tagCategory)}`
            })
            .then((res) => {
                return {
                    tags: res.data,
                    tagLabelPrefix
                };
            })
            .catch((error) => {
                throw error;
            })
);
