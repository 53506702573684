const BASE_URL = 'https://storage.googleapis.com/blameless-showcase';

export const Slack = `${BASE_URL}/slack.png`;
export const MSTeams = `${BASE_URL}/ms-teams.png`;
export const Jira = `${BASE_URL}/jira.png`;
export const PagerDuty = `${BASE_URL}/pagerduty.png`;
export const Opsgenie = `${BASE_URL}/opsgenie.png`;
export const Backstage = `${BASE_URL}/backstage.png`;
export const Pingdom = `${BASE_URL}/pingdom.png`;
export const ZoomIcon = `${BASE_URL}/zoom-icon.png`;
export const Zoom = `${BASE_URL}/zoom.png`;
export const Logo = `${BASE_URL}/logo.png`;
export const Circle = `${BASE_URL}/circle.png`;
export const HomeBackground = `${BASE_URL}/home-background.png`;
export const BlamelessFavicon = `${BASE_URL}/favicon.svg`;
export const BlamelessCircleLogo = `${BASE_URL}/blameless-circle-logo.png`;
export const IconSquare = `${BASE_URL}/Icon_square.png`;
export const JiraLogo = `${BASE_URL}/jira_logo.png`;
export const GoogleCalendar = `${BASE_URL}/google_calendar.png`;
export const GoogleHangouts = `${BASE_URL}/hangouts.png`;
export const Datadog = `${BASE_URL}/datadog-logo.png`;
export const ServiceNow = `${BASE_URL}/servicenow-logo.png`;
export const AutoTask = `${BASE_URL}/autotask.png`;
export const Prometheus = `${BASE_URL}/prometheus-logo.png`;
export const Statuspage = `${BASE_URL}/statuspage.jpg`;
export const GitHubLogo = `${BASE_URL}/GitHub-Mark-120px-plus.png`;
export const GoogleLogo = `${BASE_URL}/google-g-logo.svg`;
export const AppDynamics = `${BASE_URL}/app-dynamics-logo.png`;
export const PlaceholderImage = `${BASE_URL}/placeholder.svg`;
export const GoToMeeting = `${BASE_URL}/gotomeeting.png`;
export const SumoLogic = `${BASE_URL}/sumologic_logo.png`;
export const Custom = `${BASE_URL}/material-icons/linkp.png`;
export const SplunkLogo = `${BASE_URL}/splunk_logo.png`;
export const LogoBlamelessWhiteText = `${BASE_URL}/logo-blameless-white-text.svg`;

interface IVendorIcons {
    AppDynamics: string;
    AppDynamicsWithText: string;
    AutoTask: string;
    BlamelessInvert: string;
    BlamelessCircleLogo: string;
    Blameless: string;
    DatadogWithText: string;
    Datadog: string;
    Github: string;
    Google: string;
    Jira: string;
    MSTeams: string;
    NewRelicWithText: string;
    NewRelic: string;
    Opsgenie: string;
    Backstage: string;
    PagerDuty: string;
    PagerDutyInvert: string;
    PagerDutyOld: string;
    PagerDutyOldInvert: string;
    Pingdom: string;
    PrometheusGray: string;
    PrometheusInvert: string;
    Prometheus: string;
    ServiceNow: string;
    Slack: string;
    StatusPage: string;
    Zoom: string;
    Splunk: string;
    SumoLogic: string;
    GoogleDrive: string;
    Notion: string;
    OpenAI: string;
    Confluence: string;
}

const generateV2VendorIcons = (type: 'svg' | 'png'): IVendorIcons => {
    const generatePathV2Icons = (name: string) => {
        const NEW_VENDOR_PNG_PATH = `${BASE_URL}/vendor-icons/PNG`;
        const NEW_VENDOR_SVG_PATH = `${BASE_URL}/vendor-icons/SVG`;

        if (type === 'svg') {
            return `${NEW_VENDOR_SVG_PATH}/${name}.${type}`;
        }
        return `${NEW_VENDOR_PNG_PATH}/${name}.png`;
    };

    const IMAGE_NAMES = [
        'AppDynamics',
        'AppDynamics-With-Text',
        'AutoTask',
        'Blameless-Invert',
        'Blameless-Circle-Logo',
        'Blameless',
        'Datadog-With-Text',
        'Datadog',
        'Github',
        'Google',
        'Jira',
        'NewRelic-With-Text',
        'NewRelic',
        'Opsgenie',
        'Backstage',
        'PagerDuty',
        'PagerDuty-Invert',
        'PagerDuty-Old',
        'PagerDuty-Old-Invert',
        'Pingdom',
        'Prometheus-Gray',
        'Prometheus-Invert',
        'Prometheus',
        'ServiceNow',
        'Slack',
        'MSTeams',
        'StatusPage',
        'Zoom',
        'Splunk',
        'SumoLogic',
        'GoogleDrive',
        'OpenAI',
        'Notion',
        'Confluence'
    ];

    return IMAGE_NAMES.reduce((acc, fileName: string) => {
        acc[fileName.replace(/-/g, '')] = generatePathV2Icons(fileName);
        return acc;
    }, {}) as IVendorIcons;
};

export const VendorIconsPng: IVendorIcons = generateV2VendorIcons('png');
export const VendorIconsSvg: IVendorIcons = generateV2VendorIcons('svg');

export default {
    AppDynamics,
    AutoTask,
    BlamelessFavicon,
    BlamelessCircleLogo,
    Circle,
    Datadog,
    GitHubLogo,
    GoogleCalendar,
    GoogleHangouts,
    GoogleLogo,
    IconSquare,
    Jira,
    JiraLogo,
    Logo,
    Opsgenie,
    Backstage,
    PagerDuty,
    Pingdom,
    Prometheus,
    ServiceNow,
    Slack,
    MSTeams,
    Statuspage,
    Zoom,
    ZoomIcon,
    PlaceholderImage,
    GoToMeeting,
    SplunkLogo,
    SumoLogic
};
