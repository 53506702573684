export default {
    dataTypes: [
        {
            name: 'text',
            defaultAggregate: 'concatenate',
            aggregateTypes: [
                {
                    name: 'concatenate',
                    label: 'Concatenate',
                    returnDataType: 'text'
                },
                {
                    name: 'count',
                    label: 'Count',
                    returnDataType: 'number'
                }
            ],
            filterTypes: [
                {
                    name: 'eq',
                    label: 'Equals'
                },
                {
                    name: '!eq',
                    label: 'Not equals'
                },
                {
                    name: 'ct',
                    label: 'Contains'
                },
                {
                    name: '!ct',
                    label: 'Not contains'
                },
                {
                    name: 'sw',
                    label: 'Starts with'
                },
                {
                    name: 'ew',
                    label: 'Ends with'
                },
                {
                    name: 'in',
                    label: 'From list'
                },
                {
                    name: '!in',
                    label: 'Not from list'
                },
                {
                    name: 'empty',
                    label: 'Empty'
                },
                {
                    name: '!empty',
                    label: 'Not empty'
                }
            ],
            functionTypes: [
                {
                    name: 'length',
                    label: 'Length',
                    returnDataType: 'number'
                }
            ],
            formatTypes: [
                {
                    name: 'upper',
                    label: 'Upper',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'lower',
                    label: 'Lower',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'pretty',
                    label: 'Pretty',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'trim_15',
                    label: 'Trim 15',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'trim_30',
                    label: 'Trim 30',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'pretty_trim_15',
                    label: 'Pretty Trim 15',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'pretty_trim_30',
                    label: 'Pretty Trim 30',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'link',
                    label: 'Link',
                    internal: false,
                    clientSide: true
                },
                {
                    name: 'image_url',
                    label: 'Image URL',
                    internal: false,
                    clientSide: true
                }
            ]
        },
        {
            name: 'number',
            defaultAggregate: 'sum',
            aggregateTypes: [
                {
                    name: 'sum',
                    label: 'Sum',
                    returnDataType: 'number'
                },
                {
                    name: 'count',
                    label: 'Count',
                    returnDataType: 'number'
                },
                {
                    name: 'min',
                    label: 'Minimum',
                    returnDataType: 'number'
                },
                {
                    name: 'max',
                    label: 'Maximum',
                    returnDataType: 'number'
                },
                {
                    name: 'avg',
                    label: 'Average',
                    returnDataType: 'number'
                },
                {
                    name: 'concatenate',
                    label: 'Concatenate',
                    returnDataType: 'text'
                }
            ],
            filterTypes: [
                {
                    name: 'eq',
                    label: 'Equals'
                },
                {
                    name: '!eq',
                    label: 'Not equals'
                },
                {
                    name: 'gt',
                    label: 'Greater than'
                },
                {
                    name: 'gte',
                    label: 'Greater than or equal to'
                },
                {
                    name: 'lt',
                    label: 'Less than'
                },
                {
                    name: 'lte',
                    label: 'Less than or equal to'
                },
                {
                    name: 'btw',
                    label: 'Between'
                },
                {
                    name: 'empty',
                    label: 'Empty'
                },
                {
                    name: '!empty',
                    label: 'Not empty'
                }
            ],
            functionTypes: [
                {
                    name: 'ifnull_0',
                    label: 'Zero if empty',
                    returnDataType: 'number'
                }
            ],
            formatTypes: [
                {
                    name: 'currency',
                    label: 'Currency',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'usd_currency',
                    label: '$ Currency',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'cad_currency',
                    label: 'CAD Currency',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'comma_separator',
                    label: 'Comma separator',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'percentage',
                    label: 'Percentage',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'abs',
                    label: 'Absolute',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'ceiling',
                    label: 'Ceiling',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'floor',
                    label: 'Floor',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'round_1',
                    label: 'Round 1 decimal',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'round_2',
                    label: 'Round 2 decimal',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'truncate',
                    label: 'Truncate no decimal',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'incident_url',
                    label: 'Incident URL',
                    internal: true,
                    clientSide: true
                }
            ]
        },
        {
            name: 'datetime',
            defaultAggregate: 'count',
            aggregateTypes: [
                {
                    name: 'count',
                    label: 'Count',
                    returnDataType: 'number'
                },
                {
                    name: 'min',
                    label: 'Minimum',
                    returnDataType: 'datetime'
                },
                {
                    name: 'max',
                    label: 'Maximum',
                    returnDataType: 'datetime'
                },
                {
                    name: 'concatenate',
                    label: 'Concatenate',
                    returnDataType: 'text'
                }
            ],
            filterTypes: [
                {
                    name: 'rw',
                    label: 'Rolling window'
                },
                {
                    name: 'eq',
                    label: 'Equals'
                },
                {
                    name: '!eq',
                    label: 'Not equals'
                },
                {
                    name: 'gt',
                    label: 'Greater than'
                },
                {
                    name: 'gte',
                    label: 'Greater than or equal to'
                },
                {
                    name: 'lt',
                    label: 'Less than'
                },
                {
                    name: 'lte',
                    label: 'Less than or equal to'
                },
                {
                    name: 'btw',
                    label: 'Between'
                },
                {
                    name: 'empty',
                    label: 'Empty'
                },
                {
                    name: '!empty',
                    label: 'Not empty'
                }
            ],
            functionTypes: [
                {
                    name: 'date',
                    label: 'Date',
                    returnDataType: 'datetime'
                },
                {
                    name: 'year',
                    label: 'Year',
                    returnDataType: 'number'
                },
                {
                    name: 'month',
                    label: 'Month',
                    returnDataType: 'text'
                },
                {
                    name: 'month_name',
                    label: 'Month name',
                    returnDataType: 'text'
                },
                {
                    name: 'month_number',
                    label: 'Month number',
                    returnDataType: 'number'
                },
                {
                    name: 'day_number',
                    label: 'Day number',
                    returnDataType: 'number'
                },
                {
                    name: 'month_name_day',
                    label: 'Month name and day',
                    returnDataType: 'text'
                },
                {
                    name: 'month_day',
                    label: 'Month number and day',
                    returnDataType: 'text'
                },
                {
                    name: 'day_name',
                    label: 'Day name',
                    returnDataType: 'text'
                },
                {
                    name: 'hour',
                    label: 'Hour',
                    returnDataType: 'number'
                },
                {
                    name: 'minute',
                    label: 'Minute',
                    returnDataType: 'number'
                },
                {
                    name: 'second',
                    label: 'Second',
                    returnDataType: 'number'
                }
            ],
            formatTypes: [
                {
                    name: 'year_month_number_day_number_slash',
                    label: 'year/month#/day',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'year_month_number_day_number_hyphen',
                    label: 'year-month#-day',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'month_name_day_number_year_space',
                    label: 'month day, year',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'year_month_number_day_number_time_hyphen',
                    label: 'year-month#-day time',
                    internal: false,
                    clientSide: false
                }
            ]
        },
        {
            name: 'boolean',
            defaultAggregate: 'count',
            aggregateTypes: [
                {
                    name: 'count',
                    label: 'Count',
                    returnDataType: 'number'
                },
                {
                    name: 'concatenate',
                    label: 'Concatenate',
                    returnDataType: 'text'
                }
            ],
            filterTypes: [
                {
                    name: 'eq',
                    label: 'Equals'
                },
                {
                    name: 'empty',
                    label: 'Empty'
                },
                {
                    name: '!empty',
                    label: 'Not empty'
                }
            ],
            functionTypes: [
                {
                    name: 'ifnull_false',
                    label: 'False if empty',
                    returnDataType: 'boolean'
                }
            ],
            formatTypes: [
                {
                    name: 'yes_no',
                    label: 'Yes/No',
                    internal: false,
                    clientSide: false
                },
                {
                    name: 'true_false',
                    label: 'True/False',
                    internal: false,
                    clientSide: false
                }
            ]
        }
    ]
};
