import { TextField, IconButton, Typography, createStyles, Button, Dialog, InputLabel } from '@material-ui/core';
import { XIcon } from '@blamelesshq/blameless-components';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { BLANK_BOARD } from 'src/constants/dashboard';
import { addBoard } from 'src/actions/dashboard/dashboard-page-actions';

const messages = defineMessages({
    newBoard: {
        id: 'dashboard.modal.addBlankBoard.newBoard',
        defaultMessage: 'Create New Board',
        description: 'Reliability Insights add blank board modal tile'
    },
    boardName: {
        id: 'dashboard.modal.addBoard.boardName',
        defaultMessage: 'Board Name',
        description: 'Reliability Insights add board input label'
    },
    cancel: {
        id: 'dashboard.modal.addBoard.cancel',
        defaultMessage: 'cancel',
        description: 'Reliability Insights cancel button label'
    },
    create: {
        id: 'dashboard.modal.addBoard.create',
        defaultMessage: 'create',
        description: 'Reliability Insights create button label'
    }
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.colors.ui.grey1,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            height: '456px',
            [theme.breakpoints.up('xs')]: {
                width: '300px'
            },
            [theme.breakpoints.up('sm')]: {
                width: '564px'
            }
        },
        modalContent: {
            flexGrow: 1,
            padding: '16px',
            '& label': {
                color: theme.colors.ui.grey3,
                fontSize: '1.2rem',
                marginBottom: '5px',
                '& > span': {
                    color: theme.colors.semantic.error
                }
            }
        },
        modalHeader: {
            background: theme.colors.ui.grey5,
            color: theme.colors.ui.grey1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '56px',
            padding: '0 16px'
        },
        modalFooter: {
            borderTop: `1px solid ${theme.colors.ui.grey5}`,
            flexShrink: 0,
            height: '64px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        closeButton: {
            width: '24px',
            height: '24px',
            '&:hover': {
                background: 'none'
            }
        }
    })
);

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}
const AddBlankBoardModal = ({ isOpen, onClose }: IProps) => {
    const { formatMessage } = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [newBoardName, setNewBoardName] = useState('');

    const handleCloseModal = () => {
        onClose();
        setNewBoardName('');
    };
    const handleAddBoard = () => {
        dispatch(addBoard({ ...BLANK_BOARD, name: newBoardName }));
        onClose();
        setNewBoardName('');
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseModal} classes={{ paper: classes.root }} data-cy="create-new-board">
            <div className={classes.modalHeader}>
                <Typography variant="h6">{formatMessage(messages.newBoard)}</Typography>
                <IconButton className={classes.closeButton} onClick={handleCloseModal}>
                    <XIcon />
                </IconButton>
            </div>
            <div className={classes.modalContent}>
                <InputLabel required>{formatMessage(messages.boardName)}</InputLabel>
                <TextField
                    data-cy="new-board-input"
                    onChange={(event) => setNewBoardName(event.target.value)}
                    value={newBoardName}
                    id="name"
                    variant="outlined"
                    fullWidth
                    autoFocus
                />
            </div>
            <div className={classes.modalFooter}>
                <Button
                    data-cy="cancel-new-board-button"
                    onClick={handleCloseModal}
                    variant="contained"
                    color="secondary"
                >
                    {formatMessage(messages.cancel)}
                </Button>
                <Button
                    data-cy="create-new-board-button"
                    disabled={!newBoardName}
                    variant="contained"
                    color="primary"
                    onClick={handleAddBoard}
                >
                    {formatMessage(messages.create)}
                </Button>
            </div>
        </Dialog>
    );
};

export default AddBlankBoardModal;
