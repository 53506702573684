import {
    IRandomDateTime,
    generateRandomIntInRange,
    generateRandomDecimalInRange,
    getRandomString
} from 'src/components/dashboard/demo-data/utils';

// Utils
const generateTotalParticipantRows = (dateRows: IRandomDateTime[]) => {
    return new Array(Math.round(dateRows.length / 2)).fill(null).map(() => {
        return {
            'Duration Of Customer Impact': generateRandomIntInRange(1, 50),
            'Incident #': generateRandomIntInRange(1, 1000),
            'Incident Type': getRandomString(['Customer Incident', 'Security', 'Triage', 'Unplanned']),
            'Participant Hours': generateRandomDecimalInRange(0, 100).toString(),
            'Severity Label': getRandomString(['SEV1', 'SEV2', 'SEV3', 'SEV4']),
            'Team Size': generateRandomIntInRange(1, 10)
        };
    });
};

const generateTotalParticipantGroupedRows = (data) => {
    const groupedData = data.reduce((acc, row) => {
        const incidentType = row['Incident Type'];
        const sevLabel = row['Severity Label'];
        const key = `${incidentType}__${sevLabel}`;

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(row);

        return acc;
    }, {});

    return Object.keys(groupedData).map((key) => {
        return {
            ['Incident #']: groupedData[key].length,
            ['Incident Type']: groupedData[key][0]['Incident Type'],
            ['Severity Label']: groupedData[key][0]['Severity Label'],
            ['Participant Hours']: groupedData[key][0]['Participant Hours'],
            ['Team Size']: groupedData[key].reduce((acc, row) => acc + row['Team Size'], 0),
            ['Duration Of Customer Impact']: groupedData[key].reduce(
                (acc, row) => acc + row['Duration Of Customer Impact'],
                0
            )
        };
    });
};

const generateGroupedRows = (data: any[], field: string) => {
    return data.reduce((acc, row) => {
        const key = row[field];

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(row);

        return acc;
    }, {});
};
// End Utils

const getHoursInIncidentsBoardTiles = (dateRows: IRandomDateTime[]) => {
    // ---Total Participant Hours / Total Participant Hours - By Incident Type---
    const generatedTotalParticipantHoursByIncidentTypeRows = generateTotalParticipantRows(dateRows);
    const totalParticipantHoursByIncidentTypeRows = generateTotalParticipantGroupedRows(
        generatedTotalParticipantHoursByIncidentTypeRows
    );

    // ---Top Participants In Incidents - By Hours---
    const topParticipantRows = dateRows.map((date) => {
        return {
            'Incident Created': `${date.date}`,
            'Incident Duration': generateRandomDecimalInRange(0, 100),
            'Incident Team Name': getRandomString(['Nicholas Mason', 'Matthew Dodge', 'Kim Serrano', 'Paul Chu'])
        };
    });
    const topParticipantRowsGrouped = generateGroupedRows(topParticipantRows, 'Incident Team Name');
    const topParticipantGroupedRows = Object.keys(topParticipantRowsGrouped).map((key) => {
        return {
            ['Incident Created']: topParticipantRowsGrouped[key].reduce((acc, row) => {
                return acc ? `${acc}, ${row['Incident Created']}` : `${row['Incident Created']}`;
            }, ''),
            ['Incident Duration']: topParticipantRowsGrouped[key]
                .reduce((acc, row) => acc + row['Incident Duration'], 0)
                .toFixed(2),
            ['Incident Team Name']: topParticipantRowsGrouped[key][0]['Incident Team Name']
        };
    });

    // --- Participant Hours Vs Incidents Count---
    const participantHoursVsIncidentsCountRows = dateRows.map((date) => {
        return {
            'Incident Created': `${date.date}`,
            'Participant Hours': generateRandomDecimalInRange(0, 100),
            'Incident #': generateRandomIntInRange(1, 1000)
        };
    });
    const participantHoursVsIncidentsRowsGrouped = generateGroupedRows(
        participantHoursVsIncidentsCountRows,
        'Incident Created'
    );
    const participantHoursVsIncidentsGroupedRows = Object.keys(participantHoursVsIncidentsRowsGrouped).map((key) => {
        return {
            ['Incident Created']: participantHoursVsIncidentsRowsGrouped[key][0]['Incident Created'],
            ['Participant Hours']: participantHoursVsIncidentsRowsGrouped[key]
                .reduce((acc, row) => acc + row['Participant Hours'], 0)
                .toFixed(2),
            ['Incident #']: participantHoursVsIncidentsRowsGrouped[key].length
        };
    });

    return {
        '5dbcc924af029e00015321fc': {
            headers: [
                'Team Size',
                'Incident Type',
                'Severity Label',
                'Incident #',
                'Duration Of Customer Impact',
                'Participant Hours'
            ],
            dataTypes: ['number', 'text', 'text', 'number', 'number', 'number'],
            rows: totalParticipantHoursByIncidentTypeRows
        },
        '5dc0ac8def65b40001bd5297': {
            headers: [
                'Team Size',
                'Incident Type',
                'Severity Label',
                'Incident #',
                'Duration Of Customer Impact',
                'Participant Hours'
            ],
            dataTypes: ['number', 'text', 'text', 'number', 'number', 'number'],
            rows: totalParticipantHoursByIncidentTypeRows
        },
        '5dc0b2c1af029e00015321ff': {
            headers: ['Incident Created', 'Incident Team Name', 'Incident Duration'],
            dataTypes: ['text', 'text', 'number'],
            rows: topParticipantGroupedRows
        },
        '5dc0c25282edb9a40dc3aaaa': {
            headers: ['Incident Created', 'Participant Hours', 'Incident #'],
            dataTypes: ['text', 'number', 'number'],
            rows: participantHoursVsIncidentsGroupedRows
        }
    };
};

export default getHoursInIncidentsBoardTiles;
