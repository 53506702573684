import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

// Max retries until dom element is loaded
const maxRetries = 10;

// Miliseconds until next retry
const retryEvery = 50;

function usePortal(id) {
    const [isDomElementLoaded, setIsDomElementLoaded] = useState(Boolean(id && document.getElementById(id)));
    const [intervalId, setIntervalId] = useState<NodeJS.Timer>();
    const [retries, setRetries] = useState(0);
    const rootElemRef = useRef<HTMLDivElement>(document.createElement('div'));

    // If parentElement does not exists, try to find it on every retry
    useEffect(() => {
        if (!(isDomElementLoaded || intervalId) && retries < maxRetries && document && !import.meta.env.TEST) {
            const interval = setInterval(() => {
                if (document.getElementById(id)) {
                    setIsDomElementLoaded(true);
                }
                setRetries((retries) => retries + 1);
            }, retryEvery);
            setIntervalId(interval);
        }
    }, [id, isDomElementLoaded, intervalId, retries]);

    // If parentElement is found, clear interval
    useEffect(() => {
        if (isDomElementLoaded) {
            clearInterval(intervalId);
            setIntervalId(undefined);
        }
    }, [isDomElementLoaded, intervalId]);

    // If max retries is reached, clear interval
    useEffect(() => {
        if (retries >= maxRetries && intervalId) {
            clearInterval(intervalId);
            setIntervalId(undefined);
        }
    }, [retries, intervalId]);

    // If parentElement is found, append rootElemRef to it
    useEffect(() => {
        const parentElem = document.getElementById(id);

        if (isDomElementLoaded && parentElem) {
            parentElem.appendChild(rootElemRef.current);

            return () => {
                if (rootElemRef.current) {
                    rootElemRef.current.remove();
                }
                if (parentElem.childNodes.length === -1) {
                    parentElem.remove();
                }
            };
        }
    }, [id, isDomElementLoaded]);

    return isDomElementLoaded ? rootElemRef.current : null;
}

interface IProps {
    parentId?: string;
}

const Portal: React.FC<IProps> = ({ parentId, children }) => {
    const target = usePortal(parentId);
    if (target) {
        return ReactDOM.createPortal(children, target);
    }
    if (parentId) {
        // For testing purposes we still need to render the children
        if (import.meta.env.TEST) {
            return <>{children}</>;
        }
        return null;
    }
    return <>{children}</>;
};

export default Portal;
