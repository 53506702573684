import { INTEGRATIONS } from 'src/constants/settings';

export const MIN_AGGREGATION_PERIOD_VALUE = 60;
export const MAX_AGGREGATION_PERIOD_VALUE = 600;

export const SLI_MENU_ID = {
    AVAILABILITY: 1,
    LATENCY: 2
};

export enum SLI_DATASOURCE {
    PROMETHEUS = 1,
    DATADOG = 2,
    APPDYNAMICS = 3,
    NEWRELIC = 4,
    BLAMELESSAPI = 5,
    PINGDOM = 6,
    SPLUNK = 7,
    SUMOLOGIC = 8
}

export enum SLI_TYPES {
    AVAILABILITY = 'Availability',
    AVAILABILITY_GOOD = 'Availability_good',
    AVAILABILITY_VALID = 'Availability_valid',
    LATENCY = 'Latency',
    THROUGHPUT = 'Throughput',
    SATURATION = 'Saturation',
    DURABILITY = 'Durability',
    CORRECTNESS = 'Correctness'
}

export const SLI_MENU: {
    AVAILABILITY: string;
    LATENCY: string;
    THROUGHPUT: string;
    SATURATION: string;
    DURABILITY: string;
    CORRECTNESS: string;
} = {
    AVAILABILITY: 'AVAILABILITY',
    LATENCY: 'LATENCY',
    THROUGHPUT: 'THROUGHPUT',
    SATURATION: 'SATURATION',
    DURABILITY: 'DURABILITY',
    CORRECTNESS: 'CORRECTNESS'
};

export enum METRIC_PATHS {
    LATENCY = 'metricPath.latency',
    SATURATION = 'metricPath.saturation',
    THROUGHPUT = 'metricPath.throughput',
    DURABILITY = 'metricPath.durability',
    AVAILABILITY_GOOD = 'metricPath.availability.good',
    AVAILABILITY_VALID = 'metricPath.availability.valid',
    CORRECTNESS = 'metricPath.correctness'
}

export enum SLO_STATUS {
    DEVELOPMENT = 1,
    TESTING = 2,
    ACTIVE = 3
}

export default {
    SLI_TYPES,
    SLO_STATUS
};

export const DATASOURCES = [
    INTEGRATIONS.APPDYNAMICS,
    INTEGRATIONS.DATADOG,
    INTEGRATIONS.PROMETHEUS,
    'New Relic',
    'Blameless API'
];

export enum SLI_BACKFILLING_INGEST_STATUS {
    ERROR = 'Error',
    SUCCESS = 'Success'
}

export enum SLI_INGEST_TYPE {
    BACKFILL = 'Backfill',
    REGULAR = 'Regular'
}

export const WIZARD_STEPS_ID = {
    USER_JOURNEY: 0,
    SLI: 1,
    SLO: 2
};

export const WIZARD_QUERY_PARAMS = {
    USER_JOURNEY: 'userJourneyId',
    SLI: 'sliId',
    SLO: 'sloId'
};

export const SERVICES_PER_PAGE = 1000;

export const SLIS_PER_PAGE = 1000;

export const SLOS_PER_PAGE = 1000;

export const ERROR_BUDGET_THRESHOLDS_PER_PAGE = 1000;

export const WIZARD_ACTION_CONTAINER = 'wizard-action-container';

export const WIZARD_BANNER_CONTAINER = 'wizard-banner-container';

export const WIZARD_BUTTONS_CONTAINER = 'wizard-buttons-container';
export const WIZARD_SAVE_BUTTON = 'wizard-save-button';
export enum ViewMode {
    card = 'CARD',
    table = 'TABLE'
}

export const SLOS_STORED_VIEW_MODE = 'SLOS_STORED_VIEW_MODE';

export const SLO_TABLE_HEADERS = {
    SLO_ID: 'sloId',
    SLO_NAME: 'sloName',
    SLI_TYPE: 'sliType',
    TARGET_SLO: 'targetSlo',
    CURRENT_SLO: 'currentSlo',
    SLO_STATUS: 'sloStatus',
    CURRENT_EB: 'currentEB',
    BURN_RATE: 'burnRate',
    DEPLETED_IN: 'depletedIn'
};

export const SLOS_STORED_DAY_RANGES = 'SLOS_STORED_DAY_RANGES';

export const SLO_STATS_KEYS = {
    DEPLETED: 'depleted',
    INACTIVE: 'inactive',
    VALID_SLOS_FOR_DAY_RANGES: 'validSlosForDayRanges'
};

export const SLO_DEFAULT_DAY_RANGES = [20, 5];
export const SLO_DAY_RANGE_MARKS = [5, 10, 15, 20];
export const SLO_DAY_RANGE_MAX = 28;
export const SLO_DAY_RANGE_MIN = 1;

export const USER_JOURNEY_QUERY_PARAMS = {
    SEARCH: 'search'
};

export const ERROR_BUDGET_THRESHOLDS_TABLE = {
    TYPE: 'type',
    THRESHOLD: 'threshold',
    ACTIVE_NOTIFICATIONS: 'active_notifications',
    NUMBER_OF_NOTIFICATIONS: 'number_of_notifications'
};

export const THRESHOLD_TYPE = {
    PERCENTAGE: 'percentage',
    SECONDS: 'seconds'
};
export enum SLI_EXAMPLE_METRICS {
    LATENCY = 'avg:gcp.loadbalancing.https.backend_latencies.avg{*}',
    SATURATION = 'max(rabbitmq_node_mem_used{namespace="blameless"} / rabbitmq_node_mem_limit{namespace="blameless"})*100',
    THROUGHPUT = 'round(sum(irate(nginx_ingress_controller_requests{ingress=~"blameless"}[1m])) by (ingress), 1)',
    DURABILITY = 'round(sum(irate(nginx_ingress_controller_requests{ingress=~"ticketmaster"}[1m])) by (ingress), 1)',
    AVAILABILITY_GOOD = '(sum:gcp.loadbalancing.https.backend_request_count{response_code:200})',
    AVAILABILITY_VALID_P1 = '(sum:gcp.loadbalancing.https.backend_request_count{response_code:200}',
    AVAILABILITY_VALID_P2 = 'sum:gcp.loadbalancing.https.backend_request_count{response_code:500})',
    CORRECTNESS = ''
}

export const SLI_INGEST_LOGS_TABLE_HEADERS = {
    CREATED_AT: 'createdAt',
    TYPE: 'type',
    TYPE_ID: 'typeId',
    STATUS: 'status',
    STATUS_ID: 'statusId',
    START: 'start',
    END: 'end',
    ERROR_MESSAGE: 'errorMessage'
};

export const SLI_INGEST_LOG_ROWS_PER_PAGE = 10;

export const SLI_INGEST_LOG_PREFETCH_PAGES = 10;
