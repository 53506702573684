export enum INTEGRATIONS {
    APPDYNAMICS = 'AppDynamics',
    AUTOTASK = 'AutoTask',
    DATADOG = 'Datadog',
    GITHUB = 'GitHub',
    GOOGLE_CALENDAR = 'Google Calendar',
    JIRA = 'Jira',
    OPSGENIE = 'Opsgenie',
    BACKSTAGE = 'Backstage',
    PAGERDUTY = 'PagerDuty',
    PINGDOM = 'Pingdom',
    PROMETHEUS = 'Prometheus',
    SERVICENOW = 'ServiceNow',
    SLACK = 'Slack',
    MSTEAMS = 'Microsoft Teams',
    MSTEAMS_MEETING = 'Microsoft Teams Video',
    STATUSPAGE = 'Statuspage',
    ZOOM = 'Zoom',
    GOTOMEETING = 'GoToMeeting',
    NEWRELIC = 'NewRelic'
}

export const SETTINGS_INTEGRATIONS: {
    APPDYNAMICS: string;
    AUTOTASK: string;
    DATADOG: string;
    GITHUB: string;
    GOOGLE_CALENDAR: string;
    JIRA: string;
    OPSGENIE: string;
    PAGERDUTY: string;
    BACKSTAGE: string;
    PINGDOM: string;
    PROMETHEUS: string;
    SERVICENOW: string;
    SLACK: string;
    MSTEAMS: string;
    MSTEAMS_MEETING: string;
    STATUSPAGE: string;
    ZOOM: string;
    GOTOMEETING: string;
    NEWRELIC: string;
    SPLUNK: string;
    SUMOLOGIC: string;
} = {
    APPDYNAMICS: 'org.integration.appdynamics',
    AUTOTASK: 'org.integration.autotask',
    DATADOG: 'org.integration.datadog',
    GITHUB: 'org.integrations.github',
    GOOGLE_CALENDAR: 'org.integration.google.calendar',
    JIRA: 'org.integration.jira',
    OPSGENIE: 'org.integration.opsgenie',
    PAGERDUTY: 'org.integration.pagerduty',
    BACKSTAGE: 'org.integration.backstage',
    PINGDOM: 'org.integration.pingdom',
    PROMETHEUS: 'org.integration.prometheus',
    SERVICENOW: 'org.integration.service-now',
    SLACK: 'org.integration.slack',
    MSTEAMS: 'org.integration.msteams',
    MSTEAMS_MEETING: 'org.integration.msteams-meeting',
    STATUSPAGE: 'org.integration.statuspage',
    ZOOM: 'org.integration.zoom',
    GOTOMEETING: 'org.integration.gotomeeting',
    NEWRELIC: 'org.integration.newrelic',
    SPLUNK: 'org.integration.splunk',
    SUMOLOGIC: 'org.integration.sumologic'
};

export const SETTINGS_SECTIONS: {
    INCIDENT: string;
    INTEGRATIONS: string;
    ORGANIZATION_PROFILE: string;
    SECURITY: string;
    USER_PROFILE: string;
    WEBHOOKS: string;
    SSO: string;
} = {
    INCIDENT: 'incident',
    INTEGRATIONS: 'integrations',
    ORGANIZATION_PROFILE: 'org-profile',
    SECURITY: 'security',
    USER_PROFILE: 'user-profile',
    WEBHOOKS: 'webhooks',
    SSO: 'sso'
};

export const JIRA_DYNAMIC_FIELD = 'org.integration.jira-incident-required-fields';

export const SEVERITY_SCOPE_TYPE = 'incident-type-severity';

export const INCIDENT_ROLES = 'org.incident.roles';

export const settingsElements: {
    TEXT_FIELD: string;
    INPUT: string;
    CODE_EDITOR: string;
    NUMERIC_INPUT: string;
    AUTOCOMPLETE: string;
    INPUT_AUTOCOMPLETE: string;
    TEXT_ENCRYPTED: string;
    DATE_PICKER: string;
    DATE: string;
    MULTISELECT: string;
    DROPDOWN: string;
    DROPDOWN_SEARCH: string;
    CHECKLIST: string;
    TOGGLE: string;
    TEXT_LIST: string;
    MODAL: string;
    INCIDENT_CHECKLIST: string;
    MARKDOWN_EDITOR: string;
    MENTION_INPUT: string;
} = {
    TEXT_FIELD: 'textinput',
    INPUT: 'input',
    CODE_EDITOR: 'codeEditor',
    NUMERIC_INPUT: 'numericinput',
    AUTOCOMPLETE: 'textinput-autocomplete',
    INPUT_AUTOCOMPLETE: 'input-autocomplete',
    TEXT_ENCRYPTED: 'textinput-encrypted',
    DATE_PICKER: 'date-picker',
    DATE: 'date',
    MULTISELECT: 'multiselect',
    DROPDOWN: 'dropdown',
    DROPDOWN_SEARCH: 'dropdown-search',
    CHECKLIST: 'checklist',
    TOGGLE: 'toggle',
    TEXT_LIST: 'textlist',
    MODAL: 'modal',
    INCIDENT_CHECKLIST: 'custom-element:incident-checklist',
    MARKDOWN_EDITOR: 'custom-element:markdown-editor',
    MENTION_INPUT: 'custom-element:mention-input'
};

export const settingsEntityScopeTypes: { INCIDENT_TYPE_SEVERITY: string } = {
    INCIDENT_TYPE_SEVERITY: 'incident-type-severity'
};

export const settingsTypes: { SINGLE: string; GROUP: string } = {
    SINGLE: 'single',
    GROUP: 'group'
};

export const settingsDisplayTypes: { MODAL: string } = {
    MODAL: 'modal'
};

export const incidentChecklistModes: { INCIDENT_CHECKLIST: string; SETTINGS_TEMPLATE: string } = {
    INCIDENT_CHECKLIST: 'INCIDENT_CHECKLIST',
    SETTINGS_TEMPLATE: 'SETTINGS_TEMPLATE'
};

export const RECURRING_OPTIONS_MENU_1 = 'RECURRING_OPTIONS_MENU_1';
export const RECURRING_OPTIONS_MENU_2 = 'RECURRING_OPTIONS_MENU_2';
export const LINK_OPTIONS_MENU = 'LINK_OPTIONS_MENU';

export const SELECT_GROUP_MODAL_STEP_1 = 'EXISTING_GROUP';
export const SELECT_GROUP_MODAL_STEP_2 = 'NEW_GROUP';

export const MENU_KEYS = {
    ORGANIZATION: 'organization',
    USER_PREFERENCES: 'user-preferences',
    INCIDENT_WORKFLOWS: 'incident-workflows',
    INCIDENT_TYPES: 'incident-types',
    INCIDENT_ASSISTANT: 'incident-assistant',
    POSTMORTEM_PREFERENCES: 'retrospective-preferences',
    EMAILS_REMINDERS: 'emails&reminders',
    CUSTOM_TAB: 'custom-tabs',
    BOT: 'bot',
    INTEGRATIONS: 'integrations',
    STORAGE: 'storage',
    GENERATIVE_AI: 'generative-ai',
    MESSAGING: 'messaging',
    TICKETING: 'ticketing',
    VIDEO_CONFERENCING: 'video-conferencing',
    ALERTING: 'alerting',
    SERVICE_CATALOG: 'service-catalog',
    METRICS: 'metrics',
    NOTIFICATIONS: 'notifications',
    SECURITY: 'security',
    TAGS: 'tags',
    TOKEN_MANAGEMENT: 'token-management',
    IAM: 'iam',
    SSO: 'sso',
    EMAIL_DOMAINS: 'emails-domains',
    RETROSPECTIVE_EXPORT: 'retrospective-export',
    COMMUNICATION: 'communication',
    COMMS_ASSISTANT: 'comms-assistant'
};

export const SETTINGS_BASE_PATH = '/settings';

export const OPTIONS = {
    DEFAULT: 'options',
    REQUIRED: 'options.required',
    FREQUENCY: 'options.frequency',
    LINK: 'options.link'
};

export const FIELD_NAME = {
    DESCRIPTION: 'description',
    OWNER: 'owner',
    COMPLETE: 'complete'
};

export const POPOVER = {
    SET_FREQUENCY: 'setFrequency',
    ADD_LINK_TO_TASK: 'addLinkToTask',
    NAME: 'moreTaskActionsPopover'
};

export const SETTINGS_ACTIONS = {
    READ: 'read'
};

export const SCOPE_SECTIONS = {
    ORGANIZATION: 'org.organization-name',
    TIMEZONE: 'timezone',
    INCIDENT: 'org.incident',
    POSTMORTEM: 'org.postmortem',
    BOT: 'org.bot',
    INTEGRATION: 'org.integration',
    COMMENTS: 'org.comments',
    SECURITY: 'org.security',
    LOCALE: 'locale',
    WEBHOOKS: 'org.webhooks',
    CHATBOT: 'org.chatbot',
    TAGS: 'org.tags',
    IAM: 'org.iam'
};

export const SECTION_PATH = {
    'org.organization-name': '/settings/organization',
    'org.incident': '/settings/incident-workflows',
    timezone: '/settings/user-preferences',
    'org.integration': '/settings/integrations',
    'org.bot': '/settings/bot',
    'org.postmortem': '/settings/retrospective-preferences',
    'org.comments': '/settings/notifications',
    'org.security': '/settings/security',
    'org.webhooks': '/settings/webhooks',
    'org.tags': '/settings/tags',
    'org.iam': '/settings/iam'
};

export const AUTH_METHOD_OPTION_VALUES = {
    BASIC: 'basic',
    OAUTH1: 'oauth-1',
    OAUTH2: 'oauth-2',
    OAUTH2CLOUD: 'oauth-2-cloud'
};

export const SELF_TRIAL_INCIDENT_TYPE = 'TUTORIAL';

export const JSON_PAYLOAD_VARIABLES = [
    {
        name: '{{incident.id}}',
        title: 'Incident ID',
        category: 'incident'
    },
    {
        name: '{{incident.title}}',
        title: 'Current Incident Title',
        category: 'incident'
    },
    {
        name: '{{incident.description}}',
        title: 'Current Incident Description',
        category: 'incident'
    },
    {
        name: '{{incident.severity}}',
        title: 'Current Incident Severity',
        category: 'incident'
    },
    {
        name: '{{incident.severity.label}}',
        title: 'Current Incident Severity Label',
        category: 'incident'
    },
    {
        name: '{{incident.channelUrl}}',
        title: 'Current Incident Channel URL',
        category: 'incident'
    },
    {
        name: '{{incident.tags}}',
        title: 'Current Incident Tags and Categories',
        category: 'incident'
    },
    {
        name: '{{incident.url}}',
        title: 'Current Incident Link',
        category: 'incident'
    },
    {
        name: '{{incident.created}}',
        title: 'Current Incident Creation Date',
        category: 'incident'
    },
    {
        name: '{{incident.updated}}',
        title: 'Current Incident Update Date',
        category: 'incident'
    },
    {
        name: '{{incident.status}}',
        title: 'Current Incident Status',
        category: 'incident'
    },
    {
        name: '{{incident.type}}',
        title: 'Current Incident Type',
        category: 'incident'
    },
    {
        name: '{{incident.customerImpactStartDate}}',
        title: 'Current Incident Customer Impact Start Date',
        category: 'incident'
    },
    {
        name: '{{incident.incidentImpactStartDate}}',
        title: 'Current Incident Impact Start Date',
        category: 'incident'
    },
    {
        name: '{{incident.videoConferenceLink}}',
        title: 'Incident video conference link',
        category: 'incident'
    },
    {
        name: '{{incident.commanderName}}',
        title: 'Incident Commander name',
        category: 'incident'
    },
    {
        name: '{{incident.tasks.uncompleted.summary}}',
        title: 'Summary of uncompleted tasks',
        category: 'incident'
    },
    {
        name: '{{incident.ticketLinks}}',
        title: 'Incident Ticket Links',
        category: 'incident'
    },

    {
        name: '{{incident.previous.status}}',
        title: 'Previous Incident Status',
        category: 'incident'
    },
    {
        name: '{{incident.previous.severity}}',
        title: 'Previous Incident Severity',
        category: 'incident'
    },
    {
        name: '{{incident.previous.severity.label}}',
        title: 'Previous Incident Severity Label',
        category: 'incident'
    },
    {
        name: '{{retrospective.url}}',
        title: 'Current Retrospective Link',
        category: 'retrospective'
    },
    {
        name: '{{retrospective.state}}',
        title: 'Current Retrospective State',
        category: 'retrospective'
    },
    {
        name: '{{retrospective.summary}}',
        title: 'Current Retrospective Summary',
        category: 'retrospective'
    }
];

export const GPT_MODELS = {
    GPT_3_5_TURBO: 'gpt-3.5-turbo',
    GPT_4: 'gpt-4'
};
