import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { Switch, Route } from 'react-router-dom';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import SectionURLs from 'src/constants/urls';

// Components
const ErrorNotFound = lazy(() => import('src/components/ui/error-not-found/error-not-found'));
const UserJourneysPage = lazy(() => import('src/components/slo/user-journey/user-journeys-page'));
const UserJourneyDetailsPage = lazy(
    () => import('src/components/slo/user-journey/user-journey-details-page/user-journey-details-page')
);

type launchDarkly = {
    sloPreviewFeature: boolean;
};

const UserJourneyRoutes = () => {
    const sloPreviewFeatureFlag = useSelector(
        (state: RootState) => (state.launchDarkly as unknown as launchDarkly).sloPreviewFeature
    );
    return (
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                {!sloPreviewFeatureFlag && <Route component={ErrorNotFound} />}
                <Route
                    exact
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.USER_JOURNEY_URL}`}
                    component={UserJourneysPage}
                />
                <Route
                    path={`${SectionURLs.SLO_MANAGE_URL}${SectionURLs.USER_JOURNEY_URL}/:id`}
                    component={UserJourneyDetailsPage}
                />
            </Switch>
        </Suspense>
    );
};

export default UserJourneyRoutes;
