import {
    createComponent,
    createProduct,
    createService,
    getComponentTypes,
    getComponents,
    getProducts,
    getServiceTypes,
    getServices,
    removeComponent,
    removeProduct,
    removeService,
    updateComponent,
    updateProduct,
    updateService
} from 'src/actions/catalog/catalog-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    saveStatus: string;
    productLoadStatus: string;
    serviceLoadStatus: string;
    componentLoadStatus: string;
    serviceTypeLoadStatus: string;
    componentTypeLoadStatus: string;
    slis: any[];
    services: any[];
    serviceTypes: any[];
    products: any[];
    components: any[];
    componentTypes: any[];
    error: string | null;
    productCount: number;
    componentCount: number;
    serviceCount: number;
}

const defaultState: IDefaultState = {
    saveStatus: LoadStatus.EMPTY,
    productLoadStatus: LoadStatus.EMPTY,
    serviceLoadStatus: LoadStatus.EMPTY,
    componentLoadStatus: LoadStatus.EMPTY,
    serviceTypeLoadStatus: LoadStatus.EMPTY,
    componentTypeLoadStatus: LoadStatus.EMPTY,
    slis: [],
    services: [],
    serviceTypes: [],
    components: [],
    componentTypes: [],
    products: [],
    error: null,
    productCount: 0,
    componentCount: 0,
    serviceCount: 0
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case removeComponent.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case removeComponent.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case removeComponent.error.getType():
                draft.error = action.payload.response;
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case removeProduct.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case removeProduct.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case removeProduct.error.getType():
                draft.error = action.payload.response;
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case removeService.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case removeService.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case removeService.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case createService.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case createService.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case createService.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case createComponent.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case createComponent.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case createComponent.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case updateService.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case updateService.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case updateService.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case updateComponent.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case updateComponent.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case updateComponent.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case updateProduct.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case updateProduct.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case updateProduct.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case createProduct.request.getType():
                draft.saveStatus = LoadStatus.REQUEST;
                return;
            case createProduct.ok.getType():
                draft.services = action.payload.response.services;
                draft.components = action.payload.response.services;
                draft.products = action.payload.response.services;
                draft.saveStatus = LoadStatus.OK;
                return;
            case createProduct.error.getType():
                draft.saveStatus = LoadStatus.ERROR;
                return;
            case getComponents.request.getType():
                draft.componentLoadStatus = LoadStatus.REQUEST;
                return;
            case getComponents.ok.getType():
                draft.components = action.payload.response.components;
                draft.componentCount = action.payload.response.pagination.count;
                draft.componentLoadStatus = LoadStatus.OK;
                return;
            case getComponents.error.getType():
                draft.componentLoadStatus = LoadStatus.ERROR;
                return;
            case getProducts.request.getType():
                draft.productLoadStatus = LoadStatus.REQUEST;
                return;
            case getProducts.ok.getType():
                draft.products = action.payload.response.products;
                draft.productCount = action.payload.response.pagination.count;
                draft.productLoadStatus = LoadStatus.OK;
                return;
            case getProducts.error.getType():
                draft.productLoadStatus = LoadStatus.ERROR;
                return;
            case getComponentTypes.request.getType():
                draft.componentTypeLoadStatus = LoadStatus.REQUEST;
                return;
            case getComponentTypes.ok.getType():
                draft.componentTypes = action.payload.response.componentTypes;
                draft.componentTypeLoadStatus = LoadStatus.OK;
                return;
            case getComponentTypes.error.getType():
                draft.componentTypeLoadStatus = LoadStatus.ERROR;
                return;
            case getServiceTypes.request.getType():
                draft.serviceTypeLoadStatus = LoadStatus.REQUEST;
                return;
            case getServiceTypes.ok.getType():
                draft.serviceTypes = action.payload.response.serviceTypes;
                draft.serviceTypeLoadStatus = LoadStatus.OK;
                return;
            case getServiceTypes.error.getType():
                draft.serviceTypeLoadStatus = LoadStatus.ERROR;
                return;
            case getServices.request.getType():
                draft.serviceLoadStatus = LoadStatus.REQUEST;
                return;
            case getServices.ok.getType():
                draft.services = action.payload.response.services;
                draft.serviceCount = action.payload.response.pagination.count;
                draft.serviceLoadStatus = LoadStatus.OK;
                return;
            case getServices.error.getType():
                draft.serviceLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
