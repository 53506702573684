export interface IRandomDateTime {
    date: string;
    time: string;
}

export const generateRandomDatesInRange = (range: string[]): IRandomDateTime[] => {
    const today = new Date();
    const [fromValue, fromUnit, toValue, toUnit] = range;
    const startDate = new Date();
    const endDate = new Date();

    switch (fromUnit) {
        case 'day':
            startDate.setDate(today.getDate() - parseInt(fromValue));
            break;
        case 'week':
            startDate.setDate(today.getDate() - parseInt(fromValue) * 7);
            break;
        case 'month':
            startDate.setMonth(today.getMonth() - parseInt(fromValue));
            break;
        case 'year':
            startDate.setFullYear(today.getFullYear() - parseInt(fromValue));
            break;
        default:
            throw new Error(`Invalid unit: ${fromUnit}`);
    }

    switch (toUnit) {
        case 'day':
            endDate.setDate(today.getDate() - parseInt(toValue));
            break;
        case 'week':
            endDate.setDate(today.getDate() - parseInt(toValue) * 7);
            break;
        case 'month':
            endDate.setMonth(today.getMonth() - parseInt(toValue));
            break;
        case 'year':
            endDate.setFullYear(today.getFullYear() - parseInt(toValue));
            break;
        default:
            throw new Error(`Invalid unit: ${toUnit}`);
    }

    const startTime = startDate.getTime();
    const endTime = endDate.getTime();
    let rangeInMonths =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
    rangeInMonths = Math.max(rangeInMonths, 1);
    const count = rangeInMonths * 15;
    const dates: IRandomDateTime[] = [];

    for (let i = 0; i < count; i++) {
        const randomTime = startTime + Math.random() * (endTime - startTime);
        const randomDate = new Date(randomTime);
        const year = randomDate.getFullYear();
        const month = String(randomDate.getMonth() + 1).padStart(2, '0');
        const day = String(randomDate.getDate()).padStart(2, '0');
        const hours = String(randomDate.getHours()).padStart(2, '0');
        const minutes = String(randomDate.getMinutes()).padStart(2, '0');
        const seconds = String(randomDate.getSeconds()).padStart(2, '0');
        const date = `${year}-${month}-${day}`;
        const time = `${hours}:${minutes}:${seconds}`;
        dates.push({ date, time });
    }

    dates.sort((a, b) => {
        const aDate = new Date(`${a.date} ${a.time}`);
        const bDate = new Date(`${b.date} ${b.time}`);
        return aDate.getTime() - bDate.getTime();
    });

    return dates;
};

export const filterDatesByRange = (
    dates: IRandomDateTime[],
    rangeString: 'last week' | 'last month' | 'last 3 months'
): IRandomDateTime[] => {
    return dates.filter((date) => {
        const d = new Date(`${date.date} ${date.time}`);
        const today = new Date();
        switch (rangeString) {
            case 'last week':
                return d > new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            case 'last month':
                return d > new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
            case 'last 3 months':
                return d > new Date(today.getFullYear(), today.getMonth() - 3, today.getDate());
            default:
                return false;
        }
    });
};

export const generateRandomIntInRange = (min = 1, max = 5): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateRandomDecimalInRange = (min = 1, max = 5): number => {
    const random = Math.random() * (max - min) + min;
    return Number(random.toFixed(1));
};

export const getRandomString = (names: string[]): string => {
    const randomIndex = Math.floor(Math.random() * names.length);
    return names[randomIndex];
};

// get max number
export const getMaxNumber = (numbers: number[]) => {
    return Math.max(...numbers);
};
