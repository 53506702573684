import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import LoadingPageIndicator from 'src/components/ui/loading-page-indicator/loading-page-indicator';
import IncidentDetailsLayoutLoading from 'src/components/incidents/incident-details-page/incident-details-layout-loading';
import { TAB_URL_DISPLAY } from 'src/constants/incident';

const IncidentsPage = lazy(() => import('src/components/incidents/incidents-page/incidents-page'));
const NewIncidentDetailsPage = lazy(
    () => import('src/components/incidents/incident-details-page/incident-details-page')
);

const IncidentsRoutes = () => (
    <>
        <Suspense fallback={<IncidentDetailsLayoutLoading />}>
            <Switch>
                <Redirect exact from="/incidents/:id" to={`/incidents/:id/${TAB_URL_DISPLAY.EVENTS_TAB}`} />
                <Route path={'/incidents/:id'} render={(props) => <NewIncidentDetailsPage {...props} />} />
            </Switch>
        </Suspense>
        <Suspense fallback={<LoadingPageIndicator />}>
            <Switch>
                <Route exact path="/incidents" component={IncidentsPage} />
            </Switch>
        </Suspense>
    </>
);
export default IncidentsRoutes;
