import {
    getErrorBudgetPolicyById,
    getErrorBudgetPolycies,
    clearErrorBudgetDatas,
    getErrorBudgetChartData,
    postNewErrorBudgetPolicy,
    getErrorBudgetDatasBySloId,
    updateExistingErrorBudgetPolicy,
    getErrorBudgetPolicyThresholdsByErrorBudgetPolicyId,
    getSLOsByErrorBudgetPolicyId,
    getManualMinutesBySloId,
    getCommentCountForErrorBudgetData,
    deleteErrorBudgetPolicy
} from 'src/actions/error-budget/error-budget-actions';
import { LoadStatus } from 'src/constants/load-status';
import { fromUnixTimeToDateGMT } from 'src/utils/date-time-util';
import produce from 'immer';

export type ErrorBudgetPolicyDetails = {
    loadPolicyStatus: string;
    policy: ErrorBudget.IErrorBudgetPolicy | null;

    loadThresholdsStatus: string;
    thresholds: ErrorBudget.IErrorBudgetPolicyThreshold[];

    loadSLOsStatus: string;
    SLOs: SLO.ISlo[];
};

interface IDefaultState {
    policy: ErrorBudget.IErrorBudgetPolicy | null;
    errorBudgetPolicies: ErrorBudget.IErrorBudgetPolicy[];
    errorBudgetDatas: ErrorBudget.IErrorBudgetData[];
    errorBudgetChartData: ErrorBudget.IChartData[];
    errorBudgetData: ErrorBudget.IErrorBudgetData | null;
    errorBudgetPoliciesMetadata: Common.IPaginationMetaData;
    errorBudgetDatasMetadata: Common.IPaginationMetaData;
    errorBudgetDatasLoadStatus: string;
    errorBudgetCommentPlotPoints: { resourceId: string; count: number }[];
    errorBudgetCommentQueryLoadStatus: string;
    removeErrorBudgetLoadStatus: string;
    postNewErrorBudgetPolicyLoadStatus: string;
    updateErrorBudgetPolicyLoadStatus: string;
    errorBudgetChartDataLoadStatus: string;
    errorBudgetPoliciesLoadStatus: string;
    errorBudgetPolicyDetails: ErrorBudgetPolicyDetails;
    manualMinutesBySloId: {
        [key: string]: ErrorBudget.ManualMinute[];
    };
}

const defaultPagination: Common.IPaginationMetaData = {
    limit: 0,
    offset: 0,
    total: 0,
    pageIndex: 0,
    rowsPerPage: 0,
    totalRows: 0,
    totalPages: 0,
    hasNextPage: false,
    hasPrevPage: false
};

const defaultState: IDefaultState = {
    policy: null,
    errorBudgetDatas: [],
    errorBudgetChartData: [],
    errorBudgetData: null,
    errorBudgetPolicies: [],
    errorBudgetCommentPlotPoints: [],
    errorBudgetPoliciesMetadata: { ...defaultPagination },
    errorBudgetDatasMetadata: { ...defaultPagination },
    errorBudgetDatasLoadStatus: LoadStatus.EMPTY,
    errorBudgetChartDataLoadStatus: LoadStatus.EMPTY,
    errorBudgetPoliciesLoadStatus: LoadStatus.EMPTY,
    postNewErrorBudgetPolicyLoadStatus: LoadStatus.EMPTY,
    updateErrorBudgetPolicyLoadStatus: LoadStatus.EMPTY,
    errorBudgetCommentQueryLoadStatus: LoadStatus.EMPTY,
    removeErrorBudgetLoadStatus: LoadStatus.EMPTY,
    errorBudgetPolicyDetails: {
        loadPolicyStatus: LoadStatus.EMPTY,
        policy: null,
        loadThresholdsStatus: LoadStatus.EMPTY,
        thresholds: [],
        loadSLOsStatus: LoadStatus.EMPTY,
        SLOs: []
    },
    manualMinutesBySloId: {}
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getManualMinutesBySloId.ok.getType():
                const { sloId, manualMinutes } = action.payload.response;
                draft.manualMinutesBySloId[sloId] = manualMinutes;
                return;
            case getErrorBudgetPolicyById.request.getType():
                draft.errorBudgetPolicyDetails.loadPolicyStatus = LoadStatus.REQUEST;
                // draft.errorBudgetPolicyDetails.policy = null;
                return;
            case getErrorBudgetPolicyById.ok.getType():
                draft.errorBudgetPolicyDetails.loadPolicyStatus = LoadStatus.OK;
                draft.errorBudgetPolicyDetails.policy = action.payload.response.errorBudgetPolicy;
                return;
            case getErrorBudgetPolicyById.error.getType():
                draft.errorBudgetPolicyDetails.loadPolicyStatus = LoadStatus.ERROR;
                draft.errorBudgetPolicyDetails.policy = null;
                return;
            case getErrorBudgetPolicyThresholdsByErrorBudgetPolicyId.request.getType():
                draft.errorBudgetPolicyDetails.loadThresholdsStatus = LoadStatus.REQUEST;
                // draft.errorBudgetPolicyDetails.thresholds = [];
                return;
            case getErrorBudgetPolicyThresholdsByErrorBudgetPolicyId.ok.getType():
                draft.errorBudgetPolicyDetails.loadThresholdsStatus = LoadStatus.OK;
                draft.errorBudgetPolicyDetails.thresholds = action.payload.response.errorBudgetPolicyThresholds;
                return;
            case getErrorBudgetPolicyThresholdsByErrorBudgetPolicyId.error.getType():
                draft.errorBudgetPolicyDetails.loadThresholdsStatus = LoadStatus.ERROR;
                draft.errorBudgetPolicyDetails.thresholds = [];
                return;
            case getSLOsByErrorBudgetPolicyId.request.getType():
                draft.errorBudgetPolicyDetails.loadSLOsStatus = LoadStatus.REQUEST;
                // draft.errorBudgetPolicyDetails.slos = [];
                return;
            case getSLOsByErrorBudgetPolicyId.ok.getType():
                draft.errorBudgetPolicyDetails.loadSLOsStatus = LoadStatus.OK;
                draft.errorBudgetPolicyDetails.SLOs = action.payload.response.slos;
                return;
            case getSLOsByErrorBudgetPolicyId.error.getType():
                draft.errorBudgetPolicyDetails.loadSLOsStatus = LoadStatus.ERROR;
                draft.errorBudgetPolicyDetails.SLOs = [];
                return;
            case getErrorBudgetPolycies.request.getType():
                draft.errorBudgetPoliciesLoadStatus = LoadStatus.REQUEST;
                return;
            case getErrorBudgetPolycies.ok.getType():
                draft.errorBudgetPolicies = action.payload.response.errorBudgetPolicies;
                draft.errorBudgetPoliciesMetadata = {
                    ...draft.errorBudgetPoliciesMetadata,
                    ...action.payload.response.metadata
                };
                draft.errorBudgetPoliciesLoadStatus = LoadStatus.OK;
                return;
            case getErrorBudgetPolycies.error.getType():
                draft.errorBudgetPoliciesLoadStatus = LoadStatus.ERROR;
                return;
            case postNewErrorBudgetPolicy.request.getType():
                draft.postNewErrorBudgetPolicyLoadStatus = LoadStatus.REQUEST;
                return;
            case postNewErrorBudgetPolicy.ok.getType():
                draft.postNewErrorBudgetPolicyLoadStatus = LoadStatus.OK;
                draft.errorBudgetPolicies.unshift(action.payload.response);
                return;
            case postNewErrorBudgetPolicy.error.getType():
                draft.postNewErrorBudgetPolicyLoadStatus = LoadStatus.ERROR;
                return;
            case updateExistingErrorBudgetPolicy.request.getType():
                draft.updateErrorBudgetPolicyLoadStatus = LoadStatus.REQUEST;
                return;
            case updateExistingErrorBudgetPolicy.ok.getType():
                draft.updateErrorBudgetPolicyLoadStatus = LoadStatus.OK;
                const updatedPolicy = action.payload.response;
                const policyIndex = draft.errorBudgetPolicies.findIndex((policy) => policy.id === updatedPolicy.id);
                if (policyIndex >= 0) {
                    draft.errorBudgetPolicies[policyIndex] = updatedPolicy;
                }
                return;
            case updateExistingErrorBudgetPolicy.error.getType():
                draft.updateErrorBudgetPolicyLoadStatus = LoadStatus.ERROR;
                return;
            case getErrorBudgetDatasBySloId.request.getType():
                draft.errorBudgetDatasLoadStatus = LoadStatus.REQUEST;
                return;
            case getErrorBudgetDatasBySloId.ok.getType():
                draft.errorBudgetDatas = draft.errorBudgetDatas.concat(action.payload.response.errorBudgetDatas);
                draft.errorBudgetDatasMetadata = {
                    ...draft.errorBudgetDatasMetadata,
                    ...action.payload.response.metadata
                };
                draft.errorBudgetDatasLoadStatus = LoadStatus.OK;
                return;
            case getErrorBudgetDatasBySloId.error.getType():
                draft.errorBudgetDatasLoadStatus = LoadStatus.ERROR;
                return;
            case getErrorBudgetChartData.request.getType():
                draft.errorBudgetChartDataLoadStatus = LoadStatus.REQUEST;
                return;
            case getErrorBudgetChartData.ok.getType():
                const chartData = action.payload.response.chartData.map((data: ErrorBudget.IChartData) => {
                    const date = fromUnixTimeToDateGMT(data.x);
                    return {
                        x: date,
                        y: data.y,
                        errorBudgetData: data.errorBudgetData
                    };
                });
                draft.errorBudgetChartData = chartData;
                draft.errorBudgetChartDataLoadStatus = LoadStatus.OK;
                return;
            case getErrorBudgetChartData.error.getType():
                draft.errorBudgetChartDataLoadStatus = LoadStatus.ERROR;
                return;
            case clearErrorBudgetDatas.getType():
                draft.errorBudgetDatas = [];
                draft.errorBudgetData = null;
                draft.errorBudgetChartData = [];
                draft.errorBudgetCommentPlotPoints = [];
                draft.errorBudgetDatasMetadata = { ...defaultPagination };
                draft.errorBudgetDatasLoadStatus = LoadStatus.EMPTY;
                return;
            case getCommentCountForErrorBudgetData.request.getType():
                draft.errorBudgetCommentQueryLoadStatus = LoadStatus.REQUEST;
                return;
            case getCommentCountForErrorBudgetData.error.getType():
                draft.errorBudgetCommentQueryLoadStatus = LoadStatus.ERROR;
                return;
            case getCommentCountForErrorBudgetData.ok.getType():
                draft.errorBudgetCommentPlotPoints = action.payload.response.result.rows;
                draft.errorBudgetCommentQueryLoadStatus = LoadStatus.OK;
                return;
            case deleteErrorBudgetPolicy.request.getType():
                draft.removeErrorBudgetLoadStatus = LoadStatus.REQUEST;
                return;
            case deleteErrorBudgetPolicy.ok.getType():
                draft.errorBudgetPolicies = draft.errorBudgetPolicies.filter(
                    (eb) => eb.id !== action.payload.response.id
                );
                draft.removeErrorBudgetLoadStatus = LoadStatus.OK;
                return;
            case deleteErrorBudgetPolicy.error.getType():
                draft.removeErrorBudgetLoadStatus = LoadStatus.ERROR;
                return;
        }
    });
}
