import { createSsoSettings, getSsoSettings, updateSsoSettings } from 'src/actions/sso/sso-actions';
import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';

interface IDefaultState {
    ssoSettings?: IAM.ISsoSettings;
    ssoLoadStatus: string;
}

export const defaultState: IDefaultState = {
    ssoSettings: undefined,
    ssoLoadStatus: LoadStatus.EMPTY
};

export default function (state: IDefaultState = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case createSsoSettings.request.getType():
            case getSsoSettings.request.getType():
            case updateSsoSettings.request.getType():
                draft.ssoLoadStatus = LoadStatus.REQUEST;
                return;
            case createSsoSettings.ok.getType():
            case getSsoSettings.ok.getType():
            case updateSsoSettings.ok.getType():
                draft.ssoLoadStatus = LoadStatus.OK;
                if (action?.payload?.response) {
                    draft.ssoSettings = action.payload.response;
                } else {
                    draft.ssoSettings = {
                        enabled: false,
                        signInEndpoint: '',
                        signingCert: '',
                        type: 'saml',
                        createdAt: undefined
                    };
                }
                return;
            case getSsoSettings.error.getType():
                draft.ssoLoadStatus = LoadStatus.ERROR;
                return;
            case createSsoSettings.error.getType():
            case updateSsoSettings.error.getType():
                draft.ssoLoadStatus = LoadStatus.OK;
                return;
        }
    });
}
