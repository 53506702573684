import {
    Grid,
    IconButton,
    InputBase,
    Link,
    List,
    ListItem,
    ListItemText,
    Theme,
    Typography,
    WithStyles,
    WithTheme,
    createStyles
} from '@material-ui/core';
import { LoadingIndicator, XIcon } from '@blamelesshq/blameless-components';
import React, { Component } from 'react';
import { clearSearchResults, searchIncidents, toggleSearchSidebar } from 'src/actions/search/search-actions';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { LoadStatus } from 'src/constants/load-status';
import { debounce } from 'lodash';

import { connect } from 'react-redux';
import history from 'src/history';
import withSentryErrorBoundary from 'src/components/hocs/sentryErrorBoundary';
import { COMMON_DEBOUNCE_TIME } from 'src/constants/common';

const styles = (theme: Theme) =>
    createStyles({
        closeWrapper: {
            width: '22px'
        },
        incidentsResultList: {
            borderTop: `1px solid ${theme.colors.ui.grey2}`,
            marginTop: '20px',
            width: 'inherit'
        },
        link: {
            color: theme.colors.ui.white,
            cursor: 'pointer'
        },
        root: {
            background: theme.colors.ui.grey1,
            color: theme.colors.ui.white,
            padding: '20px'
        },
        resultsHeader: {
            color: theme.colors.ui.grey3,
            textTransform: 'uppercase'
        },
        searchInput: {
            background: theme.colors.ui.white,
            marginTop: '30px',
            width: '100%'
        }
    });

interface IProps extends WithStyles<typeof styles>, WithTheme, IStateFromProps, IDispatchFromProps {}

interface IState {
    query: string;
}

class SearchPage extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            query: ''
        };
    }

    handleQueryChange = (e) => {
        const { onClearSearchResults, onSearchIncidents } = this.props;
        const { value } = e.target;
        this.setState({
            query: value
        });

        if (value === '') {
            onClearSearchResults();
        } else {
            debounce(() => {
                if (value.length > 0) {
                    onSearchIncidents(value);
                }
                // @ts-ignore
            }, COMMON_DEBOUNCE_TIME)(value);
        }
    };

    handleClickResult = (incident) => {
        const { onToggleSearchSidebar } = this.props;
        onToggleSearchSidebar();
        history.push(`/incidents/${incident._id}`);
    };

    renderSearchResults = () => {
        const { classes, searchStatus, searchResults } = this.props;
        const { query } = this.state;

        if (searchStatus === LoadStatus.REQUEST) {
            return <LoadingIndicator text="Searching" />;
        }

        return (
            <>
                {query.length > 0 && (
                    <List className={classes.incidentsResultList}>
                        <Typography className={classes.resultsHeader}>
                            {`Incidents (${searchResults.length})`}
                        </Typography>
                        {searchResults.map((incident) => (
                            <ListItem dense key={incident._id}>
                                <Link className={classes.link} onClick={() => this.handleClickResult(incident)}>
                                    <ListItemText primary={incident.description} />
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                )}
            </>
        );
    };

    render() {
        const { classes, theme, onToggleSearchSidebar } = this.props;
        const { query } = this.state;

        return (
            <div className={classes.root}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs>
                        <Typography variant="subtitle2">Search</Typography>
                    </Grid>
                    <div className={classes.closeWrapper}>
                        <IconButton>
                            <XIcon fillColor={theme.colors.icon.light} onClick={() => onToggleSearchSidebar()} />
                        </IconButton>
                    </div>
                </Grid>
                <Grid container>
                    <Grid item xs={12}>
                        <InputBase
                            autoFocus
                            classes={{
                                root: classes.searchInput
                            }}
                            value={query}
                            onChange={this.handleQueryChange}
                            placeholder="Search"
                            type="search"
                        />
                    </Grid>
                </Grid>
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    {this.renderSearchResults()}
                </Grid>
            </div>
        );
    }
}

interface IStateFromProps {
    searchStatus: string;
    searchResults: any[];
}

const mapStateToProps = ({ search }) => ({
    searchStatus: search.searchStatus,
    searchResults: search.searchResults
});

interface IDispatchFromProps {
    onClearSearchResults: () => void;
    onToggleSearchSidebar: () => void;
    onSearchIncidents: (query) => any[];
}

const mapDispatchToProps = (dispatch) => ({
    onClearSearchResults: () => dispatch(clearSearchResults()),
    onToggleSearchSidebar: () => dispatch(toggleSearchSidebar()),
    onSearchIncidents: (query) => dispatch(searchIncidents(query))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSentryErrorBoundary(withTheme(withStyles(styles)(SearchPage))));
