import { $api_identity_v2 } from 'src/utils/apis/v2';
import { createActionAsync } from 'redux-act-async';

export const getAllKeys = createActionAsync('GET_ALL_KEYS', () =>
    $api_identity_v2
        .get({ url: '/keys' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createKey = createActionAsync('CREATE_KEY', (keyName: string) =>
    $api_identity_v2
        .post({ url: '/keys', data: { name: keyName } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteKey = createActionAsync('DELETE_KEY', (keyId: string) =>
    $api_identity_v2
        .delete({ url: `/keys/${keyId}` })
        .then((res) => ({ ...res.data, keyId }))
        .catch((error) => {
            throw error;
        })
);

export const editKey = createActionAsync('EDIT_KEY', (keyId: string, keyName: string) =>
    $api_identity_v2
        .put({ url: `/keys/${keyId}`, data: { name: keyName } })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
