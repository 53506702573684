export const ADD_GROUP_MODAL = 'ADD_GROUP_MODAL';
export const EDIT_GROUP_MODAL = 'EDIT_GROUP_MODAL';
export const ASSIGN_USERS_MODAL = 'ASSIGN_USERS_MODAL';
export const ASSIGN_ROLES_MODAL = 'ASSIGN_ROLES_MODAL';
export const REMOVE_GROUP_MODAL = 'REMOVE_GROUP_MODAL';
export const SHOW_ASSIGNMENTS_MODAL = 'SHOW_ASSIGNMENTS_MODAL';
export const EDIT_USER_MODAL = 'EDIT_USER_MODAL';

export const UNAUTHORIZED_ERROR_STATUS = 403;

export const ROW_HEIGHT = 49;
export const ROWS_PER_PAGE_OPTIONS = 10;

export const KEY_MANAGEMENT_LOADING_SKELETONS_NUMBER = 10;
export const KEY_LIMIT = 10;
export const KEY_LENGTH_LIMIT = 100;

export const KEY_MANAGEMENT_TABLE_HEADERS = {
    KEY_ID: 'id',
    KEY_NAME: 'name',
    OBFUSCATED_KEY: 'obfuscatedKey',
    CREATED_AT: 'createdAt'
};

export const TABS = {
    GROUPS: 'groups',
    USERS: 'users',
    ROLES: 'roles',
    KEY_MANAGEMENT: 'key-management'
};

export const EMAIL_DOMAINS_LOADING_SKELETONS_NUMBER = 10;

export const EMAIL_DOMAIN_TABLE_HEADERS = {
    ID: 'id',
    DOMAIN: 'domain',
    ACTIVE: 'active',
    CREATED_AT: 'createdAt',
    UPDATED_AT: 'updatedAt'
};
