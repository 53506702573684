import { IRandomDateTime, generateRandomIntInRange, getRandomString } from 'src/components/dashboard/demo-data/utils';

// Utils
const generateRows = (dateRows: IRandomDateTime[], tagCategory: string, tagLabelOptions: string[]) => {
    return new Array(Math.round(dateRows.length / 2)).fill(null).map(() => {
        return {
            'Incident #': generateRandomIntInRange(1, 1000),
            'Incident Severity': generateRandomIntInRange(0, 3),
            'Tag Category': tagCategory,
            'Tag Label': getRandomString(tagLabelOptions),
            'Incident Created': [...dateRows]
                .splice(
                    generateRandomIntInRange(0, dateRows.length / 2 - 1),
                    generateRandomIntInRange(dateRows.length / 2, dateRows.length - 1)
                )
                .map((date) => `${date.date} ${date.time}`)[0]
        };
    });
};

const generateGroupedRows = (data, tagLabel, incidentSeverity) => {
    const groupedData = data.reduce((acc, row) => {
        const tag = row[tagLabel];
        const severity = row[incidentSeverity];
        const key = `${tag}__${severity}`;

        if (!acc[key]) {
            acc[key] = [];
        }

        acc[key].push(row);

        return acc;
    }, {});

    return Object.keys(groupedData).map((key) => {
        const [tag] = key.split('__');
        return {
            ['Incident #']: groupedData[key].length,
            ['Incident Severity']: groupedData[key][0][incidentSeverity],
            ['Tag Category']: groupedData[key][0]['Tag Category'],
            [tagLabel]: tag
        };
    });
};
// End Utils

const getTagCategoriesBoardTiles = (dateRows: IRandomDateTime[]) => {
    // ---Alert Source Rows---
    const generatedAlertSourceRows = generateRows(dateRows, 'Alert Source', [
        'Customer Submitted',
        'Pagerduty',
        'Slo Manager'
    ]);
    const tagCategorySeverityAlertSourceRows = generateGroupedRows(
        generatedAlertSourceRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownAlertSourceRows = generatedAlertSourceRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Contributing Factor Rows---
    const generatedContributingFactorRows = generateRows(dateRows, 'Contributing Factor', [
        '3Rd Party Outage',
        'Capital Letters',
        'AWS is down',
        'Friday Deploy'
    ]);
    const tagCategorySeverityContributingFactorRows = generateGroupedRows(
        generatedContributingFactorRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownContributingFactorRows = generatedContributingFactorRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Customer Rows---const generatedCustomerRows = customerRows();
    const generatedCustomerRows = generateRows(dateRows, 'Customer', [
        'Amazon',
        'Google',
        'Meta',
        'Salesforce',
        'All Customers',
        'Apple'
    ]);
    const tagCategorySeverityCustomerRows = generateGroupedRows(
        generatedCustomerRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownCustomerRows = generatedCustomerRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Group/Team Rows---
    const generatedGroupTeamRows = generateRows(dateRows, 'Group/Team', ['Backend', 'Frontend', 'SRE', 'All Teams']);
    const tagCategorySeverityGroupTeamRows = generateGroupedRows(
        generatedGroupTeamRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownGroupTeamRows = generatedGroupTeamRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Number of Impacted Users Rows---
    const generatedNumberOfImpactedUsersRows = generateRows(dateRows, 'Number of Impacted Users', [
        '0-100',
        '100-1000',
        '1000-10000',
        '10000+'
    ]);
    const tagCategorySeverityNumberOfImpactedUsersRows = generateGroupedRows(
        generatedNumberOfImpactedUsersRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownNumberOfImpactedUsersRows = generatedNumberOfImpactedUsersRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Owning Squad Rows---
    const generatedOwningSquadRows = generateRows(dateRows, 'Owning Squad', [
        'Squad A',
        'Squad B',
        'Squad C',
        'Squad D'
    ]);
    const tagCategorySeverityOwningSquadRows = generateGroupedRows(
        generatedOwningSquadRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownOwningSquadRows = generatedOwningSquadRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Product Rows---
    const generatedProductRows = generateRows(dateRows, 'Product', ['Blamelessbot', 'Login', 'Web UI', 'Pagerduty']);
    const tagCategorySeverityProductRows = generateGroupedRows(generatedProductRows, 'Tag Label', 'Incident Severity');
    const tagCategoryBreakdownProductRows = generatedProductRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Region Rows---
    const generatedRegionRows = generateRows(dateRows, 'Region', [
        'US',
        'EU',
        'APAC',
        'North',
        'South',
        'East',
        'West'
    ]);
    const tagCategorySeverityRegionRows = generateGroupedRows(generatedRegionRows, 'Tag Label', 'Incident Severity');
    const tagCategoryBreakdownRegionRows = generatedRegionRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Service Impacted Rows---
    const generatedServiceImpactedRows = generateRows(dateRows, 'Service Impacted', [
        'Showcase',
        'Resto',
        'Blamo',
        'Stargate',
        'Locko',
        'Identity Management'
    ]);
    const tagCategorySeverityServiceImpactedRows = generateGroupedRows(
        generatedServiceImpactedRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownServiceImpactedRows = generatedServiceImpactedRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Team Rows---
    const generatedTeamRows = generateRows(dateRows, 'Team', ['Noc', 'Security', 'Im']);
    const tagCategorySeverityTeamRows = generateGroupedRows(generatedTeamRows, 'Tag Label', 'Incident Severity');
    const tagCategoryBreakdownTeamRows = generatedTeamRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    // ---Was There a Workaround? Rows---
    const generatedWasThereAWorkaroundRows = generateRows(dateRows, 'Was There a Workaround?', ['Yes', 'No']);
    const tagCategorySeverityWasThereAWorkaroundRows = generateGroupedRows(
        generatedWasThereAWorkaroundRows,
        'Tag Label',
        'Incident Severity'
    );
    const tagCategoryBreakdownWasThereAWorkaroundRows = generatedWasThereAWorkaroundRows.map((row) => ({
        ['Incident #']: row['Incident #'],
        ['Incident Created']: row['Incident Created'],
        ['Tag Category']: row['Tag Category'],
        ['Tag Label']: `[${row['Tag Label']}]`
    }));

    return {
        'tag-category-severity-alert-source': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityAlertSourceRows
        },
        'tag-category-breakdown-alert-source': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownAlertSourceRows
        },
        'tag-category-severity-contributing-factor': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityContributingFactorRows
        },
        'tag-category-breakdown-contributing-factor': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownContributingFactorRows
        },
        'tag-category-severity-customer': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityCustomerRows
        },
        'tag-category-breakdown-customer': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownCustomerRows
        },
        'tag-category-severity-group/team': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityGroupTeamRows
        },
        'tag-category-breakdown-group/team': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownGroupTeamRows
        },
        'tag-category-severity-number-of-impacted-users': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityNumberOfImpactedUsersRows
        },
        'tag-category-breakdown-number-of-impacted-users': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownNumberOfImpactedUsersRows
        },
        'tag-category-severity-owning-squad': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityOwningSquadRows
        },
        'tag-category-breakdown-owning-squad': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownOwningSquadRows
        },
        'tag-category-severity-product': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityProductRows
        },
        'tag-category-breakdown-product': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownProductRows
        },
        'tag-category-severity-region': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityRegionRows
        },
        'tag-category-breakdown-region': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownRegionRows
        },
        'tag-category-severity-service-impacted': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityServiceImpactedRows
        },
        'tag-category-breakdown-service-impacted': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownServiceImpactedRows
        },
        'tag-category-severity-team': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityTeamRows
        },
        'tag-category-breakdown-team': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownTeamRows
        },
        'tag-category-severity-was-there-a-workaround?': {
            headers: ['Incident #', 'Tag Category', 'Tag Label', 'Incident Severity'],
            dataTypes: ['number', 'text', 'text', 'number'],
            rows: tagCategorySeverityWasThereAWorkaroundRows
        },
        'tag-category-breakdown-was-there-a-workaround?': {
            headers: ['Incident #', 'Incident Created', 'Tag Category', 'Tag Label'],
            dataTypes: ['number', 'datetime', 'text', 'text'],
            rows: tagCategoryBreakdownWasThereAWorkaroundRows
        }
    };
};

export default getTagCategoriesBoardTiles;
