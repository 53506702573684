import { createActionAsync } from 'redux-act-async';
import { $api_ai_v2 } from 'src/utils/apis/v2';
import $api from 'src/utils/api-interface';

export const createIncidentBrief = createActionAsync('CREATE_INCIDENT_BRIEF', (payload) =>
    $api_ai_v2
        .post({ url: '/briefs', data: payload })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const updateIncidentBrief = createActionAsync('UPDATE_INCIDENT_BRIEF', (id, payload) =>
    $api_ai_v2
        .put({ url: `/briefs/${id}`, data: payload })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getIncidentBriefs = createActionAsync('GET_INCIDENT_BRIEFS', () =>
    $api_ai_v2
        .get({ url: '/briefs' })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getIncidentBrief = createActionAsync('GET_INCIDENT_BRIEF', (id) =>
    $api_ai_v2
        .get({ url: `/briefs/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const deleteIncidentBrief = createActionAsync('DELETE_INCIDENT_BRIEF', (id) =>
    $api_ai_v2
        .delete({ url: `/briefs/${id}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const upsertIncidentBriefChannels = createActionAsync('UPSERT_INCIDENT_BRIEF_CHANNELS', (id, payload) =>
    $api
        .put({ url: `/briefs/${id}/channels`, data: payload })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getIncidentBriefChannels = createActionAsync('GET_INCIDENT_BRIEF_CHANNELS', (id) =>
    $api
        .get({ url: `/briefs/${id}/channels` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
