import {
    setAuthProfile,
    showLoginErrorMessage,
    getBlamelessId,
    getAuthUserRoles,
    getAuthUserIntegrations,
    deactivateUser
} from 'src/actions/auth/auth-actions';

import { LoadStatus } from 'src/constants/load-status';

interface IDefaultState {
    loginStatus: string;
    logoutStatus: string;
    loginErrorMessage: string;
    logoutMessage: string;
    authProfile: Partial<{
        email: string;
        blamelessId: string;
        given_name: string;
        family_name: string;
        nickname: string;
        name: string;
        picture: string;
        locale: string;
        updated_at: string;
        email_verified: boolean;
        sub: string;
        userId: string;
    }>;
    userRoles: IUserPermissions.IUserRole[];
    getAuthUserRolesLoadStatus: string;
    userIntegrations: IAuth.IIntegration[];
    getAuthUserIntegrationsLoadStatus: string;
    getBlamelessIdLoadStatus: string;
    isDeactivatedUser: boolean;
}

export const defaultState: IDefaultState = {
    loginStatus: LoadStatus.EMPTY,
    logoutStatus: LoadStatus.EMPTY,
    loginErrorMessage: 'Error logging in',
    logoutMessage: 'You have been successfully logged out',
    authProfile: {},
    userRoles: [],
    userIntegrations: [],
    getAuthUserRolesLoadStatus: LoadStatus.EMPTY,
    getAuthUserIntegrationsLoadStatus: LoadStatus.EMPTY,
    getBlamelessIdLoadStatus: LoadStatus.EMPTY,
    isDeactivatedUser: false
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case showLoginErrorMessage.getType():
            return {
                ...state,
                loginStatus: LoadStatus.ERROR,
                loginErrorMessage: action.payload.errorDescription
            };
        case setAuthProfile.getType():
            const authProfile = action.payload;
            authProfile.userId = authProfile.sub.split('|')[1];
            return {
                ...state,
                authProfile
            };
        case getBlamelessId.ok.getType():
            return {
                ...state,
                authProfile: {
                    blamelessId: action.payload.response.response.id,
                    orgId: action.payload.response.response.orgId,
                    name: action.payload.response.response.name,
                    ...state.authProfile
                },
                getBlamelessIdLoadStatus: LoadStatus.OK
            };
        case getBlamelessId.request.getType():
            return {
                ...state,
                getBlamelessIdLoadStatus: LoadStatus.ERROR
            };
        case getBlamelessId.error.getType():
            return {
                ...state,
                getBlamelessIdLoadStatus: LoadStatus.ERROR
            };
        case getAuthUserRoles.request.getType():
            return {
                ...state,
                getAuthUserRolesLoadStatus: LoadStatus.REQUEST
            };
        case getAuthUserRoles.ok.getType():
            return {
                ...state,
                getAuthUserRolesLoadStatus: LoadStatus.OK,
                userRoles: action.payload.response.response.roles
            };
        case getAuthUserRoles.error.getType():
            return {
                ...state,
                getAuthUserRolesLoadStatus: LoadStatus.ERROR
            };
        case getAuthUserIntegrations.request.getType():
            return {
                ...state,
                getAuthUserIntegrationsLoadStatus: LoadStatus.REQUEST
            };
        case getAuthUserIntegrations.ok.getType():
            return {
                ...state,
                getAuthUserIntegrationsLoadStatus: LoadStatus.OK,
                userIntegrations: action.payload.response.response.integrations
            };
        case getAuthUserIntegrations.error.getType():
            return {
                ...state,
                getAuthUserIntegrationsLoadStatus: LoadStatus.ERROR
            };
        case deactivateUser.getType():
            return {
                ...state,
                isDeactivatedUser: true
            };
        default:
            return {
                ...state
            };
    }
}
