import {
    addGroup,
    addRoleCustom,
    assignRoles,
    assignUsers,
    editGroup,
    editUser,
    getGroupRoles,
    getGroupUsers,
    getGroups,
    getIntegrations,
    getOrgs,
    getRoleGroups,
    getRoleUsers,
    getRoles,
    getUserGroups,
    getUserIntegrations,
    getUserRoles,
    getUser,
    getUsers,
    getUsersIncludingDeleted,
    getUsersByIds,
    deleteUser,
    removeGroup,
    saveOnboarding,
    setGroupAsDefault,
    getTenantId,
    getEmailDomains,
    updateEmailDomain,
    addEmailDomain,
    getUserIncidentPermissions,
    getUserIdsByLegacyIds,
    clearUserIdsByLegacyIds
} from 'src/actions/identity-management/identity-management-actions';

import { LoadStatus } from 'src/constants/load-status';
import produce from 'immer';
import { deactivateUser } from 'src/actions/auth/auth-actions';

interface IDefaultState {
    orgs: IAuth.IOrganization[];
    getOrgsLoadStatus: string;
    groups: IAuth.IGroup[];
    getGroupsLoadStatus: string;
    addGroupLoadStatus: string;
    setGroupAsDefaultLoadStatus: string;
    editGroupLoadStatus: string;
    removeGroupLoadStatus: string;
    users: IAuth.IUser[];
    usersByIds: IQuery.IUser[];
    user: IAuth.IUser | null;
    getUserLoadStatus: string;
    getUsersLoadStatus: string;
    getUsersByIdsLoadStatus: string;
    deleteUserLoadStatus: string;
    assignUsersLoadStatus: string;
    editUserLoadStatus: string;
    roles: IUserPermissions.IUserRole[];
    getRolesLoadStatus: string;
    assignRolesLoadStatus: string;
    addRoleCustomLoadStatus: string;
    groupUsers: IAuth.IUser[];
    getGroupUsersLoadStatus: string;
    groupRoles: IAuth.IGroupRole[];
    getGroupRolesLoadStatus: string;
    userGroups: IAuth.IGroup[];
    getUserGroupsLoadStatus: string;
    userRoles: IUserPermissions.IUserRole[];
    getUserRolesLoadStatus: string;
    userIntegrations: IAuth.IIntegration[];
    getUserIntegrationsLoadStatus: string;
    roleGroups: IAuth.IRoleGroup[];
    getRoleGroupsLoadStatus: string;
    roleUsers: IAuth.IUser[];
    getRoleUsersLoadStatus: string;
    integrations: any[];
    getIntegrationsLoadStatus: string;
    tenantId: null | number;
    getTenantIdLoadStatus: string;
    getUsersIncludingDeletedLoadStatus: string;
    usersIncludingDeleted: IAuth.IUser[];
    getEmailDomainsLoadStatus: string;
    emailDomains: IAM.IEmailDomain[];
    addNewEmailDomainLoadStatus: string;
    userIncidentPermissions: { action: string; incidentId: number }[];
    userIncidentPermissionsLoadStatus: string;
    userIdsByLegacyIds: IAM.IIdByLegacyId[];
    getUserIdsByLegacyIdsLoadStatus: string;
}

export const defaultState: IDefaultState = {
    orgs: [],
    getOrgsLoadStatus: LoadStatus.EMPTY,
    groups: [],
    getGroupsLoadStatus: LoadStatus.EMPTY,
    addGroupLoadStatus: LoadStatus.EMPTY,
    setGroupAsDefaultLoadStatus: LoadStatus.EMPTY,
    editGroupLoadStatus: LoadStatus.EMPTY,
    removeGroupLoadStatus: LoadStatus.EMPTY,
    users: [],
    usersByIds: [],
    user: null,
    getUserLoadStatus: LoadStatus.EMPTY,
    getUsersLoadStatus: LoadStatus.EMPTY,
    getUsersByIdsLoadStatus: LoadStatus.EMPTY,
    deleteUserLoadStatus: LoadStatus.EMPTY,
    assignUsersLoadStatus: LoadStatus.EMPTY,
    editUserLoadStatus: LoadStatus.EMPTY,
    roles: [],
    getRolesLoadStatus: LoadStatus.EMPTY,
    assignRolesLoadStatus: LoadStatus.EMPTY,
    addRoleCustomLoadStatus: LoadStatus.EMPTY,
    groupUsers: [],
    getGroupUsersLoadStatus: LoadStatus.EMPTY,
    groupRoles: [],
    getGroupRolesLoadStatus: LoadStatus.EMPTY,
    userGroups: [],
    getUserGroupsLoadStatus: LoadStatus.EMPTY,
    userRoles: [],
    getUserRolesLoadStatus: LoadStatus.EMPTY,
    userIntegrations: [],
    getUserIntegrationsLoadStatus: LoadStatus.EMPTY,
    roleGroups: [],
    getRoleGroupsLoadStatus: LoadStatus.EMPTY,
    roleUsers: [],
    getRoleUsersLoadStatus: LoadStatus.EMPTY,
    integrations: [],
    getIntegrationsLoadStatus: LoadStatus.EMPTY,
    tenantId: null,
    getTenantIdLoadStatus: LoadStatus.EMPTY,
    getUsersIncludingDeletedLoadStatus: LoadStatus.EMPTY,
    usersIncludingDeleted: [],
    getEmailDomainsLoadStatus: LoadStatus.EMPTY,
    emailDomains: [],
    addNewEmailDomainLoadStatus: LoadStatus.EMPTY,
    userIncidentPermissions: [],
    userIncidentPermissionsLoadStatus: LoadStatus.EMPTY,
    userIdsByLegacyIds: [],
    getUserIdsByLegacyIdsLoadStatus: LoadStatus.EMPTY
};

export default function (state = defaultState, action: Common.IReducerAction) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getOrgs.request.getType():
                draft.getOrgsLoadStatus = LoadStatus.REQUEST;
                return;
            case getOrgs.ok.getType():
                draft.getOrgsLoadStatus = LoadStatus.OK;
                draft.orgs = action.payload.response.response.organizations;
                return;
            case getOrgs.error.getType():
                draft.getOrgsLoadStatus = LoadStatus.ERROR;
                return;
            case getGroups.request.getType():
                draft.getGroupsLoadStatus = LoadStatus.REQUEST;
                return;
            case getGroups.ok.getType():
                draft.getGroupsLoadStatus = LoadStatus.OK;
                draft.groups = action.payload.response.response.groups;
                return;
            case getGroups.error.getType():
                draft.getGroupsLoadStatus = LoadStatus.ERROR;
                return;
            case addGroup.request.getType():
                draft.addGroupLoadStatus = LoadStatus.REQUEST;
                return;
            case addGroup.ok.getType():
                draft.addGroupLoadStatus = LoadStatus.OK;
                draft.groups.push(action.payload.response.response);
                return;
            case addGroup.error.getType():
                draft.addGroupLoadStatus = LoadStatus.ERROR;
                return;
            case setGroupAsDefault.request.getType():
                draft.setGroupAsDefaultLoadStatus = LoadStatus.REQUEST;
                return;
            case setGroupAsDefault.ok.getType():
                draft.setGroupAsDefaultLoadStatus = LoadStatus.OK;
                draft.orgs = [action.payload.response.response];
                return;
            case setGroupAsDefault.error.getType():
                draft.setGroupAsDefaultLoadStatus = LoadStatus.ERROR;
                return;
            case editGroup.request.getType():
                draft.editGroupLoadStatus = LoadStatus.REQUEST;
                return;
            case editGroup.ok.getType():
                draft.editGroupLoadStatus = LoadStatus.OK;
                draft.groups = state.groups.map((group) => {
                    if (group.id === action.payload.response.response.id) {
                        return action.payload.response.response;
                    }
                    return group;
                });
                return;
            case editGroup.error.getType():
                draft.editGroupLoadStatus = LoadStatus.ERROR;
                return;
            case removeGroup.request.getType():
                draft.removeGroupLoadStatus = LoadStatus.REQUEST;
                return;
            case removeGroup.ok.getType():
                draft.removeGroupLoadStatus = LoadStatus.OK;
                draft.groups = state.groups.filter((group) => {
                    return group.id !== action.payload.response.response.id;
                });
                return;
            case deleteUser.error.getType():
                draft.deleteUserLoadStatus = LoadStatus.ERROR;
                return;
            case deleteUser.request.getType():
                draft.deleteUserLoadStatus = LoadStatus.REQUEST;
                return;
            case deleteUser.ok.getType():
                draft.deleteUserLoadStatus = LoadStatus.OK;
                draft.users = state.users.filter((user) => {
                    return user.id !== action.payload.response.response.id;
                });
                return;
            case getUsers.request.getType():
                draft.getUsersLoadStatus = LoadStatus.REQUEST;
                return;
            case getUsers.ok.getType():
                draft.getUsersLoadStatus = LoadStatus.OK;
                draft.users = action.payload.response.response.users;
                return;
            case getUsers.error.getType():
                draft.getUsersLoadStatus = LoadStatus.ERROR;
                return;
            case getUsersIncludingDeleted.request.getType():
                draft.getUsersIncludingDeletedLoadStatus = LoadStatus.REQUEST;
                return;
            case getUsersIncludingDeleted.ok.getType():
                draft.getUsersIncludingDeletedLoadStatus = LoadStatus.OK;
                draft.usersIncludingDeleted = action.payload.response.response.users;
                return;
            case getUsersIncludingDeleted.error.getType():
                draft.getUsersIncludingDeletedLoadStatus = LoadStatus.ERROR;
                return;
            case getUsersByIds.request.getType():
                draft.getUsersByIdsLoadStatus = LoadStatus.REQUEST;
                return;
            case getUsersByIds.ok.getType():
                draft.getUsersByIdsLoadStatus = LoadStatus.OK;
                draft.usersByIds = action.payload.response;
                return;
            case getUsersByIds.error.getType():
                draft.getUsersByIdsLoadStatus = LoadStatus.ERROR;
                return;
            case getUser.request.getType():
                draft.getUserLoadStatus = LoadStatus.REQUEST;
                return;
            case getUser.ok.getType():
                draft.getUserLoadStatus = LoadStatus.OK;
                draft.user = action.payload.response.response.user;
                return;
            case getUser.error.getType():
                draft.getUserLoadStatus = LoadStatus.ERROR;
                return;
            case assignUsers.request.getType():
                draft.assignUsersLoadStatus = LoadStatus.REQUEST;
                return;
            case assignUsers.ok.getType():
                draft.assignUsersLoadStatus = LoadStatus.OK;
                return;
            case assignUsers.error.getType():
                draft.assignUsersLoadStatus = LoadStatus.ERROR;
                return;
            case editUser.request.getType():
                draft.editUserLoadStatus = LoadStatus.REQUEST;
                return;
            case editUser.ok.getType():
                const updateUser = (user) => {
                    if (user.id === action.payload.request[0]) {
                        const { ...newUser } = action.payload.response.response;
                        return { ...user, ...newUser };
                    }
                    return user;
                };
                draft.editUserLoadStatus = LoadStatus.OK;
                draft.users = state.users.map(updateUser);
                draft.usersIncludingDeleted = state.usersIncludingDeleted.map(updateUser);
                return;
            case editUser.error.getType():
                draft.editUserLoadStatus = LoadStatus.ERROR;
                return;
            case getRoles.request.getType():
                draft.getRolesLoadStatus = LoadStatus.REQUEST;
                return;
            case getRoles.ok.getType():
                draft.getRolesLoadStatus = LoadStatus.OK;
                draft.roles = action.payload.response.response.roles;
                return;
            case getRoles.error.getType():
                draft.getRolesLoadStatus = LoadStatus.ERROR;
                return;
            case assignRoles.request.getType():
                draft.assignRolesLoadStatus = LoadStatus.REQUEST;
                return;
            case assignRoles.ok.getType():
                draft.assignRolesLoadStatus = LoadStatus.OK;
                return;
            case assignRoles.error.getType():
                draft.assignRolesLoadStatus = LoadStatus.ERROR;
                return;
            case addRoleCustom.request.getType():
                draft.addRoleCustomLoadStatus = LoadStatus.REQUEST;
                return;
            case addRoleCustom.ok.getType():
                draft.addRoleCustomLoadStatus = LoadStatus.OK;
                return;
            case addRoleCustom.error.getType():
                draft.addRoleCustomLoadStatus = LoadStatus.ERROR;
                return;
            case getGroupUsers.request.getType():
                draft.getGroupUsersLoadStatus = LoadStatus.REQUEST;
                return;
            case getGroupUsers.ok.getType():
                draft.getGroupUsersLoadStatus = LoadStatus.OK;
                draft.groupUsers = action.payload.response.response.users;
                return;
            case getGroupUsers.error.getType():
                draft.getGroupUsersLoadStatus = LoadStatus.ERROR;
                return;
            case getGroupRoles.request.getType():
                draft.getGroupRolesLoadStatus = LoadStatus.REQUEST;
                return;
            case getGroupRoles.ok.getType():
                draft.getGroupRolesLoadStatus = LoadStatus.OK;
                draft.groupRoles = action.payload.response.response.roles;
                return;
            case getGroupRoles.error.getType():
                draft.getGroupRolesLoadStatus = LoadStatus.ERROR;
                return;
            case getUserGroups.request.getType():
                draft.getUserGroupsLoadStatus = LoadStatus.REQUEST;
                return;
            case getUserGroups.ok.getType():
                draft.getUserGroupsLoadStatus = LoadStatus.OK;
                draft.userGroups = action.payload.response.response.groups;
                return;
            case getUserGroups.error.getType():
                draft.getUserGroupsLoadStatus = LoadStatus.ERROR;
                return;
            case getUserRoles.request.getType():
                draft.getUserRolesLoadStatus = LoadStatus.REQUEST;
                return;
            case getUserRoles.ok.getType():
                draft.getUserRolesLoadStatus = LoadStatus.OK;
                draft.userRoles = action.payload.response.response.roles;
                return;
            case getUserRoles.error.getType():
                draft.getUserRolesLoadStatus = LoadStatus.ERROR;
                return;
            case getUserIntegrations.request.getType():
                draft.getUserIntegrationsLoadStatus = LoadStatus.REQUEST;
                return;
            case getUserIntegrations.ok.getType():
                draft.getUserIntegrationsLoadStatus = LoadStatus.OK;
                draft.userIntegrations = action.payload.response.response.integrations;
                return;
            case getUserIntegrations.error.getType():
                draft.getUserIntegrationsLoadStatus = LoadStatus.ERROR;
                return;
            case getRoleGroups.request.getType():
                draft.getRoleGroupsLoadStatus = LoadStatus.REQUEST;
                return;
            case getRoleGroups.ok.getType():
                draft.getRoleGroupsLoadStatus = LoadStatus.OK;
                draft.roleGroups = action.payload.response.response.groups;
                return;
            case getRoleGroups.error.getType():
                draft.getRoleGroupsLoadStatus = LoadStatus.ERROR;
                return;
            case getRoleUsers.request.getType():
                draft.getRoleUsersLoadStatus = LoadStatus.REQUEST;
                return;
            case getRoleUsers.ok.getType():
                draft.getRoleUsersLoadStatus = LoadStatus.OK;
                draft.roleUsers = action.payload.response.response.users;
                return;
            case getRoleUsers.error.getType():
                draft.getRoleUsersLoadStatus = LoadStatus.ERROR;
                return;
            case getIntegrations.request.getType():
                draft.getIntegrationsLoadStatus = LoadStatus.REQUEST;
                return;
            case getIntegrations.ok.getType():
                draft.getIntegrationsLoadStatus = LoadStatus.OK;
                draft.integrations = action.payload.response.response;
                return;
            case getIntegrations.error.getType():
                draft.getIntegrationsLoadStatus = LoadStatus.ERROR;
                return;
            case saveOnboarding.ok.getType():
                const orgs = [...draft.orgs];
                const newOrg = action.payload.response.response;
                const oldIndex = orgs.findIndex((org) => org.id === newOrg.id);
                if (oldIndex > -1) {
                    orgs.splice(oldIndex, 1, newOrg);
                }
                draft.orgs = orgs;
                return;
            case getTenantId.request.getType():
                draft.getTenantIdLoadStatus = LoadStatus.REQUEST;
                return;
            case getTenantId.ok.getType():
                draft.getTenantIdLoadStatus = LoadStatus.OK;
                draft.tenantId = action.payload.response.tenant_id;
                return;
            case getTenantId.error.getType():
                draft.getTenantIdLoadStatus = LoadStatus.ERROR;
                return;
            case deactivateUser.getType():
                draft.userRoles = defaultState.userRoles;
                draft.userIntegrations = defaultState.userIntegrations;
                return;
            case getEmailDomains.request.getType():
                draft.getEmailDomainsLoadStatus = LoadStatus.REQUEST;
                return;
            case getEmailDomains.ok.getType():
                draft.getEmailDomainsLoadStatus = LoadStatus.OK;
                draft.emailDomains = action.payload.response.emailDomains;
                return;
            case getEmailDomains.error.getType():
                draft.getEmailDomainsLoadStatus = LoadStatus.ERROR;
                return;
            case updateEmailDomain.ok.getType():
                const { id } = action.payload.response;
                draft.emailDomains = state.emailDomains.map((domain) => {
                    if (domain.id === id) {
                        return action.payload.response;
                    }
                    return domain;
                });
                return;
            case addEmailDomain.request.getType():
                draft.addNewEmailDomainLoadStatus = LoadStatus.REQUEST;
                return;
            case addEmailDomain.ok.getType():
                draft.addNewEmailDomainLoadStatus = LoadStatus.OK;
                draft.emailDomains = [...state.emailDomains, action.payload.response];
                return;
            case addEmailDomain.error.getType():
                draft.addNewEmailDomainLoadStatus = LoadStatus.OK;
                return;
            case getUserIncidentPermissions.request.getType():
                draft.userIncidentPermissionsLoadStatus = LoadStatus.REQUEST;
                return;
            case getUserIncidentPermissions.ok.getType():
                draft.userIncidentPermissionsLoadStatus = LoadStatus.OK;
                draft.userIncidentPermissions = action.payload.response;
                return;
            case getUserIncidentPermissions.error.getType():
                draft.userIncidentPermissionsLoadStatus = LoadStatus.ERROR;
                return;
            case getUserIdsByLegacyIds.request.getType():
                draft.getUserIdsByLegacyIdsLoadStatus = LoadStatus.REQUEST;
                return;
            case getUserIdsByLegacyIds.ok.getType():
                draft.getUserIdsByLegacyIdsLoadStatus = LoadStatus.OK;
                draft.userIdsByLegacyIds = action.payload.response;
                return;
            case getUserIdsByLegacyIds.error.getType():
                draft.getUserIdsByLegacyIdsLoadStatus = LoadStatus.ERROR;
                return;
            case clearUserIdsByLegacyIds.getType():
                draft.userIdsByLegacyIds = [];
                draft.getUserIdsByLegacyIdsLoadStatus = LoadStatus.EMPTY;
                return;
        }
    });
}
