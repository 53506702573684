import { createActionAsync } from 'redux-act-async';
import { PAGE_SIZE } from 'src/constants/common';
import $api from 'src/utils/api-interface';
import { enqueueSnackbar } from 'src/actions/notification/notification-actions';

export const getIncidentList = createActionAsync('GET_INCIDENT_LIST', (pageNum, orderBy, filtersQuery) =>
    $api
        .get({
            url: `/incidents?limit=${PAGE_SIZE}&offset=${PAGE_SIZE * pageNum}&order_by=${orderBy}${
                filtersQuery ? '&' + filtersQuery : ''
            }`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const getMoreIncidents = createActionAsync('GET_MORE_INCIDENTS', (pageNum, orderBy, filtersQuery) =>
    $api
        .get({
            url: `/incidents?limit=${PAGE_SIZE}&offset=${PAGE_SIZE * pageNum}&order_by=${orderBy}${
                filtersQuery ? '&' + filtersQuery : ''
            }`
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const createIncident = createActionAsync('CREATE_INCIDENT', (data, dispatch) =>
    $api
        .post({ url: '/incidents', data })
        .then((res) => {
            const { ok, errorMsg } = res.data;
            if (ok) {
                dispatch(
                    enqueueSnackbar({
                        message: 'Incident creation success',
                        options: {
                            variant: 'success'
                        }
                    })
                );
                return res.data;
            }
            dispatch(
                enqueueSnackbar({
                    message: errorMsg,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw new Error(errorMsg);
        })
        .catch((error) => {
            dispatch(
                enqueueSnackbar({
                    message: `Incident creation failed: ${error.response.data.message}`,
                    options: {
                        variant: 'error'
                    }
                })
            );
            throw error;
        })
);

export const deleteIncident = createActionAsync('DELETE_INCIDENT', (incidentId) =>
    $api
        .delete({ url: `/incidents/${incidentId}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
