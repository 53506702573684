import $api from 'src/utils/api-interface';
import { createAction } from 'redux-act';
import { createActionAsync } from 'redux-act-async';

export const toggleSearchSidebar = createAction('TOGGLE_SEARCH_SIDEBAR');
export const clearSearchResults = createAction('CLEAR_SEARCH_RESULTS');

export const searchIncidents = createActionAsync('SEARCH_INCIDENTS', (search) =>
    $api
        .get({
            url: '/incidents',
            params: {
                search
            }
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export default searchIncidents;
