import $api from 'src/utils/api-interface';
import { createActionAsync } from 'redux-act-async';

export const getComments = createActionAsync('GET_COMMENTS', (componentRef: string) => {
    return $api
        .get({ url: `/comments/${componentRef}` })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const registerComponentRef = createActionAsync(
    'REGISTER_COMPONENT_REF',
    (componentRef: string, data: Record<string, any>) => {
        return $api
            .post({ url: `/comments/${componentRef}/register`, data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }
);

export const getMentionSuggestions = createActionAsync(
    'GET_MENTION_SUGGESTIONS',
    (substring: string, limitMentionToMembers: string[]) => {
        const data = {
            items: [
                {
                    formula: '[slack_user._id]'
                },
                {
                    formula: '[slack_user.profile_real_name]',
                    sort: 'ASC',
                    header: 'name',
                    filters: [{ operator: 'ct', values: [substring] }]
                },
                {
                    formula: '[slack_user.profile_image_32]',
                    header: 'avatar'
                },
                {
                    hidden: true,
                    formula: '[slack_user.is_bot]',
                    filters: [
                        {
                            operator: 'eq',
                            values: ['0']
                        }
                    ]
                }
            ],
            options: {
                page_number: 0,
                items_per_page: 20
            }
        };
        if (limitMentionToMembers.length > 0) {
            data.items[0].filters = [
                {
                    operator: 'in',
                    values: limitMentionToMembers
                }
            ];
        }
        return $api
            .post({ url: `/topics/slack/execute`, data })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }
);

export const deleteComment = createActionAsync(
    'DELETE_COMMENT',
    (commentId: number, componentRef: string, parentId: number | undefined) =>
        $api
            .delete({ url: `/comments/${commentId}` })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            })
);

export const putComment = createActionAsync('PUT_COMMENT', (commentId: number, data: Record<string, any>) =>
    $api
        .put({ url: `/comments/${commentId}`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);

export const resolveComment = createActionAsync('RESOLVE_COMMENT', (commentId: number, componentRef: string) => {
    const data = {};
    return $api
        .put({ url: `/comments/${commentId}/resolve`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
});

export const postComment = createActionAsync('POST_COMMENT', (data) =>
    $api
        .post({ url: `/comments`, data })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        })
);
