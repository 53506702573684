import { createAction } from 'redux-act';

export const enqueueSnackbar: any = createAction('ENQUEUE_SNACKBAR', (snackbars: Record<string, any>) => {
    return {
        key: new Date().getTime() + Math.random(),
        ...snackbars
    };
});

export const removeSnackbar = createAction('REMOVE_SNACKBAR');
