import { InMemoryCache } from '@apollo/client';

const blamelessCache = new InMemoryCache({
    typePolicies: {
        commsFlowDatagraphItem: {
            keyFields: false
        },
        commsFlowDatagraphAction: {
            keyFields: false
        },
        commsFlowDatagraphArguments: {
            keyFields: false
        },
        commsFlowIncident: {
            keyFields: ['id', 'tags']
        }
    }
});

export default blamelessCache;
