import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotificationIcon } from '@blamelesshq/blameless-components';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import { RootState } from 'src/store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        item: {
            '&:hover': {
                background: 'rgba(255,255,255,0.15)'
            },
            height: '48px'
        },
        navIcon: {
            position: 'relative',
            '& svg': {
                fontSize: '3.0rem',
                marginLeft: '-5px'
            }
        },
        navIconIndicator: {
            background: theme.colors.semantic.error,
            borderRadius: '100%',
            height: '9px',
            left: '12px',
            position: 'absolute',
            top: '5px',
            width: '9px'
        }
    })
);

interface IProps {
    onClick: () => void;
}

const NotificationIndicator = memo<IProps>(({ onClick }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [isIndicating, setIsIndicating] = useState(false);
    const integrationProbes = useSelector((state: RootState) => state.settings.integrationProbes);

    useEffect(() => {
        let indicating = false;
        integrationProbes.forEach((integration: IIntegration.IStatus) => {
            if (integration.enabled) {
                if (!integration.probe.healthy) {
                    indicating = true;
                }
                if (integration.probe.degradation.length > 0) {
                    indicating = true;
                }
            }
        });
        setIsIndicating(indicating);
    }, [integrationProbes]);

    return (
        <>
            {isIndicating && <div data-cy="notification-indicator" className={classes.navIconIndicator} />}
            <NotificationIcon onClick={onClick} fillColor={theme.colors.icon.light} data-cy="notification-button" />
        </>
    );
});

export default NotificationIndicator;
