import { generateRandomIntInRange, getRandomString } from 'src/components/dashboard/demo-data/utils';

const getIncidentBoardTiles = (dateRows) => {
    const incidentsBySeverityRows = () =>
        dateRows.map((date) => {
            return {
                'Incident #': generateRandomIntInRange(1, 5),
                'Incident Created': date?.date,
                'Severity Label': getRandomString(['SEV1', 'SEV2', 'SEV3', 'SEV4'])
            };
        });

    const incidentsByTypeRows = () =>
        dateRows.map((date) => {
            return {
                'Incident #': generateRandomIntInRange(1, 5),
                'Incident Created': date?.date,
                'Incident Type': getRandomString([
                    'Customer Incident',
                    'Cloud Operations',
                    'Triage',
                    'Default',
                    'Tutorial'
                ])
            };
        });

    const completedActionsByAssigneeRows = () =>
        new Array(10).fill(null).map(() => {
            return {
                Assignee: getRandomString(['Nick Mason', 'Travis Barker', 'Dave Grohl']),
                Completed: generateRandomIntInRange(1, 5),
                Created: dateRows[generateRandomIntInRange(0, dateRows.length - 1)]?.date,
                'Incident Type': getRandomString(['Customer Incident', 'Cloud Operations', 'Triage', 'Default'])
            };
        });

    const tagCountBySeverityRows = () =>
        dateRows.map((date) => {
            return {
                'Tag Label': generateRandomIntInRange(1, 25),
                'Incident Created': date?.date,
                'Severity Label': getRandomString(['SEV1', 'SEV2', 'SEV3', 'SEV4'])
            };
        });

    const numberOfOpenPostmortemsRows = () =>
        new Array(4).fill(null).map(() => {
            return {
                'Severity Label': getRandomString(['SEV1', 'SEV2', 'SEV3', 'SEV4']),
                'Retrospective Completed': getRandomString(['Yes', 'No']),
                'Incident #': generateRandomIntInRange(1, 25)
            };
        });

    const mostRecentActionsRows = () =>
        dateRows.map((date) => {
            return {
                'Action Created': `${date?.date} ${date?.time}`,
                'Action Assignee': getRandomString(['Nick Mason', 'Travis Barker', 'Dave Grohl']),
                'Action Summary': getRandomString([
                    'I need to add more monitoring',
                    'Follow up with Eng on design',
                    'Need to add more monitoring',
                    'Follow up with Eng'
                ]),
                'Action Jira Url': getRandomString([
                    'https://blameless.atlassian.net/browse/BPOC-358',
                    'https://google.com',
                    'https://blameless.atlassian.net/browse/BPOC-353',
                    'https://blamelessprod.service-now.com/problem_task.do?sys_id=null'
                ]),
                'Action Completed': getRandomString(['Yes', 'No'])
            };
        });

    const roleAssignmentsRows = () =>
        dateRows.map((date) => {
            return {
                'Incident #': generateRandomIntInRange(1, 5),
                'Role Name': getRandomString(['Commander', 'Communication Lead', 'Operations Lead']),
                'Incident Created': date?.date
            };
        });

    const mostParticipationAcrossIncidentsRows = () =>
        new Array(8).fill(null).map(() => {
            return {
                'Incident #': generateRandomIntInRange(1, 5),
                'Incident Created': [...dateRows]
                    .splice(
                        generateRandomIntInRange(0, dateRows.length / 2 - 1),
                        generateRandomIntInRange(dateRows.length / 2, dateRows.length - 1)
                    )
                    .map((date) => date?.date),
                'Event User Name': getRandomString(['Nick Mason', 'Travis Barker', 'Dave Grohl'])
            };
        });

    const mostRecentOpenPostmortemsRows = () =>
        dateRows.map((date) => {
            return {
                'Retrospective Complete': 0,
                'Retrospective State': getRandomString(['UNASSIGNED', 'ASSIGNED', 'APPROVED']),
                'Retrospective Summary': getRandomString([
                    'A web application that allows users to search for and book flights using React and TypeScript, with a backend API built using Node.js and Express.',
                    'A mobile app that helps users track their fitness goals using React Native and TypeScript, with a backend API built using Firebase.',
                    'A browser extension that enhances the functionality of popular social media platforms using JavaScript and React, with a build process managed using npm.',
                    'A real-time chat application that allows users to communicate with each other using React and TypeScript, with a backend API built using Socket.io and Node.js.',
                    'A web-based project management tool that allows teams to collaborate on tasks and projects using React and TypeScript, with a backend API built using Django and Python.'
                ]),
                'Incident Created': `${date?.date} ${date?.time}`,
                'Severity Label': getRandomString(['SEV1', 'SEV2', 'SEV3', 'SEV4'])
            };
        });

    const mostActiveUsersByEventTypeRows = () =>
        new Array(10).fill(null).map(() => {
            return {
                'Event Date': [...dateRows]
                    .splice(
                        generateRandomIntInRange(0, dateRows.length / 2 - 1),
                        generateRandomIntInRange(dateRows.length / 2, dateRows.length - 1)
                    )
                    .map((date) => date?.date),
                'Event Value': generateRandomIntInRange(1, 5),
                'Event Type': getRandomString(['Chat Highlight', 'Image Upload']),
                'Event User Name': getRandomString(['Nick Mason', 'Travis Barker', 'Dave Grohl'])
            };
        });

    const mostRecentTagsRows = () =>
        dateRows.map((date) => {
            return {
                'Tag Label': getRandomString([
                    'Facebook',
                    'Google',
                    'Disney',
                    'Dunder Miflin',
                    'Apac',
                    'Noc',
                    'Im',
                    '10000+',
                    'Emea',
                    'United States',
                    'Fox',
                    'Salesforce',
                    'Apple',
                    'Blamelessbot',
                    'Login',
                    'Front End',
                    'Sre',
                    'Pagerduty'
                ]),
                'Tag Category': getRandomString([
                    'Customer',
                    'Region',
                    'Team',
                    'Number Of Impacted Users',
                    'Product',
                    'Was There A Workaround?',
                    'Group/Team'
                ]),
                'Incident Created': `${date?.date} ${date?.time}`,
                'Severity Label': getRandomString(['SEV1', 'SEV2', 'SEV3', 'SEV4'])
            };
        });

    return {
        incidents_by_severity: {
            headers: ['Incident #', 'Incident Created', 'Severity Label'],
            dataTypes: ['number', 'text', 'text'],
            rows: incidentsBySeverityRows()
        },
        incidents_by_type: {
            headers: ['Incident #', 'Incident Created', 'Incident Type'],
            dataTypes: ['number', 'text', 'text'],
            rows: incidentsByTypeRows()
        },
        completed_actions_by_assignee: {
            headers: ['Assignee', 'Completed', 'Created', 'Incident Type'],
            dataTypes: ['text', 'number', 'text', 'text'],
            rows: completedActionsByAssigneeRows()
        },
        tag_count_by_severity: {
            headers: ['Tag Label', 'Incident Created', 'Severity Label'],
            dataTypes: ['number', 'text', 'text'],
            rows: tagCountBySeverityRows()
        },
        number_of_open_postmortems: {
            headers: ['Severity Label', 'Retrospective Completed', 'Incident #'],
            dataTypes: ['text', 'boolean', 'number'],
            rows: numberOfOpenPostmortemsRows()
        },
        most_recent_actions: {
            headers: ['Action Created', 'Action Assignee', 'Action Summary', 'Action Jira Url', 'Action Completed'],
            dataTypes: ['datetime', 'text', 'text', 'text', 'boolean'],
            rows: mostRecentActionsRows()
        },
        role_assignments: {
            headers: ['Incident #', 'Role Name', 'Incident Created'],
            dataTypes: ['number', 'text', 'text'],
            rows: roleAssignmentsRows()
        },
        most_participation_across_incidents: {
            headers: ['Incident #', 'Incident Created', 'Event User Name'],
            dataTypes: ['number', 'text', 'text'],
            rows: mostParticipationAcrossIncidentsRows()
        },
        most_recent_open_postmortems: {
            headers: [
                'Retrospective Complete',
                'Retrospective State',
                'Retrospective Summary',
                'Incident Created',
                'Severity Label'
            ],
            dataTypes: ['boolean', 'text', 'text', 'datetime', 'text'],
            rows: mostRecentOpenPostmortemsRows()
        },
        most_active_users_by_event_type: {
            headers: ['Event Date', 'Event Value', 'Event Type', 'Event User Name'],
            dataTypes: ['text', 'number', 'text', 'text'],
            rows: mostActiveUsersByEventTypeRows()
        },
        most_recent_tags: {
            headers: ['Tag Label', 'Tag Category', 'Incident Created', 'Severity Label'],
            dataTypes: ['text', 'text', 'datetime', 'text'],
            rows: mostRecentTagsRows()
        }
    };
};

export default getIncidentBoardTiles;
