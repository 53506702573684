import { LoadStatus } from 'src/constants/load-status';
import {
    getTagAutocompleteOptions,
    getPredefinedTags,
    getAllTags,
    getIncidentTags,
    getCategoryTagsStartingWith,
    appendIncidentTags
} from 'src/actions/tag/tag-actions';
import produce from 'immer';
import ITagForFilter = IIncident.ITagForFilter;

interface IDefaultState {
    tagsAutocompleteStatus: string;
    tagsAutocompleteOptions: Postmortem.ITag[];
    predefinedTagsLoadStatus: string;
    predefinedTags: Postmortem.IPredefinedTags;
    getAllTagsLoadStatus: string;
    allTags: IIncident.ITagForFilter[];
    lastAllTagsCount: number;
    commsFlowAllTags: IIncident.ITagForFilter[];
}

export const defaultState: IDefaultState = {
    tagsAutocompleteStatus: LoadStatus.EMPTY,
    tagsAutocompleteOptions: [],
    predefinedTagsLoadStatus: LoadStatus.EMPTY,
    predefinedTags: {},
    getAllTagsLoadStatus: LoadStatus.EMPTY,
    allTags: [],
    lastAllTagsCount: 0,
    commsFlowAllTags: []
};

/**
 * Converts new tagging system tags to the shape that is expected from the
 * old tagging system
 * @param newTags Array of new tags
 */
const convertNewTagsToOldTags = (newTags): ITagForFilter[] => {
    return newTags.map((tag) => {
        return {
            _id: `${tag.tag_id}-${tag.category_id}`,
            label: tag.tag_label,
            category: tag.category_name,
            tag_id: tag.tag_id,
            category_id: tag.category_id
        };
    });
};

export default function (state = defaultState, action) {
    return produce(state, (draft) => {
        switch (action.type) {
            case getTagAutocompleteOptions.request.getType():
                draft.tagsAutocompleteStatus = LoadStatus.REQUEST;
                return;
            case getTagAutocompleteOptions.ok.getType():
                draft.tagsAutocompleteOptions = action.payload.response.tags;
                draft.tagsAutocompleteStatus = LoadStatus.OK;
                return;
            case getTagAutocompleteOptions.error.getType():
                draft.tagsAutocompleteStatus = LoadStatus.ERROR;
                return;

            case getPredefinedTags.request.getType():
                draft.predefinedTagsLoadStatus = LoadStatus.REQUEST;
                return;
            case getPredefinedTags.ok.getType():
                draft.predefinedTags = action.payload.response.tagCategories;
                draft.predefinedTagsLoadStatus = LoadStatus.OK;
                return;
            case getPredefinedTags.error.getType():
                draft.predefinedTagsLoadStatus = LoadStatus.ERROR;
                return;

            case getAllTags.request.getType():
                draft.getAllTagsLoadStatus = LoadStatus.REQUEST;
                return;
            case getAllTags.ok.getType():
                if (action.payload.response.enableTagManagement) {
                    draft.allTags = action.payload.response.data.Tags;
                    // Added commsFlowAllTags for commsFlow since we are setting
                    // allTags in two different actions with different values and that brakes commsFlow
                    draft.commsFlowAllTags = action.payload.response.data.Tags;
                } else {
                    draft.allTags = action.payload.response.data.tags;
                    // Added commsFlowAllTags for commsFlow since we are setting
                    // allTags in two different actions with different values and that brakes commsFlow
                    draft.commsFlowAllTags = action.payload.response.data.tags;
                }
                draft.getAllTagsLoadStatus = LoadStatus.OK;
                return;
            case getAllTags.error.getType():
                draft.getAllTagsLoadStatus = LoadStatus.ERROR;
                return;

            case getIncidentTags.request.getType():
                draft.getAllTagsLoadStatus = LoadStatus.REQUEST;
                return;
            case getIncidentTags.ok.getType():
                const newTags = convertNewTagsToOldTags(action.payload.response);
                draft.allTags = newTags;
                draft.lastAllTagsCount = newTags.length;
                draft.getAllTagsLoadStatus = LoadStatus.OK;
                return;
            case getIncidentTags.error.getType():
                draft.getAllTagsLoadStatus = LoadStatus.ERROR;
                return;

            case appendIncidentTags.request.getType():
                draft.getAllTagsLoadStatus = LoadStatus.REQUEST;
                return;
            case appendIncidentTags.ok.getType():
                const appendTags = convertNewTagsToOldTags(action.payload.response);
                draft.allTags = [...draft.allTags, ...appendTags];
                draft.lastAllTagsCount = appendTags.length;
                draft.getAllTagsLoadStatus = LoadStatus.OK;
                return;
            case appendIncidentTags.error.getType():
                draft.getAllTagsLoadStatus = LoadStatus.ERROR;
                return;

            case getCategoryTagsStartingWith.request.getType():
                draft.tagsAutocompleteStatus = LoadStatus.REQUEST;
                return;
            case getCategoryTagsStartingWith.ok.getType():
                const tags = action.payload.response.tags.filter((tag) =>
                    tag.tag_label.startsWith(action.payload.response.tagLabelPrefix)
                );
                draft.tagsAutocompleteOptions = convertNewTagsToOldTags(tags);
                draft.tagsAutocompleteStatus = LoadStatus.OK;
                return;
            case getCategoryTagsStartingWith.error.getType():
                draft.tagsAutocompleteStatus = LoadStatus.ERROR;
                return;
        }
    });
}
