import React from 'react';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { DataLoadingIndicator } from '@blamelesshq/blameless-components';

const useStyles = makeStyles(() =>
    createStyles({
        layoutSkeleton: {
            display: 'flex'
        },
        headerSkeletonHeader: {
            margin: '8px 0'
        },
        headerSkeletonKpi: {
            padding: '16px',
            width: '100%'
        },
        mainSkeleton: {
            marginTop: '16px'
        }
    })
);

const IncidentDetailsLayoutLoading = () => {
    const classes = useStyles();
    return (
        <>
            <div className={classes.headerSkeletonHeader}>
                <Skeleton width={200} height={70} />
            </div>
            <Paper>
                <Grid container spacing={1} className={classes.headerSkeletonKpi}>
                    <DataLoadingIndicator gridWidth={2} skeletonColumns={5} skeletonRows={2} />
                </Grid>
            </Paper>
            <Grid container spacing={1} className={classes.mainSkeleton}>
                <Grid item xs={12} md={8}>
                    <Skeleton width="100%" height={300} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton width="100%" height={300} />
                </Grid>
            </Grid>
        </>
    );
};

export default IncidentDetailsLayoutLoading;
